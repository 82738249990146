import {
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  useClick,
} from '@floating-ui/react-dom-interactions';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect } from 'react';

export type AnimatedTooltipProps = {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  tooltip: React.ReactNode;
  placement?: Placement;
  preventTooltip?: boolean;
};

const AnimatedTooltip = ({
  children,
  tooltip,
  placement = 'top',
  preventTooltip = false,
}: AnimatedTooltipProps) => {
  const [open, setOpen] = React.useState(false);
  const { x, y, reference, floating, strategy, context, refs, update } =
    useFloating({
      placement,
      open,
      onOpenChange: setOpen,
      middleware: [offset(5), flip(), shift({ padding: 8 })],
    });

  useEffect(() => {
    if (preventTooltip && open) {
      setOpen(false);
    }
  }, [preventTooltip, open]);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  React.useEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [refs.reference, refs.floating, update, open]);

  return (
    <>
      {React.cloneElement(
        children,
        getReferenceProps({ ref: reference, ...children.props })
      )}
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              type: 'spring',
              damping: 20,
              stiffness: 300,
            }}
            {...getFloatingProps({
              ref: floating,
              className: 'Tooltip',
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
              },
            })}
          >
            {tooltip}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default AnimatedTooltip;
