import React from 'react';

const ScavengeSilver = ({ className }: { className?: string }) => {
  return (
    <img
      width={120}
      height={167}
      src="/assets/icons/ScavengeSilver.png"
      alt="Scavenge Silver"
      className={className}
    />
  );
};

export default ScavengeSilver;
