import React from 'react';
import { SearchIcon } from '@battlefly/components/Icons';

export enum SearchBarStyle {
  Blue,
  Transparent,
}

interface SearchBarInterface {
  placeholder: string;
  style: SearchBarStyle;
  containerClasses?: string;
  value: string;
  onChange: (value: string) => void;
}

const SearchBar: React.FC<SearchBarInterface> = (props) => {
  return (
    <div
      className={`relative flex h-[40px] max-w-full rounded-md border border-gray-600 bg-white ${props.containerClasses}`}
    >
      <button
        type="submit"
        className="absolute top-1/2 right-[25px] m-auto ml-2 translate-x-1/2 -translate-y-1/2"
      >
        <SearchIcon className="pointer h-6 w-6 fill-current text-gray-900" />
      </button>
      <div className="w-full">
        <input
          onChange={(e) => {
            props?.onChange(e.target.value);
          }}
          value={props.value}
          className="placeholder-grey-600 block h-full w-full bg-transparent pl-[8px] pr-[50px] text-base font-normal transition delay-100 ease-in-out focus:outline-none"
          type="search"
          name="search"
          placeholder={props.placeholder}
          style={{ color: 'black' }}
        />
      </div>
    </div>
  );
};

export default SearchBar;
