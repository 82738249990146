type BattleflyNameProps = {
  name: string;
  tokenId: string;
};

const BattleflyName = (props: BattleflyNameProps) => {
  const { name, tokenId } = props;

  return (
    <>
      <div className="hidden gap-2 sm:flex">
        <h1 className="text-xxl leading-10 text-core-cyberpink">{name}</h1>
        <span className="self-end font-mono ">#{tokenId}</span>
      </div>
      <div className="block sm:hidden">
        <div className="inline-block">
          <span className="float-right mb-1 font-mono">#{tokenId}</span>
          <br />
          <h1 className="mt-2 text-[70px] leading-[60px] text-core-cyberpink">
            {name}
          </h1>
        </div>
      </div>
    </>
  );
};

export default BattleflyName;
