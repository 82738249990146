import React from 'react';

const ExpandArrow: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    width="11"
    height="17"
    viewBox="0 0 11 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} inline-block`}
  >
    <path
      d="M10.4226 8.49069C10.4226 8.69069 10.3911 8.87819 10.3281 9.05319C10.2661 9.22819 10.1601 9.39069 10.0101 9.54069L3.07263 16.4782C2.79763 16.7532 2.45363 16.8842 2.04063 16.8712C1.62863 16.8592 1.28513 16.7157 1.01013 16.4407C0.73513 16.1657 0.597629 15.8157 0.597629 15.3907C0.597629 14.9657 0.73513 14.6157 1.01013 14.3407L6.86013 8.49069L0.972629 2.60319C0.697629 2.32819 0.566129 1.98419 0.578129 1.57119C0.591129 1.15919 0.735129 0.815691 1.01013 0.540691C1.28513 0.265692 1.63513 0.128189 2.06013 0.128189C2.48513 0.128189 2.83513 0.265692 3.11013 0.540691L10.0101 7.44069C10.1601 7.59069 10.2661 7.75319 10.3281 7.92819C10.3911 8.10319 10.4226 8.29069 10.4226 8.49069Z"
      fill="#405DAB"
    />
  </svg>
);

export default ExpandArrow;
