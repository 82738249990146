import Card from 'components/core/Card';
import HuntersMark from './HuntersMark';
import { HuntersMarkType } from '@battlefly/__generated__/globalTypes';

type HuntersMarkCardProps = {
  className?: string;
  type: HuntersMarkType;
};

const HuntersMarkCard = (props: HuntersMarkCardProps) => {
  const { type } = props;
  return (
    <Card
      square
      className="flex h-20 w-20 items-center justify-center !p-2 wxga+:h-24 wxga+:w-24"
    >
      <HuntersMark type={type} className="h-16 w-16 wxga+:h-20 wxga+:w-20" />
    </Card>
  );
};

export default HuntersMarkCard;
