import { gql } from '@apollo/client';

export const BATTLEFLY_RACER_DATA = gql`
  query BattleflyRacerData {
    me {
      id
      battleFlies {
        id
        dailyRaces {
          dailyRacesMaxAllowed
          dailyRacesUsed
          history {
            seed
            score
            createdAt
            droplets
          }
        }
      }
      wallets {
        nectar
        droplets
        bestRaces {
          droplets
          score
          createdAt
        }
      }
    }
    timer {
      secondsUntilNextReset
      nextResetDate
    }
  }
`;

export const END_RACE = gql`
  mutation EndRace($input: EndRaceArgs!) {
    endRace(input: $input) {
      race {
        droplets
      }
      battleFly {
        dailyRaces {
          canRaceAgainToday
        }
        owner {
          user {
            wallets {
              nectar
              droplets
            }
          }
        }
      }
    }
  }
`;

export const GET_LEADERBOARD = gql`
  query GetLeaderBoard($input: LeaderBoardDateInput) {
    getLeaderBoard(input: $input) {
      createdAt
      droplets
      score
      avatar
      nickName
      battleFly {
        id
      }
    }
  }
`;

export const GET_PLAYER_STATISTICS = gql`
  query GetPlayerStatistics($input: WalletRaceHistoryInput) {
    me {
      id
      wallets {
        nectar
        address
        droplets
        bestRaces(input: $input) {
          droplets
          score
          createdAt
        }
      }
    }
  }
`;
