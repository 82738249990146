import {
  BattleFly,
  BattleFlyLeague,
} from '@battlefly/__generated__/globalTypes';
import { SingleLeagueBlockParams } from '@battlefly/ts/leagues';

export const getLadderProgress = (
  battlefly: BattleFly,
  leagueBlock: SingleLeagueBlockParams
): number => {
  const { league, progressElements } = leagueBlock;

  if (BattleFlyLeague.Larvae === league) {
    return 0;
  }

  const baseMaxCp = progressElements[progressElements.length - 1].cpRequired;
  const maxCp = 1.02 * baseMaxCp;
  const baseMinCp = progressElements[0].cpRequired;
  const minCp = 0.9 * baseMinCp;
  const diff = maxCp - minCp;
  const baseValue = ((battlefly.contestPoints - minCp) / diff) * 100;

  return baseValue > 100 ? 100 : baseValue;
};
