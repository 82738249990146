import React from 'react';
import classnames from 'classnames';

// TODO: move to base component
import BattleflyImageWithPlaceholder from '@battlefly/components/MissionControlGrid/BattleFlyCard/BattleflyImageWithPlaceholder';

interface SingleBattleflyBluredImageProps {
  url: string;
  containerClasses?: string;
  imageClassName?: string;
}

const SingleBattleflyBluredImage: React.FC<SingleBattleflyBluredImageProps> = (
  props
) => {
  const { imageClassName, containerClasses } = props;
  return (
    <div className="relative ">
      <div
        className={classnames(
          'image-container relative z-[0] items-center overflow-hidden rounded-2xl px-6 pb-0 pt-8',
          containerClasses
        )}
      >
        <BattleflyImageWithPlaceholder
          imageSrc={props.url}
          classes={classnames(
            'battlefly-card fade-in h-[250px] sm:h-[250px] wxga+:h-[310px] 3xl:h-[380px] w-[800px] object-cover object-center',
            imageClassName
          )}
        />
        {/* <div className="image-child-back battlefly-screen pb-full z-[1] h-full w-full"></div>
        <BattleflyImageWithPlaceholder
          imageSrc={props.url}
          classes="fade-in h-[360px] w-[600px] object-cover object-bottom p-4"
        /> */}
      </div>
    </div>
  );
};

export default SingleBattleflyBluredImage;
