import { usePrepareContractWrite, useContractWrite } from 'wagmi';
import { Result } from 'ethers/lib/utils';

import {
  failureToast,
  pendingTrxToast,
  successToast,
} from 'components/CustomToast/CustomToast';

import { BattleflyGame, CONTRACT_ABIS } from 'common/constants';
import { useContractAddresses } from './useContractAddresses';
// import BalanceContext from 'context/BalanceContext';

export function useMakeContractWrite(config: any) {
  // const { setWatchBalances } = React.useContext(BalanceContext);

  const contractWrite = useContractWrite({
    ...config,
    onMutate() {
      pendingTrxToast();
    },
    onSuccess(data) {
      successToast(null, data);
      config.onSuccess && config.onSuccess(data);
      // setTimeout(() => {
      //   console.debug('WATCH OPTION OFF');
      //   setWatchBalances(false);
      // }, 30000);
    },
    onError(error: Error) {
      console.error('contract write error', error);
      failureToast('Error');
      // setWatchBalances(false);
    },
    onSettled(data, error) {
      console.debug('onSettled', data, error);
      if (!error) {
        // console.debug('WATCH OPTION ON');
        // setWatchBalances(true);
      }
    },
  });

  return contractWrite;
}

export type ContractWritePrepareConfig = {
  functionName: string;
  enabled?: boolean;
  args?: any[];
};

export function useMakePrepareContractWrite(
  config: ContractWritePrepareConfig,
  contractName: BattleflyGame
) {
  const { functionName } = config;
  const contractAddresses = useContractAddresses();
  const contractAddress = contractAddresses[contractName];
  return usePrepareContractWrite({
    abi: CONTRACT_ABIS[contractName],
    address: contractAddress,
    onError(error: Error) {
      console.log(`prepare Error: ${functionName} (${contractAddress})`, error);
    },
    onSuccess() {
      console.debug(`prepare Success: ${functionName} (${contractAddress})`);
    },
    ...config,
  });
}

export type ComicsInWallet = number[];

export const parseComicBalanceOfBatchResponse = (
  data: Result
): ComicsInWallet => {
  if (!data) {
    return [];
  }
  return data.flatMap((item, index) => {
    const comicId = index + 1;
    const count = Number(item.toString());
    if (count > 0) {
      return Array(count).fill(comicId);
    }
    return [];
  });
};
