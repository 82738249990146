import { ArrowRight } from 'components/Icons';
import getRemainingTime from 'util/getTimeRemaining';

type BattleflyMarkDescriptionProps = {
  title: string;
  expirationDate?: string;
};

function formatTime(time: string) {
  const { days, hours, minutes } = getRemainingTime(time, {
    leadingZero: true,
  });
  return `${days}:${hours}:${minutes}`;
}

const BattleflyMarkDescription = (props: BattleflyMarkDescriptionProps) => {
  const { title, expirationDate } = props;

  const heading = expirationDate ? 'Time Remaining' : title;
  const text = expirationDate ? formatTime(expirationDate) : 'Equip Now';

  return (
    <div className="mt-2 flex flex-col items-start justify-start text-xs">
      <div className="font-bold"> {heading} </div>
      <div className="mb-3">{text} </div>
      <ArrowRight />
    </div>
  );
};

export default BattleflyMarkDescription;
