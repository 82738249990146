import React from 'react';
import classnames from 'classnames';

import LeagueDigit from '@battlefly/components/Icons/Leagues/LeagueDigit';
import LeagueDigitContainer from '@battlefly/components/Icons/Leagues/LeagueDigitContainer';
import { LeagueWithDigitProps } from '@battlefly/components/Icons/Leagues/PupaWithDigitIcon';
import { IconProps } from '@battlefly/components/Icons';

interface LeagueWithDigitIconProps extends LeagueWithDigitProps {
  icon: React.FC<IconProps>;
  colorBorderStart: string;
  colorBorderEnd: string;
  colorRectStart: string;
  colorRectMiddle: string;
  colorRectEnd: string;
}

const LeagueWithDigitIcon: React.FC<LeagueWithDigitIconProps> = (props) => {
  const offsetTopPx = props?.offsetTopPx ?? 0;
  return (
    <div className="relative mb-[40px] h-[50px] wxga+:h-[70px]">
      <props.icon
        {...props}
        className="flex h-full w-auto justify-center mobile:h-auto mobile:max-w-full"
      />
      <div
        className={classnames('flex translate-y-[-15px] justify-center', {
          invisible: props.hideLeagueDigit,
        })}
      >
        <LeagueDigitContainer
          offset={offsetTopPx - 22}
          colorBorderStart={props.colorBorderStart}
          colorBorderEnd={props.colorBorderEnd}
          colorRectStart={props.colorRectStart}
          colorRectMiddle={props.colorRectMiddle}
          colorRectEnd={props.colorRectEnd}
        />
        <LeagueDigit leagueNumber={props.digit} offset={offsetTopPx - 22} />
      </div>
    </div>
  );
};

export default LeagueWithDigitIcon;
