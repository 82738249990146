import React from 'react';
import LeagueDigit from '@battlefly/components/Icons/Leagues/LeagueDigit';
import LeagueDigitContainer from '@battlefly/components/Icons/Leagues/LeagueDigitContainer';
import { LeagueWithDigitProps } from '@battlefly/components/Icons/Leagues/PupaWithDigitIcon';
import ApexIcon from '@battlefly/components/Icons/Leagues/ApexIcon';
import ChampionIcon from '@battlefly/components/Icons/Leagues/ChampionIcon';
import LeagueWithDigitIcon from '@battlefly/components/Icons/Leagues/LeagueWithDigitIcon';

const ChampionWithDigitIcon: React.FC<LeagueWithDigitProps> = (props) => {
  return (
    <LeagueWithDigitIcon
      digit={props.digit}
      hideLeagueDigit={props.hideLeagueDigit}
      icon={ChampionIcon}
      colorBorderStart="#F595CE"
      colorBorderEnd="#F5CBDF"
      colorRectStart="#DE4A8B"
      colorRectMiddle="#6650A1"
      colorRectEnd="#EE5592"
    />
  );
};

export default ChampionWithDigitIcon;
