import React from 'react';
import { ComicNetworkResponse } from '@battlefly/hooks/contractsNew/useComic';

const ComicMintCardImage = ({ comics }: { comics: ComicNetworkResponse }) => {
  return (
    <div className="font-white w-full w-[48%] mobile:w-full max-w-full text-sm text-white">
      <div className="image-container relative z-[0] items-center overflow-hidden">
        <img
          className="fade-in image-child-back inset-0 z-[0] w-full object-cover object-center"
          src={comics.details?.image}
        />
        <div className="image-child-back battlefly-screen pb-full z-[1] w-full"></div>
        <img
          className="fade-in battlefly-card image-child-back inset-0 z-[2] w-full object-cover object-center"
          src={comics.details?.image}
        />
      </div>
    </div>
  );
};

export default ComicMintCardImage;
