import { BattleFly } from '@battlefly/__generated__/globalTypes';
import Card from 'components/core/Card';
import { ScavengingMarkCard } from '@battlefly/components/ScavengingMark';
import { HuntersMarkCard } from 'components/HuntersMark';
import {
  BattleflyMarkDescription,
  BattleflyMarkSlot,
} from '@battlefly/components/BattleflyMark';

type HunterMarksCardProps = {
  className?: string;
  battlefly: BattleFly;
  handleScavenging: () => void;
  handleHuntersMark: () => void;
};

const HunterMarksCard = (props: HunterMarksCardProps) => {
  const { handleHuntersMark, handleScavenging, battlefly } = props;
  const { huntersMark, scavengingMark } = battlefly;

  return (
    <Card className="overflow-scroll px-6 md:py-6 md:px-10">
      <div className="grid gap-4 sm:grid-cols-2">
        <div>
          <div className="font-heading text-2xl">Hunter Marks</div>
          <p className="leading-[19px] text-core-grey">
            Burn nectar to apply tattoos and stickers that increase your chance
            of scavenging rare mods.
          </p>
        </div>
        <div className="flex gap-4">
          <div className="flex gap-2">
            <div onClick={handleScavenging} className="cursor-pointer">
              {scavengingMark ? (
                <ScavengingMarkCard type={scavengingMark.type} />
              ) : (
                <BattleflyMarkSlot />
              )}
            </div>
            <BattleflyMarkDescription
              title="Scavengers Mark"
              expirationDate={scavengingMark?.willExpireAt}
            />
          </div>
          <div className="flex gap-2">
            <div onClick={handleHuntersMark} className="cursor-pointer">
              {huntersMark ? (
                <HuntersMarkCard type={huntersMark.type} />
              ) : (
                <BattleflyMarkSlot />
              )}
            </div>
            <BattleflyMarkDescription
              title="Hunters Mark"
              expirationDate={huntersMark?.willExpireAt}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default HunterMarksCard;
