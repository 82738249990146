import React from 'react';

import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { PaginatedList } from '@battlefly/ts/pagination';
import { BattleLog } from '@battlefly/ts/BattleLog';
import { BattleLogHeader } from 'components/lists';
import InfiniteList from 'components/InfiniteList';
import PersonalizedBattleLogItem from '../lists/BattleLogListItem/PersonalizedBattleLogListItem';

type BattlelogPageProps = {
  battlefly: BattleFly;
  logHistory: PaginatedList<BattleLog>;
};

const BattlelogPage = (props: BattlelogPageProps) => {
  const { battlefly, logHistory } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  async function handleFetchMore() {
    setIsLoading(true);
    try {
      await logHistory.fetchMore({
        variables: {
          first: 10,
          returnTotalCount: true,
          after: logHistory.endCursor,
        },
      });
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <div>
      <header className="mb-8">
        <h1 className="text-3xl">Your Battle Log</h1>
      </header>
      <section className="flex flex-col gap-2">
        <BattleLogHeader battlefly={battlefly} />
        <InfiniteList
          fetchMore={handleFetchMore}
          hasNextPage={logHistory.hasNextPage}
          isLoading={isLoading}
          endCursor={logHistory.endCursor}
          items={logHistory.items}
          renderItem={(log) => <PersonalizedBattleLogItem {...log} />}
        />
      </section>
    </div>
  );
};

export default BattlelogPage;
