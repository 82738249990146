import PageTitle from '../PageTitle/PageTitle';

const Message = ({
  text,
  className,
  display,
}: {
  text: string;
  display: boolean;
  className?: string;
}) => {
  if (display) {
    return (
      <div
        className={`flex w-full animate-pulse items-center justify-center ${className}`}
      >
        <PageTitle title={text} />
      </div>
    );
  }
  return null;
};

export default Message;
