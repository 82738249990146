import React from 'react';

const Leaderboard: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} inline-block`}
  >
    <path
      d="M13.625 26.0625H9.55356C8.69562 26.0625 8 26.7581 8 27.6161V33.4017C8 34.2597 8.69562 34.9553 9.55356 34.9553H13.625C14.4829 34.9553 15.1785 34.2597 15.1785 33.4017V27.6161C15.1785 26.7581 14.4831 26.0625 13.625 26.0625Z"
      fill="white"
    />
    <path
      d="M22.0356 16.6338H17.9642C17.1063 16.6338 16.4106 17.3294 16.4106 18.1873V33.4015C16.4106 34.2595 17.1063 34.9551 17.9642 34.9551H22.0356C22.8936 34.9551 23.5892 34.2595 23.5892 33.4015V18.1873C23.5892 17.3294 22.8938 16.6338 22.0356 16.6338Z"
      fill="white"
    />
    <path
      d="M30.4463 21.6699H26.3748C25.5169 21.6699 24.8213 22.3655 24.8213 23.2235V33.4019C24.8213 34.2599 25.5169 34.9555 26.3748 34.9555H30.4463C31.3042 34.9555 31.9998 34.2599 31.9998 33.4019V23.2235C31.9998 22.3655 31.3042 21.6699 30.4463 21.6699Z"
      fill="white"
    />
    <path
      d="M16.6914 9.45281C16.855 9.6344 16.8943 9.80964 16.8702 10.0437C16.7712 11.0004 16.6906 11.959 16.606 12.8881C16.5871 13.291 16.7058 13.3646 17.0563 13.2133C17.9695 12.8188 18.8867 12.4335 19.7979 12.0346C19.9465 11.9697 20.0666 11.9731 20.2111 12.0361C21.1476 12.4437 22.0869 12.8446 23.0258 13.2469C23.2641 13.349 23.4289 13.25 23.4082 13.0112C23.3182 11.9801 23.2293 10.949 23.1258 9.91929C23.1094 9.75615 23.1455 9.64772 23.2504 9.52926C23.9194 8.7738 24.582 8.0128 25.246 7.25283C25.4703 6.99623 25.4153 6.83493 25.0817 6.75889C24.0936 6.53385 23.1065 6.30389 22.1164 6.08746C21.97 6.05548 21.8881 5.98969 21.8153 5.86631C21.2992 4.99177 20.7709 4.1244 20.2597 3.24719C20.098 2.96969 19.8605 2.98403 19.7451 3.2515C19.7295 3.28778 19.7035 3.31934 19.6832 3.35357C19.1843 4.19183 18.6848 5.02969 18.1874 5.86877C18.121 5.98067 18.0482 6.05364 17.9048 6.085C16.9075 6.30163 15.9134 6.53282 14.9184 6.75889C14.5818 6.83534 14.5306 6.99131 14.7597 7.25365C15.4015 7.98821 16.038 8.72809 16.6914 9.45281Z"
      fill="white"
    />
  </svg>
);

export default Leaderboard;
