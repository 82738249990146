import classnames from 'classnames';

import { Image } from 'components/core';
import ControlPattern from './ControlPattern';
import ControlPatternActive from './ControlPatternActive';

type TabProps = {
  className?: string;
  n: number;
  active: boolean;
  type: string;
};

function getImageSrcByType(type: string) {
  switch (type) {
    case 'weapon':
      return '/assets/icons/mod_categories_color/weapon.png';
    case 'utility':
      return '/assets/icons/mod_categories_color/utility.png';
    case 'defense':
      return '/assets/icons/mod_categories_color/defense.png';

    default:
      return '/images/primary.png';
  }
}

const Tab = (props: TabProps) => {
  const { className, n, active, type } = props;
  const imageSrc = getImageSrcByType(type);
  return (
    <div className="relative">
      <ControlPattern />
      <div
        className={classnames(
          'absolute -top-[3px] -left-[3px] transition-opacity',
          {
            'opacity-100': active,
            'opacity-0': !active,
          }
        )}
      >
        <ControlPatternActive />
      </div>
      <div className="absolute top-0 flex h-full w-full items-start justify-end p-2 pt-0 text-xl">
        {n}
      </div>
      <div className="absolute top-0 flex h-full w-full items-end justify-center p-2">
        <Image width={22} height={22} src={imageSrc} />
      </div>
    </div>
  );
};

export default Tab;
