import React, { useRef } from 'react';
import classnames from 'classnames';

interface IInputTypeRange {
  value: string;
  defaultValue?: number;
  min: number;
  max: number;
  onChange?: (val: string) => void;
  onPointerUp?: (val: number) => void;
  testId?: string;
  className?: string;
}

const InputTypeRange: React.FC<IInputTypeRange> = (props) => {
  const ref = useRef<HTMLInputElement>(null);
  const { testId = 'InputTypeRange', className } = props;

  return (
    <input
      ref={ref}
      data-testid={testId}
      className={classnames('styled-input mx-2 w-full', className)}
      type="range"
      value={props.value}
      min={props.min}
      max={props.max}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e.target.value);
        }
      }}
      onPointerUp={(e) => {
        if (props.onPointerUp) {
          props.onPointerUp(Number(ref.current!.value));
        }
      }}
      style={{
        background: `linear-gradient(to right, #58dcdf 0%, #58dcdf ${
          ((Number(props.value) - props.min) / (props.max - props.min)) * 100
        }%, #ffffff ${
          ((Number(props.value) - props.min) / (props.max - props.min)) * 100
        }%, #ffffff 100%)`,
      }}
    />
  );
};

export default InputTypeRange;
