import {
  Battlefly as oldBattlefly,
  BattleflyClass,
  BattleflyType,
  DamageType,
  Mod as OldMod,
  ModType,
} from '@battlefly/ts/BattleflyType';
import { BattleFly, Mod, Rarity } from '@battlefly/__generated__/globalTypes';

export const getOldModEntity = (modInfo: Mod): OldMod => {
  return {
    appearance: {
      image: modInfo.blueprint.appearance.image,
      model: modInfo.blueprint.appearance.model,
    },
    id: modInfo.id,
    image: modInfo.blueprint.appearance.image,
    name: modInfo.name,
    rarity: modInfo.rarity as Rarity,
    type: modInfo.type as ModType,
    equippedAt: new Date().toString(),
    effects: [
      {
        description: modInfo?.blueprint?.description,
      },
    ],
    stats: {
      armor: modInfo.blueprint.defense?.armor,
      shieldPoints: modInfo.blueprint.defense?.shield,
      damagePerFire: modInfo.blueprint.weapon?.damagePerFire,
      damageType: modInfo.damageType as DamageType,
      reload: modInfo.blueprint.weapon?.reload,
      burst: modInfo.blueprint.weapon?.burst,
      speed: modInfo.stats?.FireRate?.current,
    },
    slot: modInfo.slot,
  };
};

export const getOldBattleflyEntity = (newBf: BattleFly): oldBattlefly => {
  const oldBfEntity: oldBattlefly = {
    edition: '',
    experience: 0,
    id: newBf.id.toString(),
    level: 0,
    mods: [],
    name: newBf.name,
    rarity: undefined,
    stage: undefined,
    stats: undefined,
    createdAt: '',
    description: '',
    isStaking: false,
    powerRating: 0,
    revealedAt: '',
    status: undefined,
    // ...newBf,
    appearance: { image: newBf.appearance.image },
    image: newBf.appearance.image,
    type: BattleflyType.Genesis,
    class: BattleflyClass[newBf.class],
  };
  return oldBfEntity;
};

export const calculateMaxDps = (mods: Mod[]) => {
  let maxDps = 0;
  mods.forEach((mod) => {
    if (mod.type === ModType.weapon) {
      const modDps = calculateWeaponDps(mod);
      if (modDps > maxDps) {
        maxDps = modDps;
      }
    }
  });
  return maxDps;
};

export const calculateWeaponDps = (mod: Mod): number => {
  if (mod.type.toLowerCase() !== ModType.weapon.toLowerCase()) {
    return 0;
  }
  return getWeaponDps(
    mod.blueprint.weapon.damagePerFire,
    mod.blueprint.weapon.burst,
    mod.stats?.FireRate?.current
  );
};

export const getWeaponDps = (
  damagePerFire: number,
  burst: number,
  fireRate: number
): number => Number(((damagePerFire * burst) / fireRate).toFixed(1));
