import { default as NextImage, ImageProps } from 'next/image';
import React from 'react';

// type ImageProps = NextImageProps;
/**
 * PLACEHOLDER
 */
const Image: React.FC<ImageProps> = (props) => <NextImage {...props} />;
// const Image: React.FC<ImageProps> = (props) => <div {...props}></div>;

export default Image;
