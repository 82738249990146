import React from 'react';
import { IconProps } from '@battlefly/components/Icons';

const StatDamageIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.60955 5.64874L10.5446 11.4008H16.8064L11.7405 14.9558L13.6755 20.7078L8.60955 17.1528L3.54363 20.7078L5.47864 14.9558L0.412719 11.4008H6.67454L8.60955 5.64874Z"
        fill="#D9D9D9"
      />
      <path
        d="M0 0L9.48192 10.9072L15.3421 8.7759L11.8981 13.8271L24 16.9489L9.76963 16.9488L6.32567 22L6.03796 15.9583L3.62734e-06 14.3556L5.86015 12.2244L0 0Z"
        fill="#D9D9D9"
      />
      <path
        d="M2.04651 1L2.04651 9.17022L10.0698 9.17022L11.0825 15.7608L16 17M1 1L1.90503 9.22482L9.84484 8.09852L11.1163 15.6383"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

export default StatDamageIcon;
