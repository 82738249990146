import classnames from 'classnames';

import { CloseIcon } from 'components/Icons';

type ActionPanelProps = {
  className?: string;
  children: React.ReactNode;
  onClose: () => void;
};

const ActionPanel = (props: ActionPanelProps) => {
  const { children, className, onClose } = props;
  return (
    <div className={classnames('h-[30%] rounded-b-xl bg-[#191623]', className)}>
      <ul className="flex flex-col gap-3 p-4">
        {children}
        <li className="flex items-center justify-center">
          <div
            onClick={onClose}
            className="flex h-12 w-12 cursor-pointer items-center justify-center"
          >
            <CloseIcon className="h-8 w-8" fill="#fff" />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ActionPanel;
