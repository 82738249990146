import React from 'react';
import { IconProps } from '@battlefly/components/Icons/index';

const MarkBackgroundIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="42.4308"
        height="42.4308"
        rx="4"
        transform="matrix(0.707033 0.70718 -0.707033 0.70718 29 -1.20264)"
        fill="#4A5568"
      />
    </svg>
  );
};

export default MarkBackgroundIcon;
