import React from 'react';
import { ModWeaponBackgroundProps } from '@battlefly/components/Icons/Mods/ModWeaponBackground';

interface ModUtilityBackgroundDefsProps extends ModWeaponBackgroundProps {
  backgroundColor: string;
  borderColor: string;
}

const ModUtilityBackgroundDefs: React.FC<ModUtilityBackgroundDefsProps> = (
  props
) => {
  return (
    <>
      <linearGradient
        id={`paint0_linear_3_7194${props.uniqueId}`}
        x1="296.354"
        y1="530.928"
        x2="296.354"
        y2="543.569"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_3_7194${props.uniqueId}`}
        x1="294.503"
        y1="544.713"
        x2="294.503"
        y2="560.541"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint2_linear_3_7194${props.uniqueId}`}
        x1="267.289"
        y1="554.55"
        x2="267.289"
        y2="651.902"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint3_linear_3_7194${props.uniqueId}`}
        x1="252.627"
        y1="593.655"
        x2="252.627"
        y2="622.557"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint4_linear_3_7194${props.uniqueId}`}
        x1="291.9"
        y1="568.399"
        x2="291.9"
        y2="586.968"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint5_linear_3_7194${props.uniqueId}`}
        x1="242.756"
        y1="603.501"
        x2="242.756"
        y2="632.432"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint6_linear_3_7194${props.uniqueId}`}
        x1="298.341"
        y1="512.172"
        x2="298.341"
        y2="524.88"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint7_linear_3_7194${props.uniqueId}`}
        x1="338.439"
        y1="662.09"
        x2="338.439"
        y2="725.749"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint8_linear_3_7194${props.uniqueId}`}
        x1="330.737"
        y1="671.826"
        x2="330.737"
        y2="759.57"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint9_linear_3_7194${props.uniqueId}`}
        x1="332.709"
        y1="587.353"
        x2="332.709"
        y2="671.423"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint10_linear_3_7194${props.uniqueId}`}
        x1="333.416"
        y1="657.08"
        x2="333.416"
        y2="681.23"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint11_linear_3_7194${props.uniqueId}`}
        x1="309.002"
        y1="612.764"
        x2="309.002"
        y2="656.7"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint12_linear_3_7194${props.uniqueId}`}
        x1="325.928"
        y1="740.28"
        x2="325.928"
        y2="778.935"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint13_linear_3_7194${props.uniqueId}`}
        x1="213.296"
        y1="632.328"
        x2="213.296"
        y2="661.561"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint14_linear_3_7194${props.uniqueId}`}
        x1="223.207"
        y1="622.909"
        x2="223.207"
        y2="651.839"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint15_linear_3_7194${props.uniqueId}`}
        x1="232.99"
        y1="613.329"
        x2="232.99"
        y2="642.249"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint16_linear_3_7194${props.uniqueId}`}
        x1="488.053"
        y1="634.937"
        x2="488.053"
        y2="713.147"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint17_linear_3_7194${props.uniqueId}`}
        x1="532.243"
        y1="615.938"
        x2="532.243"
        y2="722.972"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint18_linear_3_7194${props.uniqueId}`}
        x1="298.828"
        y1="759.747"
        x2="298.828"
        y2="808.19"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint19_linear_3_7194${props.uniqueId}`}
        x1="350.11"
        y1="539.541"
        x2="350.11"
        y2="553.912"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint20_linear_3_7194${props.uniqueId}`}
        x1="369.778"
        y1="534.897"
        x2="369.778"
        y2="563.79"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint21_linear_3_7194${props.uniqueId}`}
        x1="438.232"
        y1="466.67"
        x2="438.232"
        y2="573.675"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint22_linear_3_7194${props.uniqueId}`}
        x1="407.853"
        y1="574.106"
        x2="407.853"
        y2="604.675"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint23_linear_3_7194${props.uniqueId}`}
        x1="415.988"
        y1="504.799"
        x2="415.988"
        y2="530.311"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint24_linear_3_7194${props.uniqueId}`}
        x1="379.672"
        y1="544.782"
        x2="379.672"
        y2="573.693"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint25_linear_3_7194${props.uniqueId}`}
        x1="352.444"
        y1="525.515"
        x2="352.444"
        y2="534.504"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint26_linear_3_7194${props.uniqueId}`}
        x1="379.441"
        y1="408.01"
        x2="379.441"
        y2="436.896"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint27_linear_3_7194${props.uniqueId}`}
        x1="389.5"
        y1="554.615"
        x2="389.5"
        y2="583.512"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint28_linear_3_7194${props.uniqueId}`}
        x1="316.147"
        y1="750.037"
        x2="316.147"
        y2="788.714"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint29_linear_3_7194${props.uniqueId}`}
        x1="365.536"
        y1="484.562"
        x2="365.536"
        y2="513.544"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint30_linear_3_7194${props.uniqueId}`}
        x1="462.628"
        y1="466.575"
        x2="462.628"
        y2="505.216"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint31_linear_3_7194${props.uniqueId}`}
        x1="447.969"
        y1="456.977"
        x2="447.969"
        y2="563.962"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint32_linear_3_7194${props.uniqueId}`}
        x1="428.359"
        y1="533.815"
        x2="428.359"
        y2="573.644"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint33_linear_3_7194${props.uniqueId}`}
        x1="364.929"
        y1="437.249"
        x2="364.929"
        y2="495.431"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint34_linear_3_7194${props.uniqueId}`}
        x1="438.287"
        y1="642.648"
        x2="438.287"
        y2="671.564"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint35_linear_3_7194${props.uniqueId}`}
        x1="416.488"
        y1="681.777"
        x2="416.488"
        y2="724.563"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint36_linear_3_7194${props.uniqueId}`}
        x1="306.102"
        y1="817.84"
        x2="306.102"
        y2="857.188"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint37_linear_3_7194${props.uniqueId}`}
        x1="316.175"
        y1="808.732"
        x2="316.175"
        y2="847.457"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint38_linear_3_7194${props.uniqueId}`}
        x1="387.367"
        y1="728.506"
        x2="387.367"
        y2="842.873"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint39_linear_3_7194${props.uniqueId}`}
        x1="399.073"
        y1="564.357"
        x2="399.073"
        y2="593.395"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint40_linear_3_7194${props.uniqueId}`}
        x1="467.493"
        y1="574.255"
        x2="467.493"
        y2="603.025"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint41_linear_3_7194${props.uniqueId}`}
        x1="355.647"
        y1="573.661"
        x2="355.647"
        y2="651.906"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint42_linear_3_7194${props.uniqueId}`}
        x1="438.293"
        y1="603.388"
        x2="438.293"
        y2="632.302"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint43_linear_3_7194${props.uniqueId}`}
        x1="367.531"
        y1="681.6"
        x2="367.531"
        y2="749.649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint44_linear_3_7194${props.uniqueId}`}
        x1="63.4016"
        y1="642.361"
        x2="63.4016"
        y2="655.738"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint45_linear_3_7194${props.uniqueId}`}
        x1="82.2372"
        y1="652.095"
        x2="82.2372"
        y2="669.281"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint46_linear_3_7194${props.uniqueId}`}
        x1="-1.47944"
        y1="716.271"
        x2="-1.47944"
        y2="717.782"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint47_linear_3_7194${props.uniqueId}`}
        x1="277.475"
        y1="448.631"
        x2="277.475"
        y2="466.053"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint48_linear_3_7194${props.uniqueId}`}
        x1="131.421"
        y1="759.574"
        x2="131.421"
        y2="816.042"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint49_linear_3_7194${props.uniqueId}`}
        x1="517.918"
        y1="733.031"
        x2="517.918"
        y2="772.382"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint50_linear_3_7194${props.uniqueId}`}
        x1="436.697"
        y1="747.651"
        x2="436.697"
        y2="856.496"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint51_linear_3_7194${props.uniqueId}`}
        x1="498.171"
        y1="811.311"
        x2="498.171"
        y2="849.899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint52_linear_3_7194${props.uniqueId}`}
        x1="507.915"
        y1="742.76"
        x2="507.915"
        y2="781.462"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint53_linear_3_7194${props.uniqueId}`}
        x1="522.455"
        y1="625.665"
        x2="522.455"
        y2="732.679"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint54_linear_3_7194${props.uniqueId}`}
        x1="507.935"
        y1="801.483"
        x2="507.935"
        y2="840.174"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint55_linear_3_7194${props.uniqueId}`}
        x1="476.568"
        y1="830.707"
        x2="476.568"
        y2="867.728"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint56_linear_3_7194${props.uniqueId}`}
        x1="367.634"
        y1="556.125"
        x2="367.634"
        y2="642.697"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint57_linear_3_7194${props.uniqueId}`}
        x1="218.532"
        y1="779.345"
        x2="218.532"
        y2="915.644"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint58_linear_3_7194${props.uniqueId}`}
        x1="254.739"
        y1="789.081"
        x2="254.739"
        y2="826.443"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint59_linear_3_7194${props.uniqueId}`}
        x1="213.639"
        y1="750.009"
        x2="213.639"
        y2="896.025"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint60_linear_3_7194${props.uniqueId}`}
        x1="242.635"
        y1="826.922"
        x2="242.635"
        y2="901.936"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint61_linear_3_7194${props.uniqueId}`}
        x1="37.7475"
        y1="676.985"
        x2="37.7475"
        y2="678.107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint62_linear_3_7194${props.uniqueId}`}
        x1="61.5521"
        y1="679.386"
        x2="61.5521"
        y2="681.366"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint63_linear_3_7194${props.uniqueId}`}
        x1="323.491"
        y1="512.734"
        x2="323.491"
        y2="525.621"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint64_linear_3_7194${props.uniqueId}`}
        x1="86.4356"
        y1="661.518"
        x2="86.4356"
        y2="682.604"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint65_linear_3_7194${props.uniqueId}`}
        x1="236.345"
        y1="481.333"
        x2="236.345"
        y2="556.12"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint66_linear_3_7194${props.uniqueId}`}
        x1="334.262"
        y1="527.933"
        x2="334.262"
        y2="545.017"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint67_linear_3_7194${props.uniqueId}`}
        x1="25.3787"
        y1="686.417"
        x2="25.3787"
        y2="691.41"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint68_linear_3_7194${props.uniqueId}`}
        x1="75.4403"
        y1="693.863"
        x2="75.4403"
        y2="694.882"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint69_linear_3_7194${props.uniqueId}`}
        x1="57.8933"
        y1="691.347"
        x2="57.8933"
        y2="693.774"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint70_linear_3_7194${props.uniqueId}`}
        x1="158.5"
        y1="461.198"
        x2="158.5"
        y2="641.828"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint71_linear_3_7194${props.uniqueId}`}
        x1="95.1804"
        y1="671.618"
        x2="95.1804"
        y2="695.778"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint72_linear_3_7194${props.uniqueId}`}
        x1="321.433"
        y1="535.845"
        x2="321.433"
        y2="544.128"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint73_linear_3_7194${props.uniqueId}`}
        x1="276.95"
        y1="495.947"
        x2="276.95"
        y2="514.962"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint74_linear_3_7194${props.uniqueId}`}
        x1="340.405"
        y1="456.723"
        x2="340.405"
        y2="661.681"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint75_linear_3_7194${props.uniqueId}`}
        x1="320.043"
        y1="545.478"
        x2="320.043"
        y2="553.774"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint76_linear_3_7194${props.uniqueId}`}
        x1="267.094"
        y1="461.31"
        x2="267.094"
        y2="475.783"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint77_linear_3_7194${props.uniqueId}`}
        x1="296.461"
        y1="437.3"
        x2="296.461"
        y2="456.362"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint78_linear_3_7194${props.uniqueId}`}
        x1="318.664"
        y1="559.304"
        x2="318.664"
        y2="567.841"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint79_linear_3_7194${props.uniqueId}`}
        x1="257.23"
        y1="476.288"
        x2="257.23"
        y2="495.386"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint80_linear_3_7194${props.uniqueId}`}
        x1="350.062"
        y1="398.195"
        x2="350.062"
        y2="426.91"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint81_linear_3_7194${props.uniqueId}`}
        x1="293.683"
        y1="486.12"
        x2="293.683"
        y2="506.114"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint82_linear_3_7194${props.uniqueId}`}
        x1="189.219"
        y1="779.155"
        x2="189.219"
        y2="798.191"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint83_linear_3_7194${props.uniqueId}`}
        x1="235.768"
        y1="681.531"
        x2="235.768"
        y2="778.79"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint84_linear_3_7194${props.uniqueId}`}
        x1="142.495"
        y1="534.87"
        x2="142.495"
        y2="729.841"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint85_linear_3_7194${props.uniqueId}`}
        x1="179.483"
        y1="769.411"
        x2="179.483"
        y2="788.435"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint86_linear_3_7194${props.uniqueId}`}
        x1="156.978"
        y1="544.743"
        x2="156.978"
        y2="740.148"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint87_linear_3_7194${props.uniqueId}`}
        x1="174.12"
        y1="554.58"
        x2="174.12"
        y2="749.478"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint88_linear_3_7194${props.uniqueId}`}
        x1="213.26"
        y1="574.069"
        x2="213.26"
        y2="768.973"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint89_linear_3_7194${props.uniqueId}`}
        x1="130.512"
        y1="710.799"
        x2="130.512"
        y2="749.449"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint90_linear_3_7194${props.uniqueId}`}
        x1="39.0855"
        y1="718.337"
        x2="39.0855"
        y2="778.988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint91_linear_3_7194${props.uniqueId}`}
        x1="120.511"
        y1="701.108"
        x2="120.511"
        y2="739.914"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint92_linear_3_7194${props.uniqueId}`}
        x1="74.2332"
        y1="710.808"
        x2="74.2332"
        y2="778.947"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint93_linear_3_7194${props.uniqueId}`}
        x1="120.777"
        y1="691.235"
        x2="120.777"
        y2="710.27"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint94_linear_3_7194${props.uniqueId}`}
        x1="198.845"
        y1="789.022"
        x2="198.845"
        y2="808.057"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint95_linear_3_7194${props.uniqueId}`}
        x1="250.087"
        y1="671.902"
        x2="250.087"
        y2="788.529"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint96_linear_3_7194${props.uniqueId}`}
        x1="289.467"
        y1="662.134"
        x2="289.467"
        y2="740.808"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint97_linear_3_7194${props.uniqueId}`}
        x1="193.946"
        y1="564.341"
        x2="193.946"
        y2="759.284"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint98_linear_3_7194${props.uniqueId}`}
        x1="69.6298"
        y1="776.158"
        x2="69.6298"
        y2="805.783"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint99_linear_3_7194${props.uniqueId}`}
        x1="143.017"
        y1="750.032"
        x2="143.017"
        y2="906.014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint100_linear_3_7194${props.uniqueId}`}
        x1="98.7332"
        y1="789.005"
        x2="98.7332"
        y2="866.964"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint101_linear_3_7194${props.uniqueId}`}
        x1="308.872"
        y1="392.769"
        x2="308.872"
        y2="417.241"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint102_linear_3_7194${props.uniqueId}`}
        x1="82.7284"
        y1="779.329"
        x2="82.7284"
        y2="815.667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint103_linear_3_7194${props.uniqueId}`}
        x1="262.272"
        y1="447.638"
        x2="262.272"
        y2="466.052"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint104_linear_3_7194${props.uniqueId}`}
        x1="137.321"
        y1="816.234"
        x2="137.321"
        y2="874.259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint105_linear_3_7194${props.uniqueId}`}
        x1="48.131"
        y1="662.613"
        x2="48.131"
        y2="668.822"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint106_linear_3_7194${props.uniqueId}`}
        x1="58.3873"
        y1="625.15"
        x2="58.3873"
        y2="632.085"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint107_linear_3_7194${props.uniqueId}`}
        x1="39.6649"
        y1="651.688"
        x2="39.6649"
        y2="653.249"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint108_linear_3_7194${props.uniqueId}`}
        x1="15.1071"
        y1="674.325"
        x2="15.1071"
        y2="675.832"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint109_linear_3_7194${props.uniqueId}`}
        x1="340.409"
        y1="456.733"
        x2="340.409"
        y2="661.689"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint110_linear_3_7194${props.uniqueId}`}
        x1="387.363"
        y1="728.51"
        x2="387.363"
        y2="842.885"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint111_linear_3_7194${props.uniqueId}`}
        x1="436.697"
        y1="747.65"
        x2="436.697"
        y2="856.495"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint112_linear_3_7194${props.uniqueId}`}
        x1="236.354"
        y1="481.333"
        x2="236.354"
        y2="556.125"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint113_linear_3_7194${props.uniqueId}`}
        x1="338.442"
        y1="662.092"
        x2="338.442"
        y2="725.749"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint114_linear_3_7194${props.uniqueId}`}
        x1="242.638"
        y1="826.676"
        x2="242.638"
        y2="901.933"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint115_linear_3_7194${props.uniqueId}`}
        x1="156.987"
        y1="544.748"
        x2="156.987"
        y2="740.153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint116_linear_3_7194${props.uniqueId}`}
        x1="174.129"
        y1="554.58"
        x2="174.129"
        y2="749.483"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint117_linear_3_7194${props.uniqueId}`}
        x1="158.505"
        y1="461.202"
        x2="158.505"
        y2="641.829"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint118_linear_3_7194${props.uniqueId}`}
        x1="193.947"
        y1="564.327"
        x2="193.947"
        y2="759.271"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint119_linear_3_7194${props.uniqueId}`}
        x1="213.264"
        y1="574.073"
        x2="213.264"
        y2="768.975"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint120_linear_3_7194${props.uniqueId}`}
        x1="213.629"
        y1="750.003"
        x2="213.629"
        y2="896.016"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint121_linear_3_7194${props.uniqueId}`}
        x1="289.467"
        y1="662.138"
        x2="289.467"
        y2="740.812"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint122_linear_3_7194${props.uniqueId}`}
        x1="407.853"
        y1="574.103"
        x2="407.853"
        y2="604.675"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint123_linear_3_7194${props.uniqueId}`}
        x1="142.497"
        y1="534.865"
        x2="142.497"
        y2="729.834"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint124_linear_3_7194${props.uniqueId}`}
        x1="250.087"
        y1="671.915"
        x2="250.087"
        y2="788.538"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint125_linear_3_7194${props.uniqueId}`}
        x1="488.052"
        y1="634.926"
        x2="488.052"
        y2="713.133"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint126_linear_3_7194${props.uniqueId}`}
        x1="447.974"
        y1="456.954"
        x2="447.974"
        y2="563.953"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint127_linear_3_7194${props.uniqueId}`}
        x1="522.455"
        y1="625.655"
        x2="522.455"
        y2="732.668"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint128_linear_3_7194${props.uniqueId}`}
        x1="218.522"
        y1="779.353"
        x2="218.522"
        y2="915.659"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint129_linear_3_7194${props.uniqueId}`}
        x1="365.453"
        y1="484.562"
        x2="365.453"
        y2="514.626"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint130_linear_3_7194${props.uniqueId}`}
        x1="306.106"
        y1="817.83"
        x2="306.106"
        y2="857.183"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint131_linear_3_7194${props.uniqueId}`}
        x1="330.73"
        y1="671.817"
        x2="330.73"
        y2="759.563"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint132_linear_3_7194${props.uniqueId}`}
        x1="517.925"
        y1="733.026"
        x2="517.925"
        y2="772.368"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint133_linear_3_7194${props.uniqueId}`}
        x1="120.508"
        y1="701.115"
        x2="120.508"
        y2="739.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint134_linear_3_7194${props.uniqueId}`}
        x1="367.536"
        y1="681.599"
        x2="367.536"
        y2="749.648"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint135_linear_3_7194${props.uniqueId}`}
        x1="367.627"
        y1="555.239"
        x2="367.627"
        y2="642.692"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint136_linear_3_7194${props.uniqueId}`}
        x1="532.246"
        y1="615.95"
        x2="532.246"
        y2="722.984"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint137_linear_3_7194${props.uniqueId}`}
        x1="355.648"
        y1="573.66"
        x2="355.648"
        y2="651.901"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint138_linear_3_7194${props.uniqueId}`}
        x1="438.238"
        y1="466.667"
        x2="438.238"
        y2="573.675"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint139_linear_3_7194${props.uniqueId}`}
        x1="213.292"
        y1="632.33"
        x2="213.292"
        y2="661.565"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint140_linear_3_7194${props.uniqueId}`}
        x1="235.769"
        y1="681.526"
        x2="235.769"
        y2="778.785"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint141_linear_3_7194${props.uniqueId}`}
        x1="267.295"
        y1="554.553"
        x2="267.295"
        y2="651.909"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint142_linear_3_7194${props.uniqueId}`}
        x1="74.2332"
        y1="710.805"
        x2="74.2332"
        y2="778.942"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint143_linear_3_7194${props.uniqueId}`}
        x1="332.65"
        y1="587.245"
        x2="332.65"
        y2="671.427"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint144_linear_3_7194${props.uniqueId}`}
        x1="415.988"
        y1="504.799"
        x2="415.988"
        y2="530.311"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint145_linear_3_7194${props.uniqueId}`}
        x1="298.83"
        y1="759.747"
        x2="298.83"
        y2="808.19"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint146_linear_3_7194${props.uniqueId}`}
        x1="364.933"
        y1="437.241"
        x2="364.933"
        y2="495.428"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint147_linear_3_7194${props.uniqueId}`}
        x1="309.011"
        y1="612.75"
        x2="309.011"
        y2="656.982"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint148_linear_3_7194${props.uniqueId}`}
        x1="316.175"
        y1="808.731"
        x2="316.175"
        y2="847.456"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint149_linear_3_7194${props.uniqueId}`}
        x1="507.92"
        y1="742.765"
        x2="507.92"
        y2="781.467"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint150_linear_3_7194${props.uniqueId}`}
        x1="325.924"
        y1="740.28"
        x2="325.924"
        y2="778.935"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint151_linear_3_7194${props.uniqueId}`}
        x1="498.163"
        y1="811.301"
        x2="498.163"
        y2="849.893"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint152_linear_3_7194${props.uniqueId}`}
        x1="462.624"
        y1="466.567"
        x2="462.624"
        y2="505.217"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint153_linear_3_7194${props.uniqueId}`}
        x1="86.0219"
        y1="661.515"
        x2="86.0219"
        y2="682.601"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint154_linear_3_7194${props.uniqueId}`}
        x1="223.203"
        y1="622.9"
        x2="223.203"
        y2="651.832"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint155_linear_3_7194${props.uniqueId}`}
        x1="467.493"
        y1="574.259"
        x2="467.493"
        y2="603.029"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint156_linear_3_7194${props.uniqueId}`}
        x1="232.993"
        y1="613.324"
        x2="232.993"
        y2="642.243"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint157_linear_3_7194${props.uniqueId}`}
        x1="242.754"
        y1="603.509"
        x2="242.754"
        y2="632.441"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint158_linear_3_7194${props.uniqueId}`}
        x1="379.441"
        y1="408.008"
        x2="379.441"
        y2="436.896"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint159_linear_3_7194${props.uniqueId}`}
        x1="379.668"
        y1="544.783"
        x2="379.668"
        y2="573.697"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint160_linear_3_7194${props.uniqueId}`}
        x1="438.291"
        y1="603.394"
        x2="438.291"
        y2="632.306"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint161_linear_3_7194${props.uniqueId}`}
        x1="369.779"
        y1="534.887"
        x2="369.779"
        y2="563.784"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint162_linear_3_7194${props.uniqueId}`}
        x1="507.937"
        y1="801.501"
        x2="507.937"
        y2="840.183"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint163_linear_3_7194${props.uniqueId}`}
        x1="130.508"
        y1="710.808"
        x2="130.508"
        y2="749.456"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint164_linear_3_7194${props.uniqueId}`}
        x1="316.139"
        y1="750.037"
        x2="316.139"
        y2="788.715"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint165_linear_3_7194${props.uniqueId}`}
        x1="399.074"
        y1="564.353"
        x2="399.074"
        y2="593.392"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint166_linear_3_7194${props.uniqueId}`}
        x1="438.29"
        y1="642.65"
        x2="438.29"
        y2="671.567"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint167_linear_3_7194${props.uniqueId}`}
        x1="252.632"
        y1="593.663"
        x2="252.632"
        y2="622.564"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint168_linear_3_7194${props.uniqueId}`}
        x1="257.231"
        y1="476.293"
        x2="257.231"
        y2="495.392"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint169_linear_3_7194${props.uniqueId}`}
        x1="389.498"
        y1="554.608"
        x2="389.498"
        y2="583.51"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint170_linear_3_7194${props.uniqueId}`}
        x1="350.066"
        y1="398.199"
        x2="350.066"
        y2="426.916"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint171_linear_3_7194${props.uniqueId}`}
        x1="296.469"
        y1="437.3"
        x2="296.469"
        y2="456.362"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint172_linear_3_7194${props.uniqueId}`}
        x1="198.844"
        y1="789.021"
        x2="198.844"
        y2="808.055"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint173_linear_3_7194${props.uniqueId}`}
        x1="179.482"
        y1="769.415"
        x2="179.482"
        y2="788.437"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint174_linear_3_7194${props.uniqueId}`}
        x1="276.958"
        y1="495.943"
        x2="276.958"
        y2="514.954"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint175_linear_3_7194${props.uniqueId}`}
        x1="189.215"
        y1="779.154"
        x2="189.215"
        y2="798.188"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint176_linear_3_7194${props.uniqueId}`}
        x1="120.773"
        y1="691.229"
        x2="120.773"
        y2="710.262"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint177_linear_3_7194${props.uniqueId}`}
        x1="39.0897"
        y1="718.337"
        x2="39.0897"
        y2="778.988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint178_linear_3_7194${props.uniqueId}`}
        x1="476.566"
        y1="830.698"
        x2="476.566"
        y2="867.89"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint179_linear_3_7194${props.uniqueId}`}
        x1="416.487"
        y1="681.777"
        x2="416.487"
        y2="724.563"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint180_linear_3_7194${props.uniqueId}`}
        x1="131.421"
        y1="759.58"
        x2="131.421"
        y2="816.238"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint181_linear_3_7194${props.uniqueId}`}
        x1="254.744"
        y1="789.075"
        x2="254.744"
        y2="826.676"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint182_linear_3_7194${props.uniqueId}`}
        x1="333.365"
        y1="656.981"
        x2="333.365"
        y2="681.232"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint183_linear_3_7194${props.uniqueId}`}
        x1="428.362"
        y1="533.811"
        x2="428.362"
        y2="573.644"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint184_linear_3_7194${props.uniqueId}`}
        x1="63.2596"
        y1="642.363"
        x2="63.2596"
        y2="655.736"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint185_linear_3_7194${props.uniqueId}`}
        x1="94.8566"
        y1="671.604"
        x2="94.8566"
        y2="695.764"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint186_linear_3_7194${props.uniqueId}`}
        x1="293.683"
        y1="486.125"
        x2="293.683"
        y2="506.115"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint187_linear_3_7194${props.uniqueId}`}
        x1="82.2216"
        y1="652.093"
        x2="82.2216"
        y2="669.286"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint188_linear_3_7194${props.uniqueId}`}
        x1="267.09"
        y1="461.312"
        x2="267.09"
        y2="475.785"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint189_linear_3_7194${props.uniqueId}`}
        x1="350.11"
        y1="539.541"
        x2="350.11"
        y2="553.912"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint190_linear_3_7194${props.uniqueId}`}
        x1="25.3787"
        y1="686.421"
        x2="25.3787"
        y2="691.414"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint191_linear_3_7194${props.uniqueId}`}
        x1="57.9004"
        y1="691.347"
        x2="57.9004"
        y2="693.774"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint192_linear_3_7194${props.uniqueId}`}
        x1="291.867"
        y1="568.399"
        x2="291.867"
        y2="587.245"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint193_linear_3_7194${props.uniqueId}`}
        x1="61.302"
        y1="679.291"
        x2="61.302"
        y2="681.441"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint194_linear_3_7194${props.uniqueId}`}
        x1="334.263"
        y1="527.137"
        x2="334.263"
        y2="545.848"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint195_linear_3_7194${props.uniqueId}`}
        x1="294.53"
        y1="544.561"
        x2="294.53"
        y2="560.541"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint196_linear_3_7194${props.uniqueId}`}
        x1="323.555"
        y1="512.011"
        x2="323.555"
        y2="525.622"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint197_linear_3_7194${props.uniqueId}`}
        x1="296.283"
        y1="530.928"
        x2="296.283"
        y2="544.561"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint198_linear_3_7194${props.uniqueId}`}
        x1="298.341"
        y1="512.176"
        x2="298.341"
        y2="524.884"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint199_linear_3_7194${props.uniqueId}`}
        x1="353.956"
        y1="514.621"
        x2="353.956"
        y2="524.772"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint200_linear_3_7194${props.uniqueId}`}
        x1="352.507"
        y1="524.776"
        x2="352.507"
        y2="534.503"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint201_linear_3_7194${props.uniqueId}`}
        x1="38.0721"
        y1="676.98"
        x2="38.0721"
        y2="678.192"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint202_linear_3_7194${props.uniqueId}`}
        x1="47.2331"
        y1="678.138"
        x2="47.2331"
        y2="679.345"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint203_linear_3_7194${props.uniqueId}`}
        x1="332.705"
        y1="545.849"
        x2="332.705"
        y2="555.245"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint204_linear_3_7194${props.uniqueId}`}
        x1="321.386"
        y1="535.845"
        x2="321.386"
        y2="544.807"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint205_linear_3_7194${props.uniqueId}`}
        x1="320.091"
        y1="544.802"
        x2="320.091"
        y2="553.769"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint206_linear_3_7194${props.uniqueId}`}
        x1="318.664"
        y1="559.303"
        x2="318.664"
        y2="567.84"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint207_linear_3_7194${props.uniqueId}`}
        x1="336.934"
        y1="508.372"
        x2="336.934"
        y2="517.746"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint208_linear_3_7194${props.uniqueId}`}
        x1="335.831"
        y1="517.745"
        x2="335.831"
        y2="527.141"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint209_linear_3_7194${props.uniqueId}`}
        x1="-1.80116"
        y1="716.142"
        x2="-1.80116"
        y2="717.787"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
      <linearGradient
        id={`paint210_linear_3_7194${props.uniqueId}`}
        x1="75.7018"
        y1="693.868"
        x2="75.7018"
        y2="694.977"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} />
      </linearGradient>
    </>
  );
};

export default ModUtilityBackgroundDefs;
