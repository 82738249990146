import { gql, useMutation } from '@apollo/client';

export const RECYCLE_MODPACK = gql`
  mutation recycleModPack($modPackId: ID!) {
    recycleModPack(input: { modPackId: $modPackId }) {
      id
      isRecycled
    }
  }
`;

export const useRecycleModPack = (): [
  ReturnType<typeof useMutation>[0],
  boolean
] => {
  const [recycleModPack, { loading }] = useMutation(RECYCLE_MODPACK);

  return [recycleModPack, loading];
};
