import { getApolloContext } from '@apollo/client';
import { GET_LEADERBOARD } from '@battlefly/graphql/racerGql';
import { useContext } from 'react';
import { formatDate } from './utils';

interface LeaderboardRecord {
  nickName: string;
  droplets: number;
  score: number;
  avatar: string;
  createdAt: string;
}

interface UnityLeaderboard {
  displayName: string;
  nectar: number;
  avatarUrl: string;
  score: number;
}

const transformLeaderboard = (
  leaderboardRecord: LeaderboardRecord
): UnityLeaderboard => ({
  displayName: leaderboardRecord.nickName,
  nectar: leaderboardRecord.droplets,
  avatarUrl: leaderboardRecord.avatar,
  score: leaderboardRecord.score,
});

export const useGetLeaderboardHandler = (sendToUnity: any) => {
  const { client } = useContext(getApolloContext());

  const handleGetLeaderboard = async (input: any) => {
    const { version, maxResultsCount } = JSON.parse(input);

    const versionDaysAgo = formatDate(
      new Date(new Date().setDate(new Date().getDate() - version))
    );

    client
      .query({
        query: GET_LEADERBOARD,
        variables: {
          input: {
            startDate: versionDaysAgo,
            endDate: versionDaysAgo,
            maxRecordsCount: maxResultsCount,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        sendToUnity('GetLeaderboardSuccess', {
          version: version,
          entries: response?.data.getLeaderBoard.map(transformLeaderboard),
        });
      })
      .catch((response) => {
        sendToUnity('RequestFailed', {
          reason: response.errors,
          requestMethod: 'GetLeaderboard',
        });
      });
  };

  return { handleGetLeaderboard };
};
