import React from 'react';
import { useParams } from 'react-router';
import { useMutation } from '@apollo/client';

import {
  ModBlueprint,
  BattleFly,
  Mod,
  ModBlueprintInModPack,
} from '@battlefly/__generated__/globalTypes';
import ModPackPage, {
  AddModToInventoryPage,
} from '@battlefly/components/ModPackPage';
import { UserBalanceContext } from '@battlefly/context/userBalanceContext';
import { ModalContext } from '@battlefly/context/modalContext';
import useModPackDetails from '@battlefly/hooks/scavenge/useModPackDetails';
import useBattleflyDetails from '@battlefly/hooks/components/useBattleflyDetails';
import { getSlotNumberForEquipAction } from 'util/battleflyInventory';
import {
  EQUIP_SCAVENGE,
  REROLL_MODPACK,
} from '@battlefly/graphql/battleflyGql';
import { CustomToast, ToastStatus } from '@battlefly/components/CustomToast';
import {
  SCAVENGE_PICK_ANOTHER_MOD_MAGIC_PRICE,
  SCAVENGE_RE_ROLL_PACK_NECTAR_PRICE,
} from '@battlefly/common/constants';

// TODO: move outside of components
import useUpgradeModInModPack from '@battlefly/components/BattleflyDetails/Modals/useUpgradeModInModPack';
import { useRecycleModPack } from '@battlefly/hooks/game/useRecycleModpack';

const ModpackPage = () => {
  const [isUpgradingRarity, setIsUpgradingRarity] = React.useState(false);
  const { refetchBalances, nectarBalance } =
    React.useContext(UserBalanceContext);
  const { setConfirmModalState } = React.useContext(ModalContext);

  const [selectedMod, setSelectedMod] =
    React.useState<ModBlueprintInModPack>(null);
  const { modpackId } = useParams<{ modpackId: string }>();
  const { data, loading: bfLoading, refetch } = useBattleflyDetails();
  const battlefly: BattleFly = data?.getBattleFly?.battleFly;
  const {
    data: modPack,
    loading: modLoading,
    refetch: refetchModPack,
  } = useModPackDetails();
  const [equipScavenge, { loading: loadingEquip }] =
    useMutation(EQUIP_SCAVENGE);
  const upgradeMod = useUpgradeModInModPack();
  const [reRollModPack, { loading: reRollingModPack }] =
    useMutation(REROLL_MODPACK);
  const [recycleModpack, isRecycling] = useRecycleModPack();
  const loading = bfLoading || modLoading;

  const isRecylingDisabled = () => {
    const hasUpgradedOrEquippedBluePrints = modPack?.modBlueprints?.some(
      (mod) => mod.isUpgraded || mod.isPicked
    );
    return modPack.isRecycled || hasUpgradedOrEquippedBluePrints;
  };

  const isRerollingDisabled = () => {
    const hasUpgradedOrEquippedBluePrints = modPack?.modBlueprints?.some(
      (mod) => mod.isUpgraded || mod.isPicked
    );
    const lowBalance = nectarBalance < SCAVENGE_RE_ROLL_PACK_NECTAR_PRICE;
    const maxRerollsReached = modPack?.rerolled >= 5;

    return (
      hasUpgradedOrEquippedBluePrints ||
      lowBalance ||
      reRollingModPack ||
      modPack.isRecycled ||
      maxRerollsReached
    );
  };

  if (loading) return <div>Loading...</div>;

  function onSelectMod(mod: ModBlueprintInModPack) {
    setSelectedMod(mod);
  }

  async function onUpgradeRarity(mod: ModBlueprintInModPack) {
    setIsUpgradingRarity(true);
    const { modBlueprints } = await upgradeMod(modpackId, mod.id);
    const isSuccess = modBlueprints.every((newMod) => newMod.id !== mod.id);
    setIsUpgradingRarity(false);
    await refetchModPack();

    // TODO: replace with modal/popup
    if (isSuccess) {
      setConfirmModalState({
        type: 'success',
        title: 'Woo Hoo!',
        description: 'Well well well, what do we have here?',
        onConfirm: () => setConfirmModalState(null),
        confirmText: 'Continue',
      });
    } else {
      setConfirmModalState({
        type: 'error',
        title: 'Oops!',
        description: "That didn't work out. Better luck next time.",
        onConfirm: () => setConfirmModalState(null),
        confirmText: 'Go away',
      });
    }
  }

  function handleReroll() {
    if (nectarBalance < SCAVENGE_RE_ROLL_PACK_NECTAR_PRICE) {
      return CustomToast({
        status: ToastStatus.INFO,
        message: `It costs ${SCAVENGE_RE_ROLL_PACK_NECTAR_PRICE} nectar to roll`,
      });
    }

    reRollModPack({
      variables: { modPackId: modpackId },
      onCompleted: () => {
        refetchModPack();
        refetchBalances();
      },
      onError: (e: Error) => {
        CustomToast({
          status: ToastStatus.INFO,
          message: e.message.includes('You have only')
            ? `It costs ${SCAVENGE_RE_ROLL_PACK_NECTAR_PRICE} nectar to roll`
            : 'Unsuccessful attempt to reroll modpack',
        });
        refetchBalances();
      },
    });
  }

  function handleEquipMod(target?: Mod) {
    const slot = getSlotNumberForEquipAction(battlefly, target);
    equipScavenge({
      variables: {
        modPackId: modpackId,
        modBlueprintId: selectedMod.id,
        slot,
      },
      onCompleted: () => {
        refetchBalances();
        refetchModPack();
        // TODO: replace with modal/popup
        CustomToast({
          status: ToastStatus.SUCCESS,
          customTitleText: 'Mod equipped',
          message: `${SCAVENGE_PICK_ANOTHER_MOD_MAGIC_PRICE} Magic charged`,
        });
        setSelectedMod(null);
      },
    });
  }

  function handleRecycle() {
    recycleModpack({
      variables: {
        modPackId: modpackId,
      },
      onCompleted: () => {
        refetchBalances();
        refetchModPack();
        refetch();
      },
    });
  }

  if (selectedMod) {
    return (
      <AddModToInventoryPage
        goBack={() => setSelectedMod(null)}
        handleSelectTarget={handleEquipMod}
        modPackId={modpackId}
        mod={selectedMod}
        battlefly={battlefly}
      />
    );
  }

  return (
    <ModPackPage
      isRecycled={modPack.isRecycled}
      isRecycling={isRecycling}
      isRerollingDisabled={isRerollingDisabled()}
      isRecyclingDisabled={isRecylingDisabled()}
      isRerolling={reRollingModPack}
      isUpgradingRarity={isUpgradingRarity}
      handleRecycle={handleRecycle}
      handleReroll={handleReroll}
      handleUpgradeRarity={onUpgradeRarity}
      handleSelectMod={onSelectMod}
      modPack={modPack}
      battlefly={battlefly}
    />
  );
};

export default ModpackPage;
