import React from 'react';
import { Link } from 'react-router-dom';

import { ExpandArrow } from 'components/Icons';
import Drawer from 'components/Drawer';
import { NAVIGATION_ITEMS } from '@battlefly/common/constants';
import { getIconByName } from './utils';
import BattleflyLogo from 'components/BattleflyLogo/Logo';

export type WalletDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const WalletDrawer = (props: WalletDrawerProps) => {
  const { isOpen, onClose } = props;

  return (
    <Drawer
      className="block sm:hidden"
      onClose={onClose}
      isOpen={isOpen}
      position="left"
    >
      <div className="h-full bg-core-blue p-4">
        <header className="mb-8 flex items-center justify-between">
          <div className="h-8">
            <BattleflyLogo />
          </div>

          <div
            className="flex h-10 w-10 cursor-pointer items-center justify-center text-white"
            onClick={onClose}
          >
            &#x2715;
          </div>
        </header>
        <section>
          <ul className="relative grid grid-rows-6 gap-4">
            {NAVIGATION_ITEMS.map((item) => (
              <li key={item.label} className="">
                {item.external ? (
                  <a
                    className="flex cursor-pointer items-center justify-between gap-2"
                    target="_blank"
                    rel="noreferrer"
                    href={item.path}
                  >
                    <div className="flex gap-4">
                      <div className="flex min-h-[40px] min-w-[40px] items-center justify-center">
                        {getIconByName(item.icon)}
                      </div>

                      <div className="flex min-h-[40px] flex-col items-start justify-center">
                        <div className="font-heading text-xl uppercase leading-6">
                          {item.label}
                        </div>
                        <div className="text-xs text-core-grey">
                          {item.description}
                        </div>
                      </div>
                    </div>
                    <ExpandArrow />
                  </a>
                ) : (
                  <Link
                    className="flex cursor-pointer items-center justify-between gap-2"
                    target={item.external ? '_blank' : undefined}
                    to={item.path}
                  >
                    <div className="flex gap-4">
                      <div className="flex min-h-[40px] min-w-[40px] items-center justify-center">
                        {getIconByName(item.icon)}
                      </div>

                      <div className="flex min-h-[40px] flex-col items-start justify-center">
                        <div className="font-heading text-xl uppercase leading-6">
                          {item.label}
                        </div>
                        <div className="text-xs text-core-grey">
                          {item.description}
                        </div>
                      </div>
                    </div>
                    <ExpandArrow />
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </section>
      </div>
    </Drawer>
  );
};

export default WalletDrawer;
