import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { LAYOUT_SCROLLING_ID } from '../common/constants';
import OutsideClickHandler from './OutsideClickHandler';

type Portal = {
  isOpen: boolean;
  children: JSX.Element | JSX.Element[];
  onOutsideClick?: () => void;
  className?: string;
};

const Portal = ({ isOpen, children, onOutsideClick, className }: Portal) => {
  const [isMounted, setIsMounted] = useState(false);

  // WAIT UNTIL MOUNTED; document obj not accessible until mounted.
  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) return null;
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <OutsideClickHandler
      className={className}
      {...(onOutsideClick && { onOutsideClick: onOutsideClick })}
    >
      <>{children}</>
    </OutsideClickHandler>,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.body.querySelector(`#${LAYOUT_SCROLLING_ID}`)!
  );
};
export default Portal;
