import Cookies from 'universal-cookie';

import {
  DECREASE_REFRESH_TOKEN_DURATION_BY_SECONDS,
  JWT_TOKEN_REFRESH_TIME_SECONDS,
} from '@battlefly/common/constants';
import { UserTokenMap } from '@battlefly/ts/auth';
import jwtDecode from 'jwt-decode';
import { ConnectUserResponse } from '../redux/user/userTypes';
import { TokenPair } from '@battlefly/__generated__/globalTypes';

export const COOKIE_DURATION = 60 * 60 * 24 * 30;
export const TERMS_AND_CONDITIONS_COOKIE_NAME = '__BF_TAC__';
export const TAC_DURATION = 60 * 60 * 24 * 30;

type Jwt = {
  exp: number;
};

const cookies = new Cookies();
const userCookies: (keyof ConnectUserResponse)[] = ['token'];

export const setTACCookie = (accepted: boolean, remember: boolean) => {
  if (accepted && remember) {
    cookies.set(TERMS_AND_CONDITIONS_COOKIE_NAME, accepted, {
      maxAge: TAC_DURATION,
      sameSite: 'strict',
      path: '/',
    });
  }
};

export const getTACCookie = () => {
  return cookies.get(TERMS_AND_CONDITIONS_COOKIE_NAME);
};

export const setUserCookies = (payload: { [x: string]: unknown }) => {
  userCookies.forEach((cookie) => {
    if (payload[cookie]) {
      cookies.set(cookie, payload[cookie], {
        maxAge: COOKIE_DURATION,
        sameSite: 'strict',
        path: '/',
      });
    }
  });
};

export const setAuthCookie = (tokenMap: UserTokenMap) => {
  const existingTokenMap = cookies.get('auth') || {};
  const newTokenMap = { ...existingTokenMap, ...tokenMap };

  cookies.set('auth', JSON.stringify(newTokenMap), {
    maxAge: COOKIE_DURATION,
    sameSite: 'strict',
    path: '/',
  });
};

export const clearUserCookies = () => {
  userCookies.forEach((cookie) => {
    if (cookies.get(cookie) !== undefined) {
      cookies.remove(cookie, { path: '/' });
    }
  });
};

export const isAuthCookieValid = (authCookie: any, walletAddress: string) =>
  authCookie !== undefined && authCookie[walletAddress] !== undefined;

// TODO: This should probably also check if the graphql refresh token is still valid
export const isWalletAuthenticated = (waddr?: string) => {
  const walletAddress = waddr ?? getWalletAddress();
  const authCookie = cookies.get(`auth`);

  return isAuthCookieValid(authCookie, walletAddress);
};

export const getGraphqlToken = () => {
  const walletAddress = getWalletAddress();
  const authCookie = cookies.get(`auth`);

  if (isAuthCookieValid(authCookie, walletAddress)) {
    if (!authCookie[walletAddress].graphqlJwt) {
      return null;
    }
    return authCookie[walletAddress].graphqlJwt;
  }

  return null;
};

export const getGraphqlRefreshToken = () => {
  const walletAddress = getWalletAddress();
  const authCookie = cookies.get(`auth`);

  if (isAuthCookieValid(authCookie, walletAddress)) {
    return authCookie[walletAddress].refreshToken;
  }

  return null;
};

export const updateGraphqlTokens = (tokens: TokenPair) => {
  const walletAddress = getWalletAddress();
  const authCookie = cookies.get(`auth`);
  if (authCookie !== undefined) {
    const newAuthCookie = {
      ...authCookie,
      [walletAddress]: {
        ...authCookie[walletAddress],
        graphqlJwt: tokens.accessToken,
        refreshToken: tokens.refreshToken,
      },
    };

    cookies.set(`auth`, JSON.stringify(newAuthCookie), {
      maxAge: COOKIE_DURATION,
      sameSite: 'strict',
      path: '/',
    });
  }
};

export const getTokenDecreasedExpirationTime = (decodedToken: Jwt): number =>
  decodedToken.exp - DECREASE_REFRESH_TOKEN_DURATION_BY_SECONDS;

export const getGraphqlTokenExpiresAt = () => {
  const walletAddress = getWalletAddress();
  const authCookie = cookies.get(`auth`);

  console.log('WALLET_ADDRESS', walletAddress)
  console.log('AUTH_COOKIE', authCookie)

  if (isAuthCookieValid(authCookie, walletAddress)) {
    if (!authCookie[walletAddress].graphqlJwt) {
      return null;
    }

    const token = authCookie[walletAddress].graphqlJwt;
    const decodedToken: Jwt = jwtDecode(token);
    //Using decreased exp date to avoid fetching at last second of token life
    const expiresAt = getTokenDecreasedExpirationTime(decodedToken);
    return new Date(expiresAt * 1000).getTime();
  }
  return null;
};

export const getRefreshTokenTime = () => {
  const nowDate = new Date();
  nowDate.setSeconds(
    nowDate.getSeconds() + JWT_TOKEN_REFRESH_TIME_SECONDS - 30
  );
  return nowDate.toISOString();
};

export const removeAuthToken = (walletAddress: string) => {
  const authCookie = cookies.get(`auth`);

  if (authCookie !== undefined) {
    const newAuthCookie = {
      ...authCookie,
      [walletAddress]: undefined,
    };

    cookies.set(`auth`, JSON.stringify(newAuthCookie), {
      maxAge: COOKIE_DURATION,
      sameSite: 'strict',
      path: '/',
    });
  }
};

export const removeAuthCookie = () => {
  return cookies.remove(`auth`, { path: '/', domain: '' });
};

export const setWalletAddress = (address: string) => {
  return cookies.set('walletAddress', address);
};

export const getWalletAddress = () => {
  return cookies.get('walletAddress');
};

export const setCookie = (string: string, value: unknown) =>
  cookies.set(string, value);

export const getAllCookies = () => cookies.getAll();

export const getAuthCookie = (): UserTokenMap | null => {
  const authCookie = cookies.get(`auth`);
  if (!authCookie) {
    return null;
  }
  return JSON.parse(authCookie);
};

export const formatAuthCookie = (tokenMap: UserTokenMap): string => {
  return JSON.stringify(tokenMap);
};
