import React from 'react';

export interface ModWeaponBackgroundProps {
  uniqueId: string;
}

const ModDefenseBackground: React.FC<ModWeaponBackgroundProps> = (props) => {
  return (
    <>
      <path
        d="M103.54 814.392L86.1225 837.939L97.8036 864.795L126.904 868.108L144.325 844.563L132.642 817.702L103.54 814.392Z"
        fill={`url(#paint0_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M195.627 825.122L178.209 848.669L189.889 875.529L218.99 878.839L236.411 855.293L224.727 828.435L195.627 825.122Z"
        fill={`url(#paint1_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M152.532 793.242L135.111 816.788L146.794 843.648L175.895 846.959L193.313 823.411L181.632 796.555L152.532 793.242Z"
        fill={`url(#paint2_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M109.434 761.359L92.0136 784.905L103.696 811.765L132.798 815.076L150.216 791.529L138.535 764.672L109.434 761.359Z"
        fill={`url(#paint3_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M244.877 803.62L227.456 827.166L239.138 854.026L268.24 857.337L285.658 833.789L273.977 806.933L244.877 803.62Z"
        fill={`url(#paint4_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M158.681 739.855L141.264 763.402L152.946 790.262L182.048 793.573L199.466 770.026L187.785 743.169L158.681 739.855Z"
        fill={`url(#paint5_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M336.961 814.353L319.54 837.899L331.224 864.757L360.325 868.07L377.742 844.523L366.063 817.663L336.961 814.353Z"
        fill={`url(#paint6_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M293.864 782.47L276.443 806.016L288.127 832.874L317.227 836.187L334.645 812.64L322.965 785.781L293.864 782.47Z"
        fill={`url(#paint7_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M207.67 718.706L190.249 742.252L201.933 769.11L231.034 772.423L248.451 748.876L236.772 722.017L207.67 718.706Z"
        fill={`url(#paint8_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M164.573 686.824L147.155 710.371L158.836 737.228L187.936 740.541L205.357 716.995L193.674 690.135L164.573 686.824Z"
        fill={`url(#paint9_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M429.306 824.731L411.885 848.277L423.569 875.135L452.669 878.448L470.087 854.9L458.408 828.041L429.306 824.731Z"
        fill={`url(#paint10_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M343.111 760.966L325.693 784.513L337.377 811.371L366.477 814.684L383.895 791.137L372.215 764.278L343.111 760.966Z"
        fill={`url(#paint11_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M300.016 729.085L282.595 752.631L294.279 779.489L323.379 782.802L340.797 759.255L329.118 732.396L300.016 729.085Z"
        fill={`url(#paint12_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M256.917 697.202L239.499 720.749L251.181 747.606L280.281 750.919L297.701 727.373L286.019 700.513L256.917 697.202Z"
        fill={`url(#paint13_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M213.822 665.322L196.402 688.868L208.086 715.725L237.186 719.039L254.604 695.491L242.924 668.632L213.822 665.322Z"
        fill={`url(#paint14_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M435.196 771.696L417.778 795.244L429.458 822.103L458.56 825.413L475.98 801.867L464.296 775.01L435.196 771.696Z"
        fill={`url(#paint15_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M392.101 739.816L374.681 763.362L386.365 790.22L415.465 793.533L432.883 769.986L421.202 743.129L392.101 739.816Z"
        fill={`url(#paint16_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M349.004 707.933L331.586 731.48L343.267 758.336L372.367 761.65L389.788 738.104L378.104 711.246L349.004 707.933Z"
        fill={`url(#paint17_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M262.811 644.169L245.391 667.715L257.074 694.573L286.175 697.886L303.593 674.339L291.912 647.483L262.811 644.169Z"
        fill={`url(#paint18_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M219.713 612.287L202.295 635.834L213.976 662.691L243.076 666.004L260.497 642.458L248.813 615.6L219.713 612.287Z"
        fill={`url(#paint19_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M484.446 750.194L467.025 773.74L478.709 800.598L507.809 803.911L525.227 780.363L513.546 753.507L484.446 750.194Z"
        fill={`url(#paint20_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M441.348 718.312L423.931 741.859L435.612 768.715L464.712 772.029L482.133 748.483L470.449 721.625L441.348 718.312Z"
        fill={`url(#paint21_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M398.254 686.43L380.833 709.976L392.517 736.834L421.617 740.147L439.035 716.6L427.354 689.743L398.254 686.43Z"
        fill={`url(#paint22_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M355.156 654.548L337.738 678.095L349.419 704.952L378.52 708.265L395.94 684.719L384.256 657.861L355.156 654.548Z"
        fill={`url(#paint23_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M312.058 622.665L294.641 646.212L306.322 673.069L335.425 676.383L352.843 652.836L341.159 625.978L312.058 622.665Z"
        fill={`url(#paint24_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M268.964 590.784L251.543 614.33L263.227 641.187L292.327 644.501L309.745 620.953L298.064 594.097L268.964 590.784Z"
        fill={`url(#paint25_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M490.335 697.162L472.917 720.709L484.599 747.566L513.702 750.88L531.119 727.333L519.437 700.473L490.335 697.162Z"
        fill={`url(#paint26_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M404.142 633.398L386.724 656.946L398.405 683.802L427.507 687.113L444.926 663.569L433.244 636.709L404.142 633.398Z"
        fill={`url(#paint27_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M361.047 601.517L343.626 625.063L355.31 651.921L384.412 655.231L401.828 631.687L390.149 604.827L361.047 601.517Z"
        fill={`url(#paint28_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M317.949 569.635L300.53 593.178L312.213 620.039L341.314 623.349L358.734 599.806L347.051 572.946L317.949 569.635Z"
        fill={`url(#paint29_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M274.852 537.753L257.436 561.297L269.115 588.157L298.217 591.467L315.636 567.924L303.954 541.063L274.852 537.753Z"
        fill={`url(#paint30_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M453.393 611.896L435.974 635.439L447.656 662.3L476.758 665.61L494.174 642.066L482.495 615.206L453.393 611.896Z"
        fill={`url(#paint31_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M410.294 580.013L392.878 603.557L404.557 630.416L433.659 633.727L451.078 610.184L439.396 583.323L410.294 580.013Z"
        fill={`url(#paint32_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M324.102 516.249L306.683 539.792L318.365 566.653L347.467 569.964L364.886 546.421L353.204 519.56L324.102 516.249Z"
        fill={`url(#paint33_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M502.381 590.744L484.96 614.29L496.644 641.147L525.744 644.461L543.165 620.915L531.481 594.057L502.381 590.744Z"
        fill={`url(#paint34_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M459.283 558.861L441.865 582.409L453.546 609.265L482.646 612.578L500.067 589.032L488.383 562.175L459.283 558.861Z"
        fill={`url(#paint35_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M416.188 526.98L398.768 550.526L410.451 577.384L439.552 580.697L456.97 557.15L445.288 530.293L416.188 526.98Z"
        fill={`url(#paint36_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M373.09 495.098L355.672 518.645L367.353 545.501L396.453 548.815L413.874 525.269L402.19 498.411L373.09 495.098Z"
        fill={`url(#paint37_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M329.992 463.215L312.574 486.762L324.258 513.62L353.358 516.933L370.776 493.386L359.095 466.53L329.992 463.215Z"
        fill={`url(#paint38_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M508.533 537.358L491.112 560.904L502.796 587.762L531.896 591.075L549.317 567.529L537.633 540.671L508.533 537.358Z"
        fill={`url(#paint39_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M465.435 505.476L448.018 529.023L459.699 555.879L488.799 559.193L506.22 535.647L494.536 508.789L465.435 505.476Z"
        fill={`url(#paint40_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M422.341 473.594L404.92 497.14L416.604 523.998L445.704 527.311L463.122 503.764L451.441 476.908L422.341 473.594Z"
        fill={`url(#paint41_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
      <path
        d="M379.243 441.712L361.825 465.259L373.506 492.116L402.606 495.429L420.027 471.883L408.343 445.025L379.243 441.712Z"
        fill={`url(#paint42_linear_3_6684${props.uniqueId})`}
        fillOpacity="0.1"
      />
    </>
  );
};

export default ModDefenseBackground;
