import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { createAction } from '@reduxjs/toolkit';

export const setActiveBattlefliesList = createAction<BattleFly[]>(
  'active/setActiveBattlefliesList'
);

/**
 * Action dispatched after calling revealactive contract to return
 * token id's staked by user. this array is used as a reference,
 * when calling getActiveBattlefliesByTokenId
 */
export const setAllActiveTokenIds = createAction<number[]>(
  'active/setAllActiveTokenIds'
);

export const addBattleflyToWithdrawCart = createAction<BattleFly>(
  'active/addBattleflyToWithdrawCart'
);

export const emptyWithdrawCart = createAction<{ id: string }>(
  'active/battleflies/emptyWithdrawCart'
);

export const removeBattleflyFromWithdrawCart = createAction<{
  id: string;
}>('active/battleflies/removeBattleflyFromWithdrawCart');
