import { ApolloProvider } from '@apollo/client';
import useInitAppConfig from '@battlefly/common/initAppConfig';
import PasswordModal from '@battlefly/components/PasswordModal/PasswordModal';
import { usePasswordPage } from '@battlefly/hooks/usePasswordPage';
// END WAGMI
import client from '@battlefly/util/apolloClient';
import wagmiClient, { chains } from '@battlefly/util/wagmiClient';

import { midnightTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { WagmiConfig } from 'wagmi';
import { ENVIRONMENT } from '../common/environmentVariables';
import '../components/Accordion/Accordion.css';
import Layout from '../components/Layout/Layout';
import { AppContextProvider } from '../context';
import { wrapper } from '../store/store';
import '../styles/globals.css';
import useChameleonTracking from '@battlefly/hooks/useChameleonTracking';
import { useWalletManager } from '@battlefly/hooks/auth';

function MyApp({ Component, pageProps, router }: AppProps) {
  // const router2 = useRouter();
  // useEffect(() => {
  //   const handleRouteChange = (url: string) => {
  //     gtag.pageview(url);
  //   };
  //   router2.events.on('routeChangeComplete', handleRouteChange);
  //   return () => {
  //     router2.events.off('routeChangeComplete', handleRouteChange);
  //   };
  // }, [router2.events]);
  return (
    <>
      <Head>
        <title>BattleFly</title>
      </Head>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      {/* Only do analytics in production */}
      {ENVIRONMENT != 'development' && (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
          />
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}', {
              page_path: window.location.pathname,
            });
          `,
            }}
          />
        </>
      )}
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          chains={chains}
          // avatar={CustomAvatar}
          theme={midnightTheme()}
        >
          <ApolloProvider client={client}>
            <AppContextProvider>
              <HeartOfBattlefly
                router={router}
                Component={Component}
                pageProps={pageProps}
              />
            </AppContextProvider>
          </ApolloProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
}

const HeartOfBattlefly = ({
  pageProps,
  Component,
  router,
}: Partial<AppProps>) => {
  const [mounted, setMounted] = useState(false);

  // When mounted on client, now we can show the UI
  useEffect(() => {
    setMounted(true);
  }, []);

  useInitAppConfig();
  useChameleonTracking();
  useWalletManager();

  const { loggedIn, tryPassword } = usePasswordPage();

  if (!mounted) return null;

  return (
    <BrowserRouter>
      <Layout loggedIn={loggedIn}>
        {loggedIn && (
          <Component onClick style="container mx-auto" {...pageProps} />
        )}
        {!loggedIn && <PasswordModal onSubmit={tryPassword} />}
      </Layout>
    </BrowserRouter>
  );
};

export default wrapper.withRedux(MyApp);
