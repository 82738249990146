import React from 'react';
import classnames from 'classnames';

import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { Card } from 'components/core';
import Chevron from 'components/Accordion/Chevron';
import Loadout from '@battlefly/components/Loadout';
import { Inventory as InventoryIcon } from 'components/Icons';

type CurrentInventoryProps = {
  battlefly: BattleFly;
};

const CurrentInventory = (props: CurrentInventoryProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { battlefly } = props;

  function handleClose(event: React.SyntheticEvent) {
    if (isOpen) {
      setIsOpen(false);
      event.stopPropagation();
    }
  }

  return (
    <Card onClick={() => setIsOpen(true)} flat>
      <div
        className={classnames('flex items-center justify-center gap-4', {
          'mb-4': isOpen,
        })}
      >
        <div className="flex cursor-pointer gap-4" onClick={handleClose}>
          <div className="font-heading text-xl">Current Inventory</div>
          <Chevron
            width={14}
            fill={'#fff'}
            className={classnames({
              '-rotate-90': !isOpen,
              'rotate-90': isOpen,
            })}
          />
        </div>
      </div>
      {isOpen && (
        <div className="flex overflow-x-scroll">
          <Card className="!w-full !p-2 !px-4">
            <Loadout
              mods={battlefly.mods}
              elementClasses="w-[200px]"
              modClasses="w-[200px]"
            />
            <div className="mt-2 flex items-center gap-2 font-heading text-xl">
              <InventoryIcon className="h-5 w-5" /> Current Loadout
            </div>
          </Card>

          <Loadout
            mods={battlefly.inventory}
            containerClasses="!ml-4 pt-2"
            elementClasses="w-[200px]"
            modClasses="w-[200px]"
          />
        </div>
      )}
    </Card>
  );
};

export default CurrentInventory;
