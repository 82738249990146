import * as Types from '@battlefly/__generated__/globalTypes';

import { gql } from '@apollo/client';
import { TopBattleFlyGlobalStatisticsFragmentDoc } from '../../../components/Arenas/Tabs/__generated__/TopBattleFlyPanel';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStatisticsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStatisticsQuery = { __typename?: 'Query', getGlobalStatistics: { __typename?: 'GlobalStatistics', id: string, totalBattleFlies: number, averageDailyBattles: number, totalBattlesInDay: number, totalMagicWonInDay: number, top: { __typename?: 'GlobalTop', topBattleFlyMagicWin: number | null, topBattleFly: { __typename?: 'BattleFly', id: string, name: string, hyperdomeLosses: number, hyperdomeWins: number, provingGroundsLosses: number, provingGroundsWins: number, appearance: { __typename?: 'BattleFlyAppearance', image: string | null }, owner: { __typename?: 'Identity', user: { __typename?: 'User', wallets: Array<{ __typename?: 'Wallet', address: string }> } } | null } | null } } };


export const GetStatisticsDocument = gql`
    query getStatistics {
  getGlobalStatistics {
    id
    totalBattleFlies
    averageDailyBattles
    totalBattlesInDay
    totalMagicWonInDay
    ...TopBattleFlyGlobalStatistics
  }
}
    ${TopBattleFlyGlobalStatisticsFragmentDoc}`;

/**
 * __useGetStatisticsQuery__
 *
 * To run a query within a React component, call `useGetStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<GetStatisticsQuery, GetStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatisticsQuery, GetStatisticsQueryVariables>(GetStatisticsDocument, options);
      }
export function useGetStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatisticsQuery, GetStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatisticsQuery, GetStatisticsQueryVariables>(GetStatisticsDocument, options);
        }
export type GetStatisticsQueryHookResult = ReturnType<typeof useGetStatisticsQuery>;
export type GetStatisticsLazyQueryHookResult = ReturnType<typeof useGetStatisticsLazyQuery>;
export type GetStatisticsQueryResult = Apollo.QueryResult<GetStatisticsQuery, GetStatisticsQueryVariables>;