import React from 'react';
import classnames from 'classnames';

import ModCompactDetails from '@battlefly/components/BattleflyDetails/Mods/ModCompactDetails';
import {
  ModPack,
  Mod,
  BattleFly,
  ModBlueprintInModPack,
} from '@battlefly/__generated__/globalTypes';
import { UserBalanceContext } from 'context/userBalanceContext';
import { UPGRADE_MOD_IN_MOD_PACK_PRICE } from '@battlefly/common/constants';
import { Button, Link } from 'components/core';
import { DesktopOnly, MobileOnly } from 'components/utility';
import Chevron from 'components/Accordion/Chevron';
import ModPackActionCard from './ModPackActionCard';
import ActionPanel from './SelectActionPanel';
import CurrentInventory from './CurrentInventory';
import EmptySlot from './EmptySlot';

type ModpackPageProps = {
  modPack: ModPack;
  battlefly: BattleFly;
  handleSelectMod: (mod: ModBlueprintInModPack) => void;
  handleUpgradeRarity: (mod: ModBlueprintInModPack) => void;
  handleReroll: () => void;
  handleRecycle: () => void;
  isUpgradingRarity: boolean;
  isRerolling: boolean;
  isRerollingDisabled: boolean;
  isRecycling: boolean;
  isRecycled: boolean;
  isRecyclingDisabled: boolean;
};

const ModpackPage = (props: ModpackPageProps) => {
  const [activeModId, setActiveModId] = React.useState<string | null>(null);
  const { nectarBalance } = React.useContext(UserBalanceContext);
  const {
    modPack,
    handleSelectMod,
    handleUpgradeRarity,
    isUpgradingRarity,
    battlefly,
    isRecycled,
  } = props;
  const lowBalance = nectarBalance < UPGRADE_MOD_IN_MOD_PACK_PRICE;
  return (
    <div className="max-w-[100%]">
      <section className="md:relative md:flex md:overflow-y-scroll">
        <header className="z-50 h-full p-4 md:absolute md:pt-16 md:pl-8">
          <div className="">
            <div className="font-heading text-4xl leading-[40px] text-[#5BF8FC]">
              Scavenge Haul{' '}
            </div>
            <div className="mb-6 md:mb-8">#{modPack.id}</div>
          </div>
          <div className="md:w-[300px] md:-translate-x-12">
            <ModPackActionCard className="" {...props} />
          </div>
          <DesktopOnly xl className="mt-8 mr-12">
            <Link to="/battleflies">
              <Button light transparent>
                <div className="relative">
                  <Chevron
                    fill="#fff"
                    className="absolute -left-7 h-6 w-6 rotate-180"
                  />
                  <span>Take me to mission Control</span>
                </div>
              </Button>
            </Link>
          </DesktopOnly>
        </header>
        <div
          className={classnames({
            'pointer-events-none relative opacity-70': isRecycled,
          })}
        >
          {isRecycled && (
            <div className="absolute left-1/3">
              This haul has been recycled.
            </div>
          )}
          <div className="flex overflow-x-scroll py-[40px] pb-16 md:pl-64 md:pt-[70px]">
            <ul className="ml-16 mr-16 flex gap-8">
              {modPack.modBlueprints.map((mod) => {
                return (
                  <li key={mod.id} className="relative">
                    {mod.isPicked && (
                      <div className="h-[370px] w-[290px]">
                        <EmptySlot isPicked />
                      </div>
                    )}
                    {!mod.isPicked && (
                      <ModCompactDetails
                        onClick={() => setActiveModId(mod.id)}
                        className="cursor-pointer"
                        highlight
                        mod={
                          {
                            id: mod.id,
                            name: mod.name,
                            type: mod.type,
                            rarity: mod.rarity,
                            blueprint: mod,
                          } as any as Mod
                        }
                      />
                    )}
                    {activeModId === mod.id && (
                      <ActionPanel
                        upgradeDisabled={mod.isUpgraded || lowBalance}
                        isUpgradingRarity={isUpgradingRarity}
                        className="absolute bottom-0 left-0 z-50 w-full"
                        handleClose={() => setActiveModId(null)}
                        handleSelect={() => handleSelectMod(mod)}
                        handleUpgrade={() => handleUpgradeRarity(mod)}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
      <MobileOnly xl className="mb-12 flex justify-center">
        <div className="flex min-w-[300px] flex-col gap-4">
          <Link to="/battleflies">
            <Button light transparent>
              <div className="relative">
                <Chevron
                  fill="#fff"
                  className="absolute -left-7 h-6 w-6 rotate-180"
                />
                <span>Take me to mission Control</span>
              </div>
            </Button>
          </Link>
        </div>
      </MobileOnly>
      <section className="mt-8 md:mt-28 md:px-4">
        <CurrentInventory battlefly={battlefly} />
      </section>
    </div>
  );
};

export default ModpackPage;
