import React from 'react';
import Message from '@battlefly/components/Message';

const NotAuthorizedBlock = () => {
  return (
    <div className="scrollbar-customized flex justify-center mobile:flex-wrap">
      <Message text="Connect wallet first" display={true} />
    </div>
  );
};

export default NotAuthorizedBlock;
