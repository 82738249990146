import React from 'react';
import { IconProps } from '@battlefly/components/Icons';

const MarkAmmunition: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="149"
      height="128"
      viewBox="0 0 149 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M116.194 98.922L128.09 92.4951V88.922L113.012 78.2354C114.66 73.711 115.559 68.8258 115.559 63.7313C115.559 40.3252 96.585 21.3514 73.179 21.3514C72.7346 21.3514 72.2917 21.3592 71.8505 21.3725L73.179 19.9862L72.4534 17.0199L3.21307 0.213394L0 2.80869L32.4119 28.639L20.5155 35.0659V38.639L33.7966 48.0518C31.8636 52.9018 30.7999 58.1932 30.7999 63.7329C30.7999 87.1389 49.7737 106.113 73.1797 106.113C74.463 106.113 75.7329 106.056 76.988 105.944L75.4259 107.574L76.1515 110.54L145.393 127.348L148.606 124.753L116.194 98.922Z"
        fill="url(#paint0_linear_1_1125)"
      />
      <path
        d="M60.0082 43.9874L55.8525 47.8893L55.683 68.0651L58.486 73.1511L58.4618 76.5219L56.6257 76.5102L56.6139 78.2979L58.4501 78.3096L58.4384 79.1312L56.6022 79.1195L56.5663 83.1667L65.2402 83.2394L65.2761 79.1922L63.44 79.1804L63.4517 78.3588L65.2878 78.3705L65.2995 76.5828L63.4634 76.5711L63.4876 73.2003L66.375 68.1628L66.5445 47.9869L62.4614 44.0124L60.0082 43.9874ZM60.2378 71.8108L57.8823 67.5223V67.0873L60.2745 67.1115L60.2378 71.8108ZM60.2863 65.9509L57.894 65.9267L57.9909 54.6911L60.3831 54.7153L60.2863 65.9509ZM61.2406 79.1562H60.6244L60.6361 78.3346H61.2524L61.2406 79.1562ZM61.2649 76.5461H60.6486L60.6846 72.5958H61.3008L61.2649 76.5461ZM64.1765 67.57L61.7483 71.8101L61.7842 67.1107L64.1765 67.1349V67.57ZM64.1882 65.9876L61.7959 65.9634L61.8928 54.7278L64.285 54.752L64.1882 65.9876Z"
        fill="url(#paint1_linear_1_1125)"
      />
      <path
        d="M73.1768 47.769L69.4319 51.2969L69.2749 69.5272L71.7757 74.1179L71.7515 77.1623L70.0965 77.1506L70.0848 78.7571L71.7398 78.7689V79.5061L70.0848 79.4944L70.0489 83.155L77.8894 83.2151L77.9254 79.5545L76.2704 79.5428V78.8056L77.9254 78.8173L77.9371 77.2107L76.2821 77.199L76.3063 74.1546L78.9157 69.5998L79.0727 51.3695L75.3879 47.7815L73.1768 47.769ZM73.3455 72.8855L71.2196 69.032V68.6337L73.3822 68.6454L73.3455 72.8855ZM73.3939 67.6184L71.2313 67.6067L71.3157 57.4582L73.4783 57.47L73.3939 67.6184ZM74.264 79.5428H73.7079V78.8056H74.264V79.5428ZM74.2757 77.1865H73.7196L73.7555 73.6228H74.3116L74.2757 77.1865ZM76.9218 69.0804L74.7232 72.9105L74.7591 68.6704L76.9218 68.6821V69.0804ZM76.9343 67.6426L74.7716 67.6309L74.856 57.4825L77.0186 57.4942L76.9343 67.6426Z"
        fill="url(#paint2_linear_1_1125)"
      />
      <path
        d="M85.0286 51.3937L81.6578 54.5591L81.5008 70.9291L83.772 75.0489L83.7478 77.791L82.2615 77.7793L82.2498 79.2288L83.736 79.2406V79.9052L82.2498 79.8935L82.2256 83.1792L89.2687 83.2393L89.2929 79.9536L87.8067 79.9419V79.2773L89.2929 79.289L89.3046 77.8394L87.8184 77.8277L87.8426 75.0856L90.1864 71.0025L90.3192 54.6325L87.0093 51.407L85.0286 51.3937ZM85.1973 73.9367L83.2885 70.4691V70.1184L85.2332 70.1301L85.1973 73.9367ZM85.2332 69.2007L83.2885 69.189L83.3612 60.0801L85.3059 60.0918L85.2332 69.2007ZM86.0064 79.9169H85.5113V79.2523H86.0064V79.9169ZM86.0306 77.8027H85.523L85.5472 74.6014H86.0549L86.0306 77.8027ZM88.4112 70.5183L86.4297 73.9617L86.4657 70.1559L88.4104 70.1676V70.5183H88.4112ZM88.4112 69.2249L86.4665 69.2132L86.5391 60.1043L88.4838 60.116L88.4112 69.2249Z"
        fill="url(#paint3_linear_1_1125)"
      />
      <path
        d="M76.763 107.698L143.717 124.012L113.314 99.9123L106.393 104.238L104.786 101.317L123.82 89.7772L108.559 77.8004L108.16 78.3526C110.088 73.7493 111.099 68.7626 111.099 63.6196C111.124 53.4353 107.149 43.8671 99.9481 36.6662C92.7479 29.4661 83.1798 25.5033 72.9947 25.5033C63.2812 25.5033 54.1278 29.1084 47.055 35.6884L46.224 34.4778L70.1658 19.2763L3.21307 2.96333L33.6154 27.0629L40.5367 22.7377L42.1432 25.6587L23.1108 37.1973L37.8602 48.7734C35.8983 53.4087 34.8666 58.4337 34.8666 63.6196C34.8666 73.804 38.8294 83.3721 46.0295 90.573C53.2297 97.7739 62.7978 101.736 72.9829 101.736C83.0134 101.736 92.4457 97.8926 99.6075 90.8971L100.705 92.4966L76.763 107.698ZM91.9146 92.6021L89.5833 89.0383C84.8113 92.1553 79.1209 93.9673 72.9962 93.9673C66.9317 93.9673 61.2897 92.1913 56.5536 89.1352L54.2458 92.7114C44.701 86.5375 38.371 75.7971 38.371 63.6196C38.371 51.5265 44.6167 40.847 54.0403 34.6614L56.3842 38.2134C61.1561 35.0847 66.8583 33.2719 72.9837 33.2719C79.0725 33.2719 84.7387 35.0722 89.4989 38.1525L91.7943 34.5762C101.302 40.7493 107.608 51.4655 107.608 63.6196C107.62 75.737 101.362 86.4048 91.9146 92.6021Z"
        fill="url(#paint4_linear_1_1125)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_1125"
          x1="-0.000257733"
          y1="63.7805"
          x2="148.606"
          y2="63.7805"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#434343" />
          <stop offset="0.4888" stopColor="#3B3C3B" />
          <stop offset="1" stopColor="#4A494A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_1125"
          x1="55.6831"
          y1="63.6135"
          x2="66.5441"
          y2="63.6135"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_1125"
          x1="69.2744"
          y1="65.4921"
          x2="79.0722"
          y2="65.4921"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_1125"
          x1="81.5005"
          y1="67.3164"
          x2="90.3198"
          y2="67.3164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1_1125"
          x1="3.21304"
          y1="63.4873"
          x2="143.717"
          y2="63.4873"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MarkAmmunition;
