import React from 'react';

import { PersonalizedBattleLogItem } from '@battlefly/ts/BattleLog';
import BattleLogListItem from './BattleLogListItem';

const PersonalizedBattleLogItem = (props: PersonalizedBattleLogItem) => {
  const { ownBattleFly, opponent } = props;

  const log = {
    ...props,
    battleflies: [ownBattleFly, opponent],
  };
  return <BattleLogListItem log={log} />;
};

export default PersonalizedBattleLogItem;
