import classnames from 'classnames';

type CardProps = {
  className?: string;
  containerClassName?: string;
  onClick?: () => void;
  children: React.ReactNode;
  flat?: boolean;

  darkOrchid?: boolean;
  darkBlue?: boolean;
  blueMint?: boolean;
  square?: boolean;
  pink?: boolean;
  mark?: boolean;
  gfly?: boolean;
  treasure?: boolean;
  leaderboard?: boolean;
};

const nameClassesMap = {
  darkOrchid: ['bg-core-dark-orchid', 'gra-card-dark-orchid-border'],
  darkBlue: ['gra-card-dark-blue', 'gra-card-border'],
  blueMint: ['gra-card-blue-mint', 'gra-card-blue-mint-border'],
  mark: ['gra-card-mark', 'gra-card-border'],
  pink: ['gra-card-pink', 'gra-card-pink-border'],
  square: ['gra-card-square', 'gra-card-square-border'],
  gfly: ['gra-card-gfly', 'gra-card-border'],
  treasure: ['gra-card-treasure', 'gra-card-border'],
  leaderboard: ['gra-card-leaderboard', 'gra-card-border'],
};

function getColors(props: CardProps) {
  const colorSettingKey = Object.keys(nameClassesMap).find((name) => {
    return props[name as keyof CardProps];
  });

  if (colorSettingKey) {
    return nameClassesMap[colorSettingKey as keyof typeof nameClassesMap];
  }

  return ['gra-card', 'gra-card-border'];
}

const Card = (props: CardProps) => {
  const [bgColor, gradientColor] = getColors(props);
  const { className, containerClassName, children, onClick, flat } = props;

  function handleClick() {
    if (onClick) {
      onClick();
    }
  }

  return (
    <div
      className={classnames(
        gradientColor,
        'box-shadow-card rounded-[16px] p-[2px]',
        containerClassName
      )}
    >
      <div
        onClick={handleClick}
        className={classnames(bgColor, 'rounded-[14px] p-8', className, {
          'shadow-none': flat,
        })}
      >
        {children}
      </div>
    </div>
  );

  // return (
  //   <div className="gra-card rounded-2xl border border-core-grey">
  //     {children}
  //   </div>
  // );
};

export default Card;
