import React from 'react';

import { FullScreenCard } from 'components/Layout';
import { BattleFly } from '@battlefly/__generated__/globalTypes';
import BattlelogPage from 'components/BattlelogPage';
import useBattleflyDetails from 'hooks/components/useBattleflyDetails';
import { useCombatLogHistoryData } from 'hooks/useCombatLogsData';
import { PaginatedList } from '@battlefly/ts/pagination';
import { BattleLog } from '@battlefly/ts/BattleLog';

const BattlelogRoute = () => {
  const { data, loading, refetch } = useBattleflyDetails();
  const battlefly: BattleFly = data?.getBattleFly?.battleFly;
  const { logs, ...rest } = useCombatLogHistoryData(
    battlefly?.id?.toString(),
    10
  );
  const logHistory: PaginatedList<BattleLog> = {
    items: logs,
    ...rest,
  };

  return (
    <FullScreenCard>
      {loading && <div>Loading...</div>}
      {battlefly && (
        <BattlelogPage logHistory={logHistory} battlefly={battlefly} />
      )}
    </FullScreenCard>
  );
};

export default BattlelogRoute;
