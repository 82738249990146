import { Link as RouterLink } from 'react-router-dom';

type LinkProps = {
  to: string;
  target?: string;
  className?: string;
  children: React.ReactNode;
};

const Link = (props: LinkProps) => {
  return <RouterLink {...props} />;
};

export default Link;
