import {
  failureToast,
  pendingSignatureToast,
  successToast,
} from '@battlefly/components/CustomToast/CustomToast';
import { BigNumber } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { BattleflyGame, CONTRACT_ABIS } from '../../common/constants';
import { useContractAddresses } from './useContractAddresses';
import {
  stakeBfCartIds,
  unStakeBfCartIds,
} from '@battlefly/graphql/cache/cache';

/**
 *
 * @param tokenId: BattleFly Token Id
 * @returns Owner Address of Battlefly TokenId.
 */
export const useOwnerOfStakingBattlefly = (tokenId: string | string[]) => {
  const contractAddress = useContractAddresses();

  const { data, isError, isLoading, refetch } = useContractRead({
    address: contractAddress[BattleflyGame.RevealStakingContract],
    abi: CONTRACT_ABIS[BattleflyGame.RevealStakingContract],
    functionName: 'OwnerOfStakingBattlefly',
    args: [tokenId],
  });

  return {
    refetch,
    ownerAddress: data ?? '',
    isLoading,
    isError,
  };
};

/**
 *
 * @param address UserBlockChainAddress
 * @returns battlefly token ids owned by user
 */
export const useStakingBattlefliesOfOwner = () => {
  const contractAddress = useContractAddresses();
  const { address } = useAccount();

  const { data, isError, isLoading, refetch } = useContractRead({
    address: contractAddress[BattleflyGame.RevealStakingContract],
    abi: CONTRACT_ABIS[BattleflyGame.RevealStakingContract],
    functionName: 'stakingBattlefliesOfOwner',
    enabled: !!address,
    args: [address],
    watch: false,
  });

  const dataOrDefault = Array.isArray(data) ? data : [];

  return {
    refetch,
    stakedTokenIds: dataOrDefault.map((id) => id.toNumber()) ?? [],
    isLoading,
    isError,
  };
};

export const useBulkStakeBattlefly = () => {
  let nowStakingIds: number[] = [];
  let successCb: (ids: number[]) => void = null;
  const contractAddress = useContractAddresses();
  const { write, isLoading, isSuccess, status } = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: contractAddress[BattleflyGame.RevealStakingContract],
    abi: CONTRACT_ABIS[BattleflyGame.RevealStakingContract],
    functionName: 'bulkStakeBattlefly',
    onMutate({ args, overrides }) {
      pendingSignatureToast();
      console.log('Mutate', { args, overrides });
    },
    onSuccess(data) {
      successToast(`BattleFly(s) Staked`, data);
      if (successCb) {
        successCb(nowStakingIds);
        successCb = null;
      }
      stakeBfCartIds([]);
    },
    onError(error) {
      failureToast('Failed to Stake BattleFly(s)');
      console.log('Error', error);
    },
  });
  const bulkStakeBattlefly = async (
    selectedTokenIds: (number | string)[],
    successCallback?: (ids: number[]) => void
  ) => {
    if (selectedTokenIds.length === 0) {
      failureToast('No BattleFly(s) selected');
      return;
    }
    nowStakingIds = selectedTokenIds.map((x) => Number(x));
    if (successCallback) {
      successCb = successCallback;
    }
    write({ recklesslySetUnpreparedArgs: [selectedTokenIds] });
  };

  return { bulkStakeBattlefly, isLoading, isSuccess, status };
};

export const useBulkUnstakeBattlefly = () => {
  const contractAddress = useContractAddresses();
  const { writeAsync, isLoading, isSuccess, status } = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: contractAddress[BattleflyGame.RevealStakingContract],
    abi: CONTRACT_ABIS[BattleflyGame.RevealStakingContract],
    functionName: 'bulkUnstakeBattlefly',
    onMutate({ args, overrides }) {
      pendingSignatureToast();
      console.log('Mutate', { args, overrides });
    },
    onSuccess(data) {
      successToast(`BattleFly(s) Unstaked`, data);
      unStakeBfCartIds([]);
    },
    onError(error) {
      failureToast(error);
      console.log('Error', error);
    },
  });
  const bulkUnstakeBattlefly = async (
    selectedTokenIds: string[],
    stagesIds: number[],
    v: number,
    r: string,
    s: string,
    onSuccess?: (ids: number[]) => void
  ) => {
    if (selectedTokenIds.length === 0) {
      failureToast('No BattleFly(s) selected');
      return;
    }
    try {
      await writeAsync({
        recklesslySetUnpreparedArgs: [
          selectedTokenIds.map((i) => BigNumber.from(i)),
          stagesIds.map(() => BigNumber.from(0)),
          v,
          r,
          s,
        ],
      }).then(() => {
        if (onSuccess) {
          onSuccess(selectedTokenIds.map((x) => Number(x)));
        }
      });
    } catch (error) {
      console.error(error.message);
      failureToast(
        'There was an error during unstaking. Please try again later.'
      );
    }
  };

  return { bulkUnstakeBattlefly, isLoading, isSuccess, status };
};
