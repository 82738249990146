import {
  BattleFlyRacerObject,
  LeaderboardRecord,
  UnityLeaderboard,
} from './types';

export const formatDate = (date: Date) => date.toISOString().split('T')[0]; // YYYY-MM-DD

export const mapBattleFlies = (battleFlies: BattleFlyRacerObject[]) => {
  return battleFlies?.map((battleFly) => {
    return { ...battleFly, id: parseInt(battleFly.id, 10) };
  });
};

export const getHighestScore = (
  history: { score: number; droplets: number }[]
) => {
  const highScore = history?.reduce((prev, current) => {
    if (+current.score > +prev.score) {
      return current;
    } else {
      return prev;
    }
  }) ?? { score: 0, droplets: 0 };
  return highScore;
};

export const transformLeaderboard = (
  leaderboardRecord: LeaderboardRecord
): UnityLeaderboard => ({
  displayName: leaderboardRecord.nickName,
  nectar: leaderboardRecord.droplets,
  avatarUrl: leaderboardRecord.avatar,
  score: leaderboardRecord.score,
});

export const RACER_BASE_URL =
  process.env.NEXT_PUBLIC_ENV == 'production'
    ? 'https://racer.battlefly.game'
    : 'https://racer-staging.battlefly.game';
