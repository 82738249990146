import React from 'react';

interface ModStatPlateProps {
  statName: string;
  statValue: string;
  statIcon: React.ReactNode;
  unitName?: string;
  containerClasses?: string;
}

const ModStatPlate: React.FC<ModStatPlateProps> = (props) => {
  return (
    <div className="relative aspect-[100/130] w-full rounded-[20%] bg-[#D9D9D930]">
      {props.statIcon}
      <div className="absolute left-0 top-[35%] w-full text-center">
        <div className="w-full font-heading text-[12px] leading-none xl:text-[14px] 3xl:text-[16px]">
          {props.statValue}
          {props?.unitName && (
            <span className="text-[8px] font-light text-[#D9D9D9] 3xl:text-[10px]">
              {props.unitName}
            </span>
          )}
        </div>
        <div className="-mt-[8%] w-full text-center font-main text-[5px] leading-none text-[#D9D9D9] md:text-[6px] xl:mt-0 xl:text-[8px] mobile:mt-0 3xl:text-[10px]">
          {props.statName}
        </div>
      </div>
    </div>
  );
};

export default ModStatPlate;
