/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AnyAction,
  AsyncThunk,
  AsyncThunkPayloadCreator,
  combineReducers,
  configureStore,
  Dispatch,
} from '@reduxjs/toolkit';
import {
  nextReduxCookieMiddleware,
  wrapMakeStore,
} from 'next-redux-cookie-wrapper';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import activeSlice from '../redux/active/activeReducer';
import holdingSlice from '../redux/holding/holdingReducer';
import stakingSlice from '../redux/staking/stakingReducer';
import { LOGOUT } from '../redux/user';
import userSlice from '../redux/user/userReducer';

/**
 * Whatever redux slice key value is placed in this array, its information will be stored as a cookie
 * so the information will persist.
 * Staking and holding do not need to persist. They are merely stores for holding cart data.
 */
const PERSISTED_REDUCERS = ['user', 'marketplace'];
const combinedReducer = combineReducers({
  /**
   * Reducer for the user.
   *
   */
  user: userSlice,
  // reducer for owned battleflies
  holding: holdingSlice,
  // reducer for staked batleflies
  staking: stakingSlice,
  // reducer for active in-game battleflies
  active: activeSlice,
});

const reducer = (state: any, action: AnyAction) => {
  // logout action clears state;
  if (action.type === LOGOUT) {
    return clearReduxStateOnLogout(action);
  }
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};

const clearReduxStateOnLogout = (action: AnyAction) => {
  return combinedReducer(undefined, action);
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(
      nextReduxCookieMiddleware({
        // PERSIST AUTH SLICE IN STATE
        subtrees: PERSISTED_REDUCERS,
      })
    ),
});

export const appStore = store;

const makeStore = wrapMakeStore(() => store);

type Store = ReturnType<typeof makeStore>;

export type AppDispatch = Store['dispatch'];
export type RootState = ReturnType<Store['getState']>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetStateFromReducers<T> = T extends (...args: any[]) => infer Ret
  ? Ret
  : T extends Record<any, any>
  ? {
      [K in keyof T]: GetStateFromReducers<T[K]>;
    }
  : T;

export type GlobalState = GetStateFromReducers<typeof combinedReducer>;

export const wrapper = createWrapper(makeStore, { debug: false });

declare module '@reduxjs/toolkit' {
  type AsyncThunkConfig = {
    state?: unknown;
    dispatch?: Dispatch;
    extra?: unknown;
    rejectValue?: unknown;
    serializedErrorType?: unknown;
  };

  function createAsyncThunk<
    Returned,
    ThunkArg = void,
    ThunkApiConfig extends AsyncThunkConfig = {
      // DECLARING WHAT MY getState() method returns;
      state: GlobalState; // this line makes a difference
    }
  >(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<
      Returned,
      ThunkArg,
      ThunkApiConfig
    >,
    options?: any
  ): AsyncThunk<Returned, ThunkArg, ThunkApiConfig>;
}
