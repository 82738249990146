import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_BATTLE_LOG,
  GET_USER_BATTLE_LOG,
} from '@battlefly/graphql/arenasGql';
import { formatBattleLogs } from '@battlefly/hooks/arenas/utils';
import { BattleFlyLeague } from '@battlefly/__generated__/globalTypes';

export interface BattleLogBattleFly {
  name: string;
  placeInGlobalLeaderboard: number;
  appearance: {
    image: string;
  };
  mods: {
    name: string;
    slot: number;
    damageType: string;
    stats: any;
  }[];
  owner: {
    user: {
      wallets: {
        address: string;
      }[];
    };
  };
  league: BattleFlyLeague;
}

export interface BattleLogResp {
  getCombatLog: {
    id: string;
    location: string;
    battleFlyWinner: BattleLogBattleFly;
    battleFlyLooser: BattleLogBattleFly;
  }[];
}

export interface UserBattleLogResp {
  getUserCombatLog: {
    id: string;
    location: string;
    battleFlyWinner: BattleLogBattleFly;
    battleFlyLooser: BattleLogBattleFly;
  }[];
}

export interface Equipment {
  name: string;
  category: string;
  stat: number;
}

export interface BattleLog {
  id: string;
  location: string;
  battleFly1: {
    image: string;
    ownerName: string;
    name: string;
    placeInLeaderboard: number;
    equipment: {
      weapons: Equipment[];
      shields: Equipment[];
      utilities: Equipment[];
    };
    league: BattleFlyLeague;
  };
  battleFly2: {
    image: string;
    ownerName: string;
    name: string;
    placeInLeaderboard: number;
    equipment: {
      weapons: Equipment[];
      shields: Equipment[];
      utilities: Equipment[];
    };
    league: BattleFlyLeague;
  };
}

const useBattleLogData = (logType: 'global' | 'user' = 'global') => {
  const { data, loading } = useQuery<BattleLogResp | UserBattleLogResp>(
    logType === 'global' ? GET_BATTLE_LOG : GET_USER_BATTLE_LOG
  );

  const battleLog = useMemo<BattleLog[] | undefined>(() => {
    if (isBattleLogResp(data)) {
      if (!data?.getCombatLog) {
        return undefined;
      }
      return formatBattleLogs(data.getCombatLog);
    } else {
      if (!data?.getUserCombatLog) {
        return undefined;
      }
      return formatBattleLogs(data.getUserCombatLog);
    }
  }, [data]);

  return { data: battleLog, loading };
};

export default useBattleLogData;

function isBattleLogResp(arg: unknown): arg is BattleLogResp {
  return (
    arg !== null &&
    typeof arg === 'object' &&
    arg.hasOwnProperty('getCombatLog')
  );
}
