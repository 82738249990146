import React from 'react';
import { IconProps } from '@battlefly/components/Icons';

const StatReloadIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.1946 13.2187L19.4973 12.0261L16.4558 17.6691C16.4038 17.7656 16.327 17.8436 16.2358 17.8969C16.1442 17.9504 16.0382 17.9791 15.9281 17.9769L9.59899 17.8482L10.2954 19.0392L16.984 19.1752L20.1946 13.2187Z"
        fill="white"
      />
      <path
        d="M18.8046 10.8417L18.1171 9.66604L15.0757 15.309C15.0237 15.4055 14.9468 15.4835 14.8557 15.5368C14.7641 15.5903 14.6581 15.619 14.548 15.6167L8.21886 15.4881L8.90541 16.6622L15.5941 16.7982L18.8046 10.8417Z"
        fill="white"
      />
      <path
        d="M17.8457 20.0463C17.7937 20.1428 17.7168 20.2208 17.6257 20.2741C17.5341 20.3276 17.4281 20.3563 17.318 20.3541L10.9889 20.2254L12.2132 22.3193C12.3161 22.4953 12.5032 22.6051 12.7071 22.6093L18.708 22.7313C18.818 22.7335 18.9241 22.7048 19.0157 22.6513C19.1068 22.598 19.1836 22.52 19.2357 22.4235L22.121 17.0703C22.2179 16.8904 22.2141 16.673 22.111 16.4966L20.8871 14.4034L17.8457 20.0463Z"
        fill="white"
      />
      <path
        d="M14.2139 14.4379L17.4244 8.48133L14.9026 4.1683C14.798 3.98942 14.6068 3.87911 14.3995 3.87814C10.5787 3.86055 7.50738 5.6473 5.00976 9.34076C4.88348 9.52749 4.87552 9.77009 4.9893 9.96468L7.5253 14.3019L14.2139 14.4379Z"
        fill="white"
      />
    </svg>
  );
};

export default StatReloadIcon;
