import { InMemoryCache, makeVar } from '@apollo/client';
// @ts-ignore
import typeDefs from '../../local-schema.graphql';
import { relayStylePagination } from '@apollo/client/utilities';

export const cacheTypeDefs = typeDefs;
export const isModalGetMagicOpenedVar = makeVar<boolean>(false);
export const holdingTokenIds = makeVar<number[]>([]);
export const stakedTokenIds = makeVar<number[]>([]);
export const modalBfToProvingGroundsId = makeVar<number>(null);
export const unStakeBfCartIds = makeVar<number[]>([]);
export const stakeBfCartIds = makeVar<number[]>([]);
export const justNowUnStakedStakedBfIds = makeVar<number[]>([]);

const cache = new InMemoryCache({
  typePolicies: {
    BattleFly: {
      fields: {
        combatHistoryPaginated: relayStylePagination(),
      },
    },
    Query: {
      fields: {
        getCombatLogPaginated: relayStylePagination(),
        getUserCombatLogPaginated: relayStylePagination(),
        isModalGetMagicOpened: {
          read() {
            return isModalGetMagicOpenedVar();
          },
        },
        holdingTokenIds: {
          read() {
            return holdingTokenIds();
          },
        },
        stakedTokenIds: {
          read() {
            return stakedTokenIds();
          },
        },
        modalBfToProvingGroundsId: {
          read() {
            return modalBfToProvingGroundsId();
          },
        },
        unStakeBfCartIds: {
          read() {
            return unStakeBfCartIds();
          },
        },
        stakeBfCartIds: {
          read() {
            return stakeBfCartIds();
          },
        },
        justNowUnStakedStakedBfIds: {
          read() {
            return justNowUnStakedStakedBfIds();
          },
        },
      },
    },
  },
});

export default cache;
