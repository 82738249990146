import { gql } from '@apollo/client';
import { useGetUserStatisticsQuery } from '@battlefly/hooks/arenas/__generated__/useUserStatisticsData';

gql`
  query getUserStatistics {
    getUserStatistics {
      id
      totalBattleFlies
      averageDailyBattles
      totalBattlesInDay
      totalMagicWonInDay
    }
  }
`;

const useUserStatisticsData = () => {
  const { data, loading } = useGetUserStatisticsQuery();
  return { data: data?.getUserStatistics, loading };
};

export default useUserStatisticsData;
