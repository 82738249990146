import React from 'react';
import { IconProps } from '@battlefly/components/Icons';

const MarkShield: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="144"
      height="110"
      viewBox="0 0 144 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M59.3793 9.8934L117.656 20.2746L118.654 22.9581L109.993 73.6643L120.87 79.8445V84.5407L116.915 89.2955L143.363 104.314V107.775L141.632 110L83.5281 107.528L80.8211 106.342L81.3241 102.832L88.4258 95.3897L29.1668 85.7762L27.4368 84.7875V82.1234L35.5937 36.3446L21.5043 29.1632V27.4809V26.3422L27.9312 19.7802L1.48314 7.17393L0 3.98351V1.24217L3.52547 0.00582886L61.8817 1.24217L62.8025 3.98351V6.43275L61.8817 7.91589L59.3793 9.8934Z"
        fill="url(#paint0_linear_1_1128)"
      />
      <path
        d="M72.3112 49.6304L74.5308 37.8348L65.425 30.0138L54.0996 33.9899L51.88 45.7855L60.9858 53.6057L72.3112 49.6304Z"
        fill="url(#paint1_linear_1_1128)"
      />
      <path
        d="M75.7672 37.7317L73.5468 49.5273L82.6525 57.3475L93.9779 53.3722L96.1976 41.5766L87.0926 33.7564L75.7672 37.7317Z"
        fill="url(#paint2_linear_1_1128)"
      />
      <path
        d="M61.602 55.1505L59.3824 66.9461L68.4874 74.7671L79.8128 70.791L82.0332 58.9954L72.9274 51.1752L61.602 55.1505Z"
        fill="url(#paint3_linear_1_1128)"
      />
      <path
        d="M24.9248 28.2018C24.8874 28.2104 24.8601 28.2244 24.8446 28.2454C24.8056 28.2953 24.8352 28.2782 24.9248 28.2018Z"
        fill="url(#paint4_linear_1_1128)"
      />
      <path
        d="M84.1935 105.23L140.054 106.342L112.217 92.8092L107.241 96.4862L105.758 94.5001C105.758 94.5001 117.465 83.1341 118.654 82.1227C117.648 82.3593 109.064 78.4886 105.475 76.8391L114.769 23.0245L53.5483 12.452L59.3856 5.09566L3.52548 3.9835L31.3622 17.516L36.338 13.839L37.8211 15.8252C37.8211 15.8252 26.1146 27.1912 24.9251 28.2026C26.1458 27.9152 38.5186 33.6736 39.5956 34.1765L31.3591 81.8673L93.6516 92.6257L91.6951 95.7747L84.1935 105.23ZM33.5974 79.8062L41.4442 68.1692L37.9078 54.8483L41.2076 35.7409L57.9063 15.3698L112.12 24.7326L104.317 36.6601L107.92 49.0508L104.399 69.4383L88.5422 89.2954L33.5974 79.8062Z"
        fill="url(#paint5_linear_1_1128)"
      />
      <path
        d="M118.654 82.1232C118.691 82.1147 118.719 82.1007 118.734 82.0796C118.773 82.0298 118.743 82.0469 118.654 82.1232Z"
        fill="url(#paint6_linear_1_1128)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_1128"
          x1="9.37211e-05"
          y1="55.0029"
          x2="143.362"
          y2="55.0029"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#434343" />
          <stop offset="0.4888" stopColor="#3B3C3B" />
          <stop offset="1" stopColor="#4A494A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_1128"
          x1="51.8801"
          y1="41.8099"
          x2="74.5309"
          y2="41.8099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_1128"
          x1="73.5471"
          y1="45.5519"
          x2="96.198"
          y2="45.5519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_1128"
          x1="59.3821"
          y1="62.971"
          x2="82.0329"
          y2="62.971"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1_1128"
          x1="24.8275"
          y1="28.2378"
          x2="24.9245"
          y2="28.2378"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1_1128"
          x1="3.52524"
          y1="55.1625"
          x2="140.053"
          y2="55.1625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1_1128"
          x1="118.654"
          y1="82.0871"
          x2="118.751"
          y2="82.0871"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MarkShield;
