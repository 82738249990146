import { useCallback, useContext, useEffect, useRef } from 'react';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import { ApolloClient, getApolloContext } from '@apollo/client';
import { shortenAddress } from '@battlefly/util/shortenAddress';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { verifyMessage } from 'ethers/lib/utils';

import { LOGIN_SIGNATURE } from '@battlefly/common/constants';
import { UserContext } from '@battlefly/context/userContext';
import { GET_USER_INFO, USER_CONNECT } from '@battlefly/graphql/userGql';
import { GetUserInfoQuery } from '@battlefly/graphql/__generated__/userGql';
import { useAppDispatch } from '@battlefly/hooks';

import { logoutUser, setUserAuthorized } from '@battlefly/redux/user';
import {
  ConnectUserRequest,
  ConnectUserResponse,
} from '@battlefly/redux/user/userTypes';
import {
  clearUserCookies,
  isWalletAuthenticated,
  removeAuthToken,
  setAuthCookie,
  setWalletAddress,
} from '@battlefly/util/cookieManager';
import { Button } from 'components/core';
import ConnectWalletButton from '../Buttons/ConnectWalletButton';
import { CustomToast, ToastStatus } from 'components/CustomToast/CustomToast';

import { loginUser } from 'actions/user';

const Web3ModalButton = () => {
  const { client: apolloClient } = useContext(getApolloContext());

  const dispatch = useAppDispatch();

  const connectedAddress = useRef<string>();

  const recoveredAddress = useRef<string>();
  const { userAvatarImg, smolDomain } = useContext(UserContext);

  const { signMessage, isLoading: isSignMessageLoading } = useSignMessage({
    message: LOGIN_SIGNATURE,
    onSuccess(data, variables) {
      console.log('SIGN_IN_SUCCESS', data, variables);
      console.log('RECOVERED_ADDRESS', recoveredAddress);
      console.log('CONNECTED_ADDRESS', connectedAddress);
      // Verify signature when sign message succeeds
      const addr = verifyMessage(variables.message, data);

      recoveredAddress.current = addr;

      const connectUserRequest: ConnectUserRequest = {
        address: recoveredAddress.current,
        sign: data,
      };

      loginUser(apolloClient, dispatch, connectUserRequest);
    },
    onError() {
      CustomToast({ status: ToastStatus.WALLET_DISCONNECTED });
      clearUserCookies();
      disconnectProvider();
    },
  });

  const switchAccount = useCallback(
    async (connectingAddress?: string) => {
      console.log('SWTICH ACCOUNT', connectingAddress);
      setWalletAddress(connectingAddress);
      connectedAddress.current = connectingAddress;
      try {
        // THEN CHECK IF GRAPHQL AUTHORIZED
        if (
          connectingAddress &&
          !isWalletAuthenticated(connectingAddress) &&
          !isSignMessageLoading
        ) {
          signMessage();
        } else {
          dispatch(setUserAuthorized(true));
        }
      } catch {
        if (!isSignMessageLoading) {
          signMessage();
        }
      }
    },
    [dispatch, isSignMessageLoading, signMessage]
  );

  const { address } = useAccount({
    onConnect: async ({ address: connectingAddress }) => {
      console.log('ON.Connect.use.account.address', connectedAddress);
      connectedAddress.current = connectingAddress;
      recoveredAddress.current = connectingAddress;

      console.log('USE.account.>connectingaddress?', connectingAddress);
      console.log('use.account.revoceredAddress', recoveredAddress);
      switchAccount(connectingAddress);
    },
    onDisconnect: () => {
      CustomToast({
        status: ToastStatus.WALLET_DISCONNECTED,
      });
      dispatch(setUserAuthorized(false));
      removeAuthToken(address);
      setWalletAddress(null);
      connectedAddress.current = null;
    },
  });

  console.log('ADDRESS', address);

  useEffect(() => {
    console.log('EFFECT.to.maybe.swtich.wallet');
    if (address && connectedAddress.current) {
      if (connectedAddress.current !== address) {
        switchAccount(address);
      }
    }
  }, [address, switchAccount]);

  const { disconnect } = useDisconnect();

  const disconnectProvider = async () => {
    await Promise.resolve(disconnect).then(() => {
      dispatch(logoutUser());
      dispatch(setUserAuthorized(false));
      dispatch(logoutUser());
      disconnect();
    });
  };

  useEffect(() => {
    if (isSignMessageLoading) {
      CustomToast({ status: ToastStatus.PENDING_SIGNATURE_REQUEST });
    }
  }, [isSignMessageLoading]);

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        return (
          <div
            {...(!mounted && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!mounted || !account || !chain) {
                return (
                  <Button onClick={openConnectModal} transparent light>
                    Connect Wallet
                  </Button>
                );
              }

              if (chain.unsupported) {
                return (
                  <ConnectWalletButton
                    avatarImg={userAvatarImg}
                    onClick={openChainModal}
                    wrongNetwork
                  >
                    <span className="">Wrong Network</span>
                  </ConnectWalletButton>
                );
              }

              return (
                <ConnectWalletButton
                  avatarImg={userAvatarImg}
                  onClick={openAccountModal}
                >
                  <span className="">
                    {account && smolDomain
                      ? smolDomain
                      : shortenAddress(account.address, 'lg')}
                  </span>
                </ConnectWalletButton>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default Web3ModalButton;
