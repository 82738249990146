import React from 'react';

import { useAppSelector } from '@battlefly/hooks';
import { selectIsUserAuthorized } from 'redux/user/userReducer';
import { FullScreenCard } from 'components/Layout';
import { MissionControl } from 'components/pages';
import { useBattleflies } from 'hooks/game/useBattleflies';

const LeaderBoardRoute = () => {
  const isAuthenticated = useAppSelector(selectIsUserAuthorized());

  const { battleflies, loading, refetch } = useBattleflies(isAuthenticated);
  return (
    <FullScreenCard>
      <MissionControl battleflies={battleflies} loading={loading} />
    </FullScreenCard>
  );
};

export default LeaderBoardRoute;
