import React, { LegacyRef } from 'react';

export const ButtonType = {
  walletConnected: 'bg-core-black hover:bg-button-hover',
  connectWallet: 'bg-button-semi rounded-md',
  primary:
    'bg-button-default hover:bg-button-hover focus:bg-button-focus font-medium transition rounded-sm disabled:bg-button-primary-disabled',
  missionControl:
    'bg-core-black transition rounded-sm border-[1px] border-button-default',
  missionControlDisabled: 'bg-core-black transition rounded-sm opacity-50',
  missionControlSelected:
    'border border-2 bg-gradient-to-r from-[rgba(15,15,55,1)] via-[rgba(52,71,109,1)] to-[rgba(15,15,55,1)] hover:bg-gradient-to-r hover:via-[#7D77FC] transition rounded-sm',
  translucent: 'bg-button-semi hover:bg-button-hover',
  secondary:
    'bg-button-secondary hover:bg-button-secondary-hover focus:bg-button-secondary-focus font-medium transition rounded-sm disabled:bg-button-secondary-disabled',
  basic: 'bg-white hover:bg-gray-700 text-gray-700 font-medium rounded-sm',
  delete:
    'bg-core-red hover:bg-core-red-highlight text-white font-medium rounded-sm disabled:bg-button-warning-disabled',
  warning:
    'bg-core-red hover:bg-core-red-highlight text-white font-medium rounded-sm disabled:bg-button-warning-disabled',
  greenGradient: 'button-green-gradient',
  blackRounded: 'bg-transparent border border-gray-600 font-normal',
  transparent: 'bg-transparent',
};
export const ButtonSize = {
  sm: 'py-1 px-2',
  md: 'py-2 px-3',
  lg: 'py-3 px-3',
};

type ButtonProps = {
  onClick?: () => void;
  size?: string;
  type: keyof typeof ButtonType | '';
  children?: React.ReactNode;
  className?: string;
  width?: string;
  disabled?: boolean;
  primary?: boolean;
  style?: unknown;
  href?: string;
  testId?: string;
};

const Button = React.forwardRef(
  (
    {
      style,
      onClick,
      size = 'md',
      type,
      disabled,
      width = 'w-full',
      children,
      className,
      testId = 'Button',
    }: ButtonProps,
    ref: LegacyRef<HTMLButtonElement>
  ) => {
    //@ts-ignore

    const classNames = `${width} whitespace-nowrap flex justify-center ${ButtonType[type]} ${ButtonSize[size]} transition text-center ${className} disabled:opacity-75`;

    return (
      <button
        data-testid={testId}
        ref={ref}
        {...(style && { style: style })}
        disabled={disabled}
        onClick={!disabled ? onClick : undefined}
        className={classNames}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
