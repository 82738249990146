import React from 'react';
import { IconProps } from '@battlefly/components/Icons/index';

const ChartIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="24"
      height="30"
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block"
      {...props}
    >
      <path
        d="M21.3378 0.133789L16.7762 3.20798L18.673 3.56198L15.2381 18.2386L14.7454 20.3438C14.5612 21.1303 15.0497 21.9172 15.8362 22.1013C16.6228 22.2854 17.4096 21.797 17.5938 21.0105L19.9966 10.744L21.5519 4.09921L24.0002 4.55614L21.3378 0.133789Z"
        fill="white"
      />
      <path
        d="M10.5458 8.16871C9.75922 7.98458 8.97233 8.47303 8.78821 9.25956L6.1941 20.3435C6.00997 21.13 6.49842 21.9169 7.28495 22.101C8.07148 22.2851 8.85837 21.7967 9.04249 21.0102L11.6366 9.92625C11.8207 9.13972 11.3325 8.35283 10.5458 8.16871Z"
        fill="white"
      />
      <path
        d="M14.8329 8.16871C14.0463 7.98458 13.2594 8.47303 13.0753 9.25956L10.4812 20.3435C10.2971 21.13 10.7855 21.9169 11.5721 22.101C12.3586 22.2851 13.1455 21.7967 13.3296 21.0102L15.9237 9.92625C16.1078 9.13972 15.6194 8.35283 14.8329 8.16871Z"
        fill="white"
      />
      <path
        d="M6.42438 8.16871C5.63785 7.98458 4.85096 8.47303 4.66684 9.25956L2.68012 17.7483L0 29.2001L2.8484 29.8668L4.9213 21.0102L7.51541 9.92625C7.69936 9.13972 7.21092 8.35283 6.42438 8.16871Z"
        fill="white"
      />
    </svg>
  );
};

export default ChartIcon;
