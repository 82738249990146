import dayjs from 'dayjs';

import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { PersonalizedBattleLogItem } from '@battlefly/ts/BattleLog';
import Card from 'components/core/Card';
import BattleLogItem from './BattleLogItem';
import { groupBattleLogsByDay } from 'util/helpers';
import { Link } from 'react-router-dom';

type BattleflyCombatLogProps = {
  battlefly: BattleFly;
  logs: PersonalizedBattleLogItem[];
};

const Today = () => {
  return (
    <div className="flex items-center gap-2">
      <div className="h-3 w-3 rounded-xl bg-[#98FC5B]" />
      <div className="mb-[1px] text-xs font-light leading-[14px]">Today</div>
    </div>
  );
};

const BattleflyCombatLog = (props: BattleflyCombatLogProps) => {
  const { logs, battlefly } = props;
  const logsGroupedByDay = groupBattleLogsByDay(logs);
  return (
    <Card className="mobile:p-6 mobile:px-4">
      <div className="flex items-center justify-between">
        <div className="pb-4 font-heading text-5xl leading-10">Battle Log</div>
        <Link
          className="w-auto"
          to={`/battleflies/view/${battlefly.id}/battlelog`}
        >
          <span className="underline">View all battles</span>
        </Link>
      </div>
      <ul className="flex flex-col gap-2">
        {Object.keys(logsGroupedByDay)
          .sort()
          .reverse()
          .map((date, index) => {
            const logs = logsGroupedByDay[date];
            const isToday = dayjs().isSame(date, 'day');
            return (
              <>
                <li className="mt-2" key={`${date}-${index}`}>
                  {isToday ? (
                    <Today />
                  ) : (
                    <div className="text-xs font-light">{date}</div>
                  )}
                </li>
                {logs.map((log) => (
                  <li key={log.id}>
                    <BattleLogItem log={log} />
                  </li>
                ))}
              </>
            );
          })}
      </ul>
    </Card>
  );
};

export default BattleflyCombatLog;
