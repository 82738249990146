import React from 'react';

import { LeaderboardItem } from '@battlefly/__generated__/globalTypes';
import { Card } from 'components/core';
import { formatMoney, shortenAddress } from 'lib/utils';
import { MagicIcon, NectarIcon } from 'components/Icons';
import { DesktopOnly, MobileOnly } from 'components/utility';

type LeaderboardPageProps = {
  className?: string;
  items: LeaderboardItem[];
};
const LeaderboardPage = (props: LeaderboardPageProps) => {
  const { items } = props;

  return (
    <div>
      <header className="mb-8">
        <h1 className="text-4xl">Gobal Leaderboard</h1>
        <h2 className="text-2xl">Player Rankings</h2>
      </header>
      <section className="flex flex-col gap-2">
        <div className="flex items-end justify-between p-4 py-0 font-heading md:items-center">
          <div className="w-2/12 md:w-1/12">
            <span>Rank</span>
          </div>
          <div className="w-4/12 md:w-7/12">Player</div>
          <div className="w-3/12 md:w-2/12">
            <span className="flex items-center justify-center gap-2">
              <MagicIcon className="mb-1 flex self-end md:mb-0 md:self-center" />
              $Magic Earned
            </span>
          </div>
          <div className="w-3/12 md:w-2/12">
            <span className="flex items-center justify-center gap-2">
              <NectarIcon className="mb-1 flex self-end md:mb-0 md:self-center" />
              Nectar Earned
            </span>
          </div>
        </div>
        {items.map((item, index) => (
          <div key={item.nickName}>
            <Card leaderboard className="!p-4 !py-2">
              <div className="flex items-center justify-between">
                <div className="w-2/12 font-heading text-5xl md:w-1/12">
                  {index + 1}
                </div>
                <div className="w-4/12 font-heading text-xl md:w-7/12">
                  <DesktopOnly>{item.nickName}</DesktopOnly>
                  <MobileOnly>{shortenAddress(item.nickName)}</MobileOnly>
                </div>
                <div className="w-3/12 text-center md:w-2/12">
                  {formatMoney(item.earnedMagic)}
                </div>
                <div className="w-3/12 text-center md:w-2/12">
                  {formatMoney(item.earnedNectar)}
                </div>
              </div>
            </Card>
          </div>
        ))}
      </section>
    </div>
  );
};

export default LeaderboardPage;
