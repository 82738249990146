import { gql } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import {
  useGetCombatLogsPaginatedLazyQuery,
  useGetUserCombatLogsPaginatedLazyQuery,
} from '@battlefly/hooks/game/__generated__/useBattleLogsData';
import { mapCombatLog } from 'lib/mapper/battlelog';
import { Combat } from '@battlefly/__generated__/globalTypes';

const BattleFlyFragment = gql`
  fragment BattleFlyFragment on BattleFly {
    id
    name
    appearance {
      image
    }
    owner {
      user {
        wallets {
          address
        }
      }
    }
    placeInGlobalLeaderboard
    starsRate
    rarity
    league
  }
`;

gql`
  query getCombatLogsPaginated(
    $first: Int
    $returnTotalCount: Boolean
    $after: String
  ) {
    getCombatLogPaginated(
      first: $first
      returnTotalCount: $returnTotalCount
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          location
          createdAt
          battleFlyWinner {
            ...BattleFlyFragment
          }
          battleFlyLooser {
            ...BattleFlyFragment
          }
          log {
            participants {
              battleFlyId
              weapons {
                name
                blueprint {
                  ...CoreModBlueprintFields
                }
              }
              defense {
                name
                blueprint {
                  ...CoreModBlueprintFields
                }
              }
              utilities {
                name
                blueprint {
                  ...CoreModBlueprintFields
                }
              }
            }
          }
        }
      }
    }
  }
`;

gql`
  query getUserCombatLogsPaginated(
    $first: Int
    $returnTotalCount: Boolean
    $after: String
  ) {
    getUserCombatLogPaginated(
      first: $first
      returnTotalCount: $returnTotalCount
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          location
          createdAt
          battleFlyWinner {
            ...BattleFlyFragment
          }
          battleFlyLooser {
            ...BattleFlyFragment
          }
          log {
            participants {
              battleFlyId
              weapons {
                name
                blueprint {
                  ...CoreModBlueprintFields
                }
              }
              defense {
                name
                blueprint {
                  ...CoreModBlueprintFields
                }
              }
              utilities {
                name
                blueprint {
                  ...CoreModBlueprintFields
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useBattleLogData = (logType: 'global' | 'user' = 'global') => {
  const [
    fetchBattleLog,
    {
      data: battleLog,
      loading: loadingBattleLog,
      fetchMore: fetchMoreBattleLog,
    },
    // ] = useGetBattleLogPaginatedLazyQuery({
  ] = useGetCombatLogsPaginatedLazyQuery({
    variables: {
      first: 10,
      returnTotalCount: true,
    },
  });

  const [
    fetchUserBattleLog,
    {
      data: userBattleLog,
      loading: loadingUserBattleLog,
      fetchMore: fetchMoreUserBattleLog,
    },
  ] = useGetUserCombatLogsPaginatedLazyQuery({
    variables: {
      first: 10,
      returnTotalCount: true,
    },
  });

  const isUserLogType = logType === 'user';

  const logs = useMemo(
    () =>
      logType === 'user'
        ? userBattleLog?.getUserCombatLogPaginated.edges.map((edge) =>
            mapCombatLog(edge.node as Partial<Combat>)
          )
        : battleLog?.getCombatLogPaginated.edges.map((edge) =>
            mapCombatLog(edge.node as Partial<Combat>)
          ),
    [logType, userBattleLog, battleLog]
  );

  const endCursor = isUserLogType
    ? userBattleLog?.getUserCombatLogPaginated.pageInfo.endCursor
    : battleLog?.getCombatLogPaginated.pageInfo.endCursor;

  const hasNextPage = isUserLogType
    ? userBattleLog?.getUserCombatLogPaginated.pageInfo.hasNextPage
    : battleLog?.getCombatLogPaginated.pageInfo.hasNextPage;

  const loading = isUserLogType ? loadingUserBattleLog : loadingBattleLog;

  const fetchMore = isUserLogType ? fetchMoreUserBattleLog : fetchMoreBattleLog;

  useEffect(() => {
    if (isUserLogType) {
      fetchUserBattleLog();
    } else {
      fetchBattleLog();
    }
  }, [logType]);

  return { logs, endCursor, hasNextPage, loading, fetchMore };
};

export default useBattleLogData;
