import { BattleflyGame, CONTRACT_ABIS } from '@battlefly/common/constants';
import {
  failureToast,
  pendingSignatureToast,
  successToast,
} from '@battlefly/components/CustomToast/CustomToast';
import { useContractAddresses } from '@battlefly/hooks/contractsNew/useContractAddresses';
import { BigNumber, Contract } from 'ethers';
import { Result } from 'ethers/lib/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAccount,
  useContractRead,
  useContractReads,
  useContractWrite,
  useProvider,
} from 'wagmi';
import { parseComicBalanceOfBatchResponse } from './utils';

type Provider = ReturnType<typeof useProvider>;

class ComicContract {
  private _contract: Contract;

  constructor(address: string, provider: Provider) {
    this._contract = new Contract(
      address,
      CONTRACT_ABIS[BattleflyGame.ComicContract],
      provider
    );
  }

  async getComicById(comicId: number) {
    try {
      const comicDetails = await this._contract.comicIdToComic(comicId);
      return comicDetails;
    } catch (e) {
      return false;
    }
  }

  async getUnusedTokensIds(tokensList: number[]) {
    try {
      Promise.all(
        tokensList.map((item) => {
          return this._contract.comicIdToComic(item);
        })
      );

      // const comicDetails = await this._contract.comicIdToComic(comicId);
      // return comicDetails;
    } catch (e) {
      return false;
    }
  }
}

export type ComicNetworkResponse = {
  active: boolean;
  burnAmount: BigNumber;
  burnableIn: BigNumber;
  id: BigNumber;
  maxMints: BigNumber;
  maxPaidMintsPerWallet: BigNumber;
  mintType: BigNumber;
  name: string;
  priceInWei: BigNumber;
  uri: string;
  details: any;
};

const useSingleComic = (comicId: number) => {
  const provider = useProvider();
  const [loading, setLoading] = useState(true);
  const [comics, setComics] = useState<ComicNetworkResponse>();
  const contractAddress = useContractAddresses();

  const comicService = useMemo(() => {
    return new ComicContract(
      contractAddress[BattleflyGame.ComicContract],
      provider
    );
  }, [contractAddress]);

  const getComics = useCallback(
    async (comicId: number) => {
      if (!comicId) {
        return;
      }
      try {
        const comic: ComicNetworkResponse = await comicService.getComicById(
          comicId
        );

        if (comic.uri) {
          if (comic.uri.includes('cdn')) {
            comic.uri.substring(comic.uri.lastIndexOf('/') + 1);
            const additionalData = await fetch(
              `https://battlefly.infura-ipfs.io/ipfs/${comic.uri.substring(
                comic.uri.lastIndexOf('/') + 1
              )}`
            );
            if (additionalData.ok) {
              const json = await additionalData.json();
              setComics({ ...comic, details: json });
            }
          } else {
            const addonalData = await fetch(comic.uri);
            if (addonalData.ok) {
              const json = await addonalData.json();
              setComics({ ...comic, details: json });
            }
          }
        } else {
          setComics(comic);
        }
      } catch (e) {
        console.log(e.message);
      }
      setLoading(false);
    },
    [provider]
  );

  useEffect(() => {
    if (contractAddress && comicId) {
      getComics(comicId);
    }
  }, [contractAddress, comicId]);

  return { comics, loading };
};

const useContractDefaults = () => {
  const contractAddress = useContractAddresses();
  return {
    address: contractAddress[BattleflyGame.ComicContract],
    abi: CONTRACT_ABIS[BattleflyGame.ComicContract],
  };
};

export const useComicsListLength = () => {
  const [maxNumComics, setMaxNumComics] = useState<number>();
  const contractDefaults = useContractDefaults();
  const { data, isError, isLoading, refetch } = useContractRead({
    ...contractDefaults,
    functionName: 'currentComicId',
  });

  useEffect(() => {
    if (isLoading || isError || !data) {
      return;
    }

    const value: BigNumber = BigNumber.from(data);
    setMaxNumComics(value.toNumber());
  }, [data, isError, isLoading]);

  return { maxNumComics, isLoading, isError, refetch };
};

export const useMintFounders = () => {
  const contractDefaults = useContractDefaults();
  let transactionAmount: number;
  let successCallback: any;
  let transactionTokens: number[];

  const { write, isLoading, isSuccess, status } = useContractWrite({
    mode: 'recklesslyUnprepared',
    ...contractDefaults,
    functionName: 'mintFounders',
    onMutate({ args, overrides }) {
      pendingSignatureToast();
      console.log('Mutate', { args, overrides });
    },
    onSuccess(data) {
      successToast(`${transactionAmount} Comic(s) Minted by Founders`, data);
      if (successCallback) {
        successCallback(true, transactionTokens);
      }
    },
    onError(error) {
      failureToast('Failed to Mint by Founders');
      console.log('Error', error);
      if (successCallback) {
        successCallback(false, transactionTokens);
      }
    },
    onSettled(data) {
      console.log('onSettled');
    },
  });
  const mintFounders = async (
    tokens: number[],
    comicId: number,
    successCb: any
  ) => {
    successCallback = successCb;
    transactionAmount = tokens.length;
    transactionTokens = [...tokens];
    write({ recklesslySetUnpreparedArgs: [tokens, comicId] });
  };

  return { mintFounders, isLoading, isSuccess, status };
};

export const useMintPaid = () => {
  const contractDefaults = useContractDefaults();
  let transactionAmount: any;
  let successCallback: any;

  const { write, isLoading, isSuccess, status } = useContractWrite({
    mode: 'recklesslyUnprepared',
    ...contractDefaults,
    functionName: 'mintPaid',
    onMutate({ args, overrides }) {
      pendingSignatureToast();
      console.log('Mutate', { args, overrides });
    },
    onSuccess(data) {
      console.log('onSuccess', transactionAmount);
      successToast(`${transactionAmount} Comic(s) Minted by Magic`, data);
      if (successCallback) {
        successCallback(true, transactionAmount);
      }
    },
    onError(error) {
      failureToast('Failed to Mint by Magic');
      console.log('Error', error);
      if (successCallback) {
        successCallback(false, transactionAmount);
      }
    },
  });
  const bulkMintPaid = async (
    amount: number,
    comicId: number,
    successCb: any
  ) => {
    successCallback = successCb;
    transactionAmount = amount;
    write({ recklesslySetUnpreparedArgs: [amount, comicId] });
  };

  return { bulkMintPaid, isLoading, isSuccess, status };
};

export const useComicUsedPaidMints = (comicId: number) => {
  const { address } = useAccount();
  const contractDefaults = useContractDefaults();
  const { data, isError, isLoading, refetch } = useContractRead({
    ...contractDefaults,
    functionName: 'paidMints',
    args: [comicId, address],
    onSuccess(data) {
      // console.log(data)
    },
    onError(error) {
      // console.log(error)
    },
    watch: false,
  });

  return { data, isLoading, isError, refetch };
};

export const useSetApprovalForAll = () => {
  const contractAddress = useContractAddresses();
  const contractDefaults = useContractDefaults();
  const { write, isLoading, isError, isSuccess } = useContractWrite({
    mode: 'recklesslyUnprepared',
    ...contractDefaults,
    functionName: 'setApprovalForAll',
    args: [contractAddress[BattleflyGame.ComicContract], true],
    onMutate({ args, overrides }) {
      pendingSignatureToast();
      console.log('Mutate', { args, overrides });
    },
    onSuccess(data) {
      successToast('Approved', data);
      console.log('Success', data);
    },
    onError(error) {
      failureToast('Failed to Approve');
      console.log('Error', error);
    },
  });
  const setApprovalForAll = async () => {
    write();
  };

  return {
    setApprovalForAll,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useIsApprovedForAll = () => {
  const { address } = useAccount();
  const contractDefaults = useContractDefaults();
  const contractAddress = useContractAddresses();

  const { data, isError, isLoading, isSuccess, refetch } = useContractRead({
    ...contractDefaults,
    functionName: 'isApprovedForAll',
    args: [address, contractAddress[BattleflyGame.ComicContract]],
    watch: false,
  });

  return { isApproved: data, isError, isLoading, isSuccess, refetch };
};

export const useComicAvailableBattleflyMint = (
  tokenIds: number[],
  comicId: number
) => {
  const tokens = tokenIds;
  const [mixedData, setMixedData] = useState([]);
  const contractDefaults = useContractDefaults();

  const prepateContacts = (tokenIds: number[]) => {
    return tokenIds.map((value) => {
      return {
        ...contractDefaults,
        functionName: 'usedTokens',
        args: [comicId, value],
      };
    });
  };

  const { isError, isLoading, data, refetch, isRefetching } = useContractReads({
    contracts: prepateContacts(tokens),
    onSuccess(data) {
      setMixedData(
        data.map((item, inex) => {
          if (inex < tokens.length)
            return {
              tokenId: tokens[inex],
              used: item,
            };
        })
      );
    },
    onError(e) {
      setMixedData(null);
    },
    watch: false,
  });

  return { data, mixedData, isError, isLoading, refetch, isRefetching };
};

export const useComicMintBattlefly = () => {
  const contractDefaults = useContractDefaults();
  let transactionAmount: number;
  let successCallback: any;
  let currentTransactionTokens: number[];

  const { write, isLoading, isSuccess, status } = useContractWrite({
    mode: 'recklesslyUnprepared',
    ...contractDefaults,
    functionName: 'mintBattlefly',
    onMutate({ args, overrides }) {
      pendingSignatureToast();
      console.log('Mutate', { args, overrides });
    },
    onSuccess(data) {
      successToast(
        `${transactionAmount} Comics Minted by Battlefly tokens`,
        data
      );
      if (successCallback) {
        successCallback(true, currentTransactionTokens);
      }
    },
    onError(error) {
      failureToast('Failed to Mint by Battlefly tokens');
      console.log('Error', error);
      if (successCallback) {
        successCallback(false, currentTransactionTokens);
      }
    },
  });
  const bulkMintBattleflys = async (
    tokens: number[],
    comicId: number,
    successCb: any
  ) => {
    successCallback = successCb;
    transactionAmount = tokens.length;
    currentTransactionTokens = [...tokens];
    write({ recklesslySetUnpreparedArgs: [tokens, comicId] });
  };

  return { bulkMintBattleflys, isLoading, isSuccess, status };
};

export const useBurnComic = () => {
  const contractDefaults = useContractDefaults();
  let transactionAmount: number;
  let successCallback: any;

  const { write, isLoading, isSuccess, status } = useContractWrite({
    mode: 'recklesslyUnprepared',
    ...contractDefaults,
    functionName: 'burn',
    onMutate({ args, overrides }) {
      pendingSignatureToast();
      // console.log('Mutate', { args, overrides });
    },
    onSuccess(data) {
      successToast(`${transactionAmount} Comics Burned`, data);
      if (successCallback) {
        // successCallback(true, currentTransactionTokens);
      }
    },
    onError(error) {
      failureToast('Failed to Burn Comics');
      console.log('Error', error);
      if (successCallback) {
        // successCallback(false, currentTransactionTokens);
      }
    },
  });
  const bulkBurnComic = async ({
    burnId,
    amount,
    mintId,
    successCb,
  }: {
    burnId: number;
    amount: number;
    mintId: number;
    successCb?: any;
  }) => {
    transactionAmount = amount;
    successCallback = successCb;
    write({ recklesslySetUnpreparedArgs: [burnId, amount, mintId] });
  };

  return { bulkBurnComic, isLoading, isSuccess, status };
};

export const useGetComicsByWalletAddress = () => {
  const { address } = useAccount();
  const contractDefaults = useContractDefaults();
  const { data, isError, isLoading } = useContractRead({
    ...contractDefaults,
    functionName: 'balanceOfBatch',
    args: [Array(6).fill(address), [1, 2, 3, 4, 5, 6]],
  });

  const comicsInUserWallet = parseComicBalanceOfBatchResponse(data as Result);

  useEffect(() => {
    if (isLoading || isError || !data) {
      return;
    }
  }, [data, isError, isLoading]);

  return { comicsInUserWallet, isLoading, isError };
};

export default useSingleComic;
