import React from 'react';

import { ScavengingMarkType } from '__generated__/globalTypes';
import { ModalContext } from 'context/modalContext';
import { Card, Modal } from 'components/core';
import {
  SCAVENGING_MARK_BRONZE_NECTAR_PRICE,
  SCAVENGING_MARK_GOLD_NECTAR_PRICE,
  SCAVENGING_MARK_SILVER_NECTAR_PRICE,
} from '@battlefly/common/constants';
import { CloseIcon } from 'components/Icons';
import { useApplyScavengeMark } from 'hooks/game';

import DefaultView from './DefaultView';
import CompareView from './CompareView';

const orderedMarksTypes = [
  ScavengingMarkType.Bronze,
  ScavengingMarkType.Silver,
  ScavengingMarkType.Gold,
];

function isRankedLower(
  currentMark: ScavengingMarkType,
  markToCompare: ScavengingMarkType
) {
  return (
    orderedMarksTypes.indexOf(currentMark) <
    orderedMarksTypes.indexOf(markToCompare)
  );
}

function getMark(type: string): ScavengingMarkType {
  switch (type) {
    case 'Bronze':
      return ScavengingMarkType.Bronze;
    case 'Silver':
      return ScavengingMarkType.Silver;
    case 'Gold':
      return ScavengingMarkType.Gold;
    default:
      return ScavengingMarkType.Bronze;
  }
}

const ScavengerMarkModal = () => {
  const [selectedMark, setSelectedMark] =
    React.useState<ScavengingMarkType>(null);
  const [compareView, setCompareView] = React.useState(false);
  const { scavengerMarkModalState: battlefly, setScavengerMarkModalState } =
    React.useContext(ModalContext);
  const [amount, setAmount] = React.useState(null);

  const { applyMark, loading: isApplyingMark } = useApplyScavengeMark(
    battlefly?.id
  );

  React.useEffect(() => {
    if (selectedMark === ScavengingMarkType.Bronze) {
      setAmount(SCAVENGING_MARK_BRONZE_NECTAR_PRICE);
    }
    if (selectedMark === ScavengingMarkType.Silver) {
      setAmount(SCAVENGING_MARK_SILVER_NECTAR_PRICE);
    }
    if (selectedMark === ScavengingMarkType.Gold) {
      setAmount(SCAVENGING_MARK_GOLD_NECTAR_PRICE);
    }
  }, [selectedMark]);

  function handleSelect(mark: ScavengingMarkType) {
    if (!isRankedLower(battlefly.scavengingMark?.type, mark)) {
      return;
    }
    setSelectedMark(mark);
  }

  async function handleConfirm() {
    if (battlefly.scavengingMark !== null) {
      return setCompareView(true);
    }

    await handleFinalize();
  }

  async function handleFinalize() {
    await applyMark({
      variables: {
        applyScavengingMarkInput: {
          battleFlyId: battlefly.id,
          markType: selectedMark,
        },
      },
    });
    setScavengerMarkModalState(null);
  }
  return (
    <Modal
      className="!md:items-center !items-start"
      onClose={() => setScavengerMarkModalState(null)}
      isOpen={!!battlefly}
    >
      <div className="scavenger-mark-modal sm:w-auto md:w-[700px]">
        <Card>
          <div className="md:p-8">
            <header className="relative flex flex-col justify-between">
              <div className="absolute -right-5 -top-5 cursor-pointer self-end p-3 md:-right-10 md:-top-10">
                <CloseIcon
                  fill="#fff"
                  onClick={() => setScavengerMarkModalState(null)}
                />
              </div>
            </header>

            {!compareView && (
              <DefaultView
                selectedMark={selectedMark}
                handleSelect={handleSelect}
                handleConfirm={handleConfirm}
                amount={amount}
              />
            )}

            {compareView && (
              <CompareView
                currentMark={getMark(battlefly?.scavengingMark?.type)}
                selectedMark={selectedMark}
                handleConfirm={handleFinalize}
                amount={amount}
              />
            )}
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default ScavengerMarkModal;
