import classnames from 'classnames';

import Spinner from 'components/Spinner';

type ButtonProps = {
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (args?: any) => void | Promise<void>;
  width?: string;
  isLoading?: boolean;
  light?: boolean;

  cyberPink?: boolean;
  transparent?: boolean;
  gray?: boolean;
  lightGray?: boolean;
  blueMint?: boolean;
  purple?: boolean;
};

function getColors(props: ButtonProps) {
  if (props.cyberPink) {
    return ['gra-button-pink', 'gra-button-pink-border'];
  }
  if (props.transparent && props.light) {
    return ['bg-white/[.15]', '!p-0 border border-white border-opacity-25'];
  }

  if (props.transparent) {
    return ['bg-white/[.25]', 'p-0 border-2 border-white border-opacity-50'];
  }
  if (props.gray) {
    return ['gra-button-gray', 'gra-button-gray-border'];
  }
  if (props.lightGray) {
    return ['bg-[#333]', 'bg-slate-500'];
  }

  if (props.blueMint) {
    return ['gra-blue-mint', 'gra-button-blue-mint-border'];
  }
  if (props.purple) {
    return ['gra-purple', 'gra-button-purple'];
  }
  return ['gra-button', 'gra-button-border'];
}

const Button = (props: ButtonProps) => {
  const {
    className,
    type = 'button',
    children,
    disabled,
    onClick,
    width = 'w-full',
    isLoading = false,
    light = false,
  } = props;
  const [bgColor, borderColor] = getColors(props);
  return (
    <div
      className={classnames(borderColor, width, 'rounded-[5px] text-center', {
        'p-[2px]': !light,
        'p-[1px]': light,
      })}
    >
      <button
        className={classnames(
          bgColor,
          'mx-auto block flex w-full items-center justify-center whitespace-nowrap px-4 py-2 text-center font-heading text-xl uppercase leading-6',
          {
            'rounded-[3px]': !light,
            'rounded-[4px]': light,
            'cursor-not-allowed opacity-50': disabled,
          }
        )}
        disabled={disabled}
        onClick={!disabled ? onClick : undefined}
        type={type}
      >
        {isLoading && <Spinner pending />}
        {children}
      </button>
    </div>
  );
};

export default Button;
