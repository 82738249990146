import { GlobalState } from '@battlefly/store/store';
import { createSlice } from '@reduxjs/toolkit';
import {
  addBattleflyToUnstakingCart,
  emptyUnstakingCart,
  removeBattleflyFromUnstakingCart,
  setAllStakingTokenIds,
  setStakingBattlefliesList,
} from './stakingActions';
import { InitialStakedBattlefliesState } from './stakingTypes';

export const initialState: InitialStakedBattlefliesState = {
  allStakingTokenIds: [],
  stakingBattlefliesList: [],
  pagination: {
    items: [],
    meta: {
      perPage: undefined,
      page: undefined,
      total: undefined,
      totalPages: undefined,
    },
  },
  selectedBattleflies: [],
  error: { type: '', message: '', status: 0, timestamp: '' },
  pending: false,
  hasFetched: false,
};

const staking = createSlice({
  name: 'staking',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setAllStakingTokenIds, (state, { payload }) => {
        state.allStakingTokenIds = payload;
      })
      .addCase(setStakingBattlefliesList, (state, { payload }) => {
        state.stakingBattlefliesList = payload;
      })
      .addCase(addBattleflyToUnstakingCart, (state, { payload }) => {
        const newArray = [...state.selectedBattleflies, payload];
        state.selectedBattleflies = newArray;
      })
      .addCase(removeBattleflyFromUnstakingCart, (state, { payload }) => {
        const newItems = state.selectedBattleflies.filter(
          (item) => item.id !== payload.id
        );
        state.selectedBattleflies = newItems;
      })
      .addCase(emptyUnstakingCart, (state) => {
        state.selectedBattleflies = new Array(0);
      });
  },
});

export const selectIsStakingPending = () => (state: GlobalState) =>
  state.staking.pending;

export const selectIsStakingError = () => (state: GlobalState) =>
  state.staking.error;

export const selectStakingBattlefliesList = () => (state: GlobalState) =>
  state.staking.stakingBattlefliesList;

export const selectStakingTokenIds = () => (state: GlobalState) =>
  state.staking.allStakingTokenIds;

export default staking.reducer;
