import React from 'react';

import { Link } from 'components/core';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { ChevronLeft } from 'components/Icons';

function hasPipe(breadcrumbs: any, index: number) {
  return index !== breadcrumbs.length - 1;
}

const BreadCrumbs = () => {
  const breadcrumbs = useBreadcrumbs();

  if (!breadcrumbs.length) return null;

  return (
    <div className="flex items-center gap-4">
      <div>
        <ChevronLeft fillOpacity={1} className="h-6 w-6" />
      </div>
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <>
          <Link key={match.pathname} to={match.pathname} className="text-xl">
            {breadcrumb}
          </Link>
          {hasPipe(breadcrumbs, index) && <div>|</div>}
        </>
      ))}
    </div>
  );
};

export default BreadCrumbs;
