import {
  BattleFly,
  ModBlueprint,
  ModPack,
  Mod,
  ModBlueprintInModPack,
} from '@battlefly/__generated__/globalTypes';

export const filterLoadoutByModType = (
  battlefly: BattleFly,
  mod: ModBlueprint | ModBlueprintInModPack
) => {
  return battlefly.mods.filter(
    (modInLoadout) => mod.type.toLowerCase() === modInLoadout.type.toLowerCase()
  );
};

export const filterInventoryByModType = (
  inventory: BattleFly['inventory'],
  mod: ModBlueprint | Mod
) => {
  return inventory.filter(
    (modInLoadout) => mod.type.toLowerCase() === modInLoadout.type.toLowerCase()
  );
};

export const getEmptyInventorySlots = (battlefly: BattleFly) => {
  return battlefly.slotsAvailable - battlefly.inventory.length;
};

export const filterEquippedModsFromModPack = (
  battlefly: BattleFly,
  modPack: ModPack
): ModPack => {
  const modsInLoadout = battlefly.mods.map((mod) => mod.blueprint.id);
  const modsInInventory = battlefly.inventory.map((mod) => mod.blueprint.id);
  const equippedMods = [...modsInLoadout, ...modsInInventory];
  return {
    ...modPack,
    modBlueprints: modPack.modBlueprints.filter(
      (mod) => !equippedMods.includes(mod.id)
    ),
  };
};

export const getSlotNumberForEquipAction = (
  battlefly: BattleFly,
  target?: Mod
) => {
  const isTargetInLoadout = target?.slot < 5;
  if (isTargetInLoadout) {
    return target?.slot;
  }
  if (getEmptyInventorySlots(battlefly) > 0) {
    return 100 + battlefly.inventory.length;
  }
  return target?.slot;
};

export const ensureInventorySize = (inventory: number[], size: number) => {
  const output = [...inventory];
  if (inventory.length < size) {
    for (let i = 0; i < size - inventory.length; i++) {
      output.push(null);
    }
  }
  return output;
};
