import React from 'react';

const Racer: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} inline-block`}
  >
    <path
      d="M28.2584 18.4696L27.9845 17.6937L27.0259 17.3742L26.0674 18.3327L23.9677 9.70573L19.1292 4L17.0751 5.73437L17.8511 9.24913L16.8469 12.3531L18.3533 14.5441L21.2289 17.0089L17.8511 14.6354L16.3905 14.4984L14.519 17.1002L16.8013 21.4823L19.54 22.7603L24.9718 20.7062L23.2373 23.2625L23.9219 23.8102L26.9803 21.0714L30.7689 19.7021L31.1796 18.5609L30.4037 18.3783L34.1923 16.6438L35.2877 15.594V13.4029L28.2584 18.4696Z"
      fill="white"
    />
    <path
      d="M19.9956 33.8212C20.9491 33.934 21.9545 33.9963 23.0138 34.0001L25.2909 23.8105L24.6063 24.4495L19.9956 33.8212Z"
      fill="white"
    />
    <path
      d="M22.7577 23.8096L14.1724 32.3293C15.2932 32.7945 16.5245 33.1857 17.8745 33.4722L23.819 24.2433L22.7577 23.8096Z"
      fill="white"
    />
    <path
      d="M19.7451 23.5932L18.9576 23.3193L8.39502 28.6134C9.18192 29.349 10.0889 30.0732 11.1287 30.7404L21.4224 23.5248L19.7451 23.5932Z"
      fill="white"
    />
    <path
      d="M16.0136 22.1211L4 22.4727C4.24434 23.0502 4.82576 24.2824 5.868 25.7348L17.383 22.9084L16.0136 22.1211Z"
      fill="white"
    />
  </svg>
);

export default Racer;
