import React from 'react';
import classnames from 'classnames';

import {
  Inventory,
  Lore,
  Marketplace,
  Racer,
  Leaderboard,
  Comic,
  ExpandArrow,
  BattlelogIcon,
  ChartIcon,
  DiamondIcon,
} from 'components/Icons';
import BattleflyLogo from 'components/BattleflyLogo/Logo';
import Link from 'components/core/Link';
import Drawer from 'components/Drawer';
import { NAVIGATION_ITEMS } from '@battlefly/common/constants';

export type WalletDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onToggle: () => void;
};

const Icon = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <li
    className={classnames(
      'flex min-h-[40px] items-center justify-center',
      className
    )}
  >
    {children}
  </li>
);

const WalletDrawer = (props: WalletDrawerProps) => {
  const { isOpen, onClose, onToggle } = props;

  return (
    <>
      <div
        id="DesktopNavigationDrawer"
        style={{ zIndex: 10000 }}
        className=" fixed left-0 top-0 hidden h-full w-[80px] bg-core-blue sm:block"
      >
        <div className="p-2 pt-4">
          <div className="h-12">
            <BattleflyLogo />
          </div>

          <ul
            onClick={onToggle}
            className="flex-grid-rows-7 mt-20 grid cursor-pointer gap-4 text-center"
          >
            <Icon>
              <Inventory />
            </Icon>
            <Icon>
              <BattlelogIcon />
            </Icon>
            <Icon>
              <Leaderboard />
            </Icon>
            <Icon>
              <ChartIcon />
            </Icon>
            <Icon>
              <Racer />
            </Icon>
            <Icon>
              <Marketplace />
            </Icon>
            <Icon>
              <DiamondIcon />
            </Icon>
            <Icon>
              <Lore />
            </Icon>
            <Icon>
              <Comic />
            </Icon>
          </ul>
        </div>
        <Drawer
          className="hidden sm:block"
          onClose={onClose}
          isOpen={isOpen}
          position="left"
          querySelector="#__main"
          maxWidth="400px"
        >
          <div className="h-full bg-core-blue p-4">
            <header className="flex items-center justify-end">
              <div
                className="flex h-12 cursor-pointer items-center justify-center text-[36px] text-lg text-white"
                onClick={onClose}
              >
                &#x2715;
              </div>
            </header>
            <section className="mt-20">
              <ul className="relative grid grid-rows-6 gap-4">
                {NAVIGATION_ITEMS.map((item) => (
                  <li key={item.label} className="">
                    {item.external ? (
                      <a
                        href={item.path}
                        target="_blank"
                        rel="noreferer noreferrer"
                        className="flex cursor-pointer items-center justify-between"
                      >
                        <div className="flex min-h-[40px] flex-col items-start justify-center">
                          <div className="font-heading text-xl uppercase leading-6">
                            {item.label}
                          </div>
                          <div className="text-xs text-core-grey">
                            {item.description}
                          </div>
                        </div>
                        <ExpandArrow />
                      </a>
                    ) : (
                      <Link
                        target={item.external ? '_blank' : undefined}
                        className="flex cursor-pointer items-center justify-between"
                        to={item.path}
                      >
                        <div className="flex min-h-[40px] flex-col items-start justify-center">
                          <div className="font-heading text-xl uppercase leading-6">
                            {item.label}
                          </div>
                          <div className="text-xs text-core-grey">
                            {item.description}
                          </div>
                        </div>
                        <ExpandArrow />
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </section>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default WalletDrawer;
