import {
  BattleFly,
  BattleFlyLocation,
} from '@battlefly/__generated__/globalTypes';
import { BattleLog } from '@battlefly/ts/BattleLog';
import Card from '@battlefly/components/core/Card';
import Button from '@battlefly/components/core/Button';
import { isInBattle, battleflyIsIn } from '@battlefly/util/helpers';
import LastBattles from './LastBattles';
import classnames from 'classnames';

type BattleStatusCardProps = {
  battlefly: BattleFly;
  logs: BattleLog[];
  readonly?: boolean;
  handleUpdateSettings: () => void;
  handleBattleNow: () => void;
  handleWithdraw: () => void;
  isWithdrawing: boolean;
};

function getLocationLabel(battlefly: BattleFly) {
  if (battleflyIsIn(battlefly.location, BattleFlyLocation.ProvingGrounds)) {
    return 'Proving Grounds';
  }
  return 'Hyperdome';
}

const BattleStatusCard = (props: BattleStatusCardProps) => {
  const {
    handleUpdateSettings,
    handleWithdraw,
    handleBattleNow,
    battlefly,
    readonly,
    isWithdrawing,
    logs,
  } = props;

  if (isInBattle(battlefly)) {
    return (
      <Card pink className="grid grid-rows-2 !p-6 mobile:p-4">
        <div className="flex justify-between">
          <div className="font-heading text-3xl">
            Battle Status: {getLocationLabel(battlefly)}
          </div>
          <div>{logs.length > 0 && <LastBattles logs={logs} />}</div>
        </div>
        <div className={classnames("flex gap-6", { hidden: readonly })}>
          <div className="w-1/2">
            <Button type="button" cyberPink onClick={handleUpdateSettings}>
              Update Settings
            </Button>
          </div>
          <div className="w-1/2">
            <Button
              onClick={handleWithdraw}
              type="button"
              transparent
              isLoading={isWithdrawing}
              disabled={isWithdrawing}
            >
              Withdraw
            </Button>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card darkOrchid className="grid grid-rows-2 !p-6 mobile:p-4">
      <div className="flex justify-between">
        <div className="font-heading text-3xl">Battle Status: Idle</div>
        <div>{logs.length > 0 && <LastBattles logs={logs} />}</div>
      </div>
      <div className={classnames("flex gap-6", { hidden: readonly })}>
        <div className="w-1/2">
          <Button type="button" cyberPink onClick={handleBattleNow}>
            Battle now
          </Button>
        </div>
        <div className="w-1/2">
          <Button type="button" transparent disabled>
            List on Trove
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default BattleStatusCard;
