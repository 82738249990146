import React from 'react';
import Button from '@battlefly/components/Buttons/Button';

const ChangeMintValueTab = ({
  mintCount,
  handleDecreaseMintCount,
  handleIncreaseMintCount,
  handleMintMaxCount,
}: {
  mintCount: number;
  handleDecreaseMintCount: any;
  handleIncreaseMintCount: any;
  handleMintMaxCount: any;
}) => {
  return (
    <div className="flex">
      <div className="relative flex w-full flex-row justify-between rounded-lg bg-transparent transition">
        <div className="mb-2 mr-2 inline-block flex w-full justify-between rounded-sm bg-button-default py-1 px-1">
          <span className="ml-2 self-center text-xs">Mints</span>
          <div className="mr-1 flex w-full justify-end">
            <button
              onClick={() => handleDecreaseMintCount()}
              data-action="decrement"
              className="h-full cursor-pointer rounded-l outline-none"
            >
              <img src="/assets/icons/mint-decrement.svg" />
            </button>
            <div className=" ml-3 flex w-[2.8rem] min-w-[30px] items-center justify-center">
              <input
                disabled={true}
                type="number"
                className="text-md md:text-basecursor-default flex h-[75%] w-full items-center rounded-md bg-white text-center text-sm font-semibold text-gray-700  outline-none hover:text-black focus:text-black focus:outline-none"
                name="custom-input-number"
                value={mintCount}
              />
            </div>
            <button
              onClick={() => handleIncreaseMintCount()}
              data-action="increment"
              className="ml-3 h-full cursor-pointer rounded-r"
            >
              <img src="/assets/icons/mint-increment.svg" />
            </button>
          </div>
        </div>
      </div>
      <div>
        <Button
          type="secondary"
          className="mb-2 inline-block"
          onClick={() => handleMintMaxCount()}
        >
          <a className="flex justify-center pl-5 pr-5">Max</a>
        </Button>
      </div>
    </div>
  );
};

export default ChangeMintValueTab;
