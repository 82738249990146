import {
  Inventory,
  DiamondIcon,
  BattlelogIcon,
  ChartIcon,
  Lore,
  Marketplace,
  Racer,
  Leaderboard,
  Comic,
} from 'components/Icons';

export const getIconByName = (name: string) => {
  switch (name) {
    case 'inventory':
      return <Inventory />;
    case 'battlelog':
      return <BattlelogIcon />;
    case 'statistics':
      return <ChartIcon />;
    case 'leaderboard':
      return <Leaderboard />;
    case 'racer':
      return <Racer />;
    case 'marketplace':
      return <Marketplace />;
    case 'lore':
      return <Lore />;
    case 'comic':
      return <Comic />;
    case 'purchases':
      return <DiamondIcon />;
    default:
      return <Inventory />;
  }
};
