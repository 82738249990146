import classnames from 'classnames';

import { Image } from 'components/core';
import { Triangle } from 'components/Icons';

type BattlePreviewProps = {
  onStart: () => void;
  className?: string;
};

const BattlePreview = (props: BattlePreviewProps) => {
  const { onStart, className } = props;
  return (
    <div className={classnames('relative min-h-[200px] w-full', className)}>
      <Image
        src="/assets/backgrounds/BattleViewerBg.jpg"
        layout="fill"
        objectFit="cover"
      />
      <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center">
        <div
          onClick={onStart}
          className="flex h-40 w-40 cursor-pointer items-center justify-center rounded-[50%] border border-white"
        >
          <div className="">
            <Triangle />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BattlePreview;
