import React from 'react';

const Checkmark: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    width="13"
    height="11"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 13 11"
    className={`${className} mr-2 inline-block stroke-current`}
    data-testid="svg-Checkmark"
  >
    <path
      d="M1 4.5L5.16667 9.5L12 1"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Checkmark;
