import React from 'react';
import Button from '@battlefly/components/Buttons/Button';

const MintButton = ({
  disabled,
  text,
  handleMint,
}: {
  disabled: boolean;
  text: string;
  handleMint: any;
}) => {
  return (
    <div>
      <Button
        disabled={disabled}
        type="primary"
        className="mb-2 inline-block"
        onClick={() => handleMint()}
      >
        {text}
      </Button>
    </div>
  );
};

export default MintButton;
