import {
  failureToast,
  pendingSignatureToast,
  successToast,
} from '@battlefly/components/CustomToast/CustomToast';
import { parseUnits, Result } from 'ethers/lib/utils';
import {
  useAccount,
  useContractEvent,
  useContractRead,
  useContractWrite,
} from 'wagmi';
import { BattleflyGame, CONTRACT_ABIS } from '../../common/constants';
import { useContractAddresses } from './useContractAddresses';

export const useGetBattlefliesOfOwner = () => {
  const contractAddress = useContractAddresses();
  const { address } = useAccount();
  const { data, isError, isLoading, refetch } = useContractRead({
    address: contractAddress[BattleflyGame.BattleflyGameContract],
    abi: CONTRACT_ABIS[BattleflyGame.BattleflyGameContract],
    functionName: 'getBattlefliesOfOwner',
    enabled: !!address,
    args: [address],
    watch: false,
    onError(error) {
      // failureToast('Failed to Fetch Owned BattleFly(s)');
      console.log('Error', error);
    },
  });

  const ownedTokenIds = (data as Result)?.map((id) => id.toNumber()) ?? [];
  console.log('BattleflyGame.BattleflyGameContract', ownedTokenIds);

  return {
    refetch,
    ownedTokenIds,
    isLoading,
    isError,
  };
};

export const useGetSpecialNFTsOfOwner = () => {
  const contractAddress = useContractAddresses();
  const { address } = useAccount();

  const { data, isError, isLoading, refetch } = useContractRead({
    address: contractAddress[BattleflyGame.BattleflyGameContract],
    abi: CONTRACT_ABIS[BattleflyGame.BattleflyGameContract],
    functionName: 'getSpecialNFTsOfOwner',
    enabled: !!address,
    args: [address],
    onError(error) {
      // failureToast('Failed to Fetch Founders NFT(s)');
      console.log('Error', error);
    },
  });

  return {
    getSpecialNFTsOfOwner: refetch,
    tokenIds: (data as Result)?.map((id) => id.toNumber()) ?? [],
    isLoading,
    isError,
  };
};

export const useDepositMagic = () => {
  const contractAddress = useContractAddresses();
  let magicAmount = 0;

  const { writeAsync, isLoading, isSuccess, isError } = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: contractAddress[BattleflyGame.BattleflyGameContract],
    abi: CONTRACT_ABIS[BattleflyGame.BattleflyGameContract],
    functionName: 'depositMagic',
    onMutate({ args, overrides }) {
      pendingSignatureToast();
      console.log('Mutate', { args, overrides });
    },
    onSuccess(data) {
      successToast(`${magicAmount} Magic Deposited`, data);
    },
    onError(error) {
      failureToast(error);
      console.log('Error', error);
    },
  });
  const depositMagic = async (amount: number) => {
    magicAmount = amount;
    return writeAsync({
      recklesslySetUnpreparedArgs: [
        parseUnits(amount.toString(), 18).toString(),
      ],
    });
  };

  return { depositMagic };
};

export const useWithdrawMagic = () => {
  const contractAddress = useContractAddresses();

  const { writeAsync } = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: contractAddress[BattleflyGame.BattleflyGameContract],
    abi: CONTRACT_ABIS[BattleflyGame.BattleflyGameContract],
    functionName: 'claimWithdrawMagic',
    onMutate({ args, overrides }) {
      pendingSignatureToast();
      console.log('Mutate', { args, overrides });
    },
    onSuccess(data) {
      successToast('Magic withdrawn', data);
    },
    onError(error) {
      failureToast(error);
      console.log('Error', error);
    },
  });
  return async (args: {
    amount: number;
    transactionId: number;
    expiry: number;
    r: string;
    s: string;
    v: number;
  }) => {
    const { amount, transactionId, expiry, r, s, v } = args;
    const magicInWei = parseUnits(amount.toString(), 18).toString();
    return writeAsync({
      recklesslySetUnpreparedArgs: [magicInWei, transactionId, expiry, v, r, s],
    });
  };
};

export const useBulkDepositBattlefly = () => {
  const contractAddress = useContractAddresses();
  let battleflyAmount: number[] = [];

  const { write, isLoading, isSuccess, status } = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: contractAddress[BattleflyGame.BattleflyGameContract],
    abi: CONTRACT_ABIS[BattleflyGame.BattleflyGameContract],
    functionName: 'bulkDepositBattlefly',
    onMutate({ args, overrides }) {
      pendingSignatureToast();
      console.log('Mutate', { args, overrides });
    },
    onSuccess(data) {
      successToast(`${battleflyAmount.length} BattleFly(s) Deposited`, data);
    },
    onError(error) {
      failureToast('Failed to Deposit BattleFly(s)');
      console.log('Error', error);
    },
  });
  const bulkDepositBattlefly = async (tokenIds: number[]) => {
    if (tokenIds.length === 0) {
      failureToast('No BattleFly(s) selected');
      return;
    }
    battleflyAmount = tokenIds;
    write({ recklesslySetUnpreparedArgs: [tokenIds] });
  };

  return { bulkDepositBattlefly, isLoading, isSuccess, status };
};

export const useContractListener = (
  eventName: string,
  handler: Parameters<typeof useContractEvent>[0]['listener']
) => {
  const contractAddress = useContractAddresses();

  useContractEvent({
    address: contractAddress[BattleflyGame.BattleflyGameContract],
    abi: CONTRACT_ABIS[BattleflyGame.BattleflyGameContract],
    eventName: eventName,
    listener: handler,
  });
};
