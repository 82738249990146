import { ApolloClient, getApolloContext } from '@apollo/client';
import { GET_USER_INFO, USER_CONNECT } from '@battlefly/graphql/userGql';
import { GetUserInfoQuery } from '@battlefly/graphql/__generated__/userGql';
import {
  ConnectUserRequest,
  ConnectUserResponse,
} from '@battlefly/redux/user/userTypes';
import {
  clearUserCookies,
  isWalletAuthenticated,
  removeAuthToken,
  setAuthCookie,
  setWalletAddress,
} from '@battlefly/util/cookieManager';
import { CustomToast, ToastStatus } from 'components/CustomToast/CustomToast';
import { logoutUser, setUserAuthorized, setUser } from '@battlefly/redux/user';

const transformUserInfo = (userInfo: GetUserInfoQuery['me']) => {
  if (!userInfo) {
    return null;
  }

  const { createdAt, updatedAt, wallets, id, role } = userInfo;

  const info = {
    token: 'graphql',
    error: null,
    pending: false,
    isUserAuthorized: true,
    user: {
      tokens: {
        magic: wallets[0].magic,
      },
      address: wallets[0].address,
      createdAt,
      id,
      role,
      updatedAt,
      wallets: wallets.map((wallet: typeof wallets[0]) => ({
        address: wallet.address,
        id: wallet.id,
        provider: 'metamask',
      })),
    },
  } as ConnectUserResponse;

  return async () => info;
};

/**
 * Establish connection with Battlefly API
 * @param address UserBlockchain Address
 */
export const loginUser = async (
  apolloClient: ApolloClient<object>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: { address: string; sign: string }
) => {
  try {
    const clientResponse = await apolloClient.mutate({
      mutation: USER_CONNECT,
      variables: {
        input: {
          address: user.address,
          signature: user.sign,
        },
      },
      fetchPolicy: 'network-only',
    });

    const authData = {
      graphqlJwt: clientResponse?.data?.connect?.accessToken,
      refreshToken: clientResponse?.data?.connect?.refreshToken,
    };

    const tokenMap = {
      [user.address]: authData,
    };

    setAuthCookie(tokenMap);
    console.log('loginUser.setWalletAddress', user.address);
    setWalletAddress(user.address);

    const userInfo = await apolloClient.query<GetUserInfoQuery>({
      query: GET_USER_INFO,
    });
    console.log('loginUser.userInfo.graphql', userInfo);
    const userInfoTransformed = transformUserInfo(userInfo.data.me);
    console.log('loginUser.userInfoTransformed', userInfoTransformed);
    dispatch(setUserAuthorized(true));
    dispatch(userInfoTransformed);

    CustomToast({
      status: ToastStatus.WALLET_CONNECTED,
    });
  } catch (e) {
    console.error(e);
  }
};
