import { BattleflyGame } from 'common/constants';
import { useMakePrepareContractWrite, useMakeContractWrite } from './utils';

enum PaymentType {
  MAGIC,
  GFLY,
  TREASURES,
}

// TODO: handle quantify of treasures
export const useBurnTreasureForInventorySlot = (
  treasureIds: string[],
  quantities: number[],
  battleflyId: string,
  onSuccess?: () => void
) => {
  const idsAsNumbers = treasureIds.map((id) => parseInt(id, 10));
  const { config, error: prepareError } = useMakePrepareContractWrite(
    {
      functionName: 'upgradeInventorySlot',
      enabled: treasureIds.length > 0,
      args: [battleflyId, 1, PaymentType.TREASURES, idsAsNumbers, quantities],
    },
    BattleflyGame.BattleflyCreditsContract
  );

  return {
    prepareError,
    ...useMakeContractWrite({
      ...config,
      onSuccess,
    }),
  };
};

export const useBurnGFlyForInventorySlot = (
  isContractApproved: boolean,
  battleflyId: string,
  onSuccess?: () => void
) => {
  const { config, error: prepareError } = useMakePrepareContractWrite(
    {
      functionName: 'upgradeInventorySlot',
      enabled: isContractApproved,
      args: [battleflyId, 1, PaymentType.GFLY, [], []],
    },
    BattleflyGame.BattleflyCreditsContract
  );

  return {
    prepareError,
    ...useMakeContractWrite({
      ...config,
      onSuccess,
    }),
  };
};

export const useBurnMagicForInventorySlot = (
  isContractApproved: boolean,
  battleflyId: string,
  onSuccess?: () => void
) => {
  const { config, error: prepareError } = useMakePrepareContractWrite(
    {
      functionName: 'upgradeInventorySlot',
      enabled: isContractApproved,
      args: [battleflyId, 1, PaymentType.MAGIC, [], []],
    },
    BattleflyGame.BattleflyCreditsContract
  );

  return {
    prepareError,
    ...useMakeContractWrite({
      ...config,
      onSuccess,
    }),
  };
};
