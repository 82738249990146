import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { useEffect } from 'react';

import { BattleflyGame } from '@battlefly/common/constants';
import ChangeMintValueTab from '@battlefly/components/Comic/Mint/ChangeMintValueTab';
import useTabMintCount from '@battlefly/components/Comic/Mint/hooks/useTabMintCount';
import MaxMintCountTab from '@battlefly/components/Comic/Mint/MaxMintCountTab';
import MintButton from '@battlefly/components/Comic/Mint/MintButton';
import MintMagicCostTab from '@battlefly/components/Comic/Mint/MintMagicCostTab';
import useApproveMagic from '@battlefly/hooks/contractsNew/useApproveMagic';
import {
  ComicNetworkResponse,
  useComicUsedPaidMints,
  useMintPaid,
} from '@battlefly/hooks/contractsNew/useComic';
import { useContractAddresses } from '@battlefly/hooks/contractsNew/useContractAddresses';

const MintByMagic = ({
  comics,
  comicId,
  magicBalance,
}: {
  comics: ComicNetworkResponse;
  comicId: number;
  magicBalance: {
    data: { formatted: string };
    refetch: () => Promise<any>;
  };
}) => {
  const contractAddress = useContractAddresses();
  const {
    mintCount,
    maxMint,
    setMaxMint,
    handleMintMaxCountWithBalance,
    handleIncreaseMintCount,
    handleDecreaseMintCount,
    decreaseMaxMint,
  } = useTabMintCount();

  const magicBalanceFormatted = Number(magicBalance?.data?.formatted);

  const {
    approveMagic,
    isLoading: isLoadingApproveMagic,
    isMagicApproved,
  } = useApproveMagic(contractAddress[BattleflyGame.ComicContract]);

  const {
    data: dataPaidMintsUsed,
    isError: isErrorPaidMintsUsed,
    isLoading: isLoadingPaidMintsUsed,
    refetch: refetchPaidMintsUsed,
  } = useComicUsedPaidMints(Number(comicId));

  const { bulkMintPaid, isLoading, isSuccess } = useMintPaid();

  const mintedChangeValueCallback = (success: boolean, count: number) => {
    if (success) {
      decreaseMaxMint(count);
      magicBalance.refetch();
    }
  };

  useEffect(() => {
    if (
      !comics ||
      !dataPaidMintsUsed ||
      isErrorPaidMintsUsed ||
      isLoadingPaidMintsUsed
    ) {
      return;
    }
    console.log(
      'dataPaidMintsUsed',
      BigNumber.from(dataPaidMintsUsed).toNumber()
    );
    const paidUsed: number = BigNumber.from(dataPaidMintsUsed).toNumber();
    const maxPaidMintsPerWallet = comics.maxPaidMintsPerWallet.toNumber();
    const isLimited = maxPaidMintsPerWallet > 0;
    const maxPaidMints = isLimited
      ? maxPaidMintsPerWallet - paidUsed
      : Infinity;

    setMaxMint(maxPaidMints);
  }, [comics, dataPaidMintsUsed]);

  const handleMint = async () => {
    if (!maxMint) {
      return;
    }
    if (!isMagicApproved) {
      approveMagic();
      return;
    }
    await bulkMintPaid(mintCount, Number(comicId), mintedChangeValueCallback);
  };

  console.log('WHAT IS MAX MINT', maxMint);
  return (
    <div>
      {maxMint < Infinity && <MaxMintCountTab maxMint={maxMint} />}

      <MintMagicCostTab
        price={mintCount * Number(formatUnits(comics?.priceInWei, 18))}
      />

      <ChangeMintValueTab
        mintCount={mintCount}
        handleDecreaseMintCount={handleDecreaseMintCount}
        handleMintMaxCount={() =>
          handleMintMaxCountWithBalance(
            magicBalanceFormatted,
            comics.priceInWei
          )
        }
        handleIncreaseMintCount={handleIncreaseMintCount}
      />

      <MintButton
        disabled={isLoadingPaidMintsUsed || isLoadingApproveMagic || !mintCount}
        text={
          isLoadingPaidMintsUsed
            ? `Checking available mints...`
            : isMagicApproved
            ? `Mint`
            : `Approve Magic`
        }
        handleMint={handleMint}
      />
    </div>
  );
};

export default MintByMagic;
