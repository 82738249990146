import { getApolloContext } from '@apollo/client';
import { END_RACE, GET_PLAYER_STATISTICS } from '@battlefly/graphql/racerGql';
import { useContext } from 'react';
import { EndRaceRequestBody } from './types';
export const useEndRaceHandler = (
  smolDomain: string,
  userAvatarImg: string,
  address: string,
  endRace: any,
  sendToUnity: any
) => {
  const { client } = useContext(getApolloContext());

  const handleEndRace = async (score: EndRaceRequestBody) => {
    // Do something with the score
    const endingScore = JSON.parse(`${score}`);
    let endRaceResponse;

    try {
      endRaceResponse = await client.mutate({
        mutation: END_RACE,
        variables: {
          input: {
            numNectarDroplets: endingScore?.dropletsCollected,
            userWalletAddress: address.toLowerCase(),
            battleFlyTokenId: endingScore?.battleFlyTokenId,
            signature: endingScore?.signature,
            score: endingScore?.score ?? 0,
            nickName: smolDomain ? smolDomain : address,
            avatar: userAvatarImg ?? '',
          },
        },
        fetchPolicy: 'network-only',
      });

      const dropletsResponse = await client.query({
        query: GET_PLAYER_STATISTICS,
        fetchPolicy: 'network-only',
      });

      const wallets =
        dropletsResponse.data?.endRace.battleFly.owner.user.wallets || [];

      const nectar = wallets.length > 0 ? wallets[0].nectar : 0;
      const droplets = wallets.length > 0 ? wallets[0].droplets : 0;

      sendToUnity('EndRaceSuccess', {
        droplets: nectar * 100 + droplets,
        canRaceAgainToday:
          endRaceResponse.data.endRace.battleFly.dailyRaces.canRaceAgainToday,
      });
    } catch {
      sendToUnity('RequestFailed', {
        reason: endRaceResponse.errors,
        requestMethod: 'EndRace',
      });
    }

    // endRace({
    //   variables: {
    //     input: {
    //       numNectarDroplets: endingScore?.dropletsCollected,
    //       userWalletAddress: address.toLowerCase(),
    //       battleFlyTokenId: endingScore?.battleFlyTokenId,
    //       signature: endingScore?.signature,
    //       score: endingScore?.score ?? 0,
    //       nickName: smolDomain ? smolDomain : address,
    //       avatar: userAvatarImg ?? '',
    //     },
    //   },
    //   fetchPolicy: 'network-only',
    // })
    //   .then((res: FetchResult<EndRaceResponse>) => {
    //     sendToUnity('EndRaceSuccess', {
    //       droplets: res.data.endRace.race.droplets,
    //       canRaceAgainToday:
    //         res.data.endRace.battleFly.dailyRaces.canRaceAgainToday,
    //     });
    //   })
    //   .catch((res: any) => {
    //     sendToUnity('RequestFailed', {
    //       reason: res.errors,
    //       requestMethod: 'EndRace',
    //     });
    //   });
  };

  return { handleEndRace };
};
