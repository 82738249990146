import React from 'react';
import { StarIcon } from '@battlefly/components/Icons';

interface StarsRatingProps {
  filled: number;
  total: number;
  hideZipper?: boolean;
}

const StarsRating: React.FC<StarsRatingProps> = (props) => {
  const startArr = Array.from(Array(props.total).keys());
  return (
    <div className="flex items-center">
      {startArr.map((i) => (
        <StarIcon
          key={i}
          filled={props.filled > i ? 'true' : undefined}
          className={`c-star ${i < props.filled ? 'active' : ''} ${
            i < startArr.length - 1 ? 'mr-0.5' : ''
          }`}
        />
      ))}
    </div>
  );
};

export default StarsRating;
