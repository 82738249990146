import React from 'react';
import classnames from 'classnames';

interface FilterButtonProps {
  color: string;
  selected?: boolean;
  classes?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

const PillButton: React.FC<FilterButtonProps> = ({
  color,
  classes = '',
  selected,
  children,
  onClick,
}) => {
  return (
    <button
      className={classnames(
        'rounded-3xl border-2 px-[21px] py-[7px] text-sm font-light leading-none',
        classes
      )}
      style={{
        borderColor: color,
        backgroundColor: selected ? color + 'BD' : null,
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default PillButton;
