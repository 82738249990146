import React from 'react';
import { IconProps } from '@battlefly/components/Icons/index';

const PlusIconSlim: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.71875 21.5H36.2812"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 6.71875V36.2812"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusIconSlim;
