import Button from '@battlefly/components/Buttons/Button';
import ComicCard from '@battlefly/components/Comic/ComicCard';
import ComicsInWalletModal from '@battlefly/components/Comic/ComicsInWalletModal';
import ComicViewerModal from '@battlefly/components/Comic/ComicViewerModal';
import ExchangeModal from '@battlefly/components/Comic/ExchangeModal';
import NotAuthorizedBlock from '@battlefly/components/Layout/NotAuthorizedBlock';
import Message from '@battlefly/components/Message';
import { useAppSelector } from '@battlefly/hooks';
import {
  ComicNetworkResponse,
  useBurnComic,
  useComicsListLength,
  useGetComicsByWalletAddress,
  useIsApprovedForAll,
  useSetApprovalForAll,
} from '@battlefly/hooks/contractsNew/useComic';
import { selectIsUserAuthorized } from '@battlefly/redux/user/userReducer';
import type { NextPage } from 'next';
import Link from 'next/link';
import React, { useState } from 'react';

const HIDDEN_COMICS: number[] = [];

export const comicFileLocationMap: Record<number, string> = {
  1: '/BattleFly-Issue1-Retro-Cover.pdf',
  2: '/BattleFly-Issue1-Modern-Cover.pdf',
  3: '/BattleFly-Issue1-Retro-GOLD.pdf',
  4: '/BattleFly-Issue1-Modern-GOLD.pdf',
  5: '/BattleFly-Issue1-Retro-HOLO.pdf',
  6: '/BattleFly-Issue1-Modern-HOLO.pdf',
};

const ComicRewardsPage: NextPage = () => {
  const { bulkBurnComic } = useBurnComic();
  const { maxNumComics, isLoading, isError, refetch } = useComicsListLength();
  const [allComicsData, setAllComicsData] = useState<ComicNetworkResponse[]>(
    []
  );
  // callback method that returns comic data to parent, so we can find matching burn data.
  const onComicFetched = (comicData: ComicNetworkResponse) => {
    if (
      comicData &&
      !allComicsData.find(
        (comic: ComicNetworkResponse) => comic.name === comicData.name
      )
    ) {
      setAllComicsData((prev: ComicNetworkResponse[]) => [...prev, comicData]);
    }
  };

  // gets matching burn data from all comics array.
  const getBurnDetails = (burnableComic: ComicNetworkResponse) => {
    const foundComic = allComicsData.find(
      (comic: ComicNetworkResponse) =>
        comic.burnableIn.toNumber() === burnableComic.id.toNumber()
    );
    return foundComic;
  };

  const isUserAuthorized = useAppSelector(selectIsUserAuthorized());
  const [showExchangeModal, setShowExchangeModal] = useState(false);
  const [showViewer, setShowViewer] = React.useState(null);

  const [showMyComicsModal, setShowMyComicsModal] = useState(false);
  const [showSelectComicExchangeModal, setShowSelectComicExchangeModal] =
    useState(false);
  const [selectedNfts, setSelectedNfts] = useState<
    { comic: number; index: number }[]
  >([]);

  const [requestedBurnComic, setRequestedBurnComic] =
    useState<ComicNetworkResponse>(undefined);
  const [burnQuantityNeeded, setBurnQuantityNeeded] =
    useState<number>(undefined);
  const [burnComicIdNeeded, setBurnComicIdNeeeded] =
    useState<number>(undefined);
  const isSpecifiedQuantityMet = selectedNfts?.length === burnQuantityNeeded;

  const { isApproved: isApprovedMintTokens } = useIsApprovedForAll();
  const { setApprovalForAll: setApprovalForBurn } = useSetApprovalForAll();

  const [currentBurnComic, setCurrentBurnComic] =
    useState<ComicNetworkResponse>(null);

  const showExchangeStepOneModal = (comic: ComicNetworkResponse) => {
    setShowExchangeModal(true);
    setCurrentBurnComic(comic);
  };

  const { comicsInUserWallet } = useGetComicsByWalletAddress();

  const handleSelectNft = (token: { comic: number; index: number }) => {
    // ONLY allow one comic type in the array
    if (selectedNfts.length > 0 && selectedNfts[0].comic != token.comic) {
      return;
    }
    const match = selectedNfts.find(
      (selectedToken) => selectedToken.index === token.index
    );
    if (!match) {
      setSelectedNfts([token, ...selectedNfts]);
    } else {
      setSelectedNfts(
        selectedNfts.filter((selectedNft) => selectedNft.index !== token.index)
      );
    }
  };

  const handleOpenExchangeSelectComic = ({
    comicRequested,
    burnQuantityNeeded,
    burnComicIdNeeded,
  }: {
    comicRequested: ComicNetworkResponse;
    burnQuantityNeeded: number;
    burnComicIdNeeded: number;
  }) => {
    if (!showSelectComicExchangeModal) {
      setRequestedBurnComic(comicRequested);
      setBurnQuantityNeeded(burnQuantityNeeded);
      setBurnComicIdNeeeded(burnComicIdNeeded);
      setShowExchangeModal(false);
      setShowSelectComicExchangeModal(true);
      return;
    }

    setShowSelectComicExchangeModal(false);
    return;
  };

  const clearState = () => {
    setSelectedNfts([]);
    setRequestedBurnComic(null);
    setBurnQuantityNeeded(null);
    setBurnComicIdNeeeded(null);
  };

  if (!isUserAuthorized) {
    return <NotAuthorizedBlock />;
  }

  const handleBulkBurn = () => {
    if (!isApprovedMintTokens) {
      setApprovalForBurn();
      return;
    }
    // console.log('THESE ARE MY MINT REQUESTS', {
    //   burnId: selectedNfts[0].comic,
    //   amount: selectedNfts.length,
    //   mintId: requestedBurnComic.id.toNumber(),
    // });
    if (isApprovedMintTokens && isSpecifiedQuantityMet) {
      bulkBurnComic({
        burnId: selectedNfts[0].comic,
        amount: 1,
        mintId: requestedBurnComic.id.toNumber(),
        successCb: refetch(),
      });
      return;
    }
  };

  return (
    <>
      <div className="max-w-8xl m-auto mt-8 rounded-md bg-black p-6">
        <div className="relative pb-6">
          <h2 className="text-3xl uppercase md:text-5xl">Rewards</h2>
          <div className="absolute right-0 top-0 mobile:static mobile:mt-3">
            <div>
              <span className="ml-3 inline-block mobile:ml-0">
                <Button
                  type="primary"
                  onClick={() => setShowMyComicsModal(true)}
                >
                  My Comics
                </Button>
              </span>
            </div>
          </div>
        </div>

        <div className="mb-4 w-full gap-4 border-t border-b border-core-green-muted/20 text-lg">
          <Link passHref href={'/comic/rewards/'}>
            <button
              className={`border-b-2 border-b-button-secondary p-2 pb-2.5`}
            >
              Comics
            </button>
          </Link>
        </div>
        <div className="flex flex-wrap">
          {isLoading && <Message text="Loading ..." display={true} />}
          {isError && (
            <Message text="Error fetching comics ..." display={true} />
          )}
          {!isLoading &&
            !isError &&
            maxNumComics &&
            Array(maxNumComics)
              .fill(null)
              .filter((_, index) => {
                const comicId = index + 1;
                return !HIDDEN_COMICS.includes(comicId);
              })
              .map((_, index) => {
                const comicId = index + 1;
                return (
                  <ComicCard
                    handleViewComic={() =>
                      setShowViewer(comicFileLocationMap[comicId])
                    }
                    onComicFetched={onComicFetched}
                    key={index}
                    comicId={comicId}
                    handleOpenExchangeModal={showExchangeStepOneModal}
                    isOwnedByUser={comicsInUserWallet.includes(comicId)}
                  />
                );
              })}
        </div>
      </div>

      {showExchangeModal && (
        <ExchangeModal
          getBurnDetails={getBurnDetails}
          handleClose={setShowExchangeModal}
          handleOpenExchangeSelectComic={handleOpenExchangeSelectComic}
          comic={currentBurnComic}
        />
      )}

      {(showMyComicsModal || showSelectComicExchangeModal) && (
        <ComicsInWalletModal
          burnComicIdNeeded={burnComicIdNeeded}
          showExchangeModal={showSelectComicExchangeModal}
          comicsInWallet={comicsInUserWallet}
          onClose={() => {
            setShowMyComicsModal(false);
            setShowSelectComicExchangeModal(false);
            clearState();
          }}
          handleSelectNft={handleSelectNft}
          selectedNfts={selectedNfts}
          buttonActions={
            <div className="flex flex-wrap justify-end gap-6">
              <Button
                type="secondary"
                className={`w-full px-12 lg:w-auto
          `}
                disabled={
                  !selectedNfts.length ||
                  (isApprovedMintTokens && !isSpecifiedQuantityMet)
                }
                onClick={() => handleBulkBurn()}
              >
                {!isApprovedMintTokens ? `Approve` : `Burn Selected`}
              </Button>
            </div>
          }
        />
      )}

      {showViewer && (
        <ComicViewerModal
          handleClose={() => setShowViewer(null)}
          fileName={showViewer}
        />
      )}
    </>
  );
};

export default ComicRewardsPage;
