import {
  useDefaultNames,
  useGetDomainData,
} from '@battlefly/hooks/contractsNew/useSmolDomainsContract';
import { createContext, useMemo } from 'react';
import { useAccount } from 'wagmi';

// global store of data

interface UserContextType {
  userAvatarImg: string;
  smolDomain: string;
}

export const UserContext = createContext({} as UserContextType);

export const UserContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  // STATE
  const { isConnected, address } = useAccount();

  const { data: smolName, isLoading: isLoadingSmolName } =
    useDefaultNames(address);
  const { userAvatarImg } = useGetDomainData(
    smolName && !isLoadingSmolName && smolName?.toString()
  );
  const smolDomain = smolName ? `${smolName}.smol` : undefined;
  // HOOKS

  const contextData = useMemo(
    () => ({
      smolDomain,
      userAvatarImg,
    }),
    [smolDomain, userAvatarImg]
  );

  return (
    <UserContext.Provider value={contextData}>{children}</UserContext.Provider>
  );
};

export default UserContextProvider;
