import React from 'react';
import classnames from 'classnames';

import ModCompact from '@battlefly/components/BattleflyDetails/Mods/ModCompact';
import { Mod } from '@battlefly/__generated__/globalTypes';

interface LoadoutProps {
  mods: Mod[];
  elementClasses?: string;
  containerClasses?: string;
  modClasses?: string;
  overflow?: boolean;
}

const Loadout: React.FC<LoadoutProps> = ({
  mods,
  modClasses,
  containerClasses,
  elementClasses,
  overflow = false,
}) => {
  return (
    <div
      className={classnames(
        'scrollbar-hidden w-full max-w-full',
        {
          'overflow-x-scroll': overflow,
        },
        containerClasses
      )}
    >
      <div className="justify-inherit flex gap-4 mobile:inline-flex pt-2">
        {mods
          .slice()
          .sort((a, b) => a.slot - b.slot)
          .map((mod) => (
            <div key={mod.id} className={elementClasses}>
              <ModCompact
                mod={mod}
                containerClasses={classnames('mobile:h-[200px]', modClasses)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Loadout;
