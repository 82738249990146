import classnames from 'classnames';

import { BattleLog } from '@battlefly/ts/BattleLog';

type LastBattlesProps = {
  logs: BattleLog[];
  darkOrchid?: boolean;
  black?: boolean;
};

const getBorderColor = (props: LastBattlesProps) => {
  const { darkOrchid, black } = props;
  if (darkOrchid) {
    return 'border-core-dark-orchid-highlight';
  }
  if (black) {
    return 'border-black';
  }
  return 'border-core-cyberpink-highlight';
};

export const Dot = ({
  color,
  borderColor,
}: {
  color: 'green' | 'gray' | 'red';
  borderColor: string;
}) => {
  return (
    <div
      className={classnames(
        'h-3 w-3 rounded-full border',
        {
          'gra-green-dot': color === 'green',
          'gra-red-dot': color === 'red',
          'gra-gray-dot': color === 'gray',
        },
        borderColor
      )}
    />
  );
};

export const LastBattlesDots = (props: LastBattlesProps) => {
  const { logs } = props;
  const borderColor = getBorderColor(props);
  return (
    <>
      {logs
        .filter((log, index) => index < 5)
        .map((log) => (
          <Dot
            key={log.id}
            color={log.isWinner ? 'green' : 'red'}
            borderColor={borderColor}
          />
        ))}
    </>
  );
};

const LastBattles = ({ logs, darkOrchid }: LastBattlesProps) => {
  return (
    <div className="flex flex-col">
      <div className="text-right font-heading text-sm uppercase">
        Last Five Battles
      </div>
      <div className="flex gap-1">
        <LastBattlesDots logs={logs} darkOrchid={darkOrchid} />
      </div>
    </div>
  );
};

export default LastBattles;
