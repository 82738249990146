import { gql } from '@apollo/client';
import { useGetStatisticsQuery } from '@battlefly/hooks/arenas/__generated__/useStatisticsData';
import { TopBattleFlyGlobalStatisticsFragmentDoc } from '@battlefly/components/Arenas/Tabs/__generated__/TopBattleFlyPanel';

gql`
  query getStatistics {
    getGlobalStatistics {
      id
      totalBattleFlies
      averageDailyBattles
      totalBattlesInDay
      totalMagicWonInDay
      ...TopBattleFlyGlobalStatistics
    }
  }

  ${TopBattleFlyGlobalStatisticsFragmentDoc}
`;

const useStatisticsData = () => {
  const { data, loading } = useGetStatisticsQuery();
  return { data: data?.getGlobalStatistics, loading };
};

export default useStatisticsData;
