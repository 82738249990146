import { SyntheticEvent, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { BattleflyCardWrapper } from '@battlefly/components/BattleflyCards/subcomponents/Animations';
import { Divider } from '@battlefly/components/BattleflyCards/subcomponents/Divider';
import Button from '@battlefly/components/Buttons/Button';
import Spinner from '@battlefly/components/Spinner/Spinner';
import useSingleComic, {
  ComicNetworkResponse,
} from '@battlefly/hooks/contractsNew/useComic';
import { comicFileLocationMap } from '@battlefly/routes/comic';

const ComicCard = ({
  comicId,
  handleOpenExchangeModal,
  handleViewComic,
  isOwnedByUser,
  onComicFetched,
}: {
  comicId: number;
  handleOpenExchangeModal: (comics: ComicNetworkResponse) => void;
  handleViewComic: () => void;
  isOwnedByUser: boolean;
  onComicFetched: (comic: ComicNetworkResponse) => void;
}) => {
  const { comics, loading } = useSingleComic(comicId);

  const handleView = (event: SyntheticEvent) => {
    // @TODO COMIC BURN
    if (comics.mintType.toNumber() === 3) {
      event.preventDefault();
      handleOpenExchangeModal(comics);
    }
  };

  useEffect(() => {
    if (comics) {
      onComicFetched(comics);
    }
  }, [comics]);

  return (
    <BattleflyCardWrapper className="relative !p-1.5">
      <div className="font-white flex w-full flex-col gap-2 text-sm text-white">
        <div className="image-container relative z-[0] items-center overflow-hidden">
          <div className="image-container relative z-[0] items-center overflow-hidden">
            <img
              className="fade-in image-child-back inset-0 z-[0] w-full object-cover object-center"
              src={
                comics?.details?.image ||
                '/assets/test-placeholder/BattleFly-Issue1-Cover-GOLD1.png'
              }
            />
            <div className="image-child-back battlefly-screen pb-full z-[1] w-full"></div>
            <img
              className="fade-in battlefly-card image-child-back inset-0 z-[2] w-full object-cover object-center"
              src={
                comics?.details?.image ||
                '/assets/test-placeholder/BattleFly-Issue1-Cover-GOLD1.png'
              }
            />
          </div>
        </div>
      </div>

      <div className="pl-1 pr-1">
        <Divider padding="py-2 pt-6 pb-3" />
        <div className="mb-3 flex justify-between">
          <div className="text-base">{comics?.name || 'Comic Name #1'}</div>
          <div className="w-20">
            <button
              className="w-full rounded-md border-[1px] border-white py-2 text-xs sm:py-1"
              style={{
                background: `linear-gradient(137.42deg, rgba(226, 226, 228, 0.5) 5%, rgba(226, 226, 228, 0) 93.91%)`,
              }}
            >
              Normal
            </button>
          </div>
        </div>
        <div>
          <Link to={'/comic/mint/' + comicId}>
            <a className="flex justify-center" onClick={(e) => handleView(e)}>
              <Button
                type="primary"
                className="mb-2 inline-block"
                disabled={[5, 6].includes(comicId)}
              >
                {[5, 6].includes(comicId)
                  ? 'Only Mintable By Treasury'
                  : 'Mint'}
              </Button>
            </a>
          </Link>
        </div>

        <div>
          <a className="block w-full" href={comicFileLocationMap[comicId]}>
            <Button
              // onClick={() => handleViewComic()}
              type="secondary"
              disabled={!isOwnedByUser}
              className="mb-2 inline-block"
            >
              View
            </Button>
          </a>
        </div>
      </div>

      {loading && (
        <div
          className="absolute top-0 left-0 flex h-full w-full items-center justify-center"
          style={{ backdropFilter: 'blur(30px)' }}
        >
          <Spinner pending={true} />
        </div>
      )}

      {!comics?.active && (
        <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-black opacity-50">
          {/* <Spinner pending={true} /> */}
        </div>
      )}
    </BattleflyCardWrapper>
  );
};

export default ComicCard;
