import React, {
  createContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useAccount, useBalance } from 'wagmi';
import { useQuery } from '@apollo/client';
import { GET_BALANCES } from '@battlefly/graphql/userGql';
import {
  useDepositMagic,
  useContractListener,
  useWithdrawMagic,
} from '@battlefly/hooks/contractsNew/useBattleflyGameContract';
import { useContractAddresses } from '@battlefly/hooks/contractsNew/useContractAddresses';
import { BattleflyGame } from '../common/constants';
import { useAppSelector } from '@battlefly/hooks';
import { selectIsUserAuthorized } from '@battlefly/redux/user/userReducer';

type WithdrawMagicArgs = Parameters<ReturnType<typeof useWithdrawMagic>>[0];

interface UserBalanceContextType {
  unstakedMagicBalance: number;
  magicBalance: number;
  nectarBalance: number;
  isDepositing: boolean;
  isWithdrawing: boolean;
  depositMagic: (amount: number) => Promise<void>;
  withdrawMagic: (args: WithdrawMagicArgs) => Promise<void>;
  refetchBalances: () => void;
  credits: {
    quantity: number;
    type: string
  }[]
}

export const UserBalanceContext = createContext({} as UserBalanceContextType);

export const UserBalanceContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  const contractAddress = useContractAddresses();
  const { address } = useAccount();
  const { depositMagic } = useDepositMagic();
  const withdrawMagic = useWithdrawMagic();

  const [pendingAmount, setPendingAmount] = useState(0);
  const [isDepositing, setIsDepositing] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [magic, setMagic] = useState(0);

  const { refetch } = useBalance({
    address: address,
    token: contractAddress[BattleflyGame.MagicContract],
    cacheTime: 30000,
  });

  const handleDepositMagic = useCallback(
    async (value: number) => {
      try {
        setIsDepositing(true);
        setPendingAmount(value);
        await depositMagic(value);
      } catch (e) {
        setIsDepositing(false);
        setPendingAmount(0);
      }
    },
    [depositMagic]
  );

  const handleWithdrawMagic = useCallback(
    async (args: WithdrawMagicArgs) => {
      try {
        setIsWithdrawing(true);
        setPendingAmount(args.amount);
        await withdrawMagic(args);
      } catch (e) {
        setIsWithdrawing(false);
        setPendingAmount(0);
      }
    },
    [withdrawMagic]
  );

  useContractListener('DepositMagic', ([, emitterAddress]) => {
    if (emitterAddress === address) {
      setIsDepositing(false);
      setMagic(magic + pendingAmount);
      setPendingAmount(0);
      refetch();
    }
  });

  useContractListener('WithdrawMagic', ([, emitterAddress]) => {
    if (emitterAddress === address) {
      setIsWithdrawing(false);
      setMagic(magic - pendingAmount);
      setPendingAmount(0);
      refetch();
    }
  });

  const isUserAuthorized = useAppSelector(selectIsUserAuthorized());
  const {
    data,
    startPolling,
    stopPolling,
    refetch: refetchBalances,
  } = useQuery(GET_BALANCES, {
    skip: !isUserAuthorized,
  });

  const nectarBalance: number = data?.me.wallets[0].nectar;
  const magicBalance: number = data?.me.wallets[0].magic;
  const credits = data?.me?.credits || [];

  useEffect(() => {
    startPolling(30000);

    return () => {
      stopPolling();
    };
  }, []);

  useEffect(() => {
    setMagic(magicBalance);
  }, [magicBalance]);

  const { data: unstakedMagicBalanceData } = useBalance({
    address: address,
    token: contractAddress[BattleflyGame.MagicContract],
  });
  const unstakedMagicBalance = Number(
    Number(unstakedMagicBalanceData?.formatted || 0).toFixed(2)
  );

  const contextData = useMemo(
    () => ({
      nectarBalance,
      magicBalance: magic,
      isDepositing,
      isWithdrawing,
      depositMagic: handleDepositMagic,
      withdrawMagic: handleWithdrawMagic,
      unstakedMagicBalance,
      credits
    }),
    [
      nectarBalance,
      magic,
      isDepositing,
      isWithdrawing,
      handleDepositMagic,
      handleWithdrawMagic,
      unstakedMagicBalance,
      credits
    ]
  );

  return (
    <UserBalanceContext.Provider
      value={{
        ...contextData,
        refetchBalances,
      }}
    >
      {children}
    </UserBalanceContext.Provider>
  );
};

export default UserBalanceContextProvider;
