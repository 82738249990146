import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router';

import { HoldingBattlefliesContext } from '../../context/holdingBattlefliesContext';
import { StakedBattlefliesContext } from '../../context/stakedBattlefliesContext';
import { useCustomToastRules } from '../CustomToast/CustomToast';
import { ToastContainerNew } from '../CustomToast/ToastContainer';
import Header, { MobileHeader } from 'components/Header';
import UnityGame from '../UnityGame';
import WalletDrawer from 'components/WalletDrawer';
import NavigationDrawer, {
  DesktopNavigationDrawer,
} from 'components/NavigationDrawer';
import { MODAL_BF_TO_PROVING_GROUNDS_ID } from '@battlefly/graphql/localGql';
import { DrawerContext } from 'context/drawerContext';
import {
  ConfirmModal,
  ConfirmationModal,
  ScavengerMarkModal,
  PurchaseInventorySlotModal,
  HuntersMarkModal,
  BattleSettingsModal,
  TermsConditionsModal,

} from 'components/modals';

// Create toasts anywhere

import useBackgroundStyle from '@battlefly/hooks/ui/useBackgroundStyle';
import { ModalContext } from '@battlefly/context/modalContext';

const Layout = ({
  loggedIn,
  children,
}: {
  children: React.ReactNode;
  loggedIn?: boolean;
  showDetails?: boolean;
}) => {
  useCustomToastRules();
  // const [showWallet, setShowWallet] = React.useState(false);
  const [showNav, setShowNav] = React.useState(false);
  const { showBalanceDrawer: showWallet, setShowBalanceDrawer: setShowWallet } =
    React.useContext(DrawerContext);
  const { battleSettings, showTermsModal } = React.useContext(ModalContext);

  const { pageClassName } = useBackgroundStyle();

  const { isStakedBattlefliesCartOpen } = useContext(StakedBattlefliesContext);
  const { isOwnedBattlefliesCartOpen } = useContext(HoldingBattlefliesContext);
  // const { togglePlayer, isPlayingAudio, playAudioOnInitialClick } =
  //   useAudioPlayer();

  const { data: modalBfToProvingGroundsId } = useQuery(
    MODAL_BF_TO_PROVING_GROUNDS_ID
  );

  const location = useLocation();
  React.useEffect(() => {
    setShowNav(false);
  }, [location]);

  return (
    <>
      <ToastContainerNew />
      <div
        className={pageClassName}
        id="__layout"
        // onClick={() => {
        //   playAudioOnInitialClick();
        // }}
      >
        <div className="relative">
          <UnityGame />
          {loggedIn && (
            <Header toggleWallet={() => setShowWallet(!showWallet)} />
          )}
          {loggedIn && (
            <MobileHeader
              toggleWallet={() => setShowWallet(!showWallet)}
              toggleMenu={() => setShowNav(!showNav)}
            />
          )}
          <div id="__main" className="sm:flex">
            <div className="hidden w-[80px] sm:block" />
            <div
              id="__content"
              className="max-content-width mx-auto w-screen justify-center md:flex mobile:pt-20"
            >
              {isStakedBattlefliesCartOpen === true ||
              isOwnedBattlefliesCartOpen === true ? null : (
                <ToastContainerNew />
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
      <WalletDrawer isOpen={showWallet} onClose={() => setShowWallet(false)} />
      <NavigationDrawer isOpen={showNav} onClose={() => setShowNav(false)} />
      <DesktopNavigationDrawer
        isOpen={showNav}
        onToggle={() => setShowNav(!showNav)}
        onClose={() => setShowNav(false)}
      />

      <ConfirmModal />
      <ConfirmationModal />
      <ScavengerMarkModal />
      <HuntersMarkModal />
      <PurchaseInventorySlotModal />
      {battleSettings && <BattleSettingsModal />}
      {showTermsModal && <TermsConditionsModal />}
    </>
  );
};

export default Layout;
