import React from 'react';
import { useQuery } from '@apollo/client';

import { BATTLEFLY_SLOTS_BY_ID } from '@battlefly/graphql/battleflyGql';
import { BattleFly } from '@battlefly/__generated__/globalTypes';

export const usePollForInventoryUpgrade = (battleFly: BattleFly) => {
  const [isPolling, setIsPolling] = React.useState(false);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const expectedInventorySlots = battleFly?.slotsAvailable + 1;

  const { data, error, refetch } = useQuery(BATTLEFLY_SLOTS_BY_ID, {
    variables: {
      input: {
        battleFlyId: battleFly?.id,
      },
    },
    skip: !battleFly?.id || !isPolling || isUpgraded,
    fetchPolicy: 'network-only',
    pollInterval: 1000,
  });

  const updgradedBattlefly = data?.getBattleFly?.battleFly;

  React.useEffect(() => {
    if (updgradedBattlefly?.slotsAvailable === expectedInventorySlots) {
      setIsUpgraded(true);
      setIsPolling(false);
    }
  }, [updgradedBattlefly]);

  const resetListener = () => {
    setIsPolling(false);
    setIsUpgraded(false);
    refetch();
  }

  return {
    isPolling,
    success: isUpgraded,
    resetListener,
    startPolling: () => setIsPolling(true),
  };
};
