import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { GET_BALANCES } from '@battlefly/graphql/userGql';
import {
  ApplyHuntersMarkInput,
  BattleFlyHuntersMark,
} from '@battlefly/__generated__/globalTypes';
import { APPLY_HUNTERS_MARK } from '@battlefly/graphql/battleflyGql';
import {
  CustomToast,
  ToastStatus,
} from '@battlefly/components/CustomToast/CustomToast';
import { GET_BATTLEFLY_MARKS } from '@battlefly/graphql/battleflyGql';

export const useApplyHuntersMark = (battleflyId: string) => {
  const {
    // data: dataBalances,
    refetch: reFetchBalances,
  } = useQuery(GET_BALANCES);
  // const nectarBalance = dataBalances?.me?.wallets[0]?.nectar;
  const [fetchBattleflyMarks, { refetch: refetchBattleflyMarks }] =
    useLazyQuery(GET_BATTLEFLY_MARKS, {
      variables: { input: { battleFlyId: battleflyId } },
    });
  const [applyMark, { loading }] = useMutation<
    { applyHuntersMark: BattleFlyHuntersMark },
    { applyHuntersMarkInput: ApplyHuntersMarkInput }
  >(APPLY_HUNTERS_MARK, {
    onCompleted: (data) => {
      reFetchBalances();
      refetchBattleflyMarks();
      CustomToast({
        status: ToastStatus.SUCCESS,
        message: 'Mark applied',
      });
    },
    onError: () => {
      reFetchBalances();
      CustomToast({
        status: ToastStatus.REJECTED,
        message: 'Failed to apply mark',
      });
    },
  });

  return { applyMark, loading };
};
