import React, { createContext, useState } from 'react';

// global store of data

interface selectModsType {
  selectModsPortal: selectModsPortal;
}

interface selectModsPortal {
  isOpen: boolean;
  toggle: () => void;
  data: {};
  setData: (data: {}) => void;
}

export const SelectModsContext = createContext({} as selectModsType);

export const selectModsContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  const [isselectModsOpened, setselectModsOpened] = useState(false);
  const [selectModsData, setselectModsData] = useState({});
  const selectModsPortal: selectModsPortal = {
    isOpen: isselectModsOpened,
    toggle: () => setselectModsOpened(!isselectModsOpened),
    data: selectModsData,
    setData: (data) => setselectModsData(data),
  };

  const data = { selectModsPortal };

  return (
    <SelectModsContext.Provider value={data}>
      {children}
    </SelectModsContext.Provider>
  );
};

export default selectModsContextProvider;
