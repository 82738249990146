import Button from '@battlefly/components/Buttons/Button';
import { NextPage } from 'next';
import { Link } from 'react-router-dom';
import { MAINTAIN_MODE } from '../common/environmentVariables';
import PageWrapper from '../components/Layout/PageWrapper/PageWrapper';
import Maintenance from '../components/Maintenance/Maintenance';
import ModalWrapper from '../Wrappers/ModalWrapper';

const Home: NextPage = () => {
  return (
    <>
      <PageWrapper>
        {MAINTAIN_MODE == 'true' ? (
          <ModalWrapper>
            <Maintenance />
          </ModalWrapper>
        ) : (
          <div className="h-full">
            <div className="flex w-full flex-col justify-center justify-items-center">
              <h1 className="text-center text-[0.5rem] font-medium lg:text-[1rem] xl:text-[2rem]">
                SEASON 1
              </h1>
              <h1 className="text-center text-[3rem] md:text-[5rem] lg:text-[6rem] xl:text-[8rem]">
                BATTLEFLY
              </h1>
              <div className="flex w-full justify-center">
                <Link
                  className="w-full text-center"
                  to="/battleflies"
                >
                  <button
                    className="w-1/2 py-2 text-xl font-bold"
                    style={{
                      border: `3px solid #7B61FF`,
                      background: `linear-gradient(134.97deg, #7B61FF 0.29%, #5541C7 29.89%, #0F0F37 100%)`,
                    }}
                  >
                    ENTER
                  </button>
                </Link>
              </div>
              <div className="self-bottom">
                <div className="flex gap-2">
                  <Button type={'primary'} className="font-medium">
                    <a
                      className="block w-full"
                      target="_blank"
                      href={
                        'https://battlefly-2.gitbook.io/flytepaper-2.0/overview/welcome-to-battlefly'
                      }
                      rel="noreferrer"
                    >
                      GUIDE
                    </a>
                  </Button>

                  <Button type={'secondary'} className="font-medium">
                    <a
                      className="block w-full"
                      href="/files/BattleFly_Blackpaper.pdf"
                    >
                      LORE{' '}
                    </a>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </PageWrapper>
    </>
  );
};

export interface MintsAvailableForBFType {
  name: string;
  tokenId: number;
  allocatedAmount: number;
  mintedAmount: number;
}

export default Home;
