import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useHandleOutsideClick(ref: any, onOutsideClickFn: any) {
  useEffect(() => {
    function onOutsideClick(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClickFn && onOutsideClickFn();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', onOutsideClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', onOutsideClick);
    };
  }, [onOutsideClickFn, ref]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideClickHander(props: any) {
  const wrapperRef = useRef(null);
  useHandleOutsideClick(wrapperRef, props.onOutsideClick);
  return (
    <div className={classnames(props.className)} ref={wrapperRef}>
      {props.children}
    </div>
  );
}
