import { gql } from '@apollo/client';

export const IS_MODAL_GET_MAGIC_OPENED = gql`
  query isModalGetMagicOpened {
    isModalGetMagicOpened @client
  }
`;

export const STAKED_TOKEN_IDS = gql`
  query stakedTokenIds {
    stakedTokenIds @client
  }
`;

export const HOLDING_TOKEN_IDS = gql`
  query holdingTokenIds {
    holdingTokenIds @client
  }
`;

export const MODAL_BF_TO_PROVING_GROUNDS_ID = gql`
  query modalBfToProvingGroundsId {
    modalBfToProvingGroundsId @client
  }
`;

export const UNSTAKING_CART_IDS = gql`
  query unStakeBfCartIds {
    unStakeBfCartIds @client
  }
`;

export const STAKING_CART_IDS = gql`
  query stakeBfCartIds {
    stakeBfCartIds @client
  }
`;

export const JUST_NOW_STAKED_UNSTAKED_IDS = gql`
  query justNowUnStakedStakedBfIds {
    justNowUnStakedStakedBfIds @client
  }
`;
