import { gql, useMutation } from '@apollo/client';

const SET_PROVING_GROUNDS_COMBAT_RATE = gql`
  mutation setProvingGroundsCombatRate(
    $battleFlyIds: [Int!]!
    $newCombatRate: Int!
  ) {
    setProvingGroundsCombatRate(
      input: { battleFlyId: $battleFlyIds, newCombatRate: $newCombatRate }
    ) {
      id
      hyperdomeCombatRate
      provingGroundsCombatRate
      location
      tattoos {
        id
      }
    }
  }
`;

const SET_HYPERDOME_COMBAT_RATE = gql`
  mutation setHyperdomeCombatRate(
    $battleFlyIds: [Int!]!
    $newCombatRate: Int!
  ) {
    setHyperdomeCombatRate(
      input: { battleFlyId: $battleFlyIds, newCombatRate: $newCombatRate }
    ) {
      id
      hyperdomeCombatRate
      provingGroundsCombatRate
      location
      tattoos {
        id
      }
    }
  }
`;

export const useMutateCombatRate = (
  hasHyperdomeMark: boolean
): [ReturnType<typeof useMutation>[0], boolean] => {
  const [
    setProvingGroundsCombatRate,
    { loading: updatingProvingGroundsCombatRate },
  ] = useMutation(SET_PROVING_GROUNDS_COMBAT_RATE);
  const [setHyperdomeCombatRate, { loading: updatingHyperdomeCombatRate }] =
    useMutation(SET_HYPERDOME_COMBAT_RATE);

  if (hasHyperdomeMark) {
    return [setHyperdomeCombatRate, updatingHyperdomeCombatRate];
  }
  return [setProvingGroundsCombatRate, updatingProvingGroundsCombatRate];
};
