import React from 'react';
import Message from '@battlefly/components/Message';

interface PageStatusMessageProps {
  message: string;
}

const PageStatusMessage: React.FC<PageStatusMessageProps> = (props) => {
  return (
    <div className="scrollbar-customized flex justify-center mobile:flex-wrap">
      <Message text={props.message} display={true} />
    </div>
  );
};

export default PageStatusMessage;
