import { arbitrum, arbitrumGoerli } from 'wagmi/chains';
import {
  BattleflyABI,
  BattleflyCreditsABI,
  BattleflyFounderVaultABI,
  BattleflyGameABI,
  BridgeworldTreasuresABI,
  ComicABI,
  ItemABI,
  MagicABI,
  RevealStakingABI,
  SmolDomainsABI,
  SpecialNFTABI,
  GFlyContractABI,
} from '../abi';

export enum BattleflyGame {
  BattleflyContract,
  BattleflyGameContract,
  ItemContract,
  MagicContract,
  SpecialNFTContract,
  RevealStakingContract,
  SmolDomainsContract,
  ComicContract,
  FounderVaultV1,
  FounderVaultV2,
  BattleflyCreditsContract,
  BridgeworldTreasuresContract,
  GFlyContract,
}

export const JWT_TOKEN_REFRESH_TIME_SECONDS = 60 * 5;

// Chains
export const DEFAULT_CHAIN = arbitrum;

export const LAYOUT_SCROLLING_ID = '__layout';
export const LOGIN_SIGNATURE = `Confirm authentication with Battlefly.game`;

const BATTLEFLY_CONTRACT = '0x0aF85A5624D24E2C6e7Af3c0a0b102a28E36CeA3';
const BATTLEFLY_CONTRACT_GOERLI = '0x1e16Dede77E5671a984ED1A2221345747E17Da77';
// const BATTLEFLY_CONTRACT_GOERLI = '0x10f72c2810c18d4ab5843f363b46273f387583b7';

const BATTLEFLY_GAME_CONTRACT = '0xA06FafD46653dc50dC97E37159D6AcB0eD1B1893';
const BATTLEFLY_GAME_CONTRACT_GOERLI =
  '0x12414e1b496765bba1f97fBB019407a211Ea48E4';

const BATTLEFLY_CREDITS_CONTRACT = '0xf6FC95387A82Fb364798fa2Ef1F868F641D8AD6A';
const BATTLEFLY_CREDITS_CONTRACT_GOERLI =
  '0x9000a7fDbB6d119A6834227B5a550dB51F6D261C';

const BRIDGEWORLD_TREASURES_CONTRACT =
  '0xebba467ecb6b21239178033189ceae27ca12eadf';
const BRIDGEWORLD_TREASURES_CONTRACT_GOERLI =
  '0x3243e84a1b067b4cd4094114e0dc71ac13d80556';

const ITEM_CONTRACT = '0xAEbFb9431964c2c661faC9e99Be0C230D20C0C95';
const ITEM_CONTRACT_GOERLI = '';

const MAGIC_CONTRACT = '0x539bdE0d7Dbd336b79148AA742883198BBF60342';
const MAGIC_CONTRACT_GOERLI = '0x46BA651d05957a55cDAE3d52a25170EC75d5A0C2';

const REVEAL_STAKING_CONTRACT = '0xca1D055331D3a4e74a2FFCcFF83160699aC1C4B4';
const REVEAL_STAKING_CONTRACT_GOERLI =
  '0xdA3Af43039930e67d48ACFFaD856e67A6e31dbB2';
// const REVEAL_STAKING_CONTRCT_GOERLI = '0xa5622AFF125975e8EB4f4a38Fe8609cde80b27B6'

const SPECIAL_NFT_CONTRACT = '0xc43104775BD9f6076808B5F8dF6CbdBeac96d7dE';
const SPECIAL_NFT_CONTRACT_GOERLI =
  '0xf12a88F5b6c8bE289B541A3399dE9e7021a971A1';

const SMOL_DOMAINS_CONTRACT = '0xE0d972817e94c5FF9BDc49a63d8927A0bA833E4f';
const SMOL_DOMAINS_CONTRACT_GOERLI = '';

const COMIC_CONTRACT = '0x10e49b96a4bb66bef39b27c3a9d81ae19908c65d';
const COMIC_CONTRACT_GOERLI = '0x71dA1BBa36216b13a40f61a8bC61b5Ae6b0f9646';

const FOUNDER_VAULT_V1 = '0x2F67932136D84De27D99Ed89a423b907A1b10930';
const FOUNDER_VAULT_V1_GOERLI = '0xD93B5e8Adb656Aa50Ce978ECb433E41688039FA4';

const FOUNDER_VAULT_V2 = '0x6c9CC5a6d5484CB8eAB1438632BBf667A5E25eD9';
const FOUNDER_VAULT_V2_GOERLI = '0x095aFa050AaE64B37ac1e71d3eB2223E770e3EE1';

const GFLY_CONTRACT = '0x872bad41cfc8ba731f811fea8b2d0b9fd6369585';
const GFLY_CONTRACT_GOERLI = '0x7d368b55Cb2b957A43C1e535655F0E6AADeBF26C';

export const CONTRACT_ADDRESSES: Record<string, ContractMap> = {
  [arbitrum.name]: {
    [BattleflyGame.BattleflyContract]: BATTLEFLY_CONTRACT,
    [BattleflyGame.BattleflyGameContract]: BATTLEFLY_GAME_CONTRACT,
    [BattleflyGame.BattleflyCreditsContract]: BATTLEFLY_CREDITS_CONTRACT,
    [BattleflyGame.BridgeworldTreasuresContract]:
      BRIDGEWORLD_TREASURES_CONTRACT,
    [BattleflyGame.ItemContract]: ITEM_CONTRACT,
    [BattleflyGame.MagicContract]: MAGIC_CONTRACT,
    [BattleflyGame.SpecialNFTContract]: SPECIAL_NFT_CONTRACT,
    [BattleflyGame.RevealStakingContract]: REVEAL_STAKING_CONTRACT,
    [BattleflyGame.SmolDomainsContract]: SMOL_DOMAINS_CONTRACT,
    [BattleflyGame.ComicContract]: COMIC_CONTRACT,
    [BattleflyGame.FounderVaultV1]: FOUNDER_VAULT_V1,
    [BattleflyGame.FounderVaultV2]: FOUNDER_VAULT_V2,
    [BattleflyGame.GFlyContract]: GFLY_CONTRACT,
  },
  [arbitrumGoerli.name]: {
    [BattleflyGame.BattleflyContract]: BATTLEFLY_CONTRACT_GOERLI,
    [BattleflyGame.BattleflyGameContract]: BATTLEFLY_GAME_CONTRACT_GOERLI,
    [BattleflyGame.BattleflyCreditsContract]: BATTLEFLY_CREDITS_CONTRACT_GOERLI,
    [BattleflyGame.BridgeworldTreasuresContract]:
      BRIDGEWORLD_TREASURES_CONTRACT_GOERLI,
    [BattleflyGame.ItemContract]: ITEM_CONTRACT_GOERLI,
    [BattleflyGame.MagicContract]: MAGIC_CONTRACT_GOERLI,
    [BattleflyGame.SpecialNFTContract]: SPECIAL_NFT_CONTRACT_GOERLI,
    [BattleflyGame.RevealStakingContract]: REVEAL_STAKING_CONTRACT_GOERLI,
    [BattleflyGame.SmolDomainsContract]: SMOL_DOMAINS_CONTRACT_GOERLI,
    [BattleflyGame.ComicContract]: COMIC_CONTRACT_GOERLI,
    [BattleflyGame.FounderVaultV1]: FOUNDER_VAULT_V1_GOERLI,
    [BattleflyGame.FounderVaultV2]: FOUNDER_VAULT_V2_GOERLI,
    [BattleflyGame.GFlyContract]: GFLY_CONTRACT_GOERLI,
  },
};

export type ContractMap = Record<BattleflyGame, any>;

export const CONTRACT_ABIS: Partial<ContractMap> = {
  [BattleflyGame.BattleflyContract]: BattleflyABI.abi,
  [BattleflyGame.BattleflyGameContract]: BattleflyGameABI.abi,
  [BattleflyGame.BattleflyCreditsContract]: BattleflyCreditsABI,
  [BattleflyGame.BridgeworldTreasuresContract]: BridgeworldTreasuresABI,
  [BattleflyGame.ItemContract]: ItemABI.abi,
  [BattleflyGame.MagicContract]: MagicABI,
  [BattleflyGame.SpecialNFTContract]: SpecialNFTABI.abi,
  [BattleflyGame.RevealStakingContract]: RevealStakingABI.abi,
  [BattleflyGame.SmolDomainsContract]: SmolDomainsABI,
  [BattleflyGame.ComicContract]: ComicABI,
  [BattleflyGame.FounderVaultV1]: BattleflyFounderVaultABI,
  [BattleflyGame.FounderVaultV2]: BattleflyFounderVaultABI,
  [BattleflyGame.GFlyContract]: GFlyContractABI,
};

export const DECREASE_REFRESH_TOKEN_DURATION_BY_SECONDS = 30;
export const SCAVENGE_PACK_MAGIC_PRICE = 5;
export const SCAVENGE_PICK_ANOTHER_MOD_MAGIC_PRICE = 5;
export const SCAVENGE_RE_ROLL_PACK_NECTAR_PRICE = 50;
export const UPGRADE_MOD_IN_MOD_PACK_PRICE = 35;
export const MISSION_CONTROL_RE_FETCH_DATA_SECONDS = 30 * 1000;
export const RIFT_COCOONS_RE_FETCH_DATA_SECONDS = 30 * 1000;
export const BATTLEFLY_DETAILS_COMBAT_LOG_REFETCH_SECONDS = 90 * 1000;
export const HUNTERS_MARK_NECTAR_PRICE = 25;
export const SCAVENGING_MARK_BRONZE_NECTAR_PRICE = 75;
export const SCAVENGING_MARK_SILVER_NECTAR_PRICE = 225;
export const SCAVENGING_MARK_GOLD_NECTAR_PRICE = 525;

export const PRICE_TREASURE_PER_CREDIT = 2;
export const PRICE_GFLY_PER_CREDIT = 3;

export const NAVIGATION_ITEMS = [
  {
    icon: 'inventory',
    label: 'Inventory',
    path: '/battleflies',
    description: 'Battle, upgrade and managae your BattleFlys',
  },
  {
    icon: 'battlelog',
    label: 'Global Battle Log',
    path: '/global-battlelog',
    description: 'View every battle from every arena',
  },
  {
    icon: 'leaderboard',
    label: 'Leaderboard',
    path: '/leaderboard',
    description: 'View global rankings for BattleFlys',
  },
  {
    icon: 'statistics',
    label: 'Statistics',
    path: '/statistics',
    description: 'View high level metrics from the arenas',
  },
  {
    icon: 'racer',
    label: 'Race for Nectar',
    path: '/racer',
    description: 'Collect nectar in BattleFly Racer',
  },
  {
    icon: 'marketplace',
    label: 'Marketplace',
    path: 'https://trove.treasure.lol/games/battlefly',
    description: 'Visit Trove marketplace to trade BattleFly NFTs',
    external: true,
  },
  {
    icon: 'purchases',
    label: 'Purchases',
    path: '/purchases',
    description: 'See your purchases for your BattleFlys',
  },
  {
    icon: 'lore',
    label: 'Lore',
    path: '/lore',
    description: 'Explore the world of the BattleFly',
  },
  {
    icon: 'comic',
    label: 'BatteFly Comic Book',
    path: '/comic',
    description: 'Get your limited edition BattleFly Comic',
  },
];
