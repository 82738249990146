import { useState } from 'react';
import { useAppSelector } from '../../hooks';
import { selectIsUserAuthorized } from '../../redux/user/userReducer';
import Portal from '../../util/Portal';
import ModalWrapper from '../../Wrappers/ModalWrapper';
import Button from '../Buttons/Button';
import Spinner from '../Spinner/Spinner';

const PasswordModal = ({
  onSubmit,
}: {
  onSubmit: (passwordValue: string) => void;
}) => {
  const { pending, error } = useAppSelector((state) => state.user);
  const isUserAuthorized = true;
  const [val, setVal] = useState('');

  const onClickFunc = () => {
    onSubmit(val);
  };

  return (
    <Portal isOpen={true}>
      <ModalWrapper>
        <div className="flex h-full w-full flex-col items-center justify-center">
          <h1 className="py-4 text-center text-3xl">
            Please Submit Password to Continue.
          </h1>
          <div className="flex w-full flex-col gap-2 pt-5">
            {/* <label className="font-bold">Password</label> */}
            <input
              className="placeholder-grey mb-2 h-12 w-full rounded-lg bg-[#0F0F37] px-4 text-lg font-bold text-white"
              type="password"
              placeholder="Password"
              disabled={!isUserAuthorized || pending}
              onChange={(e) => setVal(e.target.value)}
            />
          </div>
          <Button
            disabled={!isUserAuthorized || pending}
            className={`flex items-center justify-center gap-4 ${!isUserAuthorized && `bg-button-disabled`
              }`}
            onClick={() => onClickFunc()}
            type="primary"
          >
            {!isUserAuthorized && `Please Connect Wallet`}
            {isUserAuthorized && !pending && !error?.message && `Submit`}
            {pending && `Processing`}
            {!pending && error?.message && `Password Failed. Please Try Again.`}
            {<Spinner pending={pending} />}
          </Button>
        </div>
      </ModalWrapper>
    </Portal>
  );
};

export default PasswordModal;
