import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import Wireframe from '../../../public/assets/videos/cocoon/wireframe.png';

export enum RenderStage {
  RESTING_COCOON = 'RESTING_COCOON',
  RELEASE_COCOON = 'RELEASE_COCOON',
  RELEASING_COCOON = 'RELEASING_COCOON',
  RELEASED_COCOON = 'RELEASED_COCOON',
}

export const MobileRevealRenderer = ({
  renderingStage,
  setRenderingStage,
  animationTriggered,
}: {
  renderingStage: RenderStage;
  setRenderingStage: Dispatch<SetStateAction<RenderStage>>;
  animationTriggered: boolean;
}) => {
  const video = useRef<HTMLVideoElement>();
  const [isLoading, setIsLoading] = useState(false);
  const loadingData = () => setIsLoading(false);
  const [playing, setPlaying] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  // trigger animation
  useEffect(() => {
    if (animationTriggered && showPlaceholder) {
      setRenderingStage(RenderStage.RELEASE_COCOON);
      setShowPlaceholder(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationTriggered, showPlaceholder]);

  // listen for rendinger stage being changed.
  useEffect(() => {
    if (renderingStage === RenderStage.RELEASE_COCOON) {
      setPlaying(true);
    }
  }, [renderingStage]);

  useEffect(() => {
    let vid: MutableRefObject<HTMLVideoElement>;
    if (video && video.current) {
      vid = video;
      vid?.current?.addEventListener('playing', loadingData, false);
      return function cleanup() {
        vid?.current?.removeEventListener('playing', loadingData, false);
      };
    }
  }, []);

  useEffect(() => {
    if (video && video.current) {
      if (playing) {
        setIsLoading(true);
        video?.current?.play();
      } else {
        video?.current?.pause();
      }
    }
  }, [playing]);

  return (
    <div className={`image-container ${isLoading && `animate-pulse`}`}>
      <div className="image-child-back pb-full z-[1] w-full"></div>
      {renderingStage === RenderStage.RELEASED_COCOON && (
        <div
          className={`${
            renderingStage === RenderStage.RELEASED_COCOON && `fade-in`
          } image-container absolute z-[5] items-center overflow-hidden rounded-md bg-black`}
        >
          <img
            className="fade-out image-child-back inset-0 z-[0] mt-2 w-full object-cover object-center p-4"
            src={Wireframe.src}
          />
          <img
            className="fade-in image-child-back inset-0 z-[0] mt-2 w-full object-cover object-center p-4"
            src={Wireframe.src}
          />
          {/* <div className="z-[1] pb-full image-child-back battlefly-screen w-full"></div> */}
          <img
            className="battlefly-card image-child-back inset-0 z-[2] mt-2 w-full object-cover object-center p-4"
            src={Wireframe.src}
          />
          <img
            className="fade-out battlefly-card image-child-back inset-0 z-[3] mt-2 w-full object-cover object-center"
            src={Wireframe.src}
          />
        </div>
      )}
      {renderingStage !== RenderStage.RELEASED_COCOON && (
        <video
          poster="/assets/videos/cocoon/cocoon_placeholder.png"
          preload="auto"
          playsInline
          ref={video}
          // ONCE VIDEO IS OVER SET IT TO RELEASED, AT WHICH POINT IT WILL HIDE THIS AND THE ELEMENT BELOW
          onEnded={() => setRenderingStage(RenderStage.RELEASED_COCOON)}
          className={`battlefly-card image-child-back absolute inset-0 z-[2] w-full rounded-xl object-cover object-center ${
            renderingStage === RenderStage.RELEASE_COCOON
              ? `opacity-100`
              : `opacity-0`
          }`}
        >
          <source
            src="/assets/videos/cocoon/cocoon_release.webm"
            type="video/mp4"
          />
          <source
            src="/assets/videos/cocoon/cocoon_release.mp4"
            type="video/mp4"
          />
          <img src="/assets/videos/cocoon/cocoon_placeholder.png" />
          Sorry, your browser does not support video content .
        </video>
      )}
      <img
        className={`absolute z-[1] ${!showPlaceholder === true && `opacity-0`}`}
        src="/assets/videos/cocoon/cocoon_placeholder.png"
      />
    </div>
  );
};

export const DesktopRevealRenderer = ({
  renderingStage,
  setRenderingStage,
  animationTriggered,
}: {
  renderingStage: RenderStage;
  setRenderingStage: Dispatch<SetStateAction<RenderStage>>;
  animationTriggered: boolean;
}) => {
  useEffect(() => {
    if (animationTriggered) {
      setRenderingStage(RenderStage.RELEASE_COCOON);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationTriggered]);

  // NOTE: COMPONENTS MUST BE PLACED IN THIS ORDER PER THEIR Z-INDEX
  return (
    <>
      <div className="image-child-back pb-full z-[1] w-full"></div>
      <BattleflyRevealedImage
        renderingStage={renderingStage}
        setRenderingStage={setRenderingStage}
      />
      <CocoonReleasingVideo
        renderingStage={renderingStage}
        setRenderingStage={setRenderingStage}
      />
      <CocoonRestingVideo
        setRenderingStage={setRenderingStage}
        renderingStage={renderingStage}
      />
    </>
  );
};

const BattleflyRevealedImage = ({
  renderingStage,
}: {
  renderingStage: RenderStage;
  setRenderingStage: Dispatch<SetStateAction<RenderStage>>;
}) => {
  // useEffect(() => {
  //   if (renderingStage === RenderStage.RELEASING_COCOON) {
  //     const timer = setTimeout(() => {
  //       setRenderingStage(RenderStage.REVEALING_BATTLEFLY);
  //     }, 1500);
  //     return () => clearTimeout(timer);
  //   }
  // }, [renderingStage]);
  if (renderingStage === RenderStage.RELEASED_COCOON) {
    return (
      <div
        className={`${
          renderingStage === RenderStage.RELEASED_COCOON && `fade-in`
        } image-container absolute z-[5] items-center overflow-hidden bg-black`}
      >
        <video
          poster="/assets/videos/wireframe/wireframe.png"
          autoPlay
          muted
          loop={true}
          className="battlefly-card image-child-back inset-0 z-[2] w-full rounded-xl object-cover object-center"
        >
          <source
            src="/assets/videos/wireframe/spawn_video.webm"
            type="video/webm"
          />
          <source
            src="/assets/videos/wireframe/spawn_video.mp4"
            type="video/mp4"
          />
          <img src="/assets/videos/wireframe/wireframe.png" />
          Sorry, your browser does not support video content .
        </video>
      </div>
    );
  }
  return null;
};

export const CocoonRestingVideo = ({
  setRenderingStage,
  renderingStage,
}: {
  renderingStage: RenderStage;
  setRenderingStage: Dispatch<SetStateAction<RenderStage>>;
}) => {
  if (
    renderingStage === RenderStage.RESTING_COCOON ||
    renderingStage === RenderStage.RELEASE_COCOON
  ) {
    return (
      <video
        poster="/assets/videos/cocoon/cocoon_placeholder.png"
        autoPlay
        muted
        loop={renderingStage === RenderStage.RESTING_COCOON}
        onEnded={() => setRenderingStage(RenderStage.RELEASING_COCOON)}
        className="battlefly-card image-child-back inset-0 z-[2] w-full rounded-xl object-cover object-center"
      >
        <source
          src="/assets/videos/cocoon/cocoon_resting.webm"
          type="video/webm"
        />
        <source
          src="/assets/videos/cocoon/cocoon_resting.mp4"
          type="video/mp4"
        />
        <img src="/assets/videos/cocoon/cocoon_placeholder.png" />
        Sorry, your browser does not support video content .
      </video>
    );
  }
  return null;
};

const CocoonReleasingVideo = ({
  renderingStage,
  setRenderingStage,
}: {
  renderingStage: RenderStage;
  setRenderingStage: Dispatch<SetStateAction<RenderStage>>;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const video = useRef<any>();
  useEffect(() => {
    if (renderingStage === RenderStage.RELEASING_COCOON) {
      video.current.play();
    }
  }, [renderingStage]);
  return (
    <video
      poster="/assets/videos/cocoon/cocoon_placeholder.png"
      ref={video}
      onEnded={() => setRenderingStage(RenderStage.RELEASED_COCOON)}
      className={`battlefly-card image-child-back inset-0 z-[2] w-full rounded-xl object-cover object-center ${
        renderingStage === RenderStage.RELEASED_COCOON && `opacity-0`
      }`}
    >
      <source
        src="/assets/videos/cocoon/cocoon_release.webm"
        type="video/mp4"
      />
      <source src="/assets/videos/cocoon/cocoon_release.mp4" type="video/mp4" />
      <img src="/assets/videos/cocoon/cocoon_placeholder.png" />
      Sorry, your browser does not support video content .
    </video>
  );
};
