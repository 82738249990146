import classnames from 'classnames';

import { BattleFly } from '@battlefly/__generated__/globalTypes';
import Card from 'components/core/Card';
import { MagicIcon } from 'components/Icons';
import { calculateStats } from 'lib/mapper/stats';
import { formatMoney } from 'lib/utils';
import DoughnutChart from './DoughnutChart';

type BattleflyFightStatsProps = {
  battlefly: BattleFly;
};

const Label = ({ children }: { children: React.ReactNode }) => (
  <div className="text-xs font-light">{children} </div>
);

const Value = ({ children }: { children: React.ReactNode }) => (
  <div className="flex items-center gap-2 font-heading text-[29px] leading-[34px]">
    {children}{' '}
  </div>
);

type BoxProps = {
  children: React.ReactNode;
  green?: boolean;
  red?: boolean;
  pink?: boolean;
  className?: string;
};

const Box = (props: BoxProps) => {
  const { children, green, red, pink, className } = props;
  return (
    <div
      className={classnames(
        'border-l-4 py-[5px] px-6',
        {
          'gra-stats-green border-core-green': green,
          'gra-stats-red border-core-red': red,
          'gra-stats-pink border-core-cyberpink': pink,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

const BattleflyFightStats = (props: BattleflyFightStatsProps) => {
  const { battlefly } = props;
  const stats = calculateStats(battlefly);
  return (
    <Card className="mobile:p-4">
      <div className="mobile:p-4 mobile:pt-8">
        <div className="pb-4 font-heading text-5xl leading-10 md:text-6xl">
          Competitive Overview
        </div>
        <p className="invisible leading-[19px] text-core-grey-muted">
          Your mission - build a BattleFly that is capable of battling dozens of
          times per day and generating a positive ROI.
        </p>
      </div>
      <div className="mt-6 gap-8 py-4 lg:flex">
        <div className="mobile:mb-10">
          <DoughnutChart winRate={stats.winRate} />
        </div>
        <div className="flex grow">
          <div className="w-1/2">
            <Box green className="mb-8 !py-3">
              <Value>{stats.wins} wins</Value>
            </Box>
            <Box pink className="mb-3">
              <Label>Lifetime Winnings</Label>
              <Value>
                {formatMoney(stats.winMagic)} <MagicIcon />
              </Value>
            </Box>
            <Box pink>
              <Label>Recent Battle Nectar</Label>
              <Value>
                {formatMoney(stats.lastNectarAmount)} <MagicIcon />
              </Value>
            </Box>
          </div>
          <div className="w-1/2">
            <Box red className="mb-8 !py-3">
              <Value>{stats.losses} losses</Value>
            </Box>
            <Box pink className="mb-3">
              <Label>Profit</Label>
              <Value>
                {formatMoney(stats.profit)} <MagicIcon />
              </Value>
            </Box>
            <Box pink>
              <Label>Total Nectar Earned</Label>
              <Value>
                {formatMoney(stats.totalNectar)} <MagicIcon />
              </Value>
            </Box>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default BattleflyFightStats;
