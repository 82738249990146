import React from 'react';

import { Card, Button } from 'components/core';
import { MagicIcon } from 'components/Icons';
import { formatMoney } from 'lib/utils';
import { BalanceItem } from './helpers';

import { utils } from 'ethers';
import { useContractAddresses } from '@battlefly/hooks/contractsNew/useContractAddresses';
import { BattleflyGame } from '@battlefly/common/constants';
import {
  useGetMagicAllowance,
  useApproveMagic,
} from '@battlefly/hooks/contractsNew/useApproveMagic';
import BalanceInput from './BalanceInput';

type DepositTabProps = {
  className?: string;
  unstakedMagicBalance: number;
  depositMagic: (amount: number) => Promise<void>;
  isDepositing: boolean;
  userAddress: string;
};

// TODO: clean up handler functions
const DepositTab = (props: DepositTabProps) => {
  const { unstakedMagicBalance, depositMagic, userAddress, isDepositing } =
    props;

  const contractAddress = useContractAddresses();

  const { approveMagic } = useApproveMagic(
    contractAddress[BattleflyGame.BattleflyGameContract]
  );
  const { magicAllowance, refetch } = useGetMagicAllowance(
    contractAddress[BattleflyGame.BattleflyGameContract]
  );
  const [inputVal, setInputVal] = React.useState(0);
  // const { data: magicBalance } = useBalance({
  //   address: userAddress,
  //   token: contractAddress[BattleflyGame.MagicContract],
  // });

  // TODO: move to hook
  const isMagicApproved = React.useMemo(() => {
    if (isNaN(inputVal) || inputVal === 0) return true;
    const magicAllowanceInWei = (magicAllowance as any).toBigInt();
    const inputValInWei = utils.parseUnits(`${inputVal}`).toBigInt();
    return magicAllowanceInWei >= inputValInWei;
  }, [magicAllowance, inputVal]);

  const handleDepositClick = React.useCallback(async () => {
    if (isNaN(inputVal) || inputVal === 0 || isDepositing) return;
    try {
      await depositMagic(inputVal);
    } catch (e) {
    } finally {
      setInputVal(0);
    }
  }, [depositMagic, inputVal, isDepositing]);

  const handleApproveMagic = React.useCallback(async () => {
    if (inputVal === 0 || isDepositing) return;
    await approveMagic();
    await refetch();
  }, [approveMagic, refetch, inputVal, isDepositing]);

  return (
    <div className="mb-4 flex w-full flex-col gap-6">
      <section>
        <h5 className="font-heading text-lg">Deposit Magic into the Game</h5>
        <Card darkBlue className="!p-6">
          <div className="flex gap-6">
            <BalanceItem>
              <MagicIcon />
              <span className="font-heading text-xl">
                {formatMoney(unstakedMagicBalance)}
              </span>
              <span className="text-[11px] text-core-grey">
                $Magic available to deposit
              </span>
            </BalanceItem>
          </div>
        </Card>
      </section>
      <section>
        <div className="pb-8 leading-[19px]">
          <p className="">Need to top up your BattleFly account?</p>
          <p className="mb-8 text-xs font-light">
            Nominate how much $Magic you would like to deposit.
          </p>
          <BalanceInput
            value={inputVal}
            maxValue={unstakedMagicBalance}
            onChange={setInputVal}
          />
        </div>
        <div className="flex gap-4">
          <a
            href="https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0xB0c7a3Ba49C7a6EaBa6cD4a96C55a1391070Ac9A"
            target="_blank"
            rel="noreferrer"
          >
            <Button transparent light>
              <span className="grow">Buy $Magic</span>
            </Button>
          </a>
          <Button
            blueMint
            isLoading={isDepositing}
            disabled={isNaN(inputVal)}
            onClick={isMagicApproved ? handleDepositClick : handleApproveMagic}
          >
            {isMagicApproved ? 'Deposit $Magic' : 'Approve $Magic'}
          </Button>
        </div>
      </section>
    </div>
  );
};

export default DepositTab;
