import React from 'react';
import classnames from 'classnames';

type DesktopOnlyProps = {
  children: React.ReactNode;
  className?: string;
  xl?: boolean;
  lg?: boolean;
  // fragment?: boolean;
};

const DesktopOnly = (props: DesktopOnlyProps) => {
  const { children, className } = props;
  return (
    <div
      className={classnames(
        'hidden',
        {
          'xl:block': props.xl,
          'lg:block': props.lg,
          'md:block': !props.xl && !props.lg,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

// const DesktopOnly = (props: DesktopOnlyProps) => {
//   const { children, className, fragment } = props;

//   if (fragment) {
//     if (typeof props.children === 'string') {
//       return <>{props.children}</>;
//     }
//     if (Array.isArray(props.children)) {
//       const extendedChildren = React.Children.map(children, (child) => {
//         if (typeof child === 'string') {
//           return child;
//         }
//         return React.cloneElement(child, { className: 'hidden md:block' });
//       });
//       return <>{extendedChildren}</>;
//     }
//     return (
//       <div className={classnames('hidden md:block', className)}>{children}</div>
//     );
//   }

//   return (
//     <div className={classnames('hidden md:block', className)}>{children}</div>
//   );
// };

export default DesktopOnly;
