import { BattleFlyLocation } from '@battlefly/__generated__/globalTypes';
import { gql, useMutation } from '@apollo/client';
import { battleflyIsIn } from '@battlefly/util/helpers';

export const LEAVE_HYPERDOME = gql`
  mutation leaveHyperdome($battleFlyIds: [Int!]!) {
    leaveHyperdome(input: { battleFlyIds: $battleFlyIds }) {
      id
      location
      provingGroundsCombatRate
      hyperdomeCombatRate
      tattoos {
        id
      }
    }
  }
`;

export const LEAVE_PROVING_GROUNDS = gql`
  mutation leaveProvingGrounds($battleFlyIds: [Int!]!) {
    leaveProvingGrounds(input: { battleFlyIds: $battleFlyIds }) {
      id
      location
      provingGroundsCombatRate
      hyperdomeCombatRate
      tattoos {
        id
      }
    }
  }
`;

export const useLeaveCombat = (
  location: BattleFlyLocation
): [ReturnType<typeof useMutation>[0], boolean] => {
  const [leaveHyperdome, { loading: loadingLeaveHyperdome }] =
    useMutation(LEAVE_HYPERDOME);

  const [leaveProvingGrounds, { loading: loadingLeaveProving }] = useMutation(
    LEAVE_PROVING_GROUNDS
  );

  if (battleflyIsIn(location, BattleFlyLocation.Hyperdome)) {
    return [leaveHyperdome, loadingLeaveHyperdome];
  }
  return [leaveProvingGrounds, loadingLeaveProving];
};
