import React from 'react';
import { IconProps } from '@battlefly/components/Icons';

const StatArmorIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2937_649)">
        <path
          d="M18.7214 1.16772H19.9603V2.33547H18.7214V1.16772Z"
          fill="white"
        />
        <path d="M17.4758 0H18.7147V1.16774H17.4758V0Z" fill="white" />
        <path
          d="M1.20752 18.7776H2.37526V19.9453H1.20752V18.7776Z"
          fill="white"
        />
        <path
          d="M1.24658 14.885H2.41433V16.0528H1.24658V14.885Z"
          fill="white"
        />
        <path
          d="M0.0397949 17.6099H1.20754V18.7776H0.0397949V17.6099Z"
          fill="white"
        />
        <path
          d="M11.8087 10.0051L12.6375 9.15128L11.4652 8.94763L11.6338 7.76965L10.5655 8.29404L10.0096 7.24194L9.45356 8.29404L8.38542 7.76969L8.55405 8.94763L7.38163 9.15132L8.21038 10.0051L7.38159 10.859L8.55405 11.0627L8.38542 12.2406L9.45356 11.7163L10.0096 12.7684L10.5655 11.7163L11.6338 12.2407L11.4652 11.0627L12.6376 10.859L11.8087 10.0051Z"
          fill="white"
        />
        <path
          d="M15.2745 6.17992L15.8844 1.91909L12.0206 3.81578L10.0096 0.0102539L7.99853 3.81578L4.13474 1.91905L4.74461 6.17988L0.503906 6.91653L3.50174 10.0051L0.503906 13.0937L4.74461 13.8304L4.13474 18.0912L7.99853 16.1945L10.0096 20L12.0206 16.1945L15.8844 18.0911L15.2745 13.8304L19.5152 13.0937L16.5174 10.0051L19.5152 6.91653L15.2745 6.17992ZM13.1028 14.2627L11.0684 13.264L10.0095 15.2677L8.95067 13.264L6.9163 14.2626L7.23743 12.0192L5.00455 11.6313L6.58299 10.0051L5.00459 8.37893L7.23743 7.99105L6.9163 5.74762L8.95067 6.74627L10.0095 4.74254L11.0684 6.74627L13.1028 5.74758L12.7817 7.99105L15.0145 8.37889L13.4361 10.0051L15.0146 11.6313L12.7817 12.0192L13.1028 14.2627Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2937_649">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StatArmorIcon;
