import { useAccount, useNetwork } from 'wagmi';
import { arbitrum } from 'wagmi/chains';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { BATTLEFLY_RACER_DATA, END_RACE } from '@battlefly/graphql/racerGql';
import { useSmolData } from '../useSmolData';
import { useEndRaceHandler } from './useEndRaceHandler';
import { useGetLeaderboardHandler } from './useGetLeaderboardHandler';
import { useGetPlayerStatisticsHandler } from './useGetPlayerStatisticsHandler';
import { mapBattleFlies } from './utils';

export const useRacerGame = (
  sendToUnity: any,
  onUnityEvent: any,
  offUnityEvent: any,
  isLoaded: boolean
) => {
  // State
  const [launchDataSent, setLaunchDataSent] = useState(false);

  // Refs
  const countdownUntilNextReset = useRef(100000);

  // Hooks
  const { address } = useAccount();
  const { smolDomain, userAvatarImg } = useSmolData(address);
  const { chain: activeChain } = useNetwork();

  const {
    data: racerData,
    loading: racerDataLoading,
    error: racerDataError,
    refetch,
  } = useQuery(BATTLEFLY_RACER_DATA, { fetchPolicy: 'network-only' });

  const [endRace] = useMutation(END_RACE);

  const NETWORK =
    activeChain?.id === arbitrum.id ? 'arbitrum' : 'arbitrumGoerli';

  const { handleEndRace } = useEndRaceHandler(
    smolDomain,
    userAvatarImg,
    address,
    endRace,
    sendToUnity
  );

  const { handleGetPlayerStatistics } =
    useGetPlayerStatisticsHandler(sendToUnity);
  const { handleGetLeaderboard } = useGetLeaderboardHandler(sendToUnity);

  useEffect(() => {
    if (racerDataError) {
      sendToUnity('RequestFailed', {
        reason: racerData?.errors,
        requestMethod: 'Launch',
      });
    }
    if (!launchDataSent && isLoaded && !racerDataLoading) {
      setLaunchDataSent(true);
      const wallets = racerData?.me?.wallets || [];

      const nectar = wallets.length > 0 ? wallets[0].nectar : 0;
      const droplets = wallets.length > 0 ? wallets[0].droplets : 0;

      const racerLaunchData = {
        walletAddress: address,
        network: NETWORK,
        avatar: userAvatarImg,
        domainName: smolDomain,
        droplets: nectar * 100 + droplets,
        battleFlies: mapBattleFlies(racerData?.me?.battleFlies),
        timer: racerData?.timer,
      };

      onUnityEvent('GetLeaderboard', handleGetLeaderboard);
      onUnityEvent('EndRace', handleEndRace);
      onUnityEvent('GetPlayerStatistics', handleGetPlayerStatistics);
      sendToUnity('Launch', racerLaunchData);

      return () => {
        offUnityEvent('GetLeaderboard', handleGetLeaderboard);
        offUnityEvent('EndRace', handleEndRace);
        offUnityEvent('GetPlayerStatistics', handleGetPlayerStatistics);
      };
    }
  }, [isLoaded, racerDataLoading]);

  useEffect(() => {
    if (racerData) {
      countdownUntilNextReset.current = racerData?.timer?.secondsUntilNextReset;
      setTimeout(() => {
        refetch().then((response) => {
          sendToUnity('DayFinished', {
            battleFlies: mapBattleFlies(response?.data?.me?.battleFlies),
            timer: response.data?.timer,
          });
        });
      }, countdownUntilNextReset?.current * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [racerData]);
};
