import React from 'react';
import classnames from 'classnames';

import { Card, Button, Link } from '@battlefly/components/core';

interface AlterLoadoutBlockProps {
  containerClasses?: string;
  battleflyId: string;
}

const AlterLoadoutBlock: React.FC<AlterLoadoutBlockProps> = ({
  containerClasses,
  battleflyId,
}) => {
  return (
    <Card
      square
      className={classnames(
        'flex w-full justify-between gap-10 px-[25px] py-[20px]',
        containerClasses
      )}
    >
      <div>
        <div className="font-heading text-base leading-[19px]">
          Alter your loadout
        </div>
        <div className="font-main text-xs leading-[14px] text-[#D9D9D9]">
          Swap mods from your inventory.
        </div>
      </div>
      <div>
        <Link to={`/battleflies/view/${battleflyId}/loadout`}>
          <Button gray>Change Loadout</Button>
        </Link>
      </div>
    </Card>
  );
};

export default AlterLoadoutBlock;
