import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { createAction } from '@reduxjs/toolkit';
import { ConnectUserResponse } from './userTypes';

// Constant used by Redux to clear user state.
export const LOGOUT = 'user/logout';

/**
 * Clear state on logout
 */
export const logoutUser = createAction<ConnectUserResponse>(LOGOUT);

/**
 * User authorized is not the same thing as connecting to a injected web3 wallet,
 * we set this to true after the user has gotten 200 response from /connect.
 * Connecting your wallet to the dapp is one thing.
 * This boolean property is meant to reveal/conceal parts of the app that would
 * otherwise only be accessible to actual battlefly user accounts.
 */
export const setUserAuthorized = createAction<boolean>(
  'user/auth/setUserAuthorized'
);

export const setUser = createAction<any>('user/auth/setUser');

export const setAllVirtualTokenIds = createAction<number[]>(
  'user/setAllVirtualTokenIds'
);

export const setVirtualBattlefliesList = createAction<BattleFly[]>(
  'user/setVirtualBattlefliesList'
);
