import * as Types from '@battlefly/__generated__/globalTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserStatisticsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserStatisticsQuery = { __typename?: 'Query', getUserStatistics: { __typename?: 'Statistics', id: string, totalBattleFlies: number, averageDailyBattles: number, totalBattlesInDay: number, totalMagicWonInDay: number } };


export const GetUserStatisticsDocument = gql`
    query getUserStatistics {
  getUserStatistics {
    id
    totalBattleFlies
    averageDailyBattles
    totalBattlesInDay
    totalMagicWonInDay
  }
}
    `;

/**
 * __useGetUserStatisticsQuery__
 *
 * To run a query within a React component, call `useGetUserStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserStatisticsQuery, GetUserStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserStatisticsQuery, GetUserStatisticsQueryVariables>(GetUserStatisticsDocument, options);
      }
export function useGetUserStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserStatisticsQuery, GetUserStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserStatisticsQuery, GetUserStatisticsQueryVariables>(GetUserStatisticsDocument, options);
        }
export type GetUserStatisticsQueryHookResult = ReturnType<typeof useGetUserStatisticsQuery>;
export type GetUserStatisticsLazyQueryHookResult = ReturnType<typeof useGetUserStatisticsLazyQuery>;
export type GetUserStatisticsQueryResult = Apollo.QueryResult<GetUserStatisticsQuery, GetUserStatisticsQueryVariables>;