import React from 'react';

import { useGetBattlefliesOfOwner } from 'hooks/contractsNew/useBattleflyGameContract';
import { useStakingBattlefliesOfOwner } from '@battlefly/hooks/contractsNew/useRevealStakingContract';

type BattleflyContextType = {
  unstaked: number[];
  staked: number[];
  isUnstaked: (tokenId: number | string) => boolean;
};

const defaultSettings: BattleflyContextType = {
  unstaked: [],
  staked: [],
  isUnstaked: () => false,
};

export const BattleflyContext =
  React.createContext<BattleflyContextType>(defaultSettings);

export const BattleflyContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [unstaked, setUnstaked] = React.useState(defaultSettings.unstaked);
  const [staked, setStaked] = React.useState(defaultSettings.staked);

  const { ownedTokenIds, refetch: refetchOwnedBattleflies } =
    useGetBattlefliesOfOwner();
  const { stakedTokenIds, refetch: refetchStakedBattleflies } =
    useStakingBattlefliesOfOwner();

  React.useEffect(() => {
    setUnstaked(ownedTokenIds);
    setStaked(stakedTokenIds);
  }, [ownedTokenIds.length, stakedTokenIds.length]);

  function reload() {
    refetchOwnedBattleflies();
    refetchStakedBattleflies();
  }

  function isUnstaked(tokenId: number | string) {
    const tokenIdAsNumber = Number(tokenId);
    return unstaked.includes(tokenIdAsNumber);
  }

  // TODO: expose reload method
  return (
    <BattleflyContext.Provider
      value={{
        unstaked,
        staked,
        isUnstaked,
      }}
    >
      {children}
    </BattleflyContext.Provider>
  );
};

export default BattleflyContextProvider;
