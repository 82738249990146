import React from 'react';
import classnames from 'classnames';

interface LeagueDigitProps {
  leagueNumber: number;
  offset: number;
  className?: string;
}

const LeagueDigit: React.FC<LeagueDigitProps> = (props) => {
  const { className } = props;
  return (
    <div
      className={classnames(
        'absolute flex h-[32px] w-[32px] items-center justify-center wxga+:h-[48px] wxga+:h-[48px]',
        className
      )}
    >
      {[...Array(props.leagueNumber).keys()].map((x) => (
        <div
          key={x}
          className="mx-[1px] h-[9px] w-[2px] bg-white wxga+:mx-[2px] wxga+:h-[15px] wxga+:w-[3px]"
        ></div>
      ))}
    </div>
  );
};

export default LeagueDigit;
