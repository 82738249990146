import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  CustomToast,
  ToastStatus,
} from '../components/CustomToast/CustomToast';
import { useAppDispatch } from '../hooks';
import { getBattleflyAttributes } from '../redux/commonActions';
import {
  GetBattleflyAttributesResponse,
  QueryParams,
} from '../redux/commonActions/types';

type Values = {
  value: string;
}[];

type Selections = {
  selections: string[];
};

type MissionControlFiltersContextType = {
  setSelectedViewOption: Dispatch<
    SetStateAction<{
      value: string;
      label: string;
    }>
  >;
  viewOptions: {
    value: string;
    label: string;
  }[];
  typeOptions: {
    value: string;
  }[];
  classOptions: {
    value: string;
  }[];
  editionOptions: {
    value: string;
  }[];
  rarityOptions: {
    value: string;
  }[];
  setSelectedTypeOptions: Dispatch<SetStateAction<any>>;
  setSelectedClassOptions: Dispatch<SetStateAction<any>>;
  setSelectedEditionOptions: Dispatch<SetStateAction<any>>;
  setSelectedRarityOptions: Dispatch<SetStateAction<any>>;
  applyMissionControlAdvancedFilters: () => void;
  toggleMissionControlAdvancedFiltersSidebar: () => void;
  isMissionControlAdvancedFiltersSidebarOpen: boolean;
  queryParams: any;
  battleflySearchValue: string;
  setBattleflySearchValue: Dispatch<SetStateAction<any>>;
  selectedViewOption: { value: string; label: string };
  selectedRarityOptions: any;
};

const viewOptions = [
  { value: null, label: 'All' },
  { value: 'cocoon', label: 'Cocoons' },
  { value: 'battlefly', label: 'Battleflies' },
] as { value: string; label: string }[];

export const MissionControlFiltersContext = createContext(
  {} as MissionControlFiltersContextType
);

export const MissionControlFiltersContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  // HOOKS
  const dispatch = useAppDispatch();

  // STATE
  const [queryParams, setQueryParams] = useState<QueryParams>(null);
  const [selectedViewOption, setSelectedViewOption] = useState(viewOptions[2]);
  const [typeOptions, setTypeOptions] = useState<Values>();
  const [classOptions, setClassOptions] = useState<Values>();
  const [editionOptions, setEditionOptions] = useState<Values>();
  const [rarityOptions, setRarityOptions] = useState<Values>();

  const [selectedTypeOptions, setSelectedTypeOptions] = useState<Selections>();
  const [selectedClassOptions, setSelectedClassOptions] =
    useState<Selections>();
  const [selectedEditionOptions, setSelectedEditionOptions] =
    useState<Selections>();
  const [selectedRarityOptions, setSelectedRarityOptions] =
    useState<Selections>();
  const [
    isMissionControlAdvancedFiltersSidebarOpen,
    setIsMissionControlAdvancedFiltersSidebarOpen,
  ] = useState(false);
  const [battleflySearchValue, setBattleflySearchValue] = useState('');

  const getAttributes = useCallback(async () => {
    try {
      const response = await dispatch(getBattleflyAttributes()).unwrap();
      if (response) {
        setOption(response, 'Type', setTypeOptions);
        setOption(response, 'Class', setClassOptions);
        setOption(response, 'Edition', setEditionOptions);
        setOption(response, 'Rarity', setRarityOptions);
      }
    } catch (e) {
      console.log(e);
      CustomToast({
        status: ToastStatus.REJECTED,
        message:
          'Something went wrong while trying to request attributes for battleflies',
      });
    }
  }, [dispatch]);

  const toggleMissionControlAdvancedFiltersSidebar = () => {
    setIsMissionControlAdvancedFiltersSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    getAttributes();
  }, [getAttributes]);

  const applyMissionControlAdvancedFilters = () => {
    setQueryParams({
      ...queryParams,
      // TODO: STAGE PARAM DOES NOT CURRENTLY WORK ALONG WITH PAGINATION
      // set query params whenever the dropdown value is changed, as this is not an advanced filter.
      ...({ stage: selectedViewOption?.value } as any),
      ...{ type: selectedTypeOptions?.selections.join(',') },
      ...{ edition: selectedEditionOptions?.selections.join(',') },
      ...{ class: selectedClassOptions?.selections.join(',') },
      ...{ rarity: selectedRarityOptions?.selections.join(',') },
    });
  };

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      // TODO: STAGE PARAM DOES NOT CURRENTLY WORK ALONG WITH PAGINATION
      // set query params whenever the dropdown value is changed, as this is not an advanced filter.
      ...({ stage: selectedViewOption?.value } as any),
    });
  }, [selectedViewOption]);

  const contextData = {
    setSelectedViewOption,
    selectedViewOption,
    setSelectedTypeOptions,
    setSelectedClassOptions,
    setSelectedEditionOptions,
    setSelectedRarityOptions,
    selectedRarityOptions,
    rarityOptions,
    typeOptions,
    classOptions,
    editionOptions,
    viewOptions,
    queryParams,
    applyMissionControlAdvancedFilters: applyMissionControlAdvancedFilters,
    isMissionControlAdvancedFiltersSidebarOpen,
    toggleMissionControlAdvancedFiltersSidebar:
      toggleMissionControlAdvancedFiltersSidebar,
    battleflySearchValue,
    setBattleflySearchValue,
  };

  return (
    <MissionControlFiltersContext.Provider value={contextData}>
      {children}
    </MissionControlFiltersContext.Provider>
  );
};

// FUNCTIONS
const setOption = (
  response: GetBattleflyAttributesResponse[],
  selection: string,
  setter: Dispatch<SetStateAction<Values>>
) => {
  try {
    const selectedAttribute = response.filter(
      (attribute) => attribute.name === selection
    );
    if (selectedAttribute.length === 0) {
      throw new Error();
    } else {
      setter(selectedAttribute[0].values);
    }
  } catch (e) {
    CustomToast({
      status: ToastStatus.REJECTED,
      message: `Something went wrong while trying to request ${selection} Options`,
    });
  }
};

export default MissionControlFiltersContextProvider;
