import styles from '../../../styles/Home.module.css';

const PageWrapper = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <>
      <main id="__test" className={`${styles.main} max-w-[1280px]`}>
        <div
          className={`m-auto mt-8 flex w-full flex-wrap items-center justify-center gap-10 sm:mt-2 ${className} pointer-events-auto z-10`}
        >
          {children}
        </div>
      </main>
    </>
  );
};

export default PageWrapper;
