import classnames from 'classnames';

import { Button } from 'components/core';
import { CloseIcon, WarningIcon } from 'components/Icons';

type ActionPanelProps = {
  handleEquip: () => void;
  handleClose: () => void;
  className?: string;
  isEquipping: boolean;
  showWarning?: boolean;
};

const Warning = () => {
  return (
    <div className="flex items-center gap-2">
      <WarningIcon className="h-10 w-10" fill="#fff" />
      <div className="text-left text-xs">
        WARNING: This action will destroy this mod to make space for your new
        haul.
      </div>
    </div>
  );
};

const ActionPanel = (props: ActionPanelProps) => {
  const { handleEquip, handleClose, className, isEquipping, showWarning } =
    props;
  return (
    <div
      className={classnames('rounded-b-xl bg-[#191623]', className, {
        'h-[170px]': showWarning,
        'h-[120px]': !showWarning,
      })}
    >
      <ul className="flex flex-col gap-3 p-4">
        <li>
          <Button
            light
            transparent
            isLoading={isEquipping}
            onClick={handleEquip}
          >
            {showWarning ? 'Replace this Mod' : 'Place in this slot'}
          </Button>
        </li>
        {showWarning && (
          <li>
            <Warning />
          </li>
        )}
        <li className="flex items-center justify-center">
          <div
            onClick={handleClose}
            className="flex h-10 w-10 cursor-pointer items-center justify-center"
          >
            <CloseIcon className="h-8 w-8" fill="#fff" />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ActionPanel;
