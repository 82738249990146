import classnames from 'classnames';

type MobileOnlyProps = {
  children: React.ReactNode;
  className?: string;
  lg?: boolean;
  xl?: boolean;
};

const MobileOnly = (props: MobileOnlyProps) => {
  const { children, className } = props;
  return (
    <div
      className={classnames(
        'block',
        {
          'lg:hidden': props.lg,
          'xl:hidden': props.xl,
          'md:hidden': !props.lg && !props.xl,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default MobileOnly;
