import React from 'react';
import { IconProps } from '@battlefly/components/Icons';

const StatDpsIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="20"
      height="33"
      viewBox="0 0 20 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16.0674 13.5H12.0674V24.5H16.0674V13.5Z" fill="white" />
      <path
        d="M12.0674 13.5C12.0674 14.8807 12.9628 16 14.0674 16C15.172 16 16.0674 14.8807 16.0674 13.5C16.0674 12.1193 15.172 8.5 14.0674 8.5C12.9628 8.5 12.0674 12.1193 12.0674 13.5Z"
        fill="white"
      />
      <path
        d="M12.0001 25.7407C12.0001 26.2929 12.6194 26.2407 14.0001 26.2407C15.3808 26.2407 16.0001 26.2929 16.0001 25.7407C16.0001 25.1884 15.5001 23.2407 14.0001 23.2407C12.5001 23.2407 12.0001 25.1884 12.0001 25.7407Z"
        fill="white"
      />
      <path d="M10.0674 16.5H6.06738V27.5H10.0674V16.5Z" fill="white" />
      <path
        d="M6.06738 16.5C6.06738 17.8807 6.96281 19 8.06738 19C9.17195 19 10.0674 17.8807 10.0674 16.5C10.0674 15.1193 9.17195 11.5 8.06738 11.5C6.96281 11.5 6.06738 15.1193 6.06738 16.5Z"
        fill="white"
      />
      <path
        d="M6.00009 28.7407C6.00009 29.2929 6.61938 29.2407 8.00009 29.2407C9.3808 29.2407 10.0001 29.2929 10.0001 28.7407C10.0001 28.1884 9.50009 26.2407 8.00009 26.2407C6.50009 26.2407 6.00009 28.1884 6.00009 28.7407Z"
        fill="white"
      />
      <path d="M4.06738 19.5H0.0673828V30.5H4.06738V19.5Z" fill="white" />
      <path
        d="M0.0673828 19.5C0.0673828 20.8807 0.962813 22 2.06738 22C3.17195 22 4.06738 20.8807 4.06738 19.5C4.06738 18.1193 3.17195 14.5 2.06738 14.5C0.962813 14.5 0.0673828 18.1193 0.0673828 19.5Z"
        fill="white"
      />
      <path
        d="M9.10759e-05 31.7407C9.10759e-05 32.2929 0.61938 32.2407 2.00009 32.2407C3.3808 32.2407 4.00009 32.2929 4.00009 31.7407C4.00009 31.1884 3.50009 29.2407 2.00009 29.2407C0.500091 29.2407 9.10759e-05 31.1884 9.10759e-05 31.7407Z"
        fill="white"
      />
      <path
        d="M14.0451 3.33789L12.9164 6.73683H9.26367L12.2188 8.83749L11.09 12.2364L14.0451 10.1358L17.0003 12.2364L15.8715 8.83749L18.8266 6.73683H15.1739L14.0451 3.33789Z"
        fill="white"
      />
      <path
        d="M19.0674 0L13.5363 6.44514L10.1178 5.18576L12.1268 8.17054L5.06738 10.0152L13.3684 10.0152L15.3774 13L15.5452 9.42992L19.0674 8.48287L15.649 7.22348L19.0674 0Z"
        fill="white"
      />
    </svg>
  );
};

export default StatDpsIcon;
