import { matchRoutes, useLocation } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { routes } from 'router/routes';

const useMyBreadcrumbs = () => {
  const currentPath = useLocation().pathname;
  const matchingRoutes = matchRoutes(routes, currentPath);
  if (!matchingRoutes || !matchingRoutes?.length) {
    return [];
  }
  const breadcrumbs = useBreadcrumbs(routes as any).filter((breadcrumb) => {
    return matchingRoutes.some(
      (route) => route.pathnameBase === breadcrumb.key
    );
  });

  return breadcrumbs;
};

export default useMyBreadcrumbs;
