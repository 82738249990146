import React from 'react';
import { FetchBalanceResult } from '@wagmi/core';

import { Card, Button } from 'components/core';
import RainbowKitModalButton from 'components/RainbowKitModalButton';
import EthLogoIcon from '/public/assets/icons/eth_logo_ic.svg';
import { MagicIcon, NectarIcon } from 'components/Icons';
import { formatMoney } from 'lib/utils';
import { BalanceItem } from './helpers';

type UserBalancesProps = {
  onDisconnect: () => void;
  unstakedMagicBalance: number;
  // TODO: make available as number
  aethBalance: FetchBalanceResult;
  magicBalance: number;
  nectarBalance: number;
  isUserAuthenticated: boolean;
  credits: {
    quantity: number;
    type: string;
  }[]
};

const UserBalances = (props: UserBalancesProps) => {
  const {
    onDisconnect,
    unstakedMagicBalance,
    aethBalance,
    magicBalance,
    nectarBalance,
    isUserAuthenticated,
    credits,
  } = props;

  return (
    <div className="mb-4 flex w-full flex-col gap-6">
      <section>
        <h5 className="font-heading text-lg"> In your wallet</h5>
        <Card darkBlue className="!p-6">
          <div className="flex gap-6">
            <BalanceItem className="w-1/2">
              <MagicIcon />
              <span className="font-heading text-xl">
                {formatMoney(unstakedMagicBalance)}
              </span>
              <span className="text-[11px] text-core-grey">$Magic</span>
            </BalanceItem>
            <BalanceItem className="w-1/2">
              <img
                src={EthLogoIcon.src}
                alt="ETH"
                className="h-[24px] w-[24px]"
              />
              <span className="font-heading text-xl">
                {formatMoney(Number(aethBalance?.formatted))}
              </span>
              <span className="text-[11px] text-core-grey">AETH</span>
            </BalanceItem>
          </div>
        </Card>
      </section>
      <section>
        <h5 className="font-heading text-lg">In the Game</h5>
        <Card darkBlue className="!p-6">
          <div className="flex gap-6">
            <BalanceItem className="w-1/2">
              <MagicIcon />
              <span className="font-heading text-xl">
                {formatMoney(magicBalance)}
              </span>
              <span className="text-[11px] text-core-grey">$Magic</span>
            </BalanceItem>
            <BalanceItem className="w-1/2">
              <NectarIcon />
              <span className="font-heading text-xl">{nectarBalance}</span>
              <span className="text-[11px] text-core-grey">Nectar</span>
            </BalanceItem>
          </div>
        </Card>
      </section>
      {credits.length > 0 && (<section>
        <h5 className="font-heading text-lg">Credits</h5>
        <Card darkBlue className="!p-6">
          <div className="grid grid-cols-2 gap-4">
            {credits.map((credit) => (
              <BalanceItem className="w-1/2" key={credit.type}>
                <span className="font-heading">{credit.type.toUpperCase()}:</span>
                <span className="font-heading">{credit.quantity}</span>
              </BalanceItem>
            ))}
          </div>
        </Card>
      </section>)}
      <section>
        <a
          href="https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0xB0c7a3Ba49C7a6EaBa6cD4a96C55a1391070Ac9A"
          target="_blank"
          rel="noreferrer"
          className="mb-4 flex w-full"
        >
          <Button
            transparent
            light
            className="flex grow rounded-sm bg-[#7B61FF] py-3 px-4 text-base font-semibold"
          >
            <span className="grow">Buy $Magic</span>
          </Button>
        </a>
        {isUserAuthenticated && (
          <Button
            transparent
            light
            className="flex justify-center rounded-sm bg-[#4D4D4D] py-3 px-8 font-mono text-base font-semibold"
            onClick={onDisconnect}
          >
            Disconnect Wallet
          </Button>
        )}
        {!isUserAuthenticated && <RainbowKitModalButton />}
      </section>
    </div>
  );
};

export default UserBalances;
