import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { setHoldingBattlefliesList } from '../redux/holding';
import { BattleflyStage } from '../ts/BattleflyType';

interface HoldingBattlefliesContextType {
  setBattleflySearchValue: Dispatch<SetStateAction<any>>;
  battleflySearchValue: string;
  toggleStakeBattlefliesCart: (boolean?: boolean) => void;
  removeStakedBattlefliesFromListOnSuccess: (
    dispatch: any,
    selectedItems: BattleFly[],
    battleflyListItems: BattleFly[]
  ) => void;
  selectedViewOption: any;
  viewOptions: { value: BattleflyStage; label: string }[];
  setSelectedViewOption: any;
  isOwnedBattlefliesCartOpen: any;
  setIsOwnedBattlefliesCartOpen: (boolean: boolean) => void;
}

export const HoldingBattlefliesContext = createContext(
  {} as HoldingBattlefliesContextType
);

const viewOptions = [
  { value: null, label: 'All' },
  { value: BattleflyStage.cocoon, label: 'Cocoons' },
  { value: BattleflyStage.battlefly, label: 'Battleflies' },
] as { value: BattleflyStage; label: string }[];

export const OwnedBattlefliesProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  //STATE
  const [battleflySearchValue, setBattleflySearchValue] = useState('');
  const [isOwnedBattlefliesCartOpen, setIsOwnedBattlefliesCartOpen] =
    useState(false);
  const [selectedViewOption, setSelectedViewOption] = useState(viewOptions[0]);

  const toggleStakeBattlefliesCart = (boolean: boolean) => {
    if (boolean === undefined) {
      setIsOwnedBattlefliesCartOpen(!isOwnedBattlefliesCartOpen);
    } else setIsOwnedBattlefliesCartOpen(boolean);
  };

  const removeStakedBattlefliesFromListOnSuccess = (
    dispatch: any,
    selectedItems: BattleFly[],
    battleflyListItems: BattleFly[]
  ) => {
    const checkedOutIds = selectedItems.map((item) => item.id);
    const newArray = battleflyListItems.filter(function (battlefly: BattleFly) {
      return checkedOutIds.indexOf(battlefly.id) === -1;
    });
    dispatch(setHoldingBattlefliesList(newArray));
  };

  const contextData = {
    selectedViewOption,
    battleflySearchValue,
    setBattleflySearchValue,
    viewOptions,
    setSelectedViewOption,
    isOwnedBattlefliesCartOpen,
    toggleStakeBattlefliesCart,
    setIsOwnedBattlefliesCartOpen,
    removeStakedBattlefliesFromListOnSuccess,
  };

  return (
    <HoldingBattlefliesContext.Provider value={contextData}>
      {children}
    </HoldingBattlefliesContext.Provider>
  );
};

export default OwnedBattlefliesProvider;
