import React from 'react';
import classNames from 'classnames';

import { Modal, Button } from 'components/core';
import { ModalContext } from 'context/modalContext';
import { Checkmark, CloseIcon } from 'components/Icons';

const ConfirmationModal = () => {
  const { confirmationModal, setConfirmationModal } =
    React.useContext(ModalContext);
  const [loading, setLoading] = React.useState(false);

  if (!confirmationModal) return null;

  const {
    onConfirm,
    onCancel,
  } = confirmationModal;

  async function handleConfirm() {
    setLoading(true);
    await onConfirm();
    setLoading(false);
    setConfirmationModal(null);
  }

  return (
    <Modal isOpen={true} onClose={() => onCancel()}>
      <div className="w-full rounded-xl bg-[#191623] md:w-[500px] p-8 md:p-20">
        <div className="flex h-full flex-col items-center justify-center">
           <div className="px-24 py-10 flex justify-center leading-[19px]  bg-core-blue-light">
              <p className="inline">
              Spend 5 $Magic to scavenge for mods
              </p>
          </div>
          <div className="flex w-full flex-col items-center justify-center">
              <div className="mt-8 flex w-full justify-center px-8">
                <Button blueMint onClick={handleConfirm} isLoading={loading}>
                  YES
                </Button>
              </div>
              <div className="mt-8 flex w-full justify-center px-8">
                <span className="underline cursor-pointer">
                  No, take me back
                </span>
              </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
