import React from 'react';

import { HuntersMarkType } from '__generated__/globalTypes';
import { ModalContext } from 'context/modalContext';
import { Card, Modal } from 'components/core';
import { CloseIcon } from 'components/Icons';
import { useApplyHuntersMark } from 'hooks/game/useHuntersMark';
import { UserBalanceContext } from 'context/userBalanceContext';
import { HUNTERS_MARK_NECTAR_PRICE } from 'common/constants';
import DefaultView from './DefaultView';
import CompareView from './CompareView';

const HuntersMarkModal = () => {
  const [selectedMark, setSelectedMark] = React.useState<HuntersMarkType>(null);
  const [compareView, setCompareView] = React.useState(false);
  const { huntersMarkModalState: battlefly, setHuntersMarkModalState } =
    React.useContext(ModalContext);
  const { nectarBalance } = React.useContext(UserBalanceContext);

  const { applyMark, loading: isApplyingMark } = useApplyHuntersMark(
    battlefly?.id
  );

  React.useEffect(() => {}, [selectedMark]);

  function handleSelect(mark: HuntersMarkType) {
    if (mark === battlefly.huntersMark?.type) {
      return;
    }
    setSelectedMark(mark);
  }

  async function handleConfirm() {
    if (battlefly.huntersMark !== null) {
      return setCompareView(true);
    }

    await handleFinalize();
  }

  async function handleFinalize() {
    await applyMark({
      variables: {
        applyHuntersMarkInput: {
          battleFlyId: battlefly.id,
          markType: selectedMark,
        },
      },
    });
    setHuntersMarkModalState(null);
  }

  return (
    <Modal
      className="!md:items-center !items-start"
      onClose={() => setHuntersMarkModalState(null)}
      isOpen={!!battlefly}
    >
      <div className="scavenger-mark-modal w-auto max-w-[100%] sm:w-[500px] md:w-[650px] lg:w-[900px]">
        <Card>
          <div className="md:p-8">
            <header className="relative flex flex-col justify-between">
              <div className="absolute -right-5 -top-5 cursor-pointer self-end p-3 md:-right-10 md:-top-10">
                <CloseIcon
                  fill="#fff"
                  onClick={() => setHuntersMarkModalState(null)}
                />
              </div>
            </header>
            {!compareView && (
              <DefaultView
                selectedMark={selectedMark}
                handleSelect={handleSelect}
                handleConfirm={handleConfirm}
                notEnoughNectar={nectarBalance < HUNTERS_MARK_NECTAR_PRICE}
              />
            )}
            {compareView && (
              <CompareView
                selectedMark={selectedMark}
                currentMark={battlefly?.huntersMark?.type}
                handleConfirm={handleFinalize}
              />
            )}
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default HuntersMarkModal;
