import React from 'react';
import { BattleFlyLeague } from '@battlefly/__generated__/globalTypes';
import PupaWithDigitIcon, {
  LeagueWithDigitProps,
} from '@battlefly/components/Icons/Leagues/PupaWithDigitIcon';
import LarvaeWithDigitIcon from '@battlefly/components/Icons/Leagues/LarvaeWithDigitIcon';
import MonarchWithDigitIcon from '@battlefly/components/Icons/Leagues/MonarchWithDigitIcon';
import PredatorWithDigitIcon from '@battlefly/components/Icons/Leagues/PredatorWithDigitIcon';
import ApexWithDigitIcon from '@battlefly/components/Icons/Leagues/ApexWithDigitIcon';
import { LeagueProgressElement } from '@battlefly/ts/leagues';

interface LeagueLadderProgressSubLeagueProps {
  points: number;
  subLeagueNumber: number;
  league: BattleFlyLeague;
  subLeagueIcon: React.FC<LeagueWithDigitProps>;
}

export const SUB_LEAGUES_WITH_DIGITS_ICONS: Record<
  BattleFlyLeague,
  React.FC<LeagueWithDigitProps>
> = {
  [BattleFlyLeague.Larvae]: LarvaeWithDigitIcon,
  [BattleFlyLeague.Pupa]: PupaWithDigitIcon,
  [BattleFlyLeague.Monarch]: MonarchWithDigitIcon,
  [BattleFlyLeague.Predator]: PredatorWithDigitIcon,
  [BattleFlyLeague.Apex]: ApexWithDigitIcon,
};

const ProgressItem: React.FC<LeagueLadderProgressSubLeagueProps> = (props) => {
  return (
    <div className="relative">
      <div className="flex flex-col items-center mobile:w-[25%] mobile:scale-75">
        <div className="absolute top-[-45px] left-[calc(50%-4px)] flex flex-col items-center justify-center">
          <div className="dashed-line h-[35px] w-[1px]" />
          <div className="h-2 w-2 rounded-full bg-white" />
        </div>
        <props.subLeagueIcon
          digit={props.subLeagueNumber}
          hideLeagueDigit={props.subLeagueNumber === 0}
          className="mb-[14px] h-[70px] w-auto"
        />
        <div className="hidden h-[28px] text-center text-xl">
          {props.points} CP
        </div>
      </div>
    </div>
  );
};

type LeagueProgressPathProps = {
  items: LeagueProgressElement[];
};

const LeagugeProgressPath = (props: LeagueProgressPathProps) => {
  const { items } = props;
  return (
    <div className="relative flex justify-between gap-4">
      {items.map((x) => (
        <ProgressItem
          subLeagueIcon={SUB_LEAGUES_WITH_DIGITS_ICONS[x.league]}
          league={x.league}
          key={x.league.toString() + x.subLevel + x.cpRequired}
          subLeagueNumber={x.subLevel}
          points={x.cpRequired}
        />
      ))}
    </div>
  );
};

export default LeagugeProgressPath;
