import React from 'react';
import { Unity } from 'react-unity-webgl';
import classnames from 'classnames';

import { Image } from 'components/core';
import { CloseIcon } from 'components/Icons';
import { useUnity } from '@battlefly/hooks/unityInitializer/useUnity';
import { useGetBattleLogByBattleByIdQuery } from '@battlefly/graphql/__generated__/hyperdomeGql';

type BattleViewerProps = {
  className?: string;
  combatLogId: string;
  onStop: () => void;
};
const baseUrl =
  process.env.NEXT_PUBLIC_SIMULATOR ||
  'https://assets-staging.battlefly.game/game';

function mapMod(mod: any, slot: number) {
  return {
    ...mod.blueprint,
    blueprint: mod.blueprint,
    type: 'Weapon',
    damageType: mod.blueprint.category,
    slot,
  };
}

const BattleViewer = (props: BattleViewerProps) => {
  const { combatLogId, onStop, className } = props;

  const [sent, setSent] = React.useState(false);

  const {
    unityProvider,
    sendToUnity,
    isLoaded,
    loadingProgression,
    onUnityEvent,
    offUnityEvent,
    unload,
    setDelayedUnload,
    delayedUnload,
    requestFullscreen,
  } = useUnity({
    gameURL: baseUrl,
    compression: 'br',
    onUnloaded: function () {
      onStop();
    },
  });

  const { data } = useGetBattleLogByBattleByIdQuery({
    variables: {
      id: combatLogId,
    },
  });

  const handleClose = async () => {
    setDelayedUnload(true);
    setSent(false);
  };

  const deblueprint = (mod: any) => {
    return {
      id: mod.id,
      name: mod.name,
      blueprintId: mod.blueprint.id,
    };
  };

  const renderCombatString = (dat: any) => {
    if (!dat) return;

    const render = dat;

    return {
      data: {
        log: {
          participants: render.log.participants.map((part: any) => {
            const participant = {
              ...part,
              shrg: 1,
              weapons: part.weapons.map(deblueprint),
              defense: part.defense.map(deblueprint),
              utilities: part.utilities.map(deblueprint),
              battleFly: {
                ...part.battleFly,
                mods: [
                  ...part.weapons.map(mapMod),
                  ...part.battleFly.mods.filter(
                    (t: any) => t.type !== 'weapon'
                  ),
                ],
              },
            };
            return participant;
          }),
          winner: Number(render.battleFlyWinner.id),
          looser: Number(render.battleFlyLooser.id),
          eventLog: render.log.eventLog,
          textLog: render.log.textLog,
        },
      },
    };
  };

  onUnityEvent('BattleOver', handleClose);

  React.useEffect(() => {
    if (data && !sent && isLoaded) {
      console.log('sending to unity', data, sent, isLoaded);

      sendToUnity('Launch', renderCombatString(data.combat));

      setSent(true);

      return () => {
        offUnityEvent('BattleOver', handleClose);
      };
    }
  }, [isLoaded, data, sent]);

  return (
    <div className={classnames('relative min-h-[200px] w-auto', className)}>
      {!isLoaded && (
        <Image
          src="/assets/backgrounds/BattleViewerBg.jpg"
          layout="fill"
          objectFit="cover"
        />
      )}

      <div className="flex max-h-full flex-col overflow-hidden">
        <div className="flex items-center">
          <h1 className="text-4xl" onClick={() => requestFullscreen(true)}>
            Battle Simulation
          </h1>
          <span className="flex-auto" />
          <button onClick={() => handleClose()}>
            <CloseIcon className="fill-current text-gray-300" />
          </button>
        </div>

        <div className="relative flex flex-col">
          <div className="scrollbar-hidden flex min-h-[1px] overflow-x-auto overflow-y-auto">
            {!isLoaded && (
              <>
                {/* <img
                  src="/assets/images/battle-simulator-loading-bg.jpg"
                  className="absolute top-1/2 left-1/2 h-full w-auto -translate-x-1/2 -translate-y-1/2 transform "
                /> */}
                <div className="absolute top-[75%] left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                  <h1
                    className="text-center text-4xl mobile:text-xl"
                    style={{ textShadow: '0px 0px 14px #000000' }}
                  >
                    Loading Battle
                    <br />
                    {parseFloat((loadingProgression * 100).toString()).toFixed(
                      2
                    )}
                    %
                  </h1>
                </div>
              </>
            )}
            <Unity
              unityProvider={unityProvider}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>

          {/* <div className="relative mx-4 mt-8 mt-auto flex hidden flex-col items-center justify-between sm:flex-row">
            <Button
              type="primary"
              size="lg"
              className="w-auto"
              onClick={() => setIsEnabled(false)}
            >
              Close
            </Button>
            <Button
              type="secondary"
              size="lg"
              className="w-auto"
              onClick={() => setIsEnabled(false)}
            >
              <span>Show Text Log</span>
            </Button> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default BattleViewer;
