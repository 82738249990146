import classnames from 'classnames';

import StarsRating from '@battlefly/components/Rating/Rating';
import { BattleFly } from '@battlefly/__generated__/globalTypes';

type RarityRankingPowerProps = {
  // battlefly: Pick<
  //   BattleFly,
  //   'starsRate' | 'rarity' | 'placeInGlobalLeaderboard'
  // >;
  battlefly: Partial<BattleFly>;
  className?: string;
};

const RarityRankingPower = (props: RarityRankingPowerProps) => {
  const { battlefly, className } = props;
  return (
    <div className={classnames('flex gap-10 font-heading text-xl', className)}>
      <div>
        <div className="text-left">Rarity</div>
        <div className="leading-[26px]">{battlefly.rarity}</div>
      </div>
      <div>
        <div>Ranking</div>
        <div className="leading-[26px]">
          {battlefly.placeInGlobalLeaderboard}
        </div>
      </div>
      <div>
        <div className="mb-1">Power Score</div>
        <div className="leading-[26px]">
          <StarsRating total={5} filled={battlefly.starsRate} />
        </div>
      </div>
    </div>
  );
};

export default RarityRankingPower;
