import { Button } from 'components/core';
import ModCardActionPanel from 'components/ModCardActionPanel';

type ActionPanelProps = {
  className?: string;
  handleClose: () => void;
  handleEquip: () => void;
};

const ActionPanel = (props: ActionPanelProps) => {
  const { handleClose, handleEquip, className } = props;
  return (
    <ModCardActionPanel onClose={handleClose} className={className}>
      <li>
        <Button light transparent onClick={handleEquip}>
          Equip
        </Button>
      </li>
    </ModCardActionPanel>
  );
};

export default ActionPanel;
