import React from 'react';
import { useAccount } from 'wagmi';
import chmln from '@chamaeleonidae/chmln';

const useChameleonTracking = () => {
  const [isInitialized, setIsInitialized] = React.useState(false);
  const { address } = useAccount();
  React.useEffect(() => {
    if (process.env.NEXT_PUBLIC_TRACKING_DISABLED === 'true') {
      return;
    }
    if (!isInitialized) {
      chmln.init(
        'S49igeRI76ALVlfIOrljK03m1EP1pJi1WK7qrWW9TXgHiE-1P7nmQ-E6dcqx7FvsHvENWV',
        { fastUrl: 'https://fast.chameleon.io/' }
      );
      setIsInitialized(true);
    }
  }, []);

  React.useEffect(() => {
    if (address && isInitialized) {
      chmln.identify(address);
    }
  }, [isInitialized, address]);
};

export default useChameleonTracking;
