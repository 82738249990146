import { Card } from 'components/core';

type FullScreenCardProps = {
  children: React.ReactNode;
};

const FullScreenCard = (props: FullScreenCardProps) => {
  const { children } = props;
  return (
    <div className="mx-2 mb-8 w-full max-w-[100%] sm:mt-4 wxga+:max-w-[1280px]">
      <Card flat className="mobile:px-[7px]">
        {children}
      </Card>
    </div>
  );
};

export default FullScreenCard;
