import React from 'react';

import Card from 'components/core/Card';
import ScavengingMark from './ScavengingMark';
import { ScavengingMarkType } from '@battlefly/__generated__/globalTypes';

type ScavengingMarkCardProps = {
  className?: string;
  type: ScavengingMarkType;
};

const ScavengingMarkCard = (props: ScavengingMarkCardProps) => {
  const { type } = props;
  return (
    <Card
      square
      className="flex flex h-20 w-20 items-center justify-center !p-2 wxga+:h-24 wxga+:w-24"
    >
      <ScavengingMark type={type} className="w-14 wxga+:w-16" />
    </Card>
  );
};

export default ScavengingMarkCard;
