import classnames from 'classnames';

export const BalanceItem = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={classnames(
        'flex flex-row items-center justify-start gap-1',
        className
      )}
    >
      {children}
    </div>
  );
};
