import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { formatPercentage } from 'lib/utils';

ChartJS.register(ArcElement, Tooltip, Legend);

type DoughnutChartProps = {
  winRate: number;
};

const DoughnutChart = (props: DoughnutChartProps) => {
  const { winRate } = props;
  const lossRate = 1 - winRate;

  const data = {
    labels: ['Win Rate'],
    datasets: [
      {
        data: [winRate, lossRate],
        backgroundColor: ['rgba(52, 238, 166, 1)', 'rgba(255, 93, 93, 1)'],
        borderColor: ['rgba(52, 238, 166, 1)', 'rgba(255, 93, 93, 1)'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="relative flex h-[200px] flex-col items-center">
      <div className="absolute top-[40%] text-center">
        <div className="font-heading text-3xl leading-[30px]">Win Rate</div>
        <div className="leading-[19px]">{formatPercentage(winRate || 0)}</div>
      </div>
      <Doughnut
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          cutout: '72%',
        }}
      />
    </div>
  );
};

export default DoughnutChart;
