import React from 'react';
import classnames from 'classnames';

import { BattleFlyLeague } from '@battlefly/__generated__/globalTypes';
import { Card } from 'components/core';
import { MagicIcon } from '@battlefly/components/Icons';
import { formatMoney } from '@battlefly/lib/utils';

import { LEAGUES_BLOCKS } from './config';

type LeagueProgressPathProps = {
  currentLeague: BattleFlyLeague;
  leagueData: any;
};

type ItemProps = {
  league: BattleFlyLeague;
  numBattleflies: number;
  numMagic: number;
  icon: React.FC<React.ComponentProps<'svg'>>;
  active: boolean;
  borderClass: string;
  color: string;
};

function getLeageDataByName(leagueData: any, name: BattleFlyLeague) {
  const data = leagueData.find((x: any) => x.name === name);
  return data || { topBattleFliesAmount: 0, sharedMagic: 0 };
}
const ProgressItem = (props: ItemProps) => {
  const { league, numBattleflies, numMagic, active, borderClass, color } =
    props;
  return (
    <div className="flex h-full flex-col gap-3">
      <div
        className={classnames(
          'h-full rounded-2xl p-[2px]',
          {
            active: active,
          },
          borderClass
        )}
      >
        <div className="gra-league-bg relative h-full rounded-2xl p-4">
          <div className="items-between flex h-full flex-col justify-between gap-2 wxga+:gap-2">
            <div className="text-center font-heading text-xl">{league}</div>
            <div className="flex justify-center">
              <props.icon className="h-auto w-4/5" />
            </div>
            <div
              className={classnames('text-[11px] wxga+:text-[12px]', {
                invisible: BattleFlyLeague.Larvae === league,
              })}
            >
              <div className="text-center ">Top {numBattleflies} BattleFly</div>
              <div className="flex items-center justify-center gap-1">
                <span>Shared {formatMoney(numMagic)} </span>{' '}
                <MagicIcon className="h-3 w-3" fill="#fff" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classnames('h-[5px]', {
          visible: active,
          invisible: !active,
        })}
        style={{
          background: color,
        }}
      />
    </div>
  );
};

const LeagugeProgressPath = (props: LeagueProgressPathProps) => {
  const { currentLeague, leagueData } = props;
  return (
    <Card
      flat
      className="h-full !p-4 !pt-8 wxga+:!p-8"
      containerClassName="h-full"
    >
      <div className="flex flex-col gap-8">
        <div className="font-heading text-3xl">League Progress</div>
        <ul className="grid-flow-cols grid auto-rows-max grid-cols-5 gap-3 wxga+:gap-4">
          {LEAGUES_BLOCKS.map((item, index) => {
            const data = getLeageDataByName(leagueData, item.league);
            return (
              <ProgressItem
                key={index}
                league={item.league}
                numBattleflies={data.topBattleFliesAmount}
                numMagic={data.sharedMagic}
                icon={item.icon}
                active={currentLeague === item.league}
                borderClass={item.borderClass}
                color={item.color}
              />
            );
          })}
        </ul>
      </div>
    </Card>
  );
};

export default LeagugeProgressPath;
