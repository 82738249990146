import React from 'react';
import classnames from 'classnames';

import { AttentionIcon } from '@battlefly/components/Icons';

type InfoComponentProps = {
  className?: string;
  children?: React.ReactNode;
  wide?: boolean;
};

const InfoComponent: React.FC<InfoComponentProps> = (props) => {
  const { children, className, wide } = props;
  return (
    <div className={classnames('flex items-center', className)}>
      <AttentionIcon className="w-[25px]" />
      <div
        className={classnames('pl-[9px] text-[12px] text-[#D9D9D9]', {
          'max-w-[300px]': wide,
          'max-w-[80px] ': !wide,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default InfoComponent;
