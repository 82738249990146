import React from 'react';
import classnames from 'classnames';

import { HuntersMarkType } from '__generated__/globalTypes';
import { Card, Button } from 'components/core';
import { HUNTERS_MARKS_DEFS } from 'common/battleflyMarks';

type CompareViewProps = {
  currentMark: HuntersMarkType;
  selectedMark: HuntersMarkType;
  handleConfirm: () => void;
};

const CompareView = (props: CompareViewProps) => {
  const { selectedMark, currentMark, handleConfirm } = props;
  return (
    <>
      <section className="mb-12">
        <h1 className="mb-2 text-5xl leading-[40px] md:text-3xl">
          Replace Hunter&apos;s Mark
        </h1>
        <p className="leading-[19px]">
          Your Current Hunter&apos;s Mark will be replaced with the new one.
        </p>
      </section>
      <section className="hunters-mark-grid mb-8 justify-items-center">
        {[currentMark, selectedMark].map((item, index) => {
          const mark = HUNTERS_MARKS_DEFS[item];
          return (
            <div
              key={item}
              className="flex cursor-pointer flex-col gap-2 text-center"
            >
              <Card
                mark
                className={classnames(
                  'h-[80px] w-[100px] !p-0 md:h-[100px] md:w-[120px]',
                  {
                    active: index === 1,
                  }
                )}
              >
                <div className="flex h-full w-full items-center justify-center">
                  {mark.icon({
                    className: 'w-20 h-20 md:w-24 md:h-24',
                  })}
                </div>
              </Card>
              <div className="font-heading  md:text-2xl">{item}</div>
            </div>
          );
        })}
      </section>
      <section className="flex justify-center mobile:mb-12">
        <div className="w-72">
          <Button blueMint onClick={handleConfirm}>
            Buy (75 Nectar)
          </Button>
        </div>
      </section>
      <section className="text-center">
        <div className="inline-block rounded-[16px] bg-core-red-error py-2 px-3">
          WARNING: Your current Hunter&apos;s Mark will be replaced if you
          proceed.
        </div>
      </section>
    </>
  );
};

export default CompareView;
