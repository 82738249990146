import React from 'react';

const BurgerMenu: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    width="27"
    height="17"
    viewBox="0 0 27 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} inline-block stroke-current`}
  >
    <line y1="1.5" x2="27" y2="1.5" stroke="white" strokeWidth="3" />
    <line y1="15.5" x2="27" y2="15.5" stroke="white" strokeWidth="3" />
    <line y1="8.5" x2="27" y2="8.5" stroke="white" strokeWidth="3" />
  </svg>
);

export default BurgerMenu;
