import React from 'react';

import { BattleFly } from '@battlefly/__generated__/globalTypes';
import InfoComponent from 'components/InfoComponent';
import BattleflyLeagueIcon from 'components/BattleflyLeagueIcon';
import StarsRating from '@battlefly/components/Rating';
import WinLossIndicator from 'components/WinLossIndicator';
import BattleflyImageWithPlaceholder from 'components/MissionControlGrid/BattleFlyCard/BattleflyImageWithPlaceholder';
import { DesktopOnly } from 'components/utility';
import { Ranking, Rarity, NameAndRarity, Name } from 'components/lists/utils';

type BattleLogHeaderProps = {
  battlefly: BattleFly;
};

const BattleLogHeader = (props: BattleLogHeaderProps) => {
  const { battlefly } = props;
  return (
    <div className="relative flex items-center gap-4 px-4 pr-6">
      <div className="absolute bottom-0 left-0 flex gap-2 px-2">
        <InfoComponent>Click BattleFly to see details</InfoComponent>
      </div>
      <div className="flex h-full w-full gap-2 md:w-[calc(100%-60px)]">
        <div className="flex w-[48%] items-end justify-between gap-2">
          <div className="invisible h-12">
            <WinLossIndicator win={false} />
          </div>
          <DesktopOnly className="invisible h-16 w-16">
            <BattleflyImageWithPlaceholder
              imageSrc={battlefly.appearance.image}
              classes="h-[90%] w-[90%]"
              battleflyName={battlefly.name}
            />
          </DesktopOnly>
          <NameAndRarity className="invisible">
            <Name>{battlefly.name}</Name>
            <DesktopOnly>
              <Rarity>{battlefly.rarity}</Rarity>
            </DesktopOnly>
          </NameAndRarity>

          <DesktopOnly className="">
            <BattleflyLeagueIcon
              use="default"
              league={battlefly.league}
              classes="w-10 h-10 invisible"
            />
            <div className="text-xs">League</div>
          </DesktopOnly>
          <DesktopOnly xl>
            <div className="invisible">
              <StarsRating total={5} filled={battlefly.starsRate} />
            </div>
            <div className="text-center text-xs">Power Score</div>
          </DesktopOnly>
          <DesktopOnly>
            <Ranking className="invisible">
              {battlefly.placeInGlobalLeaderboard}
            </Ranking>
            <div className="text-center text-xs">Ranking</div>
          </DesktopOnly>
        </div>
        <div className="invisible w-[6%] text-center font-heading text-5xl">
          V
        </div>
        <div className="flex w-[48%] items-end justify-between gap-2">
          <DesktopOnly>
            <Ranking className="invisible">
              {battlefly.placeInGlobalLeaderboard}
            </Ranking>
            <div className="text-center text-xs">Ranking</div>
          </DesktopOnly>

          <DesktopOnly xl>
            <div className="invisible">
              <StarsRating total={5} filled={battlefly.starsRate} />
            </div>
            <div className="text-center text-xs">Power Score</div>
          </DesktopOnly>

          <DesktopOnly className="">
            <BattleflyLeagueIcon
              use="default"
              league={battlefly.league}
              classes="w-10 h-10 invisible"
            />
            <div className="text-xs">League</div>
          </DesktopOnly>
          <NameAndRarity className="invisible">
            <Name className="text-right">{battlefly.name}</Name>
            <DesktopOnly>
              <Rarity className="text-right">{battlefly.rarity}</Rarity>
            </DesktopOnly>
          </NameAndRarity>
          <DesktopOnly className="invisible h-16 w-16">
            <BattleflyImageWithPlaceholder
              imageSrc={battlefly.appearance.image}
              classes="h-[90%] w-[90%]"
              battleflyName={battlefly.name}
            />
          </DesktopOnly>
          <div className="invisible h-12">
            <WinLossIndicator win={false} />
          </div>
        </div>
      </div>
      <div className="hidden w-[60px] items-center justify-between gap-1 self-stretch md:flex"></div>
    </div>
  );
};

export default BattleLogHeader;
