import { matchRoutes, useLocation } from 'react-router-dom';

import { routes } from '@battlefly/router/routes';

const useBackgroundStyle = (): any => {
  const currentPath = useLocation().pathname;
  const hits = matchRoutes(routes, currentPath);
  let backgroundColor = null;
  let backgroundImage = null;
  let pageClassName = null;

  if (!hits || !hits.length) {
    return {
      backgroundColor,
      backgroundImage,
      pageClassName,
    };
  }

  if (hits.length > 1) {
    const exactMatch = hits.find((hit) => hit.pathname === currentPath);
    if (exactMatch) {
      backgroundImage = exactMatch.route.backgroundImage;
      backgroundColor = exactMatch.route.background;
      pageClassName = exactMatch.route.className;
    }
  } else {
    backgroundImage = hits[0].route.backgroundImage;
    backgroundColor = hits[0].route.background;
    pageClassName = hits[0].route.className;
  }

  return {
    // backgroundColor: `bg-[${backgroundColor}]`,
    backgroundColor,
    backgroundImage,
    pageClassName,
  };
};

export default useBackgroundStyle;
