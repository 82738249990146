import React from 'react';
import { LeagueWithDigitProps } from '@battlefly/components/Icons/Leagues/PupaWithDigitIcon';
import LeagueWithDigitIcon from '@battlefly/components/Icons/Leagues/LeagueWithDigitIcon';
import PupaIcon from '@battlefly/components/Icons/Leagues/PupaIcon';
import PredatorIcon from "@battlefly/components/Icons/Leagues/PredatorIcon";

const PredatorWithDigitIcon: React.FC<LeagueWithDigitProps> = (props) => {
  return (
    <LeagueWithDigitIcon
      digit={props.digit}
      hideLeagueDigit={props.hideLeagueDigit}
      icon={PredatorIcon}
      colorBorderStart="#FBF6B5"
      colorBorderEnd="#FBAA19"
      colorRectStart="#FEDC00"
      colorRectMiddle="#F47C20"
      colorRectEnd="#FECF09"
    />
  );
};

export default PredatorWithDigitIcon;
