import { useParams } from 'react-router-dom';

import useCombatLogData from 'hooks/useCombatLogData';
import BattleLogDetailsPage from 'components/BattleLogDetailsPage';

const BattleLogDetailRoute = () => {
  const { id: battleflyId, battleId } = useParams();

  const { data, loading } = useCombatLogData(battleId, battleflyId);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <BattleLogDetailsPage log={data} />;
};

export default BattleLogDetailRoute;
