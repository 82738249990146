export const MINIMUM_MAGIC_AMOUNT =
  process.env.NEXT_PUBLIC_MINIMUM_MAGIC_AMOUNT;
export const MINIMUM_MAGIC_REQUIRED =
  process.env.NEXT_PUBLIC_MINIMUM_MAGIC_REQUIRED;
export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENV;
export const ARBITRUM_NODE = process.env.NEXT_PUBLIC_ARBITRUM_NODE;
export const MAGIC_TOKEN_CONTRACT =
  process.env.NEXT_PUBLIC_MAGIC_TOKEN_CONTRACT;
export const BATTLEFLY_CONTRACT = process.env.NEXT_PUBLIC_BATTLEFLY_CONTRACT;
export const BATTLEFLY_WHITELIST_CONTRACT =
  process.env.NEXT_PUBLIC_BATTLEFLY_WHITELIST_CONTRACT;
export const BATTLEFLY_PUBLIC_MINT_CONTRACT =
  process.env.NEXT_PUBLIC_BATTLEFLY_PUBLIC_MINT_CONTRACT;
export const BATTLEFLY_GAME_CONTRACT =
  process.env.NEXT_PUBLIC_BATTLEFLY_GAME_CONTRACT;
export const FOUNDER_GENESIS_V2_CONTRACT =
  process.env.NEXT_PUBLIC_FOUNDER_GENESIS_V2_CONTRACT;
export const WHITELIST_MODE = process.env.NEXT_PUBLIC_WHITELIST_MODE;
export const FOUNDER_GENESIS_V2_VISIBLE =
  process.env.NEXT_PUBLIC_FOUNDER_GENESIS_V2_VISIBLE;

export const MAINTAIN_MODE = process.env.NEXT_PUBLIC_MAINTAIN_MODE;

export const NEXT_PUBLIC_API = process.env.NEXT_PUBLIC_API;
