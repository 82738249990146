import React from 'react';

type IconProps = React.ComponentProps<'svg'>;

const ChevronLeft: React.FC<IconProps> = (props) => {
  const { fill, fillOpacity, ...prop } = props;
  return (
    <svg
      width="81"
      height="162"
      viewBox="0 0 81 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M60.2863 158.004L3.40076 91.1704C2.16412 89.7175 1.29023 88.1435 0.779081 86.4484C0.259691 84.7534 0 82.9372 0 81C0 79.0628 0.259691 77.2466 0.779081 75.5516C1.29023 73.8565 2.16412 72.2825 3.40076 70.8296L60.2863 3.99551C62.5534 1.33183 65.4389 0 68.9427 0C72.4466 0 75.3321 1.33183 77.5992 3.99551C79.8664 6.65919 81 10.0493 81 14.1659C81 18.2825 79.8664 21.6726 77.5992 24.3363L29.3702 81L77.5992 137.664C79.8664 140.327 81 143.717 81 147.834C81 151.951 79.8664 155.341 77.5992 158.004C75.3321 160.668 72.4466 162 68.9427 162C65.4389 162 62.5534 160.668 60.2863 158.004Z"
        fill={fill || 'white'}
        fillOpacity={fillOpacity || '0.5'}
      />
    </svg>
  );
};

export default ChevronLeft;
