import React from 'react';

type IconProps = React.ComponentProps<'svg'>;

const Triangle: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="65"
      height="75"
      viewBox="0 0 65 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.940731 0.672258L64.924 37.613L0.940732 74.5537L0.940731 0.672258Z"
        fill="white"
      />
    </svg>
  );
};

export default Triangle;
