import { createContext } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HyperdomeContextType {}

export const HyperdomeContext = createContext({} as HyperdomeContextType);

export const HyperdomeContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  const contextData = {};

  return (
    <HyperdomeContext.Provider value={contextData}>
      {children}
    </HyperdomeContext.Provider>
  );
};

export default HyperdomeContextProvider;
