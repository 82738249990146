import React from 'react';
import classnames from 'classnames';

import Portal from '@battlefly/util/Portal';

type ModalProps = {
  className?: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
};

const Modal = (props: ModalProps) => {
  const { children, isOpen, onClose, className } = props;

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  function handleOutsideClick(event: any) {
    if (ref.current && !ref.current.contains(event.target)) {
      onClose && onClose();
    }
  }

  // TODO: check if w-full breaks other stuff
  return (
    <Portal isOpen={isOpen} className="Modal">
      <div
        onClick={handleOutsideClick}
        className="bg-modalBg fixed top-[50px] left-0 z-50 mx-auto flex h-full w-full max-w-[100%] justify-center backdrop-blur-sm sm:left-[80px] sm:w-[calc(100%-80px)] sm:items-center"
      >
        <div
          ref={ref}
          className={classnames(
            'inset-0 flex max-w-full items-center justify-center overflow-y-scroll',
            className
          )}
        >
          {children}
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
