import classnames from 'classnames';

import { Card } from 'components/core';
import { Checkmark } from 'components/Icons';

type EmptySlotProps = {
  n?: number;
  isPicked?: boolean;
  onClick?: () => void;
};

const EmptySlot = (props: EmptySlotProps) => {
  const { n, onClick, isPicked } = props;

  function handleClick() {
    if (onClick) {
      onClick();
    }
  }
  return (
    <Card
      onClick={handleClick}
      className="gra-card-empty-slot flex h-full cursor-pointer items-center justify-center"
      containerClassName="h-full"
    >
      <div
        className={classnames('text-center', {
          hidden: !n,
        })}
      >
        <div className="font-heading text-core-grey">Empty Mod Slot</div>
        <div className="text-core-grey">
          {n} empty {n > 1 ? 'slots' : 'slot'}
        </div>
      </div>
      {isPicked && (
        <div>
          <div className="flex flex-col items-center gap-2">
            <div className="flex h-[90px] w-[90px] items-center justify-center rounded-[50%] border-2 border-white">
              <Checkmark className="!mr-0 h-8 w-8" />
            </div>
            <div className="text-center">
              <div className="font-heading text-xl leading-[24px]">
                Scavenged Mod
              </div>
              <div>Equipped</div>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default EmptySlot;
