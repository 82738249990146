import { useNavigate } from 'react-router';
import BackArrow from '../../public/assets/icons/back-arrow.svg';

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div className="fixed left-1/2 m-auto hidden w-full max-w-[1400px] -translate-x-1/2 translate-y-1/2 pl-6 text-xs lg:block">
      <div className="flex">
        <div
          onClick={() => navigate(-1)}
          style={{ cursor: 'pointer' }}
          className="flex rounded-md border-[1px] border-button-default bg-[#1F1F5780] p-1"
        >
          <div className="relative h-4 w-4">
            <img src={BackArrow.src} alt="React Logo" />
          </div>
          <span className="pr-1">Back</span>
        </div>
      </div>
    </div>
  );
};

export default BackButton;
