import React from 'react';

type IconProps = React.ComponentProps<'svg'>;

const ControlPattern: React.FC<IconProps> = (props) => (
  <svg
    width="53"
    height="52"
    viewBox="0 0 53 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M51.8331 0.639053V43.5266L41.8506 51.3373H1.49691V8.42604L11.4795 0.615386H51.8331M52.6499 0H11.1165C7.03268 3.19527 4.73367 4.99408 0.649902 8.18935V52H42.1834C46.2671 48.8047 48.5661 47.0059 52.6499 43.8107V0Z"
      fill="white"
    />
    <path
      d="M45.1478 46.5802L44.936 46.4145L49.9273 42.5092V19.2666H50.1996V42.6039L45.1478 46.5802Z"
      fill="white"
    />
    <path
      d="M16.3564 24.9565H37.4714V46H16.3564V24.9565Z"
      fill="url(#pattern0)"
    />
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#image0_2742_22638"
          transform="translate(0 -0.00169793) scale(0.00195312 0.00195976)"
        />
      </pattern>
    </defs>
  </svg>
);

export default ControlPattern;
