import React from 'react';
import { formatUnits } from 'ethers/lib/utils';

const MintMagicCostTab = ({ price }: { price: number }) => {
  return (
    <div>
      <button className="text-md background-gradient-purple mb-2.5 h-fit  min-h-[3rem] w-full rounded-md py-2 px-3">
        <a className="flex items-center justify-between text-xs">
          <span>Price</span>
          <span className="flex items-center">{price} $MAGIC</span>
        </a>
      </button>
    </div>
  );
};

export default MintMagicCostTab;
