import classnames from 'classnames';

const WinLossIndicator = ({ win }: { win: boolean }) => {
  return (
    <div
      className={classnames('h-full w-3 rounded-md', {
        'bg-core-green': win === true,
        'bg-core-red': win === false,
      })}
    />
  );
};

export default WinLossIndicator;
