import Button from '@battlefly/components/Buttons/Button';
import { ComicNetworkResponse } from '@battlefly/hooks/contractsNew/useComic';
import Portal from '@battlefly/util/Portal';

const ExchangeModal = ({
  handleClose,
  handleOpenExchangeSelectComic,
  comic,
  getBurnDetails,
}: {
  handleClose: any;
  handleOpenExchangeSelectComic: ({
    comicRequested,
    burnComicIdNeeded,
    burnQuantityNeeded,
  }: {
    comicRequested: ComicNetworkResponse;
    burnQuantityNeeded: number;
    burnComicIdNeeded: number;
  }) => void;
  comic: ComicNetworkResponse;
  getBurnDetails: (comic: ComicNetworkResponse) => ComicNetworkResponse;
}) => {
  const burnDetails = getBurnDetails(comic);
  return (
    <Portal isOpen={true}>
      <div
        onClick={() => handleClose(false)}
        className="fixed left-0 top-0 h-full w-full"
        style={{ background: 'rgba(0,0,0,0.3)' }}
      ></div>
      <div className="fixed left-1/2 top-0 z-30 ml-auto mr-auto flex h-full w-[519px] max-w-full -translate-x-1/2 items-center justify-center align-middle">
        <div className="bg-green-gradient flex w-[519px] max-w-full flex-col items-center justify-center pt-5 pb-5 align-middle">
          <div className="fade-in mb-6 w-auto w-[290px] max-w-full">
            <div className="mb-6 text-center text-4xl">Exchange</div>
            <div className="c-card flex flex-col justify-between overflow-hidden rounded-xl border-[2px] border-amber-300 bg-black p-2 shadow-md hover:shadow-xl">
              <div className="font-white flex w-full flex-col gap-2 text-sm text-white">
                <div className="image-container relative z-[0] items-center overflow-hidden">
                  <div className="image-container relative z-[0] items-center overflow-hidden">
                    <img
                      className="fade-in image-child-back inset-0 z-[0] w-full object-cover object-center"
                      src={comic?.details?.image}
                    />
                    <div className="image-child-back battlefly-screen pb-full z-[1] w-full"></div>
                    <img
                      className="fade-in battlefly-card image-child-back inset-0 z-[2] w-full object-cover object-center"
                      src={comic?.details?.image}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full pl-10 pr-10">
            <div className="mb-6">
              <div className="text-center text-xl">
                Burn {burnDetails.burnAmount.toNumber()} normal comics to
                recieve a gold
              </div>
            </div>
            <div>
              <a className="flex justify-center">
                <Button
                  type="primary"
                  className="mb-2 inline-block"
                  onClick={() => {
                    handleOpenExchangeSelectComic({
                      comicRequested: comic,
                      burnComicIdNeeded: burnDetails.id.toNumber(),
                      burnQuantityNeeded: burnDetails.burnAmount.toNumber(),
                    });
                  }}
                >
                  Burn
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default ExchangeModal;
