import React from 'react';
import { IconProps } from '@battlefly/components/Icons/index';

const Diamond: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.169 0.0625C6.4376 0.0625 5.7546 0.428038 5.34889 1.03659L0.86236 7.76639C0.587698 8.17839 0.554848 8.706 0.776285 9.14888C3.23142 14.0591 6.64336 18.429 10.8116 22.0017L13 23.8776C13.5754 24.3709 14.4246 24.3709 15 23.8776L17.1885 22.0017C21.3566 18.429 24.7686 14.0591 27.2238 9.14888C27.4451 8.706 27.4124 8.17839 27.1376 7.76639L22.6511 1.03659C22.2454 0.428038 21.5624 0.0625 20.831 0.0625H7.169ZM6.90899 2.07665C6.96694 1.98972 7.06451 1.9375 7.169 1.9375H10.0938L7.69986 7.68286C7.64472 7.8152 7.6031 7.95169 7.57505 8.0903C6.69526 8.02481 5.81622 7.94586 4.93824 7.85345L3.1811 7.66849L6.90899 2.07665ZM3.09834 9.54512C5.34009 13.6576 8.30029 17.3393 11.8415 20.414L7.93471 9.99588C6.86929 9.92263 5.80486 9.83 4.74196 9.71812L3.09834 9.54512ZM9.98104 10.1128L14 20.83L18.019 10.1128C15.341 10.2345 12.659 10.2345 9.98104 10.1128ZM20.0654 9.99588L16.1585 20.414C19.6998 17.3393 22.6599 13.6576 24.9016 9.54512L23.258 9.71812C22.1951 9.83 21.1308 9.92263 20.0654 9.99588ZM24.8189 7.66849L23.0618 7.85345C22.1838 7.94586 21.3048 8.02481 20.425 8.0903C20.3969 7.95169 20.3553 7.8152 20.3001 7.68286L17.9063 1.9375H20.831C20.9355 1.9375 21.0331 1.98972 21.091 2.07666L24.8189 7.66849ZM18.4896 8.21256C15.4985 8.3679 12.5015 8.3679 9.5104 8.21256L12.125 1.9375H15.875L18.4896 8.21256Z"
        fill="white"
      />
    </svg>
  );
};

export default Diamond;
