import { BattleFlyLeague } from '@battlefly/__generated__/globalTypes';
import React from 'react';

import LarvaeIcon from '@battlefly/components/Icons/Leagues/LarvaeIcon';
import PupaIcon from '@battlefly/components/Icons/Leagues/PupaIcon';
import MonarchIcon from '@battlefly/components/Icons/Leagues/MonarchIcon';
import PredatorIcon from '@battlefly/components/Icons/Leagues/PredatorIcon';

interface BattleflyLeagueIconProps {
  league: BattleFlyLeague;
  use: 'default' | 'right-bottom';
  classes?: string;
}

const BattleflyLeagueIcon: React.FC<BattleflyLeagueIconProps> = (props) => {
  const defaultClasses =
    props.use === 'right-bottom'
      ? 'absolute right-[4%] bottom-[4%] h-auto w-[30%] mobile:bottom-[8%]'
      : '';
  switch (props.league) {
    case BattleFlyLeague.Larvae:
      return <LarvaeIcon className={`${defaultClasses} ${props?.classes}`} />;
    case BattleFlyLeague.Pupa:
      return <PupaIcon className={`${defaultClasses} ${props?.classes}`} />;
    case BattleFlyLeague.Monarch:
      return <MonarchIcon className={`${defaultClasses} ${props?.classes}`} />;
    case BattleFlyLeague.Predator:
      return <PredatorIcon className={`${defaultClasses} ${props?.classes}`} />;
    case BattleFlyLeague.Apex:
      return <PredatorIcon className={`${defaultClasses} ${props?.classes}`} />;
  }
};

export default BattleflyLeagueIcon;
