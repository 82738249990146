import MarkAmmunition from '@battlefly/components/Icons/Marks/MarkAmmunition';
import MarkKinetic from '@battlefly/components/Icons/Marks/MarkKinetic';
import MarkElectric from '@battlefly/components/Icons/Marks/MarkElectric';
import MarkArmor from '@battlefly/components/Icons/Marks/MarkArmor';
import MarkShield from '@battlefly/components/Icons/Marks/MarkShield';
import MarkBlackProject from '@battlefly/components/Icons/Marks/MarkBlackProject';
import MarkCybernetics from '@battlefly/components/Icons/Marks/MarkCybernetics';
import MarkEnergy from '@battlefly/components/Icons/Marks/MarkEnergy';
import MarkFusion from '@battlefly/components/Icons/Marks/MarkFusion';
import MarkMissile from '@battlefly/components/Icons/Marks/MarkMissile';

import ScavengeBronze from 'components/Icons/Marks/ScavengeBronze';
import ScavengeSilver from 'components/Icons/Marks/ScavengeSilver';
import ScavengeGold from 'components/Icons/Marks/ScavengeGold';

import {
  HuntersMarkType,
  ScavengingMarkType,
} from '@battlefly/__generated__/globalTypes';
import {
  SCAVENGING_MARK_BRONZE_NECTAR_PRICE,
  SCAVENGING_MARK_GOLD_NECTAR_PRICE,
  SCAVENGING_MARK_SILVER_NECTAR_PRICE,
} from '@battlefly/common/constants';
import { IconProps } from '@battlefly/components/Icons';

interface HuntersMarkItem {
  icon: React.FC<IconProps>;
  description: string;
}

export const HUNTERS_MARKS_DEFS: Record<HuntersMarkType, HuntersMarkItem> = {
  Kinetic: {
    icon: MarkKinetic,
    description:
      'Increases your chance of finding a Kinetic mod if you scavenge in the',
  },
  Electric: {
    icon: MarkElectric,
    description:
      'Increases your chance of finding an Electric mod if you scavenge in the',
  },
  Ammunition: {
    icon: MarkAmmunition,
    description:
      'Increases your chance of finding an Ammunition mod if you scavenge in the',
  },
  Armour: {
    icon: MarkArmor,
    description:
      'Increases your chance of finding an Armour mod if you scavenge in the',
  },
  Shield: {
    icon: MarkShield,
    description:
      'Increases your chance of finding a Shield mod if you scavenge in the',
  },
  Blackproject: {
    icon: MarkBlackProject,
    description:
      'Increases your chance of finding a BlackProject mod if you scavenge in the',
  },
  Cybernetics: {
    icon: MarkCybernetics,
    description:
      'Increases your chance of finding a Cybernetics mod if you scavenge in the',
  },
  Energy: {
    icon: MarkEnergy,
    description:
      'Increases your chance of finding an Energy mod if you scavenge in the',
  },
  Fusion: {
    icon: MarkFusion,
    description:
      'Increases your chance of finding a Fusion mod if you scavenge in the',
  },
  Missile: {
    icon: MarkMissile,
    description:
      'Increases your chance of finding a Missile mod if you scavenge in the',
  },
};

type ScavengeMarkItem = {
  icon: React.FC<IconProps>;
  description: string;
  textColorHex: string;
  nectarPrice: number;
};

export const SCAVENGING_MARKS_DEFS: Record<
  ScavengingMarkType,
  ScavengeMarkItem
> = {
  Bronze: {
    icon: ScavengeBronze,
    description: 'This mark will slightly increase your chances.',
    textColorHex: '#C58C4D',
    nectarPrice: SCAVENGING_MARK_BRONZE_NECTAR_PRICE,
  },
  Silver: {
    icon: ScavengeSilver,
    description: 'This mark will moderately increase your chances.',
    textColorHex: '#CACACA',
    nectarPrice: SCAVENGING_MARK_SILVER_NECTAR_PRICE,
  },
  Gold: {
    icon: ScavengeGold,
    description: 'This mark will greatly increase your chances.',
    textColorHex: '#E4AD11',
    nectarPrice: SCAVENGING_MARK_GOLD_NECTAR_PRICE,
  },
};
