import { GlobalState } from '@battlefly/store/store';
import { createSlice } from '@reduxjs/toolkit';
import {
  addBattleflyToWithdrawCart,
  emptyWithdrawCart,
  removeBattleflyFromWithdrawCart,
  setActiveBattlefliesList,
  setAllActiveTokenIds,
} from './activeActions';
import { InitialActiveBattlefliesState } from './activeTypes';

export const initialState: InitialActiveBattlefliesState = {
  allActiveTokenIds: [],
  activeBattlefliesList: [],
  pagination: {
    items: [],
    meta: {
      perPage: undefined,
      page: undefined,
      total: undefined,
      totalPages: undefined,
    },
  },
  selectedBattleflies: [],
  error: { type: '', message: '', status: 0, timestamp: '' },
  pending: false,
  hasFetched: false,
};

const active = createSlice({
  name: 'active',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setAllActiveTokenIds, (state, { payload }) => {
        state.allActiveTokenIds = payload;
      })
      .addCase(setActiveBattlefliesList, (state, { payload }) => {
        state.activeBattlefliesList = payload;
      })
      .addCase(addBattleflyToWithdrawCart, (state, { payload }) => {
        const newArray = [...state.selectedBattleflies, payload];
        state.selectedBattleflies = newArray;
      })
      .addCase(removeBattleflyFromWithdrawCart, (state, { payload }) => {
        const newItems = state.selectedBattleflies.filter(
          (item) => item.id !== payload.id
        );
        state.selectedBattleflies = newItems;
      })
      .addCase(emptyWithdrawCart, (state) => {
        state.selectedBattleflies = new Array(0);
      });
  },
});

export const selectIsActivePending = () => (state: GlobalState) =>
  state.active.pending;

export const selectIsActiveError = () => (state: GlobalState) =>
  state.active.error;

export const selectActiveBattlefliesList = () => (state: GlobalState) =>
  state.active.activeBattlefliesList;

export const selectAllActiveTokenIds = () => (state: GlobalState) =>
  state.active.allActiveTokenIds;

export default active.reducer;
