import { createContext, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MarketplaceContextType {
  toggleMarketplaceCart: (boolean?: boolean) => void;
  isMarketplaceCartOpen: boolean;
}

export const MarketplaceContext = createContext({} as MarketplaceContextType);

export const MarketplaceContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  const [isMarketplaceCartOpen, setIsMarketplaceCartOpen] = useState(false);
  const toggleMarketplaceCart = (boolean: boolean) => {
    if (boolean === undefined) {
      setIsMarketplaceCartOpen(!isMarketplaceCartOpen);
    } else setIsMarketplaceCartOpen(boolean);
  };
  const contextData = {
    toggleMarketplaceCart,
    isMarketplaceCartOpen,
  };

  return (
    <MarketplaceContext.Provider value={contextData}>
      {children}
    </MarketplaceContext.Provider>
  );
};

export default MarketplaceContextProvider;
