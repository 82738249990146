import { useQuery } from '@apollo/client';
import { GET_MODPACK_BY_ID } from '@battlefly/graphql/battleflyGql';
import { useAppSelector } from '@battlefly/hooks';
import { selectIsUserAuthorized } from '@battlefly/redux/user/userReducer';
import { ModPack } from '@battlefly/__generated__/globalTypes';
import { useState } from 'react';
import { useParams } from 'react-router';

const useModPackDetails = () => {
  const { modpackId, id } = useParams();

  const [loading, setLoading] = useState(true);
  const isUserAuthorized = useAppSelector(selectIsUserAuthorized());

  const { data, error, refetch } = useQuery(GET_MODPACK_BY_ID, {
    variables: {
      modPackId: modpackId,
    },
    skip: !isUserAuthorized || !modpackId,
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'cache-first',
  });

  console.log('TSETSET', data);

  const modPack: ModPack = data?.getModPack;

  return { data: modPack, loading, error, refetch };
};

export default useModPackDetails;
