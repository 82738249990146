import React from 'react';
import classnames from 'classnames';

import Button from 'components/Buttons/Button';
import Image from 'components/core/Image';
import BattleflyLogo from 'components/BattleflyLogo/Logo';
import { BurgerMenu } from 'components/Icons';

type HeaderProps = {
  className?: string;
  toggleWallet: () => void;
  toggleMenu: () => void;
};

const Header = (props: HeaderProps) => {
  const { toggleWallet, toggleMenu } = props;
  return (
    <header className="visible fixed top-0 z-50 w-full items-center justify-between bg-core-blue font-medium text-white sm:hidden">
      <div
        className={classnames(
          'space-between visible flex h-[55px] items-center justify-between px-2 sm:invisible',
          props.className
        )}
      >
        <div>
          <Button
            type="transparent"
            size="lg"
            className="flex h-full items-center bg-core-black"
            onClick={toggleMenu}
          >
            <BurgerMenu className="h-6 w-6" />
          </Button>
        </div>
        <div className="logo flex w-[200px] items-center">
          <div className="w-24">
            <BattleflyLogo />
          </div>
          <Image
            width={264}
            height={52}
            src="/assets/images/battlefly-brand.png"
            alt="logo"
          />
        </div>
        <div className="mt-2 flex items-center" onClick={toggleWallet}>
          <Image
            width={50}
            height={50}
            src="/assets/icons/wallet-icon.png"
            alt="Wallet"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
