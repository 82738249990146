import React from 'react';
import { useDisconnect, useAccount, useBalance } from 'wagmi';

import { UserBalanceContext } from 'context/userBalanceContext';
import { DrawerContext } from 'context/drawerContext';
import { useAppSelector } from '@battlefly/hooks';
import { selectIsUserAuthorized } from 'redux/user/userReducer';
import { WalletTab } from 'ts/wallet';
import { CloseIcon } from 'components/Icons';
import { shortenAddress } from '@battlefly/lib/utils';

import TabControls from './TabControls';
import UserBalances from './UserBalances';
import DepositTab from './DepositTab';
import WithdrawTab from './WithdrawTab';

const UserBalancePanel = ({ onClose }: { onClose: () => void }) => {
  const { disconnect } = useDisconnect();
  const { address } = useAccount();
  const { balanceDrawerState } = React.useContext(DrawerContext);
  const isUserAuthenticated = useAppSelector(selectIsUserAuthorized());
  const [activeTab, setActiveTab] = React.useState<WalletTab>(
    balanceDrawerState.activeTab
  );
  const { data: aETHBalance } = useBalance({
    address: address,
  });
  const balances = React.useContext(UserBalanceContext);

  React.useEffect(() => {
    setActiveTab(balanceDrawerState.activeTab);
  }, [balanceDrawerState.activeTab]);

  function handleDisconnect() {
    disconnect();
    onClose();
  }
  return (
    <div className="flex max-h-full w-full flex-col">
      <header className="flex relative h-10 justify-between">
        <div
          onClick={() => onClose()}
          className="flex h-12 w-12 -translate-y-4 -translate-x-4 cursor-pointer items-center justify-center"
        >
          <CloseIcon fill="#fff" className="h-8 w-8" />
        </div>
        <div>{isUserAuthenticated && shortenAddress(address)}</div>
      </header>
      <TabControls
        handleTabSwitch={(tab) => setActiveTab(tab)}
        active={activeTab}
      />
      <section className="pt-6">
        {activeTab === WalletTab.Balance && (
          <UserBalances
            {...balances}
            aethBalance={aETHBalance}
            onDisconnect={handleDisconnect}
            isUserAuthenticated={isUserAuthenticated}
          />
        )}
        {activeTab === WalletTab.Deposit && (
          <DepositTab userAddress={address} {...balances} />
        )}
        {activeTab === WalletTab.Withdraw && (
          <WithdrawTab userAddress={address} {...balances} />
        )}
      </section>
    </div>
  );
};

export default UserBalancePanel;
