import { getApolloContext } from '@apollo/client';
import { GET_PLAYER_STATISTICS } from '@battlefly/graphql/racerGql';
import { useContext } from 'react';
import { formatDate } from './utils';

export const useGetPlayerStatisticsHandler = (sendToUnity: any) => {
  const { client } = useContext(getApolloContext());

  const handleGetPlayerStatistics = async (input: any) => {
    const { version } = JSON.parse(input);

    const versionDaysAgo: string = formatDate(
      new Date(new Date().setDate(new Date().getDate() - version))
    );

    client
      .query({
        query: GET_PLAYER_STATISTICS,
        variables: {
          input: {
            startDate: versionDaysAgo,
            endDate: versionDaysAgo,
            maxRecordsCount: 1,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        sendToUnity('GetPlayerStatisticsSuccess', {
          version: version,
          nectar: response?.data?.me?.wallets[0]?.bestRaces[0]?.droplets ?? 0,
          score: response?.data?.me?.wallets[0]?.bestRaces[0]?.score ?? 0,
        });
      })
      .catch((response) => {
        sendToUnity('RequestFailed', {
          reason: response.errors,
          requestMethod: 'GetPlayerStatistics',
        });
      });
  };

  return { handleGetPlayerStatistics };
};
