import React, { useEffect, useState } from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Wireframe from '../../../public/assets/videos/cocoon/wireframe.png';
import {
  DesktopRevealRenderer,
  MobileRevealRenderer,
  RenderStage,
} from '../revealRenderers/RevealRenderers';

export const CocoonAnimation = ({
  triggerReveal,
  isRevealing,
  onRevealComplete,
}: {
  isRevealing?: boolean;
  triggerReveal?: boolean;
  onRevealComplete?: () => void;
}) => {
  const { width } = useWindowDimensions();

  const [isReleasingCocoon, setIsReleasingCocoon] = useState(false);
  const [animationTriggered, setAnimationTriggered] = useState(false);
  const [renderingStage, setRenderingStage] = useState<RenderStage>(
    RenderStage.RESTING_COCOON
  );

  const releaseCocoon = () => {
    setAnimationTriggered(true);
    setIsReleasingCocoon(true);
  };

  useEffect(() => {
    if (isRevealing === true) {
      releaseCocoon();
    }
  }, [isRevealing]);

  useEffect(() => {
    if (triggerReveal === true) {
      releaseCocoon();
    }
  }, [triggerReveal]);

  useEffect(() => {
    if (renderingStage === RenderStage.RELEASED_COCOON) {
      setTimeout(() => {
        onRevealComplete();
        setIsReleasingCocoon(false);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReleasingCocoon, renderingStage]);

  return (
    <>
      {width < 600 ? (
        <MobileRevealRenderer
          renderingStage={renderingStage}
          setRenderingStage={setRenderingStage}
          animationTriggered={animationTriggered}
        />
      ) : (
        <DesktopRevealRenderer
          renderingStage={renderingStage}
          setRenderingStage={setRenderingStage}
          animationTriggered={animationTriggered}
        />
      )}
    </>
  );
};

export const BattleflyWireframeAnimation = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      {width < 600 ? (
        <>
          <img
            className="fade-in image-child-back inset-0 z-[0] w-full object-cover object-center"
            src={Wireframe.src}
          />
          <div className="image-child-back battlefly-screen pb-full z-[1] w-full"></div>
          <img
            className="battlefly-card image-child-back inset-0 z-[2] w-full object-cover object-center"
            src={Wireframe.src}
          />
        </>
      ) : (
        <video
          poster="/assets/videos/wireframe/wireframe.png"
          autoPlay
          muted
          loop={true}
          className="battlefly-card image-child-back inset-0 z-[2] w-full rounded-xl object-cover object-center"
        >
          <source
            src="/assets/videos/wireframe/spawn_video.webm"
            type="video/webm"
          />
          <source
            src="/assets/videos/wireframe/spawn_video.mp4"
            type="video/mp4"
          />
          <img src="/assets/videos/wireframe/wireframe.png" />
          Sorry, your browser does not support video content .
        </video>
      )}
    </>
  );
};

export const BattleflyCardWrapper = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className="fade-in w-full p-3 sm:w-1/2 md:w-1/3 lg:w-1/4 mobile:pl-0 mobile:pr-0">
      <div
        className={`c-card flex h-full min-h-[400px] flex-col justify-between overflow-hidden rounded-xl border-[1px] border-button-default bg-black p-2 shadow-md hover:shadow-xl ${className}`}
      >
        {children}
      </div>
    </div>
  );
};

export const AnimationWrapper: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  return (
    <div className="image-container relative z-[0] items-center overflow-hidden p-2">
      {children}
    </div>
  );
};
