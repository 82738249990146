import { useQuery } from '@apollo/client';
import { GET_LEADERBOARD_DATA } from '@battlefly/graphql/arenasGql';

interface LeaderboardResp {
  getGlobalLeaderboard: {
    avatar: string;
    nickName: string;
    earnedMagic: number;
    earnedNectar: number;
  }[];
}

const useLeaderboardData = () => {
  const { data, loading } = useQuery<LeaderboardResp>(GET_LEADERBOARD_DATA);
  return { data: data?.getGlobalLeaderboard, loading };
};

export default useLeaderboardData;
