import React from 'react';

type IconProps = React.ComponentProps<'svg'>;

const ControlPatternActive: React.FC<IconProps> = (props) => (
  <svg
    width="59"
    height="59"
    viewBox="0 0 59 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M58.0733 0.725079V49.386L46.7469 58.2481H0.961025V9.56031C5.38424 6.09945 7.86416 4.15909 12.2874 0.698226H58.0733M59 0H11.8755C7.242 3.6254 4.63351 5.66636 0 9.29176V59H47.1245C51.758 55.3746 54.3665 53.3336 59 49.7082V0Z"
      fill="#FF2079"
    />
  </svg>
);

export default ControlPatternActive;
