import { Image } from 'components/core';
import { Mod } from '@battlefly/__generated__/globalTypes';
import { Inventory as InventoryIcon } from 'components/Icons';

type ModLoadoutLabelProps = {
  className?: string;
  mod: Mod;
  index: number;
};

const ModLoadoutLabel = (props: ModLoadoutLabelProps) => {
  const { mod, index } = props;

  return (
    <div className="relative">
      <div className="flex w-[260px] justify-center">
        {/* <ModLoadoutLabelBg className="" /> */}
        <Image
          width={245}
          height={50}
          className="object-cover object-bottom"
          src={'/assets/icons/mod-loadout-label-bg.svg'}
          alt="Mod Loadout Label"
        />
      </div>
      <div className="absolute left-6 bottom-2 font-heading text-xl">
        <InventoryIcon className="h-5 w-5" /> Loadout {mod.type} {index}
      </div>
    </div>
  );
};

export default ModLoadoutLabel;
