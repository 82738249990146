import React from 'react';
import classnames from 'classnames';
import { Card, Button, Link } from '@battlefly/components/core';

interface GetNewModsBlockProps {
  containerClasses?: string;
  battleflyId: string;
}

const GetNewModsBlock: React.FC<GetNewModsBlockProps> = ({
  containerClasses,
  battleflyId,
}) => {
  return (
    <Card
      blueMint
      className={classnames(
        'flex w-full justify-between gap-10 px-[25px] py-[20px]',
        containerClasses
      )}
    >
      <div>
        <div className="font-heading text-base leading-[19px]">
          Get New Mods
        </div>
        <div className="font-main text-xs leading-[14px] text-[#D9D9D9] ">
          Buy Mod Packs from the Wastelands.
        </div>
      </div>
      <div>
        <Button type="button" blueMint>
          <Link to={`/battleflies/view/${battleflyId}/scavenge`}>
            Scavenge mods
          </Link>
        </Button>
      </div>
    </Card>
  );
};

export default GetNewModsBlock;
