import React, { useEffect, useState } from 'react';

interface BattleflyImageWithPlaceholderProps {
  imageSrc: string;
  battleflyName?: string;
  classes?: string;
}
const COCOON_ANIMATION =
  'https://battlefly.infura-ipfs.io/ipfs/QmVSMdABd2hahtS74owdeu6nHauaXhWviReyMGch8Ztb6W';
export const BF_IMAGE_FALLBACK = '/assets/images/wireframe.png';

const BattleflyImageWithPlaceholder: React.FC<
  BattleflyImageWithPlaceholderProps
> = (props) => {
  const imgUrl = props.imageSrc
    .replace('ipfs.infura.io', 'battlefly.infura-ipfs.io')
    .replace(COCOON_ANIMATION, BF_IMAGE_FALLBACK);

  const [battleflyImage, setBattleflyImage] = useState(BF_IMAGE_FALLBACK);

  useEffect(() => {
    const lz = new Image();
    lz.src = imgUrl;
    lz.onload = () => {
      setBattleflyImage(imgUrl);
    };
  }, [imgUrl]);

  return (
    <img
      width={1024}
      height={1024}
      src={battleflyImage}
      alt={`Image: ${props?.battleflyName}`}
      className={props?.classes}
    />
  );
};

export default BattleflyImageWithPlaceholder;
