import React from 'react';

type IconProps = React.ComponentProps<'svg'>;

const CloseIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.5904 12.4995L18.0436 8.04639C18.4837 7.60706 18.4843 6.89414 18.045 6.45404C17.6057 6.01394 16.8927 6.01331 16.4526 6.45264L11.9995 10.9058L7.54639 6.45264C7.10628 6.01253 6.39274 6.01253 5.95264 6.45264C5.51253 6.89274 5.51253 7.60628 5.95264 8.04639L10.4058 12.4995L5.95264 16.9526C5.51253 17.3927 5.51253 18.1063 5.95264 18.5464C6.39274 18.9865 7.10628 18.9865 7.54639 18.5464L11.9995 14.0933L16.4526 18.5464C16.8927 18.9865 17.6063 18.9865 18.0464 18.5464C18.4865 18.1063 18.4865 17.3927 18.0464 16.9526L13.5904 12.4995Z" />
    </svg>
  );
};

export default CloseIcon;
