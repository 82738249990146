import React from 'react';
import { getWeaponDps } from '@battlefly/util/combatHelpers';
import { Mod, ModType, Rarity } from '@battlefly/__generated__/globalTypes';
import ModBackground, {
  MODS_DEFS,
} from '@battlefly/components/Icons/Mods/ModBackground';
import ModStatPlate from '@battlefly/components/BattleflyDetails/Mods/ModStatPlate';

import EnergyNewModIcon from '/public/assets/icons/mod_categories/energy_cat_ic.svg';
import KineticModIcon from '/public/assets/icons/mod_categories/kinetic_cat_ic.svg';
import MissileModIcon from '/public/assets/icons/mod_categories/missile_cat_ic.svg';
import FusionNewModIcon from '/public/assets/icons/mod_categories/fusion_cat_ic.svg';
import ElectricModIcon from '/public/assets/icons/mod_categories/electric_cat_ic.svg';
import AmmunitionModIcon from '/public/assets/icons/mod_categories/ammunition_cat_ic.svg';
import ArmorModIcon from '/public/assets/icons/mod_categories/armor_cat_ic.svg';
import BlackProjectModIcon from '/public/assets/icons/mod_categories/black_project_cat_ic.svg';
import CyberneticsModIcon from '/public/assets/icons/mod_categories/cybernetics_cat_ic.svg';
import ShieldModIcon from '/public/assets/icons/mod_categories/shield_cat_ic.svg';
import ModFullBackground from '@battlefly/components/Icons/Mods/ModFullBackground';
import ModSmall from '@battlefly/components/Icons/Mods/ModSmall';
import StatDpsIcon from '@battlefly/components/Icons/Mods/Stats/StatDpsIcon';
import StatReloadIcon from '@battlefly/components/Icons/Mods/Stats/StatReloadIcon';
import StatBurstIcon from '@battlefly/components/Icons/Mods/Stats/StatBurstIcon';
import StatDamageIcon from '@battlefly/components/Icons/Mods/Stats/StatDamageIcon';
import StatArmorIcon from '@battlefly/components/Icons/Mods/Stats/StatArmorIcon';
import StatEvasionIcon from '@battlefly/components/Icons/Mods/Stats/StatEvasionIcon';
import StatShieldIcon from '@battlefly/components/Icons/Mods/Stats/StatShieldIcon';

export interface ModFullInterface {
  mod: Mod;
  containerClasses?: string;
}

export const MOD_CATEGORY_ICON: Record<string, string> = {
  Ammunition: AmmunitionModIcon.src,
  Armor: ArmorModIcon.src,
  BlackProject: BlackProjectModIcon.src,
  Cybernetics: CyberneticsModIcon.src,
  Electric: ElectricModIcon.src,
  Energy: EnergyNewModIcon.src,
  Fusion: FusionNewModIcon.src,
  Kinetic: KineticModIcon.src,
  Missile: MissileModIcon.src,
  Shield: ShieldModIcon.src,
};

const ModFull: React.FC<ModFullInterface> = (props) => {
  return (
    <div
      className={`relative aspect-[100/128] max-w-full overflow-hidden rounded-xl border border-black ${props?.containerClasses}`}
    >
      <ModFullBackground
        rarity={props.mod.rarity as Rarity}
        type={props.mod.type as ModType}
        classes="absolute top-0 left-0"
      />
      <div className="relative h-full w-full">
        <div
          className={`absolute rounded-md bg-[#1E1E366e] ${
            props.mod.type.toLowerCase() !== ModType.Utility.toLowerCase()
              ? 'left-[7%] top-[8.5%] h-[76%] w-[65%]'
              : 'left-[16%] top-[22%] h-[76%] w-[71%]'
          }`}
        >
          <div className="absolute left-[5%] top-0 aspect-[100/100] w-[89%]">
            <ModSmall mod={props.mod} containerClasses="w-full" />
          </div>
          <div className="absolute top-[68%] left-[15%] h-[32%] w-[70%] font-main text-[9px] leading-[1.15] text-[#D9D9D9] 2xl:text-[11px]">
            {props.mod.blueprint.description}
          </div>
        </div>
        <div className="absolute top-[9.5%] left-[74.5%] flex w-[15%] flex-col gap-2">
          {props.mod.type.toLowerCase() === ModType.Weapon.toLowerCase() &&
            props.mod.blueprint.weapon && (
              <>
                <ModStatPlate
                  statName="DPS"
                  statValue={getWeaponDps(
                    props.mod.blueprint.weapon.damagePerFire,
                    props.mod.blueprint.weapon.burst,
                    props.mod.blueprint.weapon.reload
                  ).toString()}
                  statIcon={
                    <StatDpsIcon className="absolute -top-[15%] -left-[13%] h-auto w-[42%]" />
                  }
                />
                <ModStatPlate
                  statName="Reload"
                  statValue={props.mod.blueprint.weapon.reload.toString()}
                  statIcon={
                    <StatReloadIcon className="absolute -top-[15%] -left-[25%] h-auto w-[70%]" />
                  }
                  unitName="s"
                />
                <ModStatPlate
                  statName="Damage"
                  statValue={props.mod.blueprint.weapon.damagePerFire.toString()}
                  statIcon={
                    <StatDamageIcon className="absolute -top-[13%] -left-[13%] h-auto w-[60%]" />
                  }
                />
                <ModStatPlate
                  statName="Burst"
                  statValue={props.mod.blueprint.weapon.burst.toString()}
                  statIcon={
                    <StatBurstIcon className="absolute -top-[15%] -left-[20%] h-auto w-[70%]" />
                  }
                />
              </>
            )}
          {props.mod.type.toLowerCase() === ModType.Defense.toLowerCase() &&
            props.mod.blueprint.defense && (
              <>
                <ModStatPlate
                  statName="Armor"
                  statValue={props.mod.blueprint.defense.armor.toString()}
                  statIcon={
                    <StatArmorIcon className="absolute -top-[10%] -left-[15%] h-auto w-[55%]" />
                  }
                />
                <ModStatPlate
                  statName="Evasion"
                  statValue={props.mod.blueprint.defense.evasion.toString()}
                  statIcon={
                    <StatEvasionIcon className="absolute -top-[13%] -left-[20%] h-auto w-[60%]" />
                  }
                  unitName="%"
                />
                <ModStatPlate
                  statName="Shield"
                  statValue={props.mod.blueprint.defense.shield.toString()}
                  statIcon={
                    <StatShieldIcon className="absolute -top-[8%] -left-[10%] h-auto w-[45%]" />
                  }
                />
              </>
            )}
        </div>

        <img
          src="/assets/images/mod-battlefly-logo.png"
          className="absolute -right-[2%] -bottom-[1%] h-[21%] w-auto"
          alt="Battlefly"
        />
      </div>
    </div>
  );
};

export default ModFull;
