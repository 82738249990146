import React from 'react';

const Comic: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} inline-block`}
  >
    <path
      d="M32.0531 16.385C32.2999 16.8787 32.3965 17.4338 32.3274 17.9939L32.1702 19.266C32.0753 20.0342 31.6794 20.6929 31.1155 21.1388V24.4442H18.6795V19.2051H25.2892C25.2486 18.9467 25.2438 18.6813 25.2767 18.4145L25.4295 17.1772H9.42666V9.77627H26.3441L26.6572 7.24121H6.8916V34.0004H33.6508V7.28222L32.7108 14.8912C32.6415 15.4513 32.4127 15.9664 32.0531 16.385ZM9.42666 19.2051H16.6094V24.4442H9.42666V19.2051ZM31.1155 31.4653H9.42666V26.4723H31.1157V31.4653H31.1155Z"
      fill="white"
    />
    <path
      d="M28.5133 15.3133L29.7854 15.4705C30.2971 15.5338 30.763 15.1702 30.8263 14.6587L31.9941 5.20518C32.0573 4.6935 31.6937 4.22757 31.1822 4.16435L29.9102 4.00714C29.3985 3.94392 28.9326 4.30751 28.8693 4.819L27.7015 14.2725C27.6383 14.7842 28.0018 15.2501 28.5133 15.3133Z"
      fill="white"
    />
    <path
      d="M27.9728 19.6876L29.2449 19.8448C29.7566 19.908 30.2225 19.5444 30.2857 19.0329L30.4429 17.7608C30.5062 17.2492 30.1426 16.7832 29.6311 16.72L28.359 16.5628C27.8473 16.4996 27.3814 16.8632 27.3182 17.3747L27.161 18.6467C27.0977 19.1582 27.4611 19.6244 27.9728 19.6876Z"
      fill="white"
    />
  </svg>
);

export default Comic;
