import React from 'react';
import classnames from 'classnames';

import {
  BattleFly,
  HuntersMarkType,
} from '@battlefly/__generated__/globalTypes';
import { Image, Button } from 'components/core';
import Chevron from 'components/Accordion/Chevron';
import ScavengingMark from 'components/ScavengingMark';
import HuntersMark from 'components/HuntersMark';
import { SCAVENGE_PACK_MAGIC_PRICE } from 'common/constants';

import EnergyNewModIcon from '/public/assets/icons/mod_categories/energy_cat_ic.svg';
import KineticModIcon from '/public/assets/icons/mod_categories/kinetic_cat_ic.svg';
import MissileModIcon from '/public/assets/icons/mod_categories/missile_cat_ic.svg';
import FusionNewModIcon from '/public/assets/icons/mod_categories/fusion_cat_ic.svg';
import ElectricModIcon from '/public/assets/icons/mod_categories/electric_cat_ic.svg';
import AmmunitionModIcon from '/public/assets/icons/mod_categories/ammunition_cat_ic.svg';
import ArmorModIcon from '/public/assets/icons/mod_categories/armor_cat_ic.svg';
import BlackProjectModIcon from '/public/assets/icons/mod_categories/black_project_cat_ic.svg';
import CyberneticsModIcon from '/public/assets/icons/mod_categories/cybernetics_cat_ic.svg';
import ShieldModIcon from '/public/assets/icons/mod_categories/shield_cat_ic.svg';

const MOD_CATEGORY_ICON: Record<string, string> = {
  [HuntersMarkType.Ammunition]: AmmunitionModIcon.src,
  'Armor': ArmorModIcon.src,
  'BlackProject': BlackProjectModIcon.src,
  [HuntersMarkType.Cybernetics]: CyberneticsModIcon.src,
  [HuntersMarkType.Electric]: ElectricModIcon.src,
  [HuntersMarkType.Energy]: EnergyNewModIcon.src,
  [HuntersMarkType.Fusion]: FusionNewModIcon.src,
  [HuntersMarkType.Kinetic]: KineticModIcon.src,
  [HuntersMarkType.Missile]: MissileModIcon.src,
  [HuntersMarkType.Shield]: ShieldModIcon.src,
};

type WastelandCardProps = {
  battlefly: BattleFly;
  buttonColor: string;
  name: string;
  inGameName: string;
  image: string;
  findChanceLabel: string;
  modCategory: HuntersMarkType | string;
  id: number;
  handleManageScavengingMark: () => void;
  handleManageHunterMark: () => void;
  handleScavenge: (wastelandId: number) => Promise<void>;
};

type RowProps = {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
};
const Row = (props: RowProps) => {
  const { children, onClick, className } = props;
  return (
    <li
      onClick={onClick}
      className={classnames('flex items-center gap-2', className)}
    >
      {children}
    </li>
  );
};

const WastelandCard = (props: WastelandCardProps) => {
  const {
    id,
    inGameName,
    modCategory,
    image,
    buttonColor,
    handleManageHunterMark,
    handleManageScavengingMark,
    battlefly,
    handleScavenge,
    findChanceLabel,
  } = props;
  const { huntersMark, scavengingMark } = battlefly;
  const modCategoryIconSrc = MOD_CATEGORY_ICON[modCategory];

  return (
    <div
      className={classnames(
        'gra-wasteland-card w-[400px] rounded-xl border-2 p-8',
        {
          'border-wasteland-blue drop-shadow-wl1': id === 1,
          'border-wasteland-pink drop-shadow-wl2': id === 2,
          'border-wasteland-blue drop-shadow-wl3': id === 3,
          'border-wasteland-purple drop-shadow-wl4': id === 4,
          'border-wasteland-pink drop-shadow-wl5': id === 5,
          'border-wasteland-blue drop-shadow-wl6': id === 6,
          'border-wasteland-blue drop-shadow-wl7': id === 7,
          'border-wasteland-purple drop-shadow-wl8': id === 8,
          'border-wasteland-blue drop-shadow-wl9': id === 9,
          'border-wasteland-blue drop-shadow-wl10': id === 10,
        }
      )}
    >
      <h1 className="whitespace-nowrap text-xl+ leading-8">{inGameName}</h1>
      <p className="invisible mb-4">XXX scavenges in the last 7 days</p>
      <div className="flex flex-col gap-2">
        <div className="w-full">
          <Image
            width={330}
            height={130}
            className="rounded-lg object-cover"
            src={`/assets/images/wastelands/${image}`}
          />
        </div>
        <div className="w-full">
          <ul className="flex flex-col gap-4">
            <Row>
              <div className="w-[40px]">
                <div className="h-[35px] w-[35px] rounded-[50%] bg-core-black">
                  <Image width={35} height={35} src={modCategoryIconSrc} />
                </div>
              </div>

              <div className="flex justify-between">
                <div className="leading-[19px]">
                  50% chance of finding {findChanceLabel}.
                </div>
              </div>
            </Row>
            <Row
              className="cursor-pointer"
              onClick={handleManageScavengingMark}
            >
              <div>
                <div className="w-[40px]">
                  {scavengingMark ? (
                    <ScavengingMark
                      className="h-[35px] w-[35px]"
                      type={scavengingMark.type}
                    />
                  ) : (
                    <Image
                      width={35}
                      height={35}
                      src="/assets/icons/ScavengeNeutral.png"
                    />
                  )}
                </div>
              </div>

              <div className="flex justify-between">
                <div className="leading-[19px]">
                  Apply Scavending Mark to increase your chance of finding a
                  higher rarity mod.
                </div>
                <Chevron className={``} width={30} fill={'#fff'} />
              </div>
            </Row>
            <Row className="cursor-pointer" onClick={handleManageHunterMark}>
              <div>
                <div className="h-[40px] w-[40px]">
                  {huntersMark ? (
                    <HuntersMark
                      type={huntersMark.type}
                      className="h-[35px] w-[35px]"
                    />
                  ) : (
                    <Image
                      width={40}
                      height={40}
                      src="/assets/icons/HunterMarkNeutral.png"
                    />
                  )}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="leading-[19px]">
                  Apply Hunter`s Mark to improve your BattleFly&apos;s ability
                  to scavenge for a mod.
                </div>
                <Chevron className={``} width={30} fill={'#fff'} />
              </div>
            </Row>
          </ul>
          <div className="mx-auto mt-6 w-[80%]">
            <Button
              onClick={() => handleScavenge(id)}
              type="button"
              blueMint={buttonColor === 'wasteland-blue'}
              cyberPink={buttonColor === 'wasteland-pink'}
              purple={buttonColor === 'wasteland-purple'}
            >
              Scavenge now ({SCAVENGE_PACK_MAGIC_PRICE} Magic)
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WastelandCard;
