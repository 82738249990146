import { BattleflyStage } from '@battlefly/ts/BattleflyType';
import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { GetBattlefliesResponse } from '../redux/commonActions/types';
import { setStakingBattlefliesList } from '../redux/staking';

interface StakedBattlefliesContextType {
  stakedBattleflyTokenIds: number[];
  stakedBattleflyTokenIdsError: string;
  setBattleflySearchValue: Dispatch<SetStateAction<any>>;
  battleflySearchValue: string;
  toggleAdvancedFilters: () => void;
  isAdvancedFiltersOpen: boolean;
  battleflyListItems: GetBattlefliesResponse['items'];
  battleflyListItemsError: string;
  selectEditionParams: (data: any) => void;
  isFetchingMoreBattleflies: any;
  selectedViewOption: any;
  viewOptions: { value: BattleflyStage; label: string }[];
  setSelectedViewOption: () => void;
  isStakedBattlefliesCartOpen: any;
  isUnstakeCartOpen: any;
  setIsUnstakeCartOpen: any;
  toggleUnstakeBattlefliesCart: (any?: boolean) => void;
  removeUnstakedBattlefliesFromListOnSuccess: (
    dispatch: any,
    selectedItems: BattleFly[],
    battleflyListItems: BattleFly[]
  ) => void;
}

export const StakedBattlefliesContext = createContext(
  {} as Partial<StakedBattlefliesContextType>
);

export const StakedBattlefliesProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  const [isUnstakeCartOpen, setIsUnstakeCartOpen] = useState(false);

  const toggleUnstakeBattlefliesCart = (boolean?: boolean) => {
    if (boolean === undefined) {
      setIsUnstakeCartOpen(!isUnstakeCartOpen);
    } else setIsUnstakeCartOpen(boolean);
  };

  const removeUnstakedBattlefliesFromListOnSuccess = (
    dispatch: any,
    selectedItems: BattleFly[],
    battleflyListItems: BattleFly[]
  ) => {
    const checkedOutIds = selectedItems.map((item) => item.id);
    const newArray = battleflyListItems.filter(function (battlefly: BattleFly) {
      return checkedOutIds.indexOf(battlefly.id) === -1;
    });
    dispatch(setStakingBattlefliesList(newArray));
  };
  const contextData = {
    removeUnstakedBattlefliesFromListOnSuccess,
    isUnstakeCartOpen,
    setIsUnstakeCartOpen,
    toggleUnstakeBattlefliesCart,
  };

  return (
    <StakedBattlefliesContext.Provider value={contextData}>
      {children}
    </StakedBattlefliesContext.Provider>
  );
};

export default StakedBattlefliesProvider;
