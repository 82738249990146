import React from 'react';
import { IconProps } from '@battlefly/components/Icons/index';

const CrownIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 2000 2000"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path d="M725.33,832.49c0,0-148.84-159.3-155.81-156.98c-6.98,2.33-97.67,220.93-97.67,220.93l155.81,93.02L725.33,832.49z" />
        <polygon
          points="1357.88,1098.77 999.74,638.3 641.6,1098.77 178.81,796.44 439.28,1480.16 999.74,1480.16 1560.21,1480.16 
		1820.67,796.44 	"
        />
        <path d="M1527.65,896.44c0,0-90.7-218.6-97.67-220.93c-6.98-2.33-155.81,156.98-155.81,156.98l97.67,156.98L1527.65,896.44z" />
      </g>
      <rect x="474.16" y="1537.62" width="1053.49" height="130.92" />
    </svg>
  );
};

export default CrownIcon;
