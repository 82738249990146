import { HoldingBattlefliesContext } from '@battlefly/context/holdingBattlefliesContext';
import { StakedBattlefliesContext } from '@battlefly/context/stakedBattlefliesContext';
import useWindowDimensions from '@battlefly/hooks/useWindowDimensions';
import { useContext, useEffect, useState } from 'react';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';

export const ToastContainerNew = (): JSX.Element => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return mounted && <NotificationsContainer />;
};

const NotificationsContainer = () => {
  const { isUnstakeCartOpen } = useContext(StakedBattlefliesContext);
  const { isOwnedBattlefliesCartOpen } = useContext(HoldingBattlefliesContext);
  const [isAddingSidebarStyling, setIsAddingSidebarStyling] = useState(false);
  const { width } = useWindowDimensions();
  const [toastDuration, setToastDuration] = useState(7000);

  useEffect(() => {
    const SIDEBAR_TOASTS = [isUnstakeCartOpen, isOwnedBattlefliesCartOpen];
    setIsAddingSidebarStyling(SIDEBAR_TOASTS.some((cart) => cart === true));
  }, [isUnstakeCartOpen, isOwnedBattlefliesCartOpen]);

  const sidebarStyling = !isAddingSidebarStyling ? `absolute mt-20` : `toasty`;

  useEffect(() => {
    if (width > 500) {
      setToastDuration(7000);
    } else setToastDuration(4000);
  }, [width]);

  return (
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={10}
      containerClassName={`${sidebarStyling}`}
      containerStyle={{
        ...(isAddingSidebarStyling && { position: 'relative' }),
        display: 'block',
        zIndex: 10000000,
      }}
      toastOptions={{
        // Define default options
        className: '',
        duration: toastDuration,
        style: {
          background: '#363636',
          color: '#fff',
        },
        // Default options for specific types
        success: {
          duration: 3000,
          theme: {
            primary: 'green',
            secondary: 'black',
          },
        },
      }}
    />
  );
};

export const BlockDisplayToastContainer = (): JSX.Element => {
  const [mounted, setMounted] = useState(false);
  const { toasts } = useToasterStore();
  useEffect(() => {
    setMounted(true);
  }, []);
  const TOAST_LIMIT = 2;
  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i === TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);
  return (
    mounted && (
      <div className="relative h-[108px] max-h-[108px] min-h-[108px] overflow-hidden">
        <NotificationsContainer />
      </div>
    )
  );
};

export default NotificationsContainer;
