import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { useState } from 'react';

const useTabMintCount = () => {
  const [mintCount, setMintCount] = useState(0);
  const [maxMint, setMaxMint] = useState(undefined);

  const handleMintMaxCountWithBalance = (
    magicBalance: number,
    price: BigNumber
  ) => {
    const priceInMagic = formatUnits(price, 18);
    const max = Math.floor(magicBalance / Number(priceInMagic));

    setMintCount(max);
  };

  const handleMintMaxCount = () => {
    setMintCount(maxMint);
  };

  const handleIncreaseMintCount = () => {
    if (!maxMint) {
      return;
    }
    setMintCount(mintCount + 1 <= maxMint ? mintCount + 1 : maxMint);
  };

  const handleDecreaseMintCount = () => {
    if (!maxMint) {
      return;
    }
    setMintCount(mintCount - 1 > 0 ? mintCount - 1 : 1);
  };

  const decreaseMaxMint = (count: number) => {
    const newMaxMintVal = maxMint - count >= 0 ? maxMint - count : 0;
    setMaxMint(newMaxMintVal);
    if (mintCount > newMaxMintVal) {
      setMintCount(newMaxMintVal);
    }
  };

  return {
    mintCount,
    maxMint,
    setMintCount,
    setMaxMint,
    handleMintMaxCount,
    handleIncreaseMintCount,
    handleDecreaseMintCount,
    decreaseMaxMint,
    handleMintMaxCountWithBalance,
  };
};

export default useTabMintCount;
