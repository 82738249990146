import * as Types from '@battlefly/__generated__/globalTypes';

import { gql } from '@apollo/client';
export type TopBattleFlyGlobalStatisticsFragment = { __typename?: 'GlobalStatistics', id: string, top: { __typename?: 'GlobalTop', topBattleFlyMagicWin: number | null, topBattleFly: { __typename?: 'BattleFly', id: string, name: string, hyperdomeLosses: number, hyperdomeWins: number, provingGroundsLosses: number, provingGroundsWins: number, appearance: { __typename?: 'BattleFlyAppearance', image: string | null }, owner: { __typename?: 'Identity', user: { __typename?: 'User', wallets: Array<{ __typename?: 'Wallet', address: string }> } } | null } | null } };

export const TopBattleFlyGlobalStatisticsFragmentDoc = gql`
    fragment TopBattleFlyGlobalStatistics on GlobalStatistics {
  id
  top {
    topBattleFly {
      id
      name
      appearance {
        image
      }
      owner {
        user {
          wallets {
            address
          }
        }
      }
      hyperdomeLosses
      hyperdomeWins
      provingGroundsLosses
      provingGroundsWins
    }
    topBattleFlyMagicWin
  }
}
    `;