import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

export type DrawerProps = {
  children: JSX.Element;
  isOpen: boolean;
  className?: string;
  onClose?: () => void;
  position?: 'left' | 'right';
  querySelector?: string;
  maxWidth?: string;
};

const useMountTransition = (isMounted: boolean, unmountDelay: number) => {
  const [isTransitioning, setIsTransitioning] = React.useState(false);

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isMounted && !isTransitioning) {
      setIsTransitioning(true);
    } else if (!isMounted && isTransitioning) {
      timeoutId = setTimeout(() => setIsTransitioning(false), unmountDelay);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [unmountDelay, isMounted, isTransitioning]);

  return isTransitioning;
};

const Drawer = (props: DrawerProps) => {
  const {
    isOpen,
    children,
    className,
    onClose,
    position = 'right',
    querySelector = '#__next',
    maxWidth = '500px',
  } = props;
  const bodyRef = React.useRef(document.querySelector('body'));
  const isTransitioning = useMountTransition(isOpen, 300);
  React.useEffect(() => {
    const updatePageScroll = () => {
      if (isOpen) {
        bodyRef.current.style.overflow = 'hidden';
      } else {
        bodyRef.current.style.overflow = '';
      }
    };

    updatePageScroll();
  }, [isOpen]);

  // Allow Escape key to dismiss the drawer
  React.useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keyup', onKeyPress);
    }

    return () => {
      window.removeEventListener('keyup', onKeyPress);
    };
  }, [isOpen, onClose]);

  // if (!isTransitioning && !isOpen) {
  //   return null;
  // }

  if (!document.querySelector(querySelector)) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      aria-hidden={isOpen ? 'false' : 'true'}
      className={classnames(
        'drawer-container',
        {
          open: isOpen,
          in: isTransitioning,
        },
        className
      )}
    >
      <div
        className={classnames(
          position,
          `drawer w-[90%] max-w-[500px] sm:w-[400px]`
        )}
        role="dialog"
      >
        {children}
      </div>
      <div className="backdrop" onClick={onClose} />
    </div>,
    document.body.querySelector(querySelector)
  );
};
export default Drawer;
