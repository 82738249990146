import React from 'react';
import classnames from 'classnames';

import { TreasureToken } from '@battlefly/ts/bridgeworld';
import { Card, Image, Link, Button } from 'components/core';
import { CloseIcon } from 'components/Icons';
import Spinner from 'components/Spinner';
import { useBurnTreasureForInventorySlot } from 'hooks/contractsNew/useBattleflyCredits';
import { useApproveTreasures } from 'hooks/contractsNew/useBridgeworldContract';

type TreasureOptionCardProps = {
  onClose: () => void;
  treasures: TreasureToken[];
  loading: boolean;
  battleflyId: string;
  onPurchase: () => void;
  isPolling: boolean;
};

const Input = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: (event: React.SyntheticEvent) => void;
}) => {
  return (
    <div
      onClick={onClick}
      className="inline-block flex h-[25px] w-[25px] items-center justify-center rounded-[50%] bg-white p-0 text-2xl leading-[15px] text-core-black"
    >
      {children}
    </div>
  );
};

function formatQuantities(
  selected: string[],
  quantities: Record<string, number>
): number[] {
  return selected.map((id) => quantities[id]);
}

const TreasureOptionCard = (props: TreasureOptionCardProps) => {
  const [selected, setSelected] = React.useState([]);
  const [selectedQuantities, setSelectedQuantities] = React.useState<
    Record<string, number>
  >({});
  const { onClose, treasures, loading, battleflyId, onPurchase, isPolling } =
    props;
  const { write: burnTreasures } = useBurnTreasureForInventorySlot(
    selected,
    formatQuantities(selected, selectedQuantities),
    battleflyId,
    onPurchase
  );
  const { isContractApproved, write: approveTreasures } = useApproveTreasures();

  const isSubmitDisabled =
    isContractApproved && (selected.length === 0 || !burnTreasures);

  function handleToggleTreasure(tokenId: string) {
    if (selected.includes(tokenId)) {
      setSelected(selected.filter((id) => id !== tokenId));
      handleQuantityChange(tokenId, 0);
    } else {
      setSelected([...selected, tokenId]);
      handleQuantityChange(tokenId, 1);
    }
  }

  function handleQuantityChange(tokenId: string, quantity: number) {
    setSelectedQuantities({
      ...selectedQuantities,
      [tokenId]: quantity,
    });
  }

  function handleIncrease(tokenId: string) {
    handleQuantityChange(tokenId, selectedQuantities[tokenId] + 1);
  }

  function handleDecrease(tokenId: string) {
    handleQuantityChange(tokenId, selectedQuantities[tokenId] - 1);
  }

  function handleSubmit() {
    if (!isContractApproved) {
      return approveTreasures();
    }
    burnTreasures();
  }

  React.useEffect(() => {
    if (
      Object.keys(selectedQuantities).find(
        (tokenId) => selectedQuantities[tokenId] === 0
      )
    ) {
      setSelected(
        selected.filter((tokenId) => selectedQuantities[tokenId] > 0)
      );
    }
  }, [selectedQuantities]);

  if (loading)
    return (
      <Card
        containerClassName="max-w-[100%]"
        className="overflow:hidden w-full pt-4 pb-12 shadow-none"
      >
        Loading...
      </Card>
    );

  return (
    <div className="relative w-full ">
      <div
        className={classnames(
          'absolute left-[50%] top-[50%] z-10 -ml-12 -mt-12 flex h-12 w-12 items-center justify-center',
          {
            'pointer-events-none visible ': isPolling,
            invisible: !isPolling,
          }
        )}
      >
        <Spinner pending={isPolling} className="!h-12 !w-12" />
      </div>
      <Card
        containerClassName="max-w-[100%]"
        className={classnames('overflow:hidden !px-0 pt-4 pb-12 shadow-none', {
          'pointer-events-none opacity-25': isPolling,
        })}
      >
        <header className="mb-4 flex flex-row items-start justify-between px-4 md:mb-10 md:px-8">
          <div
            className={classnames({
              invisible: treasures.length === 0,
            })}
          >
            <h1 className="text-left text-4xl md:text-3xl">
              Purchase a Slot With Treasure
            </h1>
            <p>
              Please select 2 Treasures you wish to burn in order to purchase an
              additional inventory slot.
            </p>
          </div>
          <div onClick={onClose} className="cursor-pointer p-4">
            <CloseIcon fill="#fff" />
          </div>
        </header>

        {treasures.length > 0 && (
          <div>
            <div className="mb-4 flex flex-col gap-4 md:flex-row">
              <div className="flex max-w-[800px] overflow-x-scroll pb-4 pt-4 md:pb-8">
                <ul className="mx-4 flex gap-4 md:mx-8">
                  {treasures.map((treasure) => {
                    return (
                      <li
                        onClick={() => handleToggleTreasure(treasure.tokenId)}
                        className="h-[200px] w-[200px] cursor-pointer"
                        key={treasure.tokenId}
                      >
                        <Card
                          className={classnames('relative flex !p-2', {
                            active: selected.includes(treasure.tokenId),
                          })}
                        >
                          <Image
                            className="rounded-lg"
                            src={treasure.imageUrl}
                            width={200}
                            height={200}
                          />
                          <div className="absolute top-3 left-3 flex gap-2">
                            {selected.includes(treasure.tokenId) && (
                              <>
                                <pre className="font-[monospace]">
                                  Selected:{' '}
                                  {selectedQuantities[treasure.tokenId]}
                                </pre>
                                <Input
                                  onClick={(event) => {
                                    handleIncrease(treasure.tokenId);
                                    event.stopPropagation();
                                  }}
                                >
                                  +
                                </Input>
                                <Input
                                  onClick={(event) => {
                                    handleDecrease(treasure.tokenId);
                                    event.stopPropagation();
                                  }}
                                >
                                  -
                                </Input>
                              </>
                            )}
                          </div>

                          <div className="absolute bottom-3 right-2 ">
                            <pre className="font-[monospace]">
                              Quantity: {treasure.quantity}{' '}
                            </pre>
                          </div>
                        </Card>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="flex flex-col flex-col-reverse justify-between gap-4 px-4 md:flex-row md:px-8">
              <div>
                <span>Need to buy a treasure? </span>
                <Link
                  to="https://trove.treasure.lol/collection/treasures"
                  target="_blank"
                  className="underline"
                >
                  Visit Trove &gt;
                </Link>
              </div>
              <div className="min-w-[240px]">
                <Button
                  disabled={isSubmitDisabled}
                  transparent
                  light
                  onClick={handleSubmit}
                >
                  {!isContractApproved ? 'Approve' : "Let's lock it in"}
                </Button>
              </div>
            </div>
          </div>
        )}
        {treasures.length === 0 && (
          <div className="flex justify-center p-8 text-center">
            <div className="flex h-[400px] w-[400px] flex-col items-center justify-center gap-4 rounded-[50%] bg-core-cyberpink p-8">
              <h1 className="text-4xl">
                Oops... seems <br /> there&apos;s no treasure
              </h1>
              <p className="mb-4">
                You will need to head to Trove to purchase some new Treasures to
                lock to your BattleFly. Once done we can pickup where we left
                off.
              </p>

              <div className="w-[250px]">
                <Button transparent>
                  <Link
                    to="https://trove.treasure.lol/collection/treasures"
                    target="_blank"
                  >
                    Take me to trove
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default TreasureOptionCard;
