import classnames from 'classnames';

import { CloseIcon } from 'components/Icons';

type CloseButtonProps = {
  onClick: () => void;
  className?: string;
};

const CloseButton = (props: CloseButtonProps) => {
  const { onClick, className } = props;
  return (
    <div
      onClick={onClick}
      className={classnames(
        'flex h-[20px] w-[20px] cursor-pointer items-center justify-center bg-core-cyberpink',
        className
      )}
    >
      <CloseIcon fill="#fff" />
    </div>
  );
};

export default CloseButton;
