import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
  APPLY_SCAVENGING_MARK,
  GET_BATTLEFLY_MARKS,
} from '@battlefly/graphql/battleflyGql';
import {
  ApplyScavengingMarkInput,
  BattleFlyScavengingMark,
  ScavengingMarkType,
} from '@battlefly/__generated__/globalTypes';
import { GET_BALANCES } from 'graphql/userGql';
import {
  CustomToast,
  ToastStatus,
} from '@battlefly/components/CustomToast/CustomToast';

export const useApplyScavengeMark = (battleflyId: string) => {
  const { data: dataBalances, refetch: reFetchBalances } =
    useQuery(GET_BALANCES);

  const [fetchBattleflyMarks, { refetch: refetchBattleflyMarks }] =
    useLazyQuery(GET_BATTLEFLY_MARKS, {
      variables: { input: { battleFlyId: battleflyId } },
    });

  const [applyMark, { loading }] = useMutation<
    { applyScavengingMark: BattleFlyScavengingMark },
    { applyScavengingMarkInput: ApplyScavengingMarkInput }
  >(APPLY_SCAVENGING_MARK, {
    onCompleted: (data) => {
      reFetchBalances();
      refetchBattleflyMarks();
      CustomToast({
        status: ToastStatus.SUCCESS,
        message: 'Mark applied',
      });
    },
    onError: () => {
      reFetchBalances();
      CustomToast({
        status: ToastStatus.REJECTED,
        message: 'Failed to apply mark',
      });
    },
  });

  return { applyMark, loading };
};
