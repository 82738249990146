import React from 'react';
import { IconProps } from '@battlefly/components/Icons/index';

const ArrowRight: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="35"
      height="25"
      viewBox="0 0 35 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.358 3.39211C19.5891 2.62327 19.5891 1.37671 20.358 0.607877C21.1268 -0.160959 22.3732 -0.160959 23.142 0.607877L33.642 11.108C34.4109 11.8768 34.4109 13.1232 33.642 13.892L23.142 24.392C22.3732 25.1609 21.1268 25.1609 20.358 24.392C19.5891 23.6232 19.5891 22.3768 20.358 21.608L27.4969 14.4687H2.0625C0.975199 14.4687 0.09375 13.5873 0.09375 12.5C0.09375 11.4127 0.975199 10.5312 2.0625 10.5312H27.4969L20.358 3.39211Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowRight;
