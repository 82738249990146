import React from 'react';

type InfiniteListProps = {
  hasNextPage: boolean;
  endCursor: string;
  isLoading: boolean;
  fetchMore: (options: any) => Promise<any>;
  items: any[];
  renderItem: (item: any) => React.ReactNode;
};

const InfiniteList = (props: InfiniteListProps) => {
  const { hasNextPage, endCursor, fetchMore, items, isLoading } = props;
  const observer = React.useRef<IntersectionObserver>();

  const lastElementRef = React.useCallback(
    (node: HTMLElement) => {
      if (isLoading) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchMore({
            variables: {
              first: 10,
              returnTotalCount: true,
              after: endCursor,
            },
          });
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, hasNextPage, endCursor, fetchMore]
  );

  return (
    <div className="flex flex-col gap-2">
      {items.map((item, i) => {
        const isLastElement = i === items.length - 1;
        if (isLastElement) {
          return (
            <div ref={lastElementRef} key={item.id}>
              {props.renderItem(item)}
            </div>
          );
        } else {
          return <div key={item.id}>{props.renderItem(item)}</div>;
        }
      })}
      {isLoading && <div>Loading...</div>}
    </div>
  );
};

export default InfiniteList;
