import React from 'react';

const Inventory: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} inline-block`}
  >
    <path
      d="M40 16.0001V8.00003C40 5.79006 38.21 4 36 4H4.00003C1.79006 4 0.0200625 5.78997 0.0200625 8.00003L0.0100312 16C2.22 16.01 4.00003 17.8 4.00003 20C4.00003 22.21 2.21006 24 0.0100312 24L0 32C0 34.2101 1.78997 36 4.00003 36H36C38.2099 36 40 34.2101 40 32V24C37.7899 24 36 22.2101 36 20C36 17.79 37.7899 16.0001 40 16.0001ZM27.16 29.6L20 25L12.8401 29.6L15 21.3701L8.41996 15.9901L16.91 15.4901L20 7.60009L23.09 15.4901L31.5799 15.9901L24.9999 21.3701L27.16 29.6Z"
      fill="white"
    />
  </svg>
);

export default Inventory;
