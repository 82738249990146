import React from 'react';
import { ModWeaponBackgroundProps } from '@battlefly/components/Icons/Mods/ModWeaponBackground';

interface ModWeaponBackgroundDefsProps extends ModWeaponBackgroundProps {
  backgroundColor: string;
  borderColor: string;
}

const ModDefenseBackgroundDefs: React.FC<ModWeaponBackgroundDefsProps> = (
  props
) => {
  return (
    <>
      <linearGradient
        id={`paint0_linear_3_6684${props.uniqueId}`}
        x1="128.414"
        y1="815.533"
        x2="102.034"
        y2="866.965"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_3_6684${props.uniqueId}`}
        x1="220.499"
        y1="826.267"
        x2="194.119"
        y2="877.699"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint2_linear_3_6684${props.uniqueId}`}
        x1="177.401"
        y1="794.385"
        x2="151.022"
        y2="845.817"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint3_linear_3_6684${props.uniqueId}`}
        x1="134.304"
        y1="762.502"
        x2="107.924"
        y2="813.934"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint4_linear_3_6684${props.uniqueId}`}
        x1="269.746"
        y1="804.763"
        x2="243.366"
        y2="856.195"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint5_linear_3_6684${props.uniqueId}`}
        x1="183.554"
        y1="740.999"
        x2="157.174"
        y2="792.431"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint6_linear_3_6684${props.uniqueId}`}
        x1="361.832"
        y1="815.493"
        x2="335.452"
        y2="866.925"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint7_linear_3_6684${props.uniqueId}`}
        x1="318.734"
        y1="783.611"
        x2="292.355"
        y2="835.042"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint8_linear_3_6684${props.uniqueId}`}
        x1="232.541"
        y1="719.847"
        x2="206.161"
        y2="771.279"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint9_linear_3_6684${props.uniqueId}`}
        x1="189.446"
        y1="687.966"
        x2="163.067"
        y2="739.398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint10_linear_3_6684${props.uniqueId}`}
        x1="454.177"
        y1="825.871"
        x2="427.797"
        y2="877.303"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint11_linear_3_6684${props.uniqueId}`}
        x1="367.984"
        y1="762.108"
        x2="341.605"
        y2="813.539"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint12_linear_3_6684${props.uniqueId}`}
        x1="324.887"
        y1="730.226"
        x2="298.507"
        y2="781.658"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint13_linear_3_6684${props.uniqueId}`}
        x1="281.791"
        y1="698.344"
        x2="255.411"
        y2="749.776"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint14_linear_3_6684${props.uniqueId}`}
        x1="238.693"
        y1="666.462"
        x2="212.314"
        y2="717.894"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint15_linear_3_6684${props.uniqueId}`}
        x1="460.068"
        y1="772.841"
        x2="433.689"
        y2="824.273"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint16_linear_3_6684${props.uniqueId}`}
        x1="416.971"
        y1="740.959"
        x2="390.593"
        y2="792.388"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint17_linear_3_6684${props.uniqueId}`}
        x1="373.876"
        y1="709.077"
        x2="347.498"
        y2="760.506"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint18_linear_3_6684${props.uniqueId}`}
        x1="287.681"
        y1="645.313"
        x2="261.302"
        y2="696.742"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint19_linear_3_6684${props.uniqueId}`}
        x1="244.585"
        y1="613.432"
        x2="218.207"
        y2="664.861"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint20_linear_3_6684${props.uniqueId}`}
        x1="509.315"
        y1="751.337"
        x2="482.937"
        y2="802.766"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint21_linear_3_6684${props.uniqueId}`}
        x1="466.221"
        y1="719.456"
        x2="439.843"
        y2="770.885"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint22_linear_3_6684${props.uniqueId}`}
        x1="423.123"
        y1="687.573"
        x2="396.745"
        y2="739.002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint23_linear_3_6684${props.uniqueId}`}
        x1="380.028"
        y1="655.693"
        x2="353.65"
        y2="707.122"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint24_linear_3_6684${props.uniqueId}`}
        x1="336.931"
        y1="623.81"
        x2="310.552"
        y2="675.239"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint25_linear_3_6684${props.uniqueId}`}
        x1="293.833"
        y1="591.927"
        x2="267.455"
        y2="643.356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint26_linear_3_6684${props.uniqueId}`}
        x1="515.209"
        y1="698.304"
        x2="488.829"
        y2="749.736"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint27_linear_3_6684${props.uniqueId}`}
        x1="429.016"
        y1="634.54"
        x2="402.636"
        y2="685.972"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint28_linear_3_6684${props.uniqueId}`}
        x1="385.918"
        y1="602.657"
        x2="359.538"
        y2="654.089"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint29_linear_3_6684${props.uniqueId}`}
        x1="342.822"
        y1="570.776"
        x2="316.442"
        y2="622.208"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint30_linear_3_6684${props.uniqueId}`}
        x1="299.726"
        y1="538.895"
        x2="273.346"
        y2="590.327"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint31_linear_3_6684${props.uniqueId}`}
        x1="478.264"
        y1="613.036"
        x2="451.884"
        y2="664.468"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint32_linear_3_6684${props.uniqueId}`}
        x1="435.168"
        y1="581.155"
        x2="408.788"
        y2="632.586"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint33_linear_3_6684${props.uniqueId}`}
        x1="348.974"
        y1="517.391"
        x2="322.594"
        y2="568.822"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint34_linear_3_6684${props.uniqueId}`}
        x1="527.251"
        y1="591.888"
        x2="500.873"
        y2="643.317"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint35_linear_3_6684${props.uniqueId}`}
        x1="484.155"
        y1="560.006"
        x2="457.777"
        y2="611.435"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint36_linear_3_6684${props.uniqueId}`}
        x1="441.058"
        y1="528.123"
        x2="414.679"
        y2="579.552"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint37_linear_3_6684${props.uniqueId}`}
        x1="397.962"
        y1="496.242"
        x2="371.584"
        y2="547.671"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint38_linear_3_6684${props.uniqueId}`}
        x1="354.864"
        y1="464.36"
        x2="328.486"
        y2="515.789"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint39_linear_3_6684${props.uniqueId}`}
        x1="533.404"
        y1="538.502"
        x2="507.026"
        y2="589.931"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint40_linear_3_6684${props.uniqueId}`}
        x1="490.308"
        y1="506.62"
        x2="463.929"
        y2="558.049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint41_linear_3_6684${props.uniqueId}`}
        x1="447.21"
        y1="474.738"
        x2="420.832"
        y2="526.167"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id={`paint42_linear_3_6684${props.uniqueId}`}
        x1="404.115"
        y1="442.857"
        x2="377.737"
        y2="494.286"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.borderColor} />
        <stop offset="1" stopColor={props.backgroundColor} stopOpacity="0.2" />
      </linearGradient>
    </>
  );
};

export default ModDefenseBackgroundDefs;
