interface Props {
  title: string;
  containerClassName?: string;
  textClassName?: string;
}

const PageTitle = ({
  title,
  containerClassName = '',
  textClassName = '',
}: Props): JSX.Element => {
  return (
    <div
      className={`m-2 flex w-full items-center justify-center text-center sm:m-8 ${containerClassName}`}
    >
      <h1
        className={`m-auto text-center text-3xl font-bold tracking-tighter sm:text-3xl md:text-4xl lg:text-5xl ${textClassName}`}
      >
        {title}
      </h1>
    </div>
  );
};

export default PageTitle;
