import React from 'react';
import classNames from 'classnames';

import { Modal, Button } from 'components/core';
import { ModalContext } from 'context/modalContext';
import { Checkmark, CloseIcon } from 'components/Icons';

const ConfirmModal = () => {
  const { confirmModalState, setConfirmModalState } =
    React.useContext(ModalContext);

  if (!confirmModalState) return null;

  const {
    type,
    title,
    description,
    onConfirm,
    onCancel,
    confirmText,
    cancelText,
  } = confirmModalState;

  return (
    <Modal isOpen={true} onClose={() => setConfirmModalState(null)}>
      <div className="h-[650px] w-full rounded-xl bg-[#191623] p-4 md:w-[500px] md:p-20">
        <div className="flex h-full flex-col items-center justify-center">
          <div
            className={classNames(
              'flex h-2/5 w-full items-center justify-center',
              {
                'bg-core-green-success': type === 'success',
                'bg-core-red-error': type === 'error',
              }
            )}
          >
            <div className="flex h-[90px] w-[90px] items-center justify-center rounded-[50%] border-2 border-white">
              {type === 'success' ? (
                <Checkmark className="!mr-0 h-8 w-8" />
              ) : (
                <CloseIcon fill="#fff" className="h-12 w-12" />
              )}
            </div>
          </div>
          <div className="flex h-3/5 w-full flex-col items-center justify-center bg-core-blue-light">
            <h1 className="text-3xl">{title}</h1>
            <div className="px-12 text-center leading-[19px]">
              {' '}
              {description}{' '}
            </div>
            {onConfirm && (
              <div className="mt-8 flex w-full justify-center px-8">
                <Button transparent light onClick={onConfirm}>
                  {confirmText}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
