import React from 'react';
import PupaIcon from '@battlefly/components/Icons/Leagues/PupaIcon';
import { IconProps } from '@battlefly/components/Icons';
import LeagueWithDigitIcon from '@battlefly/components/Icons/Leagues/LeagueWithDigitIcon';

export type LeagueWithDigitProps = IconProps & {
  digit: number;
  offsetTopPx?: number;
  hideLeagueDigit?: boolean;
};

const PupaWithDigitIcon: React.FC<LeagueWithDigitProps> = (props) => {
  return (
    <LeagueWithDigitIcon
      digit={props.digit}
      hideLeagueDigit={props.hideLeagueDigit}
      icon={PupaIcon}
      colorBorderStart="#BEF9E5"
      colorBorderEnd="#01A3EC"
      colorRectStart="#2EBAEC"
      colorRectMiddle="#3F61AD"
      colorRectEnd="#4493D0"
    />
  );
};

export default PupaWithDigitIcon;
