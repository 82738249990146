import React from 'react';

import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { WalletTab } from 'ts/wallet';

type BalanceDrawerState = {
  isOpen: boolean;
  activeTab: WalletTab;
};

type DrawerContextType = {
  showBattleSettingsDrawer: boolean;
  balanceDrawerState: BalanceDrawerState;
  showBalanceDrawer: boolean;
  setShowBalanceDrawer: (showBalanceDrawer: boolean, tab?: WalletTab) => void;
  setBattleSettingsDrawer: (battlefly: BattleFly) => void;
  battlefly: BattleFly;
};

const defaultSettings: DrawerContextType = {
  balanceDrawerState: {
    isOpen: false,
    activeTab: WalletTab.Balance,
  },
  showBattleSettingsDrawer: false,
  setBattleSettingsDrawer: (battlefly: BattleFly) => {},
  showBalanceDrawer: false,
  setShowBalanceDrawer: (showBalanceDrawer: boolean) => {},
  battlefly: null,
};

export const DrawerContext =
  React.createContext<DrawerContextType>(defaultSettings);

export const DrawerContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [balanceDrawerState, setBalanceDrawerState] = React.useState(
    defaultSettings.balanceDrawerState
  );
  const [battlefly, setBattleSettingsDrawer] = React.useState(null);
  const showBattleSettingsDrawer = battlefly !== null;
  const showBalanceDrawer = balanceDrawerState.isOpen;
  const setShowBalanceDrawer = (
    showBalanceDrawer: boolean,
    tab?: WalletTab
  ) => {
    console.log('SETTING TAB', tab);
    setBalanceDrawerState({
      activeTab: tab || balanceDrawerState.activeTab,
      isOpen: showBalanceDrawer,
    });
  };

  console.log('balanceDrawerState', balanceDrawerState);

  return (
    <DrawerContext.Provider
      value={{
        showBattleSettingsDrawer,
        setBattleSettingsDrawer,
        battlefly,
        showBalanceDrawer,
        setShowBalanceDrawer,
        balanceDrawerState,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerContextProvider;
