import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { createAction } from '@reduxjs/toolkit';

export const setAllHoldingTokenIds = createAction<number[]>(
  'holding/setAllHoldingTokenIds'
);

export const setHoldingBattlefliesList = createAction<BattleFly[]>(
  'holding/setHoldingBattlefliesList'
);

/**
 * Add item to cart for staking
 */
export const addBattleFlyToStakingCart = createAction<BattleFly>(
  'holding/addBattleFlyToStakingCart'
);

/**
 * Clear staking cart
 */
export const emptyStakingCart = createAction<{ id: string }>(
  'holding/emptyStakingCart'
);

/**
 * Remove item from staking cart
 */
export const removeBattleFlyFromStakingCart = createAction<{
  id: string;
}>('holding/removeBattleFlyFromStakingCart');
