import React from 'react';
import classnames from 'classnames';

interface PupaDigitContainerProps {
  className?: string;
  offset: number;
  colorBorderStart: string;
  colorBorderEnd: string;
  colorRectStart: string;
  colorRectMiddle: string;
  colorRectEnd: string;
}

const LeagueDigitContainer: React.FC<PupaDigitContainerProps> = (props) => {
  const { className } = props;
  const addToId =
    props.colorBorderStart +
    props.colorBorderEnd +
    props.colorRectStart +
    props.colorRectMiddle +
    props.colorRectEnd;
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classnames(
        'relative flex h-[32px] w-[32px] justify-center wxga+:h-[48px] wxga+:w-[48px]',
        className
      )}
    >
      <path
        d="M24.2712 47.2547L0.977417 23.8578L24.2712 0.460938L47.5651 23.8578L24.2712 47.2547ZM4.17418 23.8578L24.2712 44.0438L44.3683 23.8578L24.2712 3.67186L4.17418 23.8578Z"
        fill={`url(#paint0_linear_22_836${addToId})`}
      />
      <path
        d="M24.2712 3.67188L4.17419 23.8578L24.2712 44.045L44.3694 23.8578L24.2712 3.67188Z"
        fill={`url(#paint1_linear_22_836${addToId})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_22_836${addToId}`}
          x1="0.978106"
          y1="23.8582"
          x2="47.5653"
          y2="23.8582"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.colorBorderStart} />
          <stop offset="1" stopColor={props.colorBorderEnd} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_22_836${addToId}`}
          x1="4.17423"
          y1="23.8583"
          x2="44.3691"
          y2="23.8583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.colorRectStart} />
          <stop offset="0.4944" stopColor={props.colorRectMiddle} />
          <stop offset="1" stopColor={props.colorRectEnd} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LeagueDigitContainer;
