import { useAppDispatch } from '.';
import {
  CustomToast,
  ToastStatus,
} from '../components/CustomToast/CustomToast';
import { withdrawBattlefly } from '../redux/commonActions';
import {
  useBulkStakeBattlefly,
  useBulkUnstakeBattlefly,
} from './contractsNew/useRevealStakingContract';

/**
 * Hook used to stake or unstake single battlefly
 * @returns contract functions to either stake or unstake; you must pass in tokenIds
 */
export const useStakeBattlefly = () => {
  const { bulkStakeBattlefly } = useBulkStakeBattlefly();
  const { bulkUnstakeBattlefly } = useBulkUnstakeBattlefly();

  const dispatch = useAppDispatch();
  // const { bulkUnstakeBattlefly: bulkUnstake, bulkStakeBattlefly: bulkStake } =
  //   useRevealStakingContract();
  const unstakeBattlefly = async (id: string | string[]) => {
    try {
      const response = await dispatch(
        withdrawBattlefly({ ids: [parseInt(id.toString())] })
      ).unwrap();
      const { battleflyIds, signature, stagesIds } = response;
      const { r, s, v } = signature;
      try {
        bulkUnstakeBattlefly(battleflyIds, stagesIds, v, r, s);
      } catch (e) {}
    } catch (e) {
      CustomToast({
        status: ToastStatus.REJECTED,
        message: 'Could not withdraw battlefly',
      });
    }
  };

  const stakeBattlefly = (ids: any) => {
    bulkStakeBattlefly(ids);
  };

  return { unstakeBattlefly, stakeBattlefly };
};
