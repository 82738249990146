import RacerGame from '@battlefly/components/RacerGame';
import ValidationPrompts from '@battlefly/components/ValidationPrompts/ValidationPrompts';
import { NextPage } from 'next';

const Racer: NextPage = () => {
  if (process.env.NEXT_PUBLIC_ENV === 'development') {
    return <RacerGame />;
  }
  return (
    <ValidationPrompts>
      <RacerGame />
    </ValidationPrompts>
  );
};

export default Racer;
