import React, { useState } from 'react';
import Button from '../../Buttons/Button';
import {
  BattleflyClass,
  BattleflyEdition,
  Rarity,
} from '@battlefly/ts/BattleflyType';

type FilterTypes = 'Class' | 'Edition' | 'Rarity' | 'Type' | 'Status';

export interface ISelectedMissionControlFilters {
  Class: string[];
  Edition: string[];
  Rarity: string[];
  Type: string[];
  Status: string[];
}

export enum BattleflyBattleStatuses {
  Battling = 'Battling',
  Inactive = 'Inactive',
  Unstaked = 'Unstaked',
}

export const ListItem: React.FC<{
  text: string;
  handleOnChange: () => void;
  selected: boolean;
}> = ({ text, handleOnChange, selected }) => {
  return (
    <div className="flex items-center p-1">
      <input
        className="mr-2 scale-150"
        type="checkbox"
        checked={selected}
        onChange={handleOnChange}
      ></input>
      <p className="column" style={{ width: '100%', float: 'left' }}>
        {text}
      </p>
    </div>
  );
};

const Filter = ({
  type,
  options,
  selected,
  onChange,
}: {
  type: FilterTypes;
  options: string[];
  selected: string[];
  onChange: (type: FilterTypes, key: string) => void;
}) => {
  const [isActive, setIsActive] = useState(false);

  function handleCheckboxChange(key: string) {
    onChange(type, key);
  }

  const CheckboxItems = () => {
    return (
      <>
        {options.map((option) => (
          <ListItem
            key={option}
            text={option}
            handleOnChange={() => handleCheckboxChange(option)}
            selected={selected.includes(option)}
          />
        ))}
      </>
    );
  };

  return (
    <div className="w-full border-t-2 border-b-2 border-button-default">
      <div className="c-card flex h-full items-center overflow-hidden shadow-md hover:shadow-xl">
        <div className="flex w-full flex-col justify-between py-1">
          <div
            onClick={() => setIsActive(!isActive)}
            className="flex w-full items-center justify-between"
          >
            <span>{type}</span>
            <span>
              <img src="/assets/icons/dropdown-caret.svg" />
            </span>
          </div>
          <div
            className={`filter-dropdown ml-4 overflow-y-auto ${
              !isActive
                ? `max-h-0 transition-all duration-300`
                : 'max-h-[380px] transition-all duration-300'
            }`}
          >
            <CheckboxItems />
          </div>
        </div>
      </div>
    </div>
  );
};

interface AdvancedFiltersSidebarInterface {
  showStatusFilters?: boolean;
  setSelectedFilters: (filters: ISelectedMissionControlFilters) => void;
}

const AdvancedFiltersSidebar: React.FC<AdvancedFiltersSidebarInterface> = (
  props
) => {
  const { showStatusFilters = false } = props;
  const applyFilters = () => {
    props.setSelectedFilters(selectedClass);
  };

  const [selectedClass, setSelectedClass] =
    useState<ISelectedMissionControlFilters>({
      Class: [],
      Edition: [],
      Rarity: [],
      Type: [],
      Status: [],
    });

  function handleCheckboxChange(type: FilterTypes, key: string) {
    const sel = [...selectedClass[type]];
    const find = selectedClass[type].indexOf(key);
    if (find > -1) {
      sel.splice(find, 1);
    } else {
      sel.push(key);
    }
    const nval = {} as ISelectedMissionControlFilters;
    Object.assign(nval, selectedClass);
    nval[type] = sel;
    setSelectedClass(nval);
  }

  return (
    <div className="flex h-full w-full flex-col justify-between gap-10 pb-10">
      <div className="scrollbar-hidden flex flex-col gap-10 overflow-auto pr-3">
        <Filter
          type="Class"
          options={Object.values(BattleflyClass)}
          selected={selectedClass['Class']}
          onChange={handleCheckboxChange}
        />
        <Filter
          type="Rarity"
          options={Object.values(Rarity)}
          selected={selectedClass['Rarity']}
          onChange={handleCheckboxChange}
        />
        <Filter
          type="Edition"
          options={Object.values(BattleflyEdition).sort((a, b) =>
            a.localeCompare(b)
          )}
          selected={selectedClass['Edition']}
          onChange={handleCheckboxChange}
        />
        {showStatusFilters && (
          <Filter
            type="Status"
            options={Object.values(BattleflyBattleStatuses) as string[]}
            selected={selectedClass['Status']}
            onChange={handleCheckboxChange}
          />
        )}
      </div>
      <Button onClick={() => applyFilters()} type="primary">
        Apply Filters
      </Button>
    </div>
  );
};

export default AdvancedFiltersSidebar;
