import React from 'react';
import { useQuery } from '@apollo/client';

import { BattleFly, Combat } from '@battlefly/__generated__/globalTypes';
import { mapSimpleBattleLog } from '@battlefly/lib/mapper/battlelog';
import { BattleflyContext } from '@battlefly/context/battleflyContext';
import {
  GET_MY_STAKED_BATTLEFLYS,
  GET_BATTLEFLYS_ALL_DATA_BY_IDS,
} from '@battlefly/graphql/userGql';
import { calculateStats } from 'lib/mapper/stats';

export const useBattleflies = (isAuthenticated: boolean) => {
  const { unstaked, staked, isUnstaked } = React.useContext(BattleflyContext);
  const {
    data,
    loading,
    refetch: refetchStaked,
    startPolling,
    stopPolling,
  } = useQuery(GET_MY_STAKED_BATTLEFLYS, {
    // pollInterval: MISSION_CONTROL_RE_FETCH_DATA_SECONDS,
    fetchPolicy: 'cache-first',
    skip: !isAuthenticated,
  });

  const { data: unstakedBattleflies, refetch } = useQuery(
    GET_BATTLEFLYS_ALL_DATA_BY_IDS,
    {
      variables: {
        battleFlyIds: unstaked,
      },
      fetchPolicy: 'cache-first',
      skip: !isAuthenticated,
    }
  );

  const stakedBattleflies =
    data?.me?.battleFlies.map((battlefly: BattleFly) => {
      const logs: any[] =
        battlefly.combatHistoryPaginated.edges.map((edge) =>
          mapSimpleBattleLog(edge.node as Partial<Combat>, battlefly.id)
        ) ?? [];

      return {
        ...battlefly,
        ...calculateStats(battlefly),
        logs,
        isUnstaked: false,
      };
    }) ?? [];

  const mappedUnstakedBattleflies =
    unstakedBattleflies?.getBattleFlies
      ?.filter((bf: any) => bf.battleFly)
      .map((bf: any) => {
        const logs: any[] =
          bf.battleFly.combatHistoryPaginated.edges.map((edge: any) =>
            mapSimpleBattleLog(edge.node as Partial<Combat>, bf.id)
          ) ?? [];
        return {
          ...bf.battleFly,
          ...calculateStats(bf.battleFly),
          logs,
          isUnstaked: true,
        };
      }) || [];

  const cocoonIds = unstaked.filter((id) => {
    return !mappedUnstakedBattleflies.find(
      (bf: any) => Number(bf.id) === Number(id)
    );
  });

  const battleflies = [
    ...stakedBattleflies,
    ...mappedUnstakedBattleflies,
    ...cocoonIds,
  ];

  return {
    battleflies,
    loading,
    refetch,
  };
};
