import BattleflyDetailedView from 'routes/battleflies/[id]';
import ComicRewardsPage from 'routes/comic';
import ComicId from 'routes/comic/mint/[comicId]';
import Racer from 'routes/racer/racer';
import Home from 'routes/_home';
import MissionControlRoute from 'routes/mission-control/mission-control';
import ScavengingPage from 'routes/battleflies/scavenge';
import ModpackPage from 'routes/modpacks/[modpackId]';
import BattleflyLoadoutPage from 'routes/battleflies/loadout';
import BattlelogRoute from 'routes/battleflies/battlelog';
import BattleLogDetailPage from 'routes/battlelogs/[battleId]';
import LeaderboardRoute from 'routes/leaderboard';
import GlobalBattleLogRoute from 'routes/global-battlelog';
import StatisticsRoute from 'routes/statistics';
import LeagueTrackRoute from 'routes/battleflies/leagues';

export interface RoutePathDefinition {
  title: string;
  nav?: boolean;
  children?: RoutePathDefinition[];
  path: string;
  element?: () => React.ReactElement;
  background?: string;
  backgroundImage?: string;
  className?: string;
  breadcrumb?: (params: any) => React.ReactElement;
}

export const routes: RoutePathDefinition[] = [
  {
    title: 'Leaderboard',
    path: '/leaderboard',
    className: 'leaderboard',
    element: () => <LeaderboardRoute />,
  },
  {
    title: 'Battlegrounds Statistics',
    path: '/statistics',
    className: 'statistics',
    element: () => <StatisticsRoute />,
  },
  {
    title: 'Global Battlelog',
    path: '/global-battlelog',
    className: 'global-battlelog',
    element: () => <GlobalBattleLogRoute />,
  },
  {
    title: 'Comic',
    path: '/comic',
    element: () => <ComicRewardsPage />,
    children: [
      {
        title: 'Mint Comics',
        path: '/comic/mint/:id',
        element: () => <ComicId />,
      },
    ],
  },
  {
    title: 'Mission Control',
    path: '/battleflies',
    className: 'mission-control',
    element: () => <MissionControlRoute />,
    breadcrumb: () => (
      <span className="whitespace-nowrap">Mission Control</span>
    ),
    children: [
      {
        title: 'BattleFly View',
        path: '/battleflies/view/:id',
        element: () => <BattleflyDetailedView />,
        className: 'battlefly-detail-view',
        background: '#0B061F',
        breadcrumb: ({ match }: { match: any }) => (
          <span className="whitespace-nowrap">BattleFly {match.params.id}</span>
        ),
        children: [
          {
            title: 'Scavenging',
            path: '/battleflies/view/:id/scavenge',
            backgroundImage: 'url("/assets/backgrounds/scavenge-bg.png")',
            className: 'scavenge',
            element: () => <ScavengingPage />,
            breadcrumb: () => (
              <span className="whitespace-nowrap">Scavenge</span>
            ),
          },
          {
            title: 'Scavenge Haul',
            path: '/battleflies/view/:id/modpacks/:modpackId',
            className: 'modpack-page',
            element: () => <ModpackPage />,
            breadcrumb: ({ match }: { match: any }) => (
              <span className="whitespace-nowrap">Scavenge Haul</span>
            ),
          },
          {
            title: 'Loadout',
            path: '/battleflies/view/:id/loadout',
            className: 'loadout',
            element: () => <BattleflyLoadoutPage />,
          },
          {
            title: 'Battle Log',
            path: '/battleflies/view/:id/battlelog',
            element: () => <BattlelogRoute />,
          },
          {
            title: 'Battle Log Details',
            path: '/battleflies/view/:id/battlelog/:battleId',
            element: () => <BattleLogDetailPage />,
            breadcrumb: ({ match }: { match: any }) => (
              <span className="whitespace-nowrap">Combat Replay</span>
            ),
          },
          {
            title: 'BattleFly League',
            path: '/battleflies/view/:id/battlefly-league',
            element: () => <LeagueTrackRoute />,
            breadcrumb: ({ match }: { match: any }) => (
              <span className="whitespace-nowrap">BattleFly League</span>
            ),
          }
        ],
      },
    ],
  },
  {
    title: 'Home',
    path: '/',
    className: 'home',
    element: () => <Home />,
  },
  {
    title: 'Racer',
    path: '/racer',
    element: () => <Racer />,
  },
];

// useRoutes() hook does not work with next js. so this is our own implementation.
// recursively retrieve all page routes
const getAllRoutes = ({
  routes,
  result = [],
}: {
  routes: RoutePathDefinition[];
  result?: RoutePathDefinition[];
}) => {
  routes?.forEach((prop) => {
    result?.push(prop);
    if (prop?.children) {
      getAllRoutes({ routes: prop?.children, result });
    }
  });
  return result;
};
const allRoutes = getAllRoutes({ routes });
export default allRoutes;
