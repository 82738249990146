import React from 'react';
import { ModWeaponBackgroundProps } from '@battlefly/components/Icons/Mods/ModWeaponBackground';

interface ModWeaponBackgroundDefsProps extends ModWeaponBackgroundProps {
  backgroundColor: string;
  borderColor: string;
}

const ModWeaponBackgroundDefs: React.FC<ModWeaponBackgroundDefsProps> = (
  props
) => {
  return (
    <>
      <linearGradient
        id={`paint0_linear_3_7868${props.uniqueId}`}
        x1="411.996"
        y1="650.633"
        x2="411.996"
        y2="732.34"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_3_7868${props.uniqueId}`}
        x1="385.563"
        y1="721.652"
        x2="385.563"
        y2="743.532"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint2_linear_3_7868${props.uniqueId}`}
        x1="386.266"
        y1="658.488"
        x2="386.266"
        y2="676.474"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint3_linear_3_7868${props.uniqueId}`}
        x1="317.423"
        y1="707.096"
        x2="317.423"
        y2="773.749"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint4_linear_3_7868${props.uniqueId}`}
        x1="244.483"
        y1="807.164"
        x2="244.483"
        y2="811.422"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint5_linear_3_7868${props.uniqueId}`}
        x1="309.361"
        y1="802.873"
        x2="309.361"
        y2="814.367"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint6_linear_3_7868${props.uniqueId}`}
        x1="321.039"
        y1="689.595"
        x2="321.039"
        y2="798.489"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint7_linear_3_7868${props.uniqueId}`}
        x1="267.519"
        y1="816.44"
        x2="267.519"
        y2="853.495"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint8_linear_3_7868${props.uniqueId}`}
        x1="379.377"
        y1="792.503"
        x2="379.377"
        y2="810.457"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint9_linear_3_7868${props.uniqueId}`}
        x1="310.608"
        y1="827.26"
        x2="310.608"
        y2="852.38"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint10_linear_3_7868${props.uniqueId}`}
        x1="372.331"
        y1="738.738"
        x2="372.331"
        y2="853.529"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint11_linear_3_7868${props.uniqueId}`}
        x1="333.373"
        y1="629.93"
        x2="333.373"
        y2="882.822"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint12_linear_3_7868${props.uniqueId}`}
        x1="338.24"
        y1="606.405"
        x2="338.24"
        y2="916.077"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint13_linear_3_7868${props.uniqueId}`}
        x1="414.6"
        y1="548.248"
        x2="414.6"
        y2="1070.89"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint14_linear_3_7868${props.uniqueId}`}
        x1="416.648"
        y1="711.243"
        x2="416.648"
        y2="760.59"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint15_linear_3_7868${props.uniqueId}`}
        x1="324.937"
        y1="670.771"
        x2="324.937"
        y2="825.09"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint16_linear_3_7868${props.uniqueId}`}
        x1="291.896"
        y1="646.113"
        x2="291.896"
        y2="784.247"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint17_linear_3_7868${props.uniqueId}`}
        x1="310.261"
        y1="689.422"
        x2="310.261"
        y2="777.291"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint18_linear_3_7868${props.uniqueId}`}
        x1="293.378"
        y1="609.548"
        x2="293.378"
        y2="823.203"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint19_linear_3_7868${props.uniqueId}`}
        x1="289.088"
        y1="580.275"
        x2="289.088"
        y2="843.909"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
      <linearGradient
        id={`paint20_linear_3_7868${props.uniqueId}`}
        x1="284.216"
        y1="547"
        x2="284.216"
        y2="867.436"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.backgroundColor} />
        <stop offset="1" stopColor={props.borderColor} />
      </linearGradient>
    </>
  );
};

export default ModWeaponBackgroundDefs;
