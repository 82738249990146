import React from 'react';
import classnames from 'classnames';

import { HuntersMarkType } from '__generated__/globalTypes';
import { Card, Button } from 'components/core';
import { HUNTERS_MARKS_DEFS } from 'common/battleflyMarks';
import { HUNTERS_MARK_NECTAR_PRICE } from 'common/constants';

type DefaultViewProps = {
  selectedMark: HuntersMarkType;
  handleSelect: (mark: HuntersMarkType) => void;
  handleConfirm: () => void;
  notEnoughNectar: boolean;
};

const DefaultView = (props: DefaultViewProps) => {
  const { selectedMark, handleSelect, handleConfirm, notEnoughNectar } = props;

  return (
    <>
      <section className="mb-12">
        <h1 className="text-5xl md:text-3xl">Hunter&apos;s Marks</h1>
        <p className="leading-[19px]">
          Each of these Hunter&apos;s Marks has the ability to improve your
          BattleFly&apos; ability to scavenge for that specific type of mod. You
          can purchase a Mark using Nectar - but be warned, your Mark must be
          used within 7 days after which it will expire. Also, your BattleFly
          can only be equipped with one Hunter&apos; Mark at a time, so choose
          wisely.
        </p>
      </section>
      <section className="hunters-mark-grid mb-8 justify-items-center">
        {(Object.values(HuntersMarkType) as HuntersMarkType[]).map((item) => {
          const mark = HUNTERS_MARKS_DEFS[item];
          return (
            <div
              key={item}
              className="flex cursor-pointer flex-col gap-2 text-center"
            >
              <Card
                mark
                className={classnames(
                  'h-[80px] w-[100px] !p-0 md:h-[100px] md:w-[120px]',
                  {
                    active: selectedMark === item,
                  }
                )}
                onClick={() => handleSelect(item as HuntersMarkType)}
              >
                <div className="flex h-full w-full items-center justify-center">
                  {mark.icon({
                    className: 'w-20 h-20 md:w-24 md:h-24',
                  })}
                </div>
              </Card>
              <div className="font-heading  md:text-2xl">{item}</div>
            </div>
          );
        })}
      </section>
      <section className="flex justify-center mobile:mb-12">
        <div className="w-72">
          <Button blueMint onClick={handleConfirm} disabled={notEnoughNectar}>
            Buy ({HUNTERS_MARK_NECTAR_PRICE} Nectar)
          </Button>
        </div>
      </section>
    </>
  );
};

export default DefaultView;
