import React from 'react';

import {
  BattleFly,
  BattleFlyLocation,
} from '@battlefly/__generated__/globalTypes';
import { calculateStats } from 'lib/mapper/stats';
import { BattleflyWithMetadata } from 'ts/BattleflyType';

import { Card, Link, Pill } from 'components/core';
import { LastBattlesDots } from 'components/BattleStatusCard/LastBattles';
import StarsRating from 'components/Rating';
import Loadout from 'components/Loadout';
import AlterLoadoutBlock from 'components/BattleflyCard/AlterLoadoutBlock';
import GetNewModsBlock from 'components/BattleflyCard/GetNewModsBlock';
import BattleflyLeagueIcon from 'components/BattleflyLeagueIcon';
import Chevron from 'components/Accordion/Chevron';
import { formatMoney, formatPercentage } from 'lib/utils';
import { useNavigate } from 'react-router';

type ListItemProps = {
  battlefly: BattleflyWithMetadata;
};

function getPill(battlefly: BattleFly, isUnstaked: boolean) {
  if (isUnstaked) {
    return (
      <Pill color={'#34EEA6'} classes="h-[33px] w-[94px] !px-0" selected>
        Unstaked
      </Pill>
    );
  }
  if (battlefly.location == BattleFlyLocation.Hyperdome) {
    return (
      <Pill color="#FF2079" classes="h-[33px] w-[94px] !px-0" selected>
        HyperDome
      </Pill>
    );
  }
  if (battlefly.location == BattleFlyLocation.ProvingGrounds) {
    return (
      <Pill color="#FF2079" classes="h-[33px] w-[94px] !px-0" selected>
        Proving Grounds
      </Pill>
    );
  }
  return (
    <Pill color={'#6037A3'} classes="h-[33px] w-[94px] !px-0" selected>
      Idle
    </Pill>
  );
}

const ListItem = (props: ListItemProps) => {
  const { battlefly } = props;
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const stats = calculateStats(battlefly);
  const navigate = useNavigate();

  function handleNavigate() {
    navigate(`/battleflies/view/${battlefly.id}`);
  }

  return (
    <Card className="!p-0">
      <div className="relative cursor-pointer pr-6 md:pr-0">
        <div className="flex items-center justify-between gap-2 px-2">
          <div className="relative flex h-[80px] w-[80px] items-center">
            <Link to={`/battleflies/view/${battlefly.id}`}>
              <img
                className="w-[80px]"
                src={battlefly.appearance.image}
                alt={battlefly.name}
              />
            </Link>
          </div>
          <div className="ml-[20px] flex w-[200px] flex-col justify-center">
            <div className="mb-1 font-heading text-xl leading-none md:mt-4">
              <Link to={`/battleflies/view/${battlefly.id}`}>
                {battlefly.name}
              </Link>
            </div>
            <div className="text-xs leading-none text-[#D9D9D9]">
              {battlefly.rarity}
            </div>
            <div className="mt-1 flex w-[110px] justify-start md:invisible">
              <StarsRating total={5} filled={battlefly.starsRate} />
            </div>
          </div>
          <div className="h-[50px] w-[50px] mobile:invisible">
            <BattleflyLeagueIcon
              use="default"
              league={battlefly.league}
              classes="h-full w-full"
            />
          </div>
          <div className="hidden w-[110px] justify-start md:flex">
            <StarsRating total={5} filled={battlefly.starsRate} />
          </div>
          <div className="flex w-[82px] items-center justify-center leading-none mobile:hidden">
            {battlefly.contestPoints}
          </div>
          <div className="w-[100px]">
            {getPill(battlefly, battlefly.isUnstaked)}
          </div>
          <div className="flex w-[82px] items-center justify-center leading-none mobile:hidden">
            {stats.winRate ? formatPercentage(stats.winRate) : '--'}
          </div>
          <div className="flex w-[82px] items-center justify-center leading-none mobile:hidden">
            {stats.winMagic ? formatMoney(stats.winMagic) : '--'}
          </div>
          <div className="flex w-[82px] items-center justify-center leading-none mobile:hidden">
            {stats.totalNectar
              ? formatMoney(stats.totalNectar, { digits: 0 })
              : '--'}
          </div>
          <div className="hidden min-w-[100px] gap-1 md:flex">
            <LastBattlesDots logs={battlefly.logs} black />
          </div>
        </div>
        {expanded && (
          <div className="relative flex flex-wrap items-end justify-between gap-3 md:px-8 md:pb-6">
            <div className="flex w-full items-center justify-between md:hidden">
              <div className="relative aspect-[100/100] h-[50px] px-4">
                <BattleflyLeagueIcon
                  use="default"
                  league={battlefly.league}
                  classes="w-auto h-[100%] absolute "
                />
              </div>
              <div className="items-right flex flex-col">
                <div className="mb-[10px] text-[12px] leading-none text-[#D9D9D9]">
                  Last 5 Battles Results
                </div>
                <div className="flex min-w-[100px] gap-1">
                  <LastBattlesDots logs={battlefly.logs} black />
                </div>
              </div>
            </div>
            <div className="grid w-full grid-cols-4 items-end justify-between gap-1 px-4 text-center leading-none md:hidden md:px-0">
              <div className="text-xs text-[#D9D9D9]">Ranking</div>
              <div className="text-xs text-[#D9D9D9]">
                <div>Win Rate</div>
              </div>
              <div className="text-xs text-[#D9D9D9]">
                <div>Lifetime</div>Winnings
              </div>
              <div className="text-xs text-[#D9D9D9]">
                <div>Total Nectar</div>Earned
              </div>
              <div>{battlefly.contestPoints}</div>
              <div>
                {stats.winRate ? formatPercentage(stats.winRate) : '--'}
              </div>
              <div>{stats.winMagic ? formatMoney(stats.winMagic) : '--'}</div>
              <div>
                {stats.totalNectar
                  ? formatMoney(stats.totalNectar, { digits: 0 })
                  : '--'}
              </div>
            </div>
            <Loadout
              mods={battlefly.mods}
              modClasses="max-h-[130px] mobile:max-h-[175px] mobile:h-[175px] md:h-[130px] md:w-[130px]"
              containerClasses="w-auto"
              overflow
            />
            <div className="flex flex-row gap-4 px-4 pb-4 md:px-0 md:pb-0 mobile:w-full mobile:flex-col">
              <div>
                <GetNewModsBlock
                  battleflyId={battlefly.id}
                  containerClasses="mobile:flex-row flex-col !gap-2 min-w-[220px] py-[12px] mobile:w-full"
                />
              </div>
              <div className="no-backgrounds-mobile">
                <AlterLoadoutBlock
                  battleflyId={battlefly.id}
                  containerClasses="mobile:flex-row flex-col !gap-2 min-w-[220px] py-[12px] mobile:w-full mobile:!bg-transparent mobile:shadow-none"
                />
              </div>
            </div>
            <div
              className="absolute right-2 bottom-0 flex h-[44px] -rotate-90 cursor-pointer mobile:bottom-[5px] mobile:right-[-20px] mobile:w-[44px]"
              onClick={() => setExpanded(false)}
            >
              <Chevron className="w-[10px] mobile:scale-y-150" fill="white" />
            </div>
          </div>
        )}
        {!expanded && (
          <>
            <div
              className="absolute left-0 top-0 h-full w-full mobile:hidden"
              onClick={handleNavigate}
            ></div>
            <div
              className="absolute right-0 top-0 flex h-full w-8 cursor-pointer items-center justify-center"
              onClick={() => setExpanded(true)}
            >
              <Chevron
                className="w-[10px] rotate-0 sm:rotate-90 mobile:scale-y-150 "
                fill="white"
              />
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

export default ListItem;
