import React from 'react';

const Lore: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} inline-block`}
  >
    <path
      d="M13.0674 20.9208C13.0674 21.2157 13.3064 21.4549 13.6015 21.4549H26.5608C26.8557 21.4549 27.0949 21.2158 27.0949 20.9208C27.0949 20.6259 26.8559 20.3867 26.5608 20.3867H13.6015C13.3066 20.3867 13.0674 20.6257 13.0674 20.9208Z"
      fill="white"
    />
    <path
      d="M26.5608 22.7305H13.6015C13.3066 22.7305 13.0674 22.9695 13.0674 23.2645C13.0674 23.5594 13.3064 23.7986 13.6015 23.7986H26.5608C26.8557 23.7986 27.0949 23.5596 27.0949 23.2645C27.0949 22.9697 26.8557 22.7305 26.5608 22.7305Z"
      fill="white"
    />
    <path
      d="M26.5608 25.0742H13.6015C13.3066 25.0742 13.0674 25.3132 13.0674 25.6083C13.0674 25.9032 13.3064 26.1424 13.6015 26.1424H26.5608C26.8557 26.1424 27.0949 25.9033 27.0949 25.6083C27.0949 25.3134 26.8557 25.0742 26.5608 25.0742Z"
      fill="white"
    />
    <path
      d="M28.3453 7.30664H11.817C10.8136 7.30664 10 8.12004 10 9.12363V31.8255C10 32.8289 10.8134 33.6425 11.817 33.6425H28.3451C29.3485 33.6425 30.1621 32.8291 30.1621 31.8255V9.12363C30.1621 8.12022 29.3487 7.30664 28.3453 7.30664ZM14.8063 8.97339C14.8063 8.70364 15.025 8.48488 15.2948 8.48488H24.8679C25.1376 8.48488 25.3564 8.70364 25.3564 8.97339C25.3564 9.24314 25.1378 9.4619 24.8679 9.4619H15.2948C15.0248 9.4619 14.8063 9.24314 14.8063 8.97339ZM25.356 31.1282C25.356 31.3979 25.1374 31.6167 24.8675 31.6167H15.2944C15.0247 31.6167 14.8059 31.3979 14.8059 31.1282C14.8059 30.8584 15.0247 30.6397 15.2944 30.6397H24.8675C25.1374 30.6399 25.356 30.8584 25.356 31.1282ZM28.5601 28.8363H11.6022V10.628H28.5602V28.8363H28.5601Z"
      fill="white"
    />
    <path
      d="M13.9258 14.1024C13.9305 14.107 13.9339 14.1126 13.9357 14.1187L14.2078 15.0634C14.2238 15.1189 14.2655 15.1644 14.3194 15.185L15.1404 15.5012L14.643 15.9914C14.6036 16.0303 14.5837 16.0843 14.5884 16.1395L14.7101 17.5421C14.7152 17.5994 14.7455 17.6502 14.7936 17.6815L15.9306 18.4214C15.9612 18.4413 15.9964 18.4515 16.0319 18.4515C16.0547 18.4515 16.0776 18.4473 16.0995 18.4386L17.7823 17.7785C17.7908 17.7753 17.8002 17.7753 17.8087 17.7785L19.4833 18.4354C19.5389 18.4572 19.602 18.4507 19.6521 18.4182L20.7891 17.6782C20.8372 17.6469 20.8678 17.5961 20.8727 17.5388L20.9943 16.1362C20.999 16.0811 20.9792 16.027 20.9397 15.9881L20.4424 15.498L21.2634 15.1818C21.3172 15.161 21.359 15.1156 21.3749 15.0601L21.647 14.1155C21.6488 14.1093 21.6523 14.1037 21.657 14.0992L22.4908 13.3095C22.5351 13.2675 22.5559 13.2062 22.5465 13.1458L22.3876 12.1399C22.3792 12.0871 22.3485 12.0405 22.3033 12.0119C22.2581 11.9833 22.2028 11.9759 22.1514 11.9911L20.0298 12.6239C20.0181 12.6273 20.0076 12.6337 19.9989 12.6422L19.9953 12.6458L17.8257 14.509L15.5877 12.6492L15.5841 12.6456C15.5754 12.6371 15.5647 12.6308 15.5531 12.6273L13.4315 11.9945C13.3802 11.9792 13.3248 11.9868 13.2796 12.0153C13.2344 12.0439 13.2037 12.0905 13.1954 12.1433L13.0365 13.1495C13.0269 13.2098 13.0477 13.271 13.0922 13.3131L13.9258 14.1024Z"
      fill="white"
    />
  </svg>
);

export default Lore;
