import React from 'react';

import { FullScreenCard } from 'components/Layout';
import { StatisticsPage } from 'components/pages';
import { useStatisticsData, useUserStatisticsData } from 'hooks/arenas';

const LeaderBoardRoute = () => {
  const [filter, setFilter] = React.useState<'user' | 'global'>('global');
  const { data: statisticsData, loading: isStatisticsLoading } =
    useStatisticsData();
  const { data: userStatisticsData, loading: isUserStatisticsLoading } =
    useUserStatisticsData();
  const statistics = filter === 'user' ? userStatisticsData : statisticsData;
  const loading = isStatisticsLoading || isUserStatisticsLoading;

  return (
    <FullScreenCard>
      <StatisticsPage
        loading={loading}
        filter={filter}
        setFilter={setFilter}
        data={statistics}
      />
    </FullScreenCard>
  );
};

export default LeaderBoardRoute;
