import React from 'react';

import { Card, Modal, Button } from 'components/core';
import useCheckTermsAndConditions from 'hooks/useCheckTermsAndConditions';
import { ModalContext } from 'context/modalContext';

const TermsAndConditionsModal = () => {
  const [isChecked, setIsChecked] = React.useState(false);
  const { saveTermsAndConditionsResult } = useCheckTermsAndConditions();
  const { setShowTermsModal } = React.useContext(ModalContext);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsChecked(e.target.checked);
  }

  function handleCancel() {
    setShowTermsModal(false);
  }

  function handleSubmit() {
    saveTermsAndConditionsResult(true, isChecked);
    setShowTermsModal(false);
  }
  return (
    <Modal isOpen={true}>
      <Card>
        <div className="w-[100%] max-w-[100%] md:w-[600px] xl:w-[800px]">
          <header className="mb-4">
            <div className="font-heading text-4xl">Terms & Conditions</div>
          </header>
          <section className="mb-4 bg-white p-4 leading-[19px] text-[#000]">
            <p className="mb-4">
              Crash logs and statistical information related to your use of the
              game will automatically be provided to BattleFly to be used to
              improve performance
            </p>
            <p className="mb-4">
              You acknowledge that you understand that BattleFly is an
              experimental GameFi project that will be updated frequently,
              without notice, and could contain errors, bugs, or inaccuracies
              that produce anomalies that may be corrected subsequently.
            </p>
            <p className="mb-4">
              The content and in-game values can and may be changed without
              notice
            </p>
            <p className="mb-4">
              You acknowledge the following related to risk of personal funds:
              <ul className="ml-8 list-disc">
                <li>
                  BattleFly is a risk based game of skill where users have their
                  personal funds at risk.
                </li>
                <li>
                  Every time a battle takes place, the user has 0.1 $Magic at risk
                  to be won or lost
                </li>
                <li>
                  BattleFly takes a 5% transaction fee per Battle reward payout.
                </li>
                <li>
                  Users can set the number of battles their BattleFly competes
                  in per day. The BattleFly will CONTINUE battling this many
                  times per day UNLESS the user removes said BattleFly from an
                  arena.
                </li>
              </ul>
            </p>
            <p className="mb-4">
              While BattleFly does not intend to, BattleFly reserves the right
              to reset all facets of the game during this initial feedback
              phase. This includes but is not limited to: player ranks,
              BattleFly&apos;s mods, and performance data.
            </p>
          </section>
          <footer>
            <div className="flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
              <div>
                <input
                  name="remember"
                  type="checkbox"
                  value={isChecked as any}
                  className="checkbox-round !h-6 !w-6 after:!text-[0.9rem]"
                  onChange={handleChange}
                />
                <label htmlFor="remember" className="ml-2">
                  Don&apos;t show this message again for 30 days
                </label>
              </div>
              <div className="flex items-center gap-4 mobile:self-end">
                <div
                  onClick={handleCancel}
                  className="cursor-pointer font-heading text-xl underline"
                >
                  Cancel
                </div>
                <div className="w-[180px]">
                  <Button onClick={handleSubmit} blueMint>
                    I agree
                  </Button>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </Card>
    </Modal>
  );
};

export default TermsAndConditionsModal;
