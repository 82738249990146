import React from 'react';
import { useMutation } from '@apollo/client';

import { MOVE_MOD } from '@battlefly/graphql/battleflyGql';
import { BattleFly } from '@battlefly/__generated__/globalTypes';
import useBattleflyDetails from '@battlefly/hooks/components/useBattleflyDetails';
import LoadoutPage from '@battlefly/components/LoadoutPage';
import { ensureInventorySize } from '@battlefly/util/battleflyInventory';
import { ModalContext } from '@battlefly/context/modalContext';

const LoadoutRoute = () => {
  const { data, loading, refetch } = useBattleflyDetails();
  const battlefly: BattleFly = data?.getBattleFly?.battleFly;
  const [moveMod, { loading: isSubmitting }] = useMutation(MOVE_MOD);
  const { setConfirmModalState } = React.useContext(ModalContext);

  async function handleSubmit(inventory: number[], loadout: number[]) {
    try {
      await moveMod({
        variables: {
          battleFlyId: battlefly.id,
          inventory: ensureInventorySize(inventory, battlefly.slotsAvailable),
          loadout,
        },
      });
      refetch();
      setConfirmModalState({
        type: 'success',
        title: 'Woo Hoo!',
        description: 'Your request has been actioned successfully',
        onConfirm: () => setConfirmModalState(null),
        confirmText: 'Continue',
      });
    } catch (e) {
      setConfirmModalState({
        type: 'error',
        title: 'Oops!',
        description: 'Something went wrong',
        onConfirm: () => setConfirmModalState(null),
        confirmText: 'Continue',
      });
    }
  }

  return (
    <div className="w-full">
      {loading && <div>Loading...</div>}
      {battlefly && (
        <LoadoutPage
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          battlefly={battlefly}
        />
      )}
    </div>
  );
};

export default LoadoutRoute;
