import React from 'react';
import { useMutation } from '@apollo/client';

import { WITHDRAW_MAGIC } from '@battlefly/graphql/battleflyGql';
import { Card, Button } from 'components/core';
import { MagicIcon, NectarIcon } from 'components/Icons';
import { formatMoney } from 'lib/utils';
import { BalanceItem } from './helpers';
import BalanceInput from './BalanceInput';
import {
  CustomToast,
  ToastStatus,
} from '@battlefly/components/CustomToast/CustomToast';

type WithdrawTabProps = {
  className?: string;
  magicBalance: number;
  nectarBalance: number;
  withdrawMagic: (obj: any) => Promise<void>;
  isWithdrawing: boolean;
  userAddress: string;
};

// TODO: clean up handler functions
const WithdrawTab = (props: WithdrawTabProps) => {
  const { magicBalance, withdrawMagic, nectarBalance, isWithdrawing } = props;
  const [getWithdrawMagicSign] = useMutation<any, { magic: number }>(
    WITHDRAW_MAGIC
  );

  const [inputVal, setInputVal] = React.useState(0);

  const handleWithdrawClick = React.useCallback(async () => {
    if (magicBalance < inputVal) return;
    try {
      const withdrawSignRes = await getWithdrawMagicSign({
        variables: { magic: inputVal },
      });
      const { r, s, v, expiry, transactionId } =
        withdrawSignRes.data.withdrawMagic;
      await withdrawMagic({
        amount: inputVal,
        transactionId,
        expiry,
        r,
        s,
        v,
      });
    } catch (e) {
      CustomToast({
        message: e.message,
        status: ToastStatus.FAILURE,
      });
    } finally {
      setInputVal(0);
    }
  }, [inputVal, magicBalance, getWithdrawMagicSign, withdrawMagic]);

  return (
    <div className="mb-4 flex w-full flex-col gap-6">
      <section>
        <h5 className="font-heading text-lg">staked Balance</h5>
        <Card darkBlue className="!p-6">
          <div className="flex gap-6">
            <BalanceItem className="w-1/2">
              <MagicIcon />
              <span className="font-heading text-xl">
                {formatMoney(magicBalance)}
              </span>
              <span className="text-[11px] text-core-grey">$Magic</span>
            </BalanceItem>
            <BalanceItem className="w-1/2">
              <NectarIcon />
              <span className="font-heading text-xl">{nectarBalance}</span>
              <span className="text-[11px] text-core-grey">Nectar</span>
            </BalanceItem>
          </div>
        </Card>
      </section>
      <section>
        <div className="pb-8 leading-[19px]">
          <p className="">Want to withdraw $Magic from the game?</p>
          <p className="mb-8 text-xs font-light">
            Nominate how much $Magic you would like to withdraw.
          </p>
          <BalanceInput
            value={inputVal}
            maxValue={magicBalance}
            onChange={setInputVal}
          />
        </div>
        <div className="flex justify-end gap-4">
          <div className="w-1/2">
            <Button
              blueMint
              isLoading={isWithdrawing}
              disabled={isNaN(inputVal)}
              onClick={handleWithdrawClick}
            >
              Withdraw $Magic
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WithdrawTab;
