import React from 'react';
import {ModWeaponBgRectangleProps} from "@battlefly/components/Icons/Mods/ModWeaponBgRectangle";
import {Rarity} from "@battlefly/__generated__/globalTypes";

const ModUtilityBgRectangle: React.FC<ModWeaponBgRectangleProps> = (props) => {
  return (
    <svg
      width="236"
      height="79"
      viewBox="0 0 236 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="1">
        <path
          d="M37.5067 0H30.4883V37.0541H37.5067V0Z"
          fill={props.fillColor}
        />
        <path
          d="M52.7513 0H45.7329V37.0541H52.7513V0Z"
          fill={props.fillColor}
        />
        <path
          d="M67.9198 0H60.9014V37.0541H67.9198V0Z"
          fill={props.fillColor}
        />
        <path
          d="M83.0887 0H76.0703V37.0541H83.0887V0Z"
          fill={props.fillColor}
        />
        <path
          d="M98.3328 0H91.3145V37.0541H98.3328V0Z"
          fill={props.fillColor}
        />
        <path
          d="M113.502 0H106.483V37.0541H113.502V0Z"
          fill={props.fillColor}
        />
        <path
          d="M128.746 0H121.728V37.0541H128.746V0Z"
          fill={props.fillColor}
        />
        <path
          d="M143.915 0H136.896V37.0541H143.915V0Z"
          fill={props.fillColor}
        />
        <path
          d="M159.084 0H152.065V37.0541H159.084V0Z"
          fill={props.fillColor}
        />
        <path d="M174.328 0H167.31V37.0541H174.328V0Z" fill={props.fillColor} />
        <path
          d="M189.497 0H182.479V37.0541H189.497V0Z"
          fill={props.fillColor}
        />
        <path
          d="M204.741 0H197.723V37.0541H204.741V0Z"
          fill={props.fillColor}
        />
        <path d="M219.91 0H212.892V37.0541H219.91V0Z" fill={props.fillColor} />
        <path d="M235.078 0H228.06V37.0541H235.078V0Z" fill={props.fillColor} />
        <path
          d="M7.39545 72.7498L6.03711 71.4669L29.8846 47.6194H235.154V49.4306H30.7147L7.39545 72.7498Z"
          fill={props.fillColor}
        />
        <path
          d="M5.28267 78.4098C8.2002 78.4098 10.5653 76.0447 10.5653 73.1271C10.5653 70.2096 8.2002 67.8445 5.28267 67.8445C2.36513 67.8445 0 70.2096 0 73.1271C0 76.0447 2.36513 78.4098 5.28267 78.4098Z"
          fill={props.fillColor}
        />
        <path
          d="M23.1681 65.2031L22.8662 64.9012L33.8843 53.8076H140.443V54.3359H34.1108L23.1681 65.2031Z"
          fill={props.fillColor}
        />
        <path
          d="M152.216 61.2788H126.633V60.826H151.99L159.008 53.8076H220.891V54.3359H159.159L152.216 61.2788Z"
          fill={props.fillColor}
        />
      </g>
    </svg>
  );
};

export default ModUtilityBgRectangle;
