import Button from '@battlefly/components/Buttons/Button';

const MaxMintCountTab = ({ maxMint }: { maxMint: number }) => {
  return (
    <div className="flex w-full items-center justify-between gap-6 py-2 pb-2 text-sm">
      <Button
        onClick={() => false}
        type=""
        className="flex items-center rounded-md rounded-lg border-2 border-button-default bg-core-black"
      >
        <img src="/assets/icons/information.svg" className="pr-4" />

        <div className="flex w-3/4 justify-center">
          <a className="flex justify-center font-bold">
            YOU HAVE {maxMint === undefined ? 0 : maxMint} COMIC(S) TO CLAIM
          </a>
        </div>
      </Button>
    </div>
  );
};

export default MaxMintCountTab;
