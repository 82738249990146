import { useAccount, useContractRead } from 'wagmi';

import { CONTRACT_ABIS } from 'common/constants';
import { BattleflyGame } from 'common/constants';
import {
  useMakeContractWrite,
  useMakePrepareContractWrite,
} from 'hooks/contractsNew/utils';
import { useContractAddresses } from './useContractAddresses';

export const useApproveTreasures = () => {
  const { address } = useAccount();
  const { isContractApproved, refetch } = useCheckTreasureApproval(address);
  const contractAddresses = useContractAddresses();
  const operatorAddress =
    contractAddresses[BattleflyGame.BattleflyCreditsContract];

  const { config } = useMakePrepareContractWrite(
    {
      functionName: 'setApprovalForAll',
      enabled: !!address,
      args: [operatorAddress, true],
    },
    BattleflyGame.BridgeworldTreasuresContract
  );
  return {
    isContractApproved,
    ...useMakeContractWrite({
      ...config,
      onSuccess() {
        refetch();
      },
    }),
  };
};

const useCheckTreasureApproval = (address: string) => {
  const contractAddresses = useContractAddresses();
  const operatorAddress =
    contractAddresses[BattleflyGame.BattleflyCreditsContract];
  const { data, isError, isLoading, refetch } = useContractRead({
    address: contractAddresses[BattleflyGame.BridgeworldTreasuresContract],
    abi: CONTRACT_ABIS[BattleflyGame.BridgeworldTreasuresContract],
    functionName: 'isApprovedForAll',
    args: [address, operatorAddress],
    watch: false,
  });

  return { isContractApproved: data, isError, isLoading, refetch };
};
