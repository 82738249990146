import React, { ComponentProps, FC } from 'react';

// function used to combine all contexts into one single provider, thus eliminating the need to wrap multiple contexts in _app.tsx
export const combineComponents = (
  ...components: FC<React.PropsWithChildren<unknown>>[]
): FC<React.PropsWithChildren<unknown>> => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      // eslint-disable-next-line react/display-name
      return ({
        children,
      }: ComponentProps<FC<React.PropsWithChildren<unknown>>>): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>
  );
};
