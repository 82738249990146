import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';
import { pdfjs } from 'react-pdf';
import workerSrc from '../../pdf-worker';

const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
};

const backdrop = {
  visible: { opacity: 1 },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.5,
      delay: 0,
    },
  },
};

pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

const ComicModalWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        variants={dropIn}
        animate="visible"
        initial="hidden"
        exit="exit"
        className="bg-modalBg fixed top-0 left-0 z-30 mx-auto flex h-full w-full items-center justify-center backdrop-blur-sm"
      >
        <motion.div
          variants={backdrop}
          initial="hidden"
          animate="visible"
          className={` mx-auto h-[90%] w-full overflow-hidden rounded border border-primary bg-black`}
        >
          {children}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ComicModalWrapper;
