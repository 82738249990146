import { Card, Image, Pill } from 'components/core';

import Wireframe from 'public/assets/videos/cocoon/wireframe.png';

const CocoonListItem = () => {
  return (
    <Card className="relative h-[80px] !px-2 shadow-[inset_0px_0px_15px_2px_rgba(0,0,0,0.6)] md:p-0 mobile:px-[14px] mobile:py-[14px]">
      <div className="flex h-full flex-row items-center gap-4 overflow-hidden">
        <div className="w-24">
          <Image src={Wireframe.src} width={100} height={100} />
        </div>
        <div className="w-[200px] text-center">--</div>
        <div className="w-[50px] text-center">--</div>
        <div className="w-[110px] text-center">--</div>
        <div className="w-[82px] text-center">--</div>
        <div className="w-[100px]">
          <Pill color={'#FFA800'} classes="h-[33px] w-[94px] !px-0" selected>
            Cocoon
          </Pill>
        </div>
        <div className="w-[82px] text-center mobile:hidden">{'--'}</div>
        <div className="w-[82px] text-center mobile:hidden">{'--'}</div>
        <div className="w-[82px] text-center mobile:hidden">{'--'}</div>
      </div>
    </Card>
  );
};

export default CocoonListItem;
