import classnames from 'classnames';

import { Button } from 'components/core';
import { CloseIcon } from 'components/Icons';

type ActionPanelProps = {
  handleSelect: () => void;
  handleUpgrade: () => void;
  handleClose: () => void;
  className?: string;
  isUpgradingRarity: boolean;
  upgradeDisabled?: boolean;
};

const ActionPanel = (props: ActionPanelProps) => {
  const {
    handleSelect,
    handleUpgrade,
    handleClose,
    className,
    isUpgradingRarity,
    upgradeDisabled,
  } = props;
  return (
    <div
      className={classnames('h-[180px] rounded-b-xl bg-[#191623]', className)}
    >
      <ul className="flex flex-col gap-3 p-4">
        <li>
          <Button light lightGray type="button" onClick={handleSelect}>
            Keep Me
          </Button>
        </li>
        <li>
          <Button
            light
            lightGray
            type="button"
            disabled={isUpgradingRarity || upgradeDisabled}
            onClick={handleUpgrade}
            isLoading={isUpgradingRarity}
          >
            Upgrade Rarity
          </Button>
        </li>
        <li className="flex items-center justify-center">
          <div
            onClick={handleClose}
            className="flex h-12 w-12 cursor-pointer items-center justify-center"
          >
            <CloseIcon className="h-8 w-8" fill="#fff" />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ActionPanel;
