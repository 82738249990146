import classnames from 'classnames';
import dayjs from 'dayjs';

export const NameAndRarity = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => <div className={classnames('w-[162px]', className)}>{children}</div>;

export const Name = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={classnames('font-heading text-[21px] leading-[26px]', className)}
  >
    {children}
  </div>
);

export const Ranking = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={classnames('min-w-[40px] text-center leading-[19px]', className)}
  >
    {children}
  </div>
);

export const Rarity = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={classnames('text-xs leading-none text-core-grey', className)}>
    {children}
  </div>
);

export const DateTime = ({
  date,
  className,
}: {
  date: string;
  className?: string;
}) => (
  <div className={classnames('text-xs text-core-grey', className)}>
    <div>{dayjs(date).format('DD.MM.YY')}</div>
    <div>{dayjs(date).format('hh:mm A')}</div>
  </div>
);
