export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type ApplyHuntersMarkInput = {
  battleFlyId: Scalars['ID'];
  markType: HuntersMarkType;
};

export type ApplyScavengingMarkInput = {
  battleFlyId: Scalars['ID'];
  markType: ScavengingMarkType;
};

export type Attributes = {
  __typename?: 'Attributes';
  classes?: Maybe<Array<Scalars['String']>>;
  editions?: Maybe<Array<Scalars['String']>>;
  types?: Maybe<Array<Scalars['String']>>;
};

export type BattleFliesInput = {
  battleFlyIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BattleFliesRevealWithdrawal = {
  __typename?: 'BattleFliesRevealWithdrawal';
  battleflyIds: Array<Scalars['Int']>;
  signature: Signature;
  stagesIds: Array<Scalars['Int']>;
};

export type BattleFliesWithdrawal = {
  __typename?: 'BattleFliesWithdrawal';
  r: Scalars['String'];
  s: Scalars['String'];
  transactionId: Scalars['String'];
  v: Scalars['Int'];
};

export type BattleFly = {
  __typename?: 'BattleFly';
  age: Scalars['Int'];
  appearance: BattleFlyAppearance;
  characteristics: Scalars['JSON'];
  class?: Maybe<BattleFlyClass>;
  combatHistory: Array<Combat>;
  combatHistoryPaginated: PaginatedCombatLog;
  contestPoints: Scalars['Float'];
  dailyRaces: DailyRaces;
  defense: Array<Mod>;
  edition: Scalars['String'];
  experience: Scalars['Int'];
  huntersMark?: Maybe<BattleFlyHuntersMark>;
  hyperdomeCombatRate: Scalars['Int'];
  hyperdomeLosses: Scalars['Int'];
  hyperdomeWins: Scalars['Int'];
  id: Scalars['ID'];
  inventory: Array<Mod>;
  lastHyperdomeCombatLost?: Maybe<Combat>;
  lastProvingGroundsCombatLost?: Maybe<Combat>;
  league: BattleFlyLeague;
  leagueTier: Scalars['Float'];
  level?: Maybe<Scalars['Int']>;
  location: BattleFlyLocation;
  modPacks: Array<ModPack>;
  mods: Array<Mod>;
  name: Scalars['String'];
  owner?: Maybe<Identity>;
  placeInGlobalLeaderboard: Scalars['Float'];
  provingGroundsCombatRate: Scalars['Int'];
  provingGroundsLosses: Scalars['Int'];
  provingGroundsWins: Scalars['Int'];
  rarity?: Maybe<Rarity>;
  scavengingMark?: Maybe<BattleFlyScavengingMark>;
  slotsAvailable: Scalars['Int'];
  stage: Scalars['String'];
  starsRate: Scalars['Float'];
  stats: Scalars['JSON'];
  tattoos: Array<Tattoo>;
  traits?: Maybe<Array<Trait>>;
  type?: Maybe<BattleFlyType>;
  utility: Array<Mod>;
  weapons: Array<Mod>;
};


export type BattleFlyCombatHistoryPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  returnTotalCount?: InputMaybe<Scalars['Boolean']>;
};

export type BattleFlyAppearance = {
  __typename?: 'BattleFlyAppearance';
  bodyColor?: Maybe<Array<Scalars['String']>>;
  bodyPattern?: Maybe<Scalars['Int']>;
  effect?: Maybe<Scalars['String']>;
  headStyle?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  stickers?: Maybe<Array<Scalars['String']>>;
  texture?: Maybe<Scalars['String']>;
};

export enum BattleFlyClass {
  Edition = 'Edition',
  Original = 'Original'
}

export type BattleFlyHuntersMark = {
  __typename?: 'BattleFlyHuntersMark';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  type: HuntersMarkType;
  willExpireAt: Scalars['DateTime'];
};

export type BattleFlyInput = {
  battleFlyId?: InputMaybe<Scalars['ID']>;
};

export enum BattleFlyLeague {
  Apex = 'Apex',
  Larvae = 'Larvae',
  Monarch = 'Monarch',
  Predator = 'Predator',
  Pupa = 'Pupa'
}

export enum BattleFlyLocation {
  Hyperdome = 'hyperdome',
  MissionControl = 'mission_control',
  ProvingGrounds = 'proving_grounds'
}

export type BattleFlyRaceHistoryInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  onDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type BattleFlyScavengingMark = {
  __typename?: 'BattleFlyScavengingMark';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  type: ScavengingMarkType;
  willExpireAt: Scalars['DateTime'];
};

export enum BattleFlyType {
  Edition = 'Edition',
  Genesis = 'Genesis'
}

export type Cocoon = {
  __typename?: 'Cocoon';
  appearance: BattleFlyAppearance;
  edition: Scalars['String'];
  id: Scalars['ID'];
  owner: Identity;
};

export type CocoonsInput = {
  cocoonIds: Array<Scalars['ID']>;
};

export type Combat = {
  __typename?: 'Combat';
  battleFlyLooser: BattleFly;
  battleFlyWinner: BattleFly;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  location: Scalars['String'];
  log: CombatLog;
  loserContestPoints?: Maybe<Scalars['Int']>;
  matchTimestamp: Scalars['DateTime'];
  winnerContestPoints?: Maybe<Scalars['Int']>;
};

export type CombatLog = {
  __typename?: 'CombatLog';
  eventLog: Array<Scalars['JSON']>;
  participants: Array<LogParticipant>;
  textLog: Array<TextLogRecord>;
};

export type CombatLogEdge = {
  __typename?: 'CombatLogEdge';
  cursor: Scalars['String'];
  node: Combat;
};

export type ConnectInput = {
  address: Scalars['String'];
  signature: Scalars['String'];
};

export type Credit = {
  __typename?: 'Credit';
  quantity: Scalars['Int'];
  type: CreditType;
  viewed: Scalars['Boolean'];
};

/** The type of credit */
export enum CreditType {
  /** Credit for battles */
  Battle = 'battle',
  Inventoryslot = 'inventoryslot',
  /** Credit for modpacks */
  Modpack = 'modpack',
  /** Credit for mod upgrades */
  Modupgrade = 'modupgrade',
  /** Credit for nectar */
  Nectar = 'nectar',
  /** Credit for modpack-rerolls */
  Reroll = 'reroll'
}

export type DailyRace = {
  __typename?: 'DailyRace';
  createdAt: Scalars['DateTime'];
  droplets: Scalars['Int'];
  leaderBoard: Array<LeaderBoardRecord>;
  score: Scalars['Int'];
  seed: Scalars['Int'];
};


export type DailyRaceDropletsArgs = {
  input?: InputMaybe<BattleFlyInput>;
};


export type DailyRaceLeaderBoardArgs = {
  input?: InputMaybe<LeaderBoardDateInput>;
};


export type DailyRaceScoreArgs = {
  input?: InputMaybe<BattleFlyInput>;
};

export type DailyRaces = {
  __typename?: 'DailyRaces';
  canRaceAgainToday: Scalars['Boolean'];
  dailyRacesMaxAllowed: Scalars['Int'];
  dailyRacesUsed: Scalars['Int'];
  history: Array<DailyRace>;
};


export type DailyRacesHistoryArgs = {
  input?: InputMaybe<BattleFlyRaceHistoryInput>;
};

export type DefenseBlueprint = {
  __typename?: 'DefenseBlueprint';
  armor: Scalars['Float'];
  evasion: Scalars['Float'];
  shield: Scalars['Float'];
};

export type EndRaceArgs = {
  avatar: Scalars['String'];
  battleFlyTokenId: Scalars['Int'];
  nickName: Scalars['String'];
  numNectarDroplets: Scalars['Int'];
  score: Scalars['Int'];
  signature: Scalars['String'];
  userWalletAddress: Scalars['String'];
};

export type EndRaceResult = {
  __typename?: 'EndRaceResult';
  battleFly: BattleFly;
  race: DailyRace;
  walletAddress: Scalars['String'];
};

export type GenericBattleFly = {
  __typename?: 'GenericBattleFly';
  battleFly?: Maybe<BattleFly>;
  cocoon?: Maybe<Cocoon>;
  id: Scalars['ID'];
  stage: Scalars['String'];
};

export type GlobalStatistics = {
  __typename?: 'GlobalStatistics';
  averageDailyBattles: Scalars['Float'];
  id: Scalars['ID'];
  top: GlobalTop;
  totalBattleFlies: Scalars['Float'];
  totalBattlesInDay: Scalars['Float'];
  totalMagicWonInDay: Scalars['Float'];
};

export type GlobalTop = {
  __typename?: 'GlobalTop';
  topBattleFly?: Maybe<BattleFly>;
  topBattleFlyMagicWin?: Maybe<Scalars['Float']>;
};

export type GoToHyperdomeInput = {
  battleFlyIds: Array<Scalars['Int']>;
  combatRate: Scalars['Int'];
};

export enum HuntersMarkType {
  Ammunition = 'Ammunition',
  Armour = 'Armour',
  Blackproject = 'Blackproject',
  Cybernetics = 'Cybernetics',
  Electric = 'Electric',
  Energy = 'Energy',
  Fusion = 'Fusion',
  Kinetic = 'Kinetic',
  Missile = 'Missile',
  Shield = 'Shield'
}

export type Identity = {
  __typename?: 'Identity';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  user: User;
};

export type InputId = {
  id: Scalars['ID'];
};

export type LeaderBoardDateInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  maxRecordsCount?: InputMaybe<Scalars['Int']>;
  onDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type LeaderBoardRecord = {
  __typename?: 'LeaderBoardRecord';
  avatar: Scalars['String'];
  battleFly: BattleFly;
  createdAt: Scalars['DateTime'];
  droplets: Scalars['Int'];
  nectar: Scalars['Int'];
  nickName: Scalars['String'];
  score: Scalars['Int'];
};

export type LeaderboardItem = {
  __typename?: 'LeaderboardItem';
  avatar?: Maybe<Scalars['String']>;
  earnedMagic: Scalars['Float'];
  earnedNectar: Scalars['Float'];
  nickName: Scalars['String'];
};

export type League = {
  __typename?: 'League';
  name: BattleFlyLeague;
  sharedMagic: Scalars['String'];
  topBattleFliesAmount: Scalars['Int'];
};

export type LeagueTier = {
  __typename?: 'LeagueTier';
  league: BattleFlyLeague;
  sharedMagic: Scalars['String'];
  tier: Scalars['Int'];
  topBattleFliesAmount: Scalars['Int'];
};

export type LeagueTierEmissionsInput = {
  league: BattleFlyLeague;
  tier: Array<Scalars['Int']>;
};

export type LeaveHyperdomeInput = {
  battleFlyIds: Array<Scalars['Int']>;
};

export type LeaveProvingGroundsInput = {
  battleFlyIds: Array<Scalars['Int']>;
};

export type LogModInfo = {
  __typename?: 'LogModInfo';
  blueprint: ModBlueprint;
  id: Scalars['Int'];
  name: Scalars['String'];
  stats: Scalars['JSON'];
};

export type LogParticipant = {
  __typename?: 'LogParticipant';
  arm?: Maybe<Scalars['Float']>;
  bat?: Maybe<Scalars['Float']>;
  battleFly: BattleFly;
  battleFlyId?: Maybe<Scalars['Int']>;
  cpu?: Maybe<Scalars['Float']>;
  crit?: Maybe<Scalars['Float']>;
  dcrit?: Maybe<Scalars['Float']>;
  defense: Array<LogModInfo>;
  engines?: Maybe<Scalars['Float']>;
  eva?: Maybe<Scalars['Float']>;
  fusionBattery?: Maybe<Scalars['Float']>;
  hp?: Maybe<Scalars['Float']>;
  hprg?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  loot?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  nanoFrame?: Maybe<Scalars['Float']>;
  rcrit?: Maybe<Scalars['Float']>;
  sensorsArray?: Maybe<Scalars['Float']>;
  shp?: Maybe<Scalars['Float']>;
  shrg?: Maybe<Scalars['Float']>;
  utilities: Array<LogModInfo>;
  weapons: Array<LogModInfo>;
};

export type MagicWithdrawal = {
  __typename?: 'MagicWithdrawal';
  expiry: Scalars['Int'];
  r: Scalars['String'];
  s: Scalars['String'];
  transactionId: Scalars['Int'];
  v: Scalars['Int'];
};

export type Mod = {
  __typename?: 'Mod';
  battleFly: BattleFly;
  blueprint: ModBlueprint;
  damageType?: Maybe<Scalars['String']>;
  effects: Scalars['JSON'];
  id: Scalars['ID'];
  name: Scalars['String'];
  rarity: Scalars['String'];
  slot: Scalars['Float'];
  stats: Scalars['JSON'];
  type: Scalars['String'];
};

export type ModAppearance = {
  __typename?: 'ModAppearance';
  image?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  model: Scalars['String'];
};

export type ModBlueprint = {
  __typename?: 'ModBlueprint';
  appearance: ModAppearance;
  category: Scalars['String'];
  defense?: Maybe<DefenseBlueprint>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  rarity: Rarity;
  type: ModType;
  weapon?: Maybe<WeaponBlueprint>;
};

export type ModBlueprintInModPack = {
  __typename?: 'ModBlueprintInModPack';
  appearance: ModAppearance;
  category: Scalars['String'];
  defense?: Maybe<DefenseBlueprint>;
  description: Scalars['String'];
  id: Scalars['ID'];
  isPicked: Scalars['Boolean'];
  isUpgraded: Scalars['Boolean'];
  name: Scalars['String'];
  rarity: Rarity;
  type: ModType;
  weapon?: Maybe<WeaponBlueprint>;
};

export type ModPack = {
  __typename?: 'ModPack';
  id: Scalars['String'];
  isRecycled: Scalars['Boolean'];
  modBlueprints: Array<ModBlueprintInModPack>;
  modPackCategory: Scalars['String'];
  primaryModBlueprintId?: Maybe<Scalars['String']>;
  rerolled: Scalars['Int'];
};

export type ModPackCreationInput = {
  battleflyId: Scalars['ID'];
  category: Scalars['String'];
};

export type ModPackInput = {
  modPackId: Scalars['ID'];
};

export type ModPackRerollInput = {
  modPackId: Scalars['ID'];
};

export enum ModType {
  Defense = 'defense',
  Utility = 'utility',
  Weapon = 'weapon'
}

export type MoveModsInput = {
  battleFlyId: Scalars['ID'];
  inventory: Array<InputMaybe<Scalars['Int']>>;
  loadout: Array<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  applyHuntersMark: BattleFlyHuntersMark;
  applyScavengingMark: BattleFlyScavengingMark;
  connect: TokenPair;
  createModPack: ModPack;
  disconnect: Identity;
  endRace: EndRaceResult;
  goToHyperdome: Array<BattleFly>;
  goToProvingGrounds: Array<BattleFly>;
  leaveHyperdome: Array<BattleFly>;
  leaveProvingGrounds: Array<BattleFly>;
  pickModFromModPack: BattleFly;
  recalculateStats: BattleFly;
  recycleModPack: ModPack;
  refreshTokens: TokenPair;
  rerollModPack: ModPack;
  reveal: Array<Cocoon>;
  setHyperdomeCombatRate: Array<BattleFly>;
  setProvingGroundsCombatRate: Array<BattleFly>;
  syncEvents: Scalars['Boolean'];
  syncNotionMods: Scalars['Boolean'];
  syncNotionTraits: Scalars['Boolean'];
  syncRarityModColors: Scalars['Boolean'];
  updateInventory: BattleFly;
  upgradeModInModPack: ModPack;
  withdrawBattleFlies: BattleFliesWithdrawal;
  withdrawMagic: MagicWithdrawal;
  withdrawRevealedBattleFlies: BattleFliesRevealWithdrawal;
};


export type MutationApplyHuntersMarkArgs = {
  input: ApplyHuntersMarkInput;
};


export type MutationApplyScavengingMarkArgs = {
  input: ApplyScavengingMarkInput;
};


export type MutationConnectArgs = {
  input: ConnectInput;
};


export type MutationCreateModPackArgs = {
  input: ModPackCreationInput;
};


export type MutationEndRaceArgs = {
  input: EndRaceArgs;
};


export type MutationGoToHyperdomeArgs = {
  input: GoToHyperdomeInput;
};


export type MutationGoToProvingGroundsArgs = {
  input: ProvingGroundsInput;
};


export type MutationLeaveHyperdomeArgs = {
  input: LeaveHyperdomeInput;
};


export type MutationLeaveProvingGroundsArgs = {
  input: LeaveProvingGroundsInput;
};


export type MutationPickModFromModPackArgs = {
  input: PickModFromModPackInput;
};


export type MutationRecalculateStatsArgs = {
  input: BattleFlyInput;
};


export type MutationRecycleModPackArgs = {
  input: RecycleModPackInput;
};


export type MutationRefreshTokensArgs = {
  input: RefreshTokenInput;
};


export type MutationRerollModPackArgs = {
  input: ModPackRerollInput;
};


export type MutationRevealArgs = {
  input: CocoonsInput;
};


export type MutationSetHyperdomeCombatRateArgs = {
  input: SetHyperdomeCombatRateInput;
};


export type MutationSetProvingGroundsCombatRateArgs = {
  input: SetProvingGroundsCombatRateInput;
};


export type MutationUpdateInventoryArgs = {
  input: MoveModsInput;
};


export type MutationUpgradeModInModPackArgs = {
  input: UpgradeModInModPackInput;
};


export type MutationWithdrawBattleFliesArgs = {
  input: WithdrawBattleFliesInput;
};


export type MutationWithdrawMagicArgs = {
  input: WithdrawMagicInput;
};


export type MutationWithdrawRevealedBattleFliesArgs = {
  input: WithdrawBattleFliesInput;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PaginatedCombatLog = {
  __typename?: 'PaginatedCombatLog';
  edges?: Maybe<Array<CombatLogEdge>>;
  pageInfo?: Maybe<PageInfo>;
  records?: Maybe<Array<Combat>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PickModFromModPackInput = {
  modBlueprintId: Scalars['ID'];
  modPackId: Scalars['ID'];
  slot: Scalars['Float'];
};

export type ProvingGroundsInput = {
  battleFlyIds: Array<Scalars['ID']>;
  combatRate: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  _fixBattleFlyPruning: BattleFly;
  _fixBattleFlyTypeCombatServicePruning: BattleFly;
  _fixBattleFlyTypeHyperdomePruning: BattleFly;
  _fixBattleFlyTypePruning: BattleFly;
  _fixTypePruning: User;
  _fixWalletTypePruning: Wallet;
  allBattleflyAttributes: Attributes;
  combat?: Maybe<Combat>;
  dailyRace: DailyRace;
  getBattleFlies: Array<GenericBattleFly>;
  getBattleFly?: Maybe<GenericBattleFly>;
  getCombatLog: Array<Combat>;
  getCombatLogForTesting: PaginatedCombatLog;
  getCombatLogPaginated: PaginatedCombatLog;
  getGlobalLeaderboard: Array<LeaderboardItem>;
  getGlobalStatistics: GlobalStatistics;
  getLeaderBoard: Array<LeaderBoardRecord>;
  getLeagueTierEmissions: LeagueTier;
  getLeagues: Array<League>;
  getModCategories: Array<Scalars['String']>;
  getModPack?: Maybe<ModPack>;
  getRevealProgress: RevealingProgress;
  getUserCombatLog: Array<Combat>;
  getUserCombatLogPaginated: PaginatedCombatLog;
  getUserStatistics: Statistics;
  holdingTokenIds?: Maybe<Array<Scalars['Int']>>;
  isModalGetMagicOpened: Scalars['Boolean'];
  justNowUnStakedBfIds?: Maybe<Array<Scalars['Int']>>;
  justNowUnStakedStakedBfIds?: Maybe<Array<Scalars['Int']>>;
  me: User;
  modalBfToProvingGroundsId?: Maybe<Array<Scalars['Int']>>;
  stakeBfCartIds?: Maybe<Array<Scalars['Int']>>;
  stakedTokenIds?: Maybe<Array<Scalars['Int']>>;
  timer: TimerInfoModel;
  unStakeBfCartIds?: Maybe<Array<Scalars['Int']>>;
  walletValidationMessage: Scalars['String'];
};


export type QueryCombatArgs = {
  input: InputId;
};


export type QueryGetBattleFliesArgs = {
  input: BattleFliesInput;
};


export type QueryGetBattleFlyArgs = {
  input: BattleFlyInput;
};


export type QueryGetCombatLogForTestingArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  returnTotalCount?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetCombatLogPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  returnTotalCount?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetLeaderBoardArgs = {
  input?: InputMaybe<LeaderBoardDateInput>;
};


export type QueryGetLeagueTierEmissionsArgs = {
  input: LeagueTierEmissionsInput;
};


export type QueryGetModPackArgs = {
  input: ModPackInput;
};


export type QueryGetRevealProgressArgs = {
  input: CocoonsInput;
};


export type QueryGetUserCombatLogPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  returnTotalCount?: InputMaybe<Scalars['Boolean']>;
};

export enum Rarity {
  Artefact = 'Artefact',
  Common = 'Common',
  Core = 'Core',
  Epic = 'Epic',
  Legendary = 'Legendary',
  Rare = 'Rare',
  Uncommon = 'Uncommon'
}

export type RecycleModPackInput = {
  modPackId: Scalars['ID'];
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String'];
};

export type RevealingProgress = {
  __typename?: 'RevealingProgress';
  battleflyId: Scalars['ID'];
  endedAt: Scalars['DateTime'];
  lastStepAt: Scalars['DateTime'];
  nextStepAt?: Maybe<Scalars['DateTime']>;
  startedAt: Scalars['DateTime'];
  step: Scalars['String'];
};

export enum ScavengingMarkType {
  Bronze = 'Bronze',
  Gold = 'Gold',
  Silver = 'Silver'
}

export type SetHyperdomeCombatRateInput = {
  battleFlyId: Array<Scalars['Int']>;
  newCombatRate: Scalars['Int'];
};

export type SetProvingGroundsCombatRateInput = {
  battleFlyId: Array<Scalars['Int']>;
  newCombatRate: Scalars['Int'];
};

export type Signature = {
  __typename?: 'Signature';
  r: Scalars['String'];
  s: Scalars['String'];
  v: Scalars['Int'];
};

export type Statistics = {
  __typename?: 'Statistics';
  averageDailyBattles: Scalars['Float'];
  id: Scalars['ID'];
  totalBattleFlies: Scalars['Float'];
  totalBattlesInDay: Scalars['Float'];
  totalMagicWonInDay: Scalars['Float'];
};

export type Tattoo = {
  __typename?: 'Tattoo';
  amount: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TextLogRecord = {
  __typename?: 'TextLogRecord';
  text: Scalars['String'];
  when: Scalars['Float'];
};

export type TimerInfoModel = {
  __typename?: 'TimerInfoModel';
  nextResetDate: Scalars['String'];
  secondsUntilNextReset: Scalars['Int'];
};

export type TokenPair = {
  __typename?: 'TokenPair';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type Trait = {
  __typename?: 'Trait';
  damageType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  stat: Scalars['String'];
  unitType: UnitType;
  value: Scalars['Float'];
};

export enum UnitType {
  Percentage = 'percentage',
  Quantity = 'quantity'
}

export type UpgradeModInModPackInput = {
  modBlueprintId: Scalars['ID'];
  modPackId: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  battleFlies: Array<BattleFly>;
  cocoons: Array<Cocoon>;
  createdAt: Scalars['DateTime'];
  credits: Array<Credit>;
  id: Scalars['ID'];
  identity: Identity;
  role: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  wallets: Array<Wallet>;
};

export type Wallet = {
  __typename?: 'Wallet';
  address: Scalars['String'];
  bestRaces: Array<LeaderBoardRecord>;
  droplets: Scalars['Int'];
  id: Scalars['ID'];
  identity: Identity;
  magic: Scalars['Float'];
  nectar: Scalars['Float'];
  v1: Scalars['Float'];
  v2: Scalars['Float'];
};


export type WalletBestRacesArgs = {
  input?: InputMaybe<WalletRaceHistoryInput>;
};

export type WalletRaceHistoryInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  maxRecordsCount?: InputMaybe<Scalars['Int']>;
  onDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type WeaponBlueprint = {
  __typename?: 'WeaponBlueprint';
  burst: Scalars['Float'];
  damagePerFire: Scalars['Float'];
  reload: Scalars['Float'];
};

export type WithdrawBattleFliesInput = {
  battleFlyIds: Array<Scalars['Int']>;
};

export type WithdrawMagicInput = {
  amount: Scalars['Float'];
};
