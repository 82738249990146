import { Input } from 'components/core';

type MobileInputProps = {
  value: string;
  onChange: (value: string) => void;
  min: number;
  max: number;
};

const MobileInput = (props: MobileInputProps) => {
  const { value, onChange, min, max } = props;
  return (
    <div className="flex items-center">
      <div className="w-1/3">
        {min} to {max}{' '}
      </div>
      <div className="w-2/3">
        <Input
          value={value}
          onChange={onChange}
          type="number"
          className="w-full"
        />
      </div>
    </div>
  );
};

export default MobileInput;
