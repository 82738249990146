import BattleflyPublicMintABI from './BatteflyPublicMint.json';
import BattleflyCreditsABI from './BattleflyCreditsContract.json';
import BattleflyABI from './BattleflyContract.json';
import BattleflyGameABI from './BattleflyGame.json';
import BattleflyWhitelistABI from './BattleflyWhitelist.json';
import BridgeworldTreasuresABI from './BridgeworldTreasures.json';
import MagicABI from './ERC20.json';
import SmolDomainsABI from './SmolDomains.json';

import BattleflyFounderVaultABI from './BattleflyFounderVault.json';
import ComicABI from './Comic.json';
import FoundersGenesisV2ABI from './FounderGenesisV2Sale.json';
import HyperdomeABI from './HyperdomeContract.json';
import ItemABI from './ItemContract.json';
import ModABI from './ModContract.json';
import RevealStakingABI from './RevealStaking.json';
import ScrapTokenABI from './ScrapTokenContract.json';
import SpecialNFTABI from './SpecialNFTContract.json';
import GFlyContractABI from './GFlyContract.json';

export {
  BattleflyABI,
  BattleflyGameABI,
  BattleflyPublicMintABI,
  BattleflyWhitelistABI,
  MagicABI,
  FoundersGenesisV2ABI,
  ItemABI,
  RevealStakingABI,
  SpecialNFTABI,
  HyperdomeABI,
  ModABI,
  ScrapTokenABI,
  SmolDomainsABI,
  ComicABI,
  BattleflyFounderVaultABI,
  BattleflyCreditsABI,
  BridgeworldTreasuresABI,
  GFlyContractABI,
};
