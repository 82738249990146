import React from 'react';
import classnames from 'classnames';

import ScavengeBronze from 'components/Icons/Marks/ScavengeBronze';
import ScavengeSilver from 'components/Icons/Marks/ScavengeSilver';
import ScavengeGold from 'components/Icons/Marks/ScavengeGold';
import { ScavengingMarkType } from '@battlefly/__generated__/globalTypes';

type ScavengingMarkProps = {
  className?: string;
  type: ScavengingMarkType;
};

function getIcon(type: ScavengingMarkType): React.ReactNode {
  switch (type) {
    case ScavengingMarkType.Bronze:
      return <ScavengeBronze className="scavenger-matrix-transform" />;
    case ScavengingMarkType.Silver:
      return <ScavengeSilver className="scavenger-matrix-transform" />;
    case ScavengingMarkType.Gold:
      return (
        <ScavengeGold className="scavenger-matrix-transform object-contain" />
      );
  }
}

const ScavengingMark = (props: ScavengingMarkProps) => {
  const { type, className } = props;
  return (
    <span className={classnames('flex items-center', className)}>
      {getIcon(type)}
    </span>
  );
};

export default ScavengingMark;
