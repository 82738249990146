import React from 'react';

export type IconProps = React.ComponentProps<'svg'>;

export { default as ArrowRight } from './ArrowRight';
export { default as AttentionIcon } from './AttentionIcon';
export { default as BattlelogIcon } from './BattlelogIcon';
export { default as BurgerMenu } from './BurgerMenu';
export { default as ChartIcon } from './ChartIcon';
export { default as Checkmark } from './Checkmark';
export { default as ChevronLeft } from './ChevronLeft';
export { default as CloseIcon } from './CloseIcon';
export { default as Comic } from './Comic';
export { default as CrownIcon } from './CrownIcon';
export { default as DiamondIcon } from './Diamond';
export { default as ExpandArrow } from './ExpandArrow';
export { default as GardenIcon } from './GardenIcon';
export { default as GFlyIcon } from './GFlyIcon';
export { default as GridViewIcon } from './GridViewIcon';
export { default as Inventory } from './Inventory';
export { default as Leaderboard } from './Leaderboard';
export { default as Lore } from './Lore';
export { default as ListViewIcon } from './ListViewIcon';
export { default as MagicIcon } from './MagicIcon';
export { default as MarkBackgroundIcon } from './MarkBackgroundIcon';
export { default as Marketplace } from './Marketplace';
export { default as NectarIcon } from './NectarIcon';
export { default as PlusIcon } from './PlusIcon';
export { default as PlusIconSlim } from './PlusIconSlim';
export { default as Racer } from './Racer';
export { default as RiftIcon } from './RiftIcon';
export { default as StarIcon } from './StarIcon';
export { default as SearchIcon } from './SearchIcon';
export { default as Triangle } from './Triangle';
export { default as TroveIcon } from './TroveIcon';
export { default as UserProfileIcon } from './UserProfile';
export { default as WalletIcon } from './WalletIcon';
export { default as WarningIcon } from './WarningIcon';
export { default as ZipperIcon } from './ZipperIcon';
