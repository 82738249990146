import useCheckTermsAndConditions from '@battlefly/hooks/useCheckTermsAndConditions';
import { BattleFly } from '@battlefly/__generated__/globalTypes';
import React from 'react';

type ModalProps = {
  type: 'success' | 'error';
  title: string;
  description: string;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmText: string;
  cancelText?: string;
};

type ConfirmationModalProps = {
  onConfirm: () => void;
  onCancel?: () => void;
}

type BattleSettings = {
  battleflies: BattleFly[];
};

type ModalContextType = {
  scavengerMarkModalState: BattleFly;
  setScavengerMarkModalState: (battlefly: BattleFly) => void;
  huntersMarkModalState: BattleFly;
  setHuntersMarkModalState: (battlefly: BattleFly) => void;
  confirmModalState?: ModalProps;
  setConfirmModalState: (value: ModalProps) => void;
  confirmationModal: ConfirmationModalProps;
  setConfirmationModal: (value: ConfirmationModalProps) => void;
  purchaseInventorySlotModalState: BattleFly;
  setPurchaseInventorySlotModalState: (battlefly: BattleFly) => void;
  battleSettings: BattleSettings;
  setBattleSettings: (value: BattleSettings) => void;
  showTermsModal: boolean;
  setShowTermsModal: (value: boolean) => void;
};

const defaultSettings: ModalContextType = {
  scavengerMarkModalState: null,
  setScavengerMarkModalState: (battlefly: BattleFly) => {},
  huntersMarkModalState: null,
  setHuntersMarkModalState: (battleFly: BattleFly) => {},
  confirmModalState: null,
  setConfirmModalState: (value: ModalProps) => {},
  confirmationModal: null,
  setConfirmationModal: (value: ConfirmationModalProps) => {},
  purchaseInventorySlotModalState: null,
  setPurchaseInventorySlotModalState: (battlefly: BattleFly) => {},
  battleSettings: null,
  setBattleSettings: (value: BattleSettings) => {},
  showTermsModal: false,
  setShowTermsModal: (value: boolean) => {},
};

export const ModalContext =
  React.createContext<ModalContextType>(defaultSettings);

export const ModalContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [huntersMarkModalState, setHuntersMarkModalState] =
    React.useState(null);
  const [scavengerMarkModalState, setScavengerMarkModalState] =
    React.useState(null);
  const [confirmModalState, setConfirmModalState] = React.useState(null);
  const [confirmationModal, setConfirmationModal] = React.useState(null);
  const [purchaseInventorySlotModalState, setPurchaseInventorySlotModalState] =
    React.useState(null);
  const [battleSettings, setBattleSettings] = React.useState(null);
  const [showTermsModal, setShowTermsModal] = React.useState(false);

  const { showTerms } = useCheckTermsAndConditions();

  React.useEffect(() => {
    setShowTermsModal(showTerms);
  }, [showTerms]);

  return (
    <ModalContext.Provider
      value={{
        scavengerMarkModalState,
        setScavengerMarkModalState,
        huntersMarkModalState,
        setHuntersMarkModalState,
        confirmModalState,
        setConfirmModalState,
        confirmationModal,
        setConfirmationModal,
        purchaseInventorySlotModalState,
        setPurchaseInventorySlotModalState,
        battleSettings,
        setBattleSettings,
        showTermsModal,
        setShowTermsModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
