import { BattleFly } from '@battlefly/__generated__/globalTypes';
import BattleflyLeagueIcon from 'components/BattleflyLeagueIcon';
import Button from 'components/core/Button';
import BattleflyImage from 'components/BattleflyImage';
import { traitDescription, numberToRoman } from 'util/helpers';
import { Link } from '../core';

type BattleflyCardProps = {
  battlefly: BattleFly;
  readonly?: boolean;
};

function getLeagueName(league: string, leagueTier: number): string {
  return `${league} ${numberToRoman(leagueTier)}`;
}

const BattleflyCard = (props: BattleflyCardProps) => {
  const { battlefly, readonly } = props;
  const { league, leagueTier, traits } = battlefly;

  return (
    <div className="gra-bf-card-border box-shadow-card rounded-2xl p-[2px]">
      <div className="gra-bf-card relative rounded-2xl">
        <BattleflyImage url={battlefly.appearance.image} />
        <div className=" w-full px-6 pb-6 mobile:h-[40%] wxga+:h-[30%]">
          <div className="flex justify-between">
            <div className="flex flex-col justify-end gap-1">
              {traits.length > 0 && (
                <div className="font-heading uppercase">Traits</div>
              )}
              {traits.map((trait) => (
                <div
                  className="text-xs font-light leading-[14px]"
                  key={trait.id}
                >
                  {traitDescription(trait)}
                </div>
              ))}
            </div>
            <div className="text-right">
              <div className="mb-2 inline-block p-1 text-center">
                <BattleflyLeagueIcon
                  use="default"
                  league={league}
                  classes="h-[75px] w-[75px] inline"
                />
                <div className="font-mono">
                  {getLeagueName(league, leagueTier)}
                </div>
              </div>
              {!readonly && (
                <Button
                  type="button"
                  transparent
                >
                  <Link to={`/battleflies/view/${battlefly.id}/battlefly-league`}>
                    View Progress
                  </Link>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BattleflyCard;
