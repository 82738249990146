import React from 'react';
import { IconProps } from '@battlefly/components/Icons';

type ExtendedProps = IconProps & {
  colorCenterFrom: string;
  colorCenterTo: string;
  colorOuterOffset: string;
};

const LeagueStatsIcon: React.FC<ExtendedProps> = (props) => {
  const addToId =
    props.colorCenterFrom + props.colorCenterTo + props.colorOuterOffset;
  return (
    <svg
      width="233"
      height="89"
      viewBox="0 0 233 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M203.23 6H29.77L7.54004 44.5L29.77 83H203.23L225.46 44.5L203.23 6Z"
        fill={`url(#paint0_radial_22_576${addToId})`}
      />
      <path
        d="M207.64 1.5H25.36L2 44.5L25.36 87.5H207.64L231 44.5L207.64 1.5Z"
        stroke={`url(#paint1_linear_22_576${addToId})`}
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <defs>
        <radialGradient
          id={`paint0_radial_22_576${addToId}`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(116.5 44.5) scale(81.71 40.855)"
        >
          <stop stopColor={props.colorCenterFrom} />
          <stop offset="1" stopColor={props.colorCenterTo} />
        </radialGradient>
        <linearGradient
          id={`paint1_linear_22_576${addToId}`}
          x1="116.5"
          y1="3.85524e-08"
          x2="116.5"
          y2="89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.colorOuterOffset} />
          <stop offset="0.5" stopColor={props.colorCenterTo} />
          <stop offset="1" stopColor={props.colorOuterOffset} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LeagueStatsIcon;
