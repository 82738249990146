import { utils } from 'ethers';
import dayjs from 'dayjs';

import { PersonalizedBattleLogItem } from '@battlefly/ts/BattleLog';
import {
  BattleFly,
  BattleFlyLocation,
  Trait,
  UnitType,
} from '@battlefly/__generated__/globalTypes';

export const getSecondsDiffBetweenDates = (
  dateOne: Date,
  dateTwo: Date
): number => {
  if (dateOne > dateTwo) {
    return Math.ceil((dateOne.getTime() - dateTwo.getTime()) / 1000);
  } else {
    return 0;
  }
};

export const magicToNumber = (stakedMagicBalance: string) =>
  Number(utils.formatEther(BigInt(stakedMagicBalance))).toFixed(1);

export const numberToRoman = (num: number): string => {
  switch (num) {
    case 1:
      return 'I';
    case 2:
      return 'II';
    case 3:
      return 'III';
    case 4:
      return 'IV';
    case 5:
      return 'V';
    case 6:
      return 'VI';
    case 7:
      return 'VII';
    default:
      return num.toString();
  }
};

export const traitDescription = (trait: Trait): string => {
  const traitsPercentPerSecond = ['hprg', 'shprg'];
  return `${trait.value > 0 ? '+' : '-'}${trait.value
    .toString()
    .replace('+', '')
    .replace('-', '')}${
    traitsPercentPerSecond.includes(trait.stat.toLowerCase())
      ? '%/s'
      : trait.unitType === UnitType.Percentage
      ? '%'
      : ''
  } ${trait.name.replace('Trait', '')}`;
};

export const battleflyIsIn = (
  cond: string,
  loc: BattleFlyLocation
): boolean => {
  switch (loc) {
    case BattleFlyLocation.MissionControl:
      return (
        cond === 'mission_control' ||
        cond === BattleFlyLocation.MissionControl ||
        cond === 'MissionControl'
      );
    case BattleFlyLocation.ProvingGrounds:
      return (
        cond === 'proving_grounds' ||
        cond === BattleFlyLocation.ProvingGrounds ||
        cond === 'ProvingGrounds'
      );
    case BattleFlyLocation.Hyperdome:
      return (
        cond === 'hyperdome' ||
        cond === BattleFlyLocation.Hyperdome ||
        cond === 'Hyperdome'
      );
    default:
      return false;
  }
};

export const getBattleflyLocation = (location: BattleFlyLocation) => {
  switch (location) {
    case BattleFlyLocation.MissionControl:
      return 'Mission Control';
    case BattleFlyLocation.ProvingGrounds:
      return 'Proving Grounds';
    case BattleFlyLocation.Hyperdome:
      return 'Hyperdome';
    default:
      return 'Unknown';
  }
};

export const isInBattle = (battleFly: BattleFly) => {
  return (
    battleflyIsIn(battleFly.location, BattleFlyLocation.ProvingGrounds) ||
    battleflyIsIn(battleFly.location, BattleFlyLocation.Hyperdome)
  );
};

export const battleflyHasHyperdomeMark = (battlefly: {
  tattoos: { id: string }[];
}): boolean =>
  battlefly?.tattoos.filter((x) => x.id === 'HyperdomeTattoo').length > 0;

export const isBattleFlyStakedAndBelongsToUser = (
  battleFly: BattleFly,
  userAddress: string
) =>
  battleFly?.owner?.user?.wallets?.length > 0
    ? battleFly.owner.user.wallets[0].address === userAddress
    : false;

export const getTimeToDate = (dateFuture: Date) => {
  const dateNow = new Date();
  const seconds = Math.floor((dateFuture.getTime() - dateNow.getTime()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  return { seconds, minutes, hours, days };
};

export const roundMagicStaked = (magic: number) => magic?.toFixed(2);

export const groupBattleLogsByDay = (
  battleLogs: PersonalizedBattleLogItem[]
): Record<string, PersonalizedBattleLogItem[]> => {
  return battleLogs.reduce((acc, battleLog) => {
    const day = dayjs(battleLog.createdAt).format('DD MMM YYYY');
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(battleLog);
    return acc;
  }, {} as Record<string, PersonalizedBattleLogItem[]>);
};
