import classnames from 'classnames';

import { ScavengingMarkType } from '__generated__/globalTypes';
import { SCAVENGING_MARKS_DEFS } from 'common/battleflyMarks';
import { Card, Button } from 'components/core';

type CompareViewProps = {
  currentMark: ScavengingMarkType;
  selectedMark: ScavengingMarkType;
  amount: number;
  handleConfirm: () => void;
};

const CompareView = (props: CompareViewProps) => {
  const { currentMark, selectedMark, amount, handleConfirm } = props;
  return (
    <>
      <section className="mb-12">
        <h1 className="mb-2 text-5xl leading-[40px] md:text-3xl">
          Replace Scavenging Mark
        </h1>
        <p className="leading-[19px]">
          Your Current Scavenging Mark will be replaced with the new one.
        </p>
      </section>
      <section className="-mx-5 mb-12 flex justify-between gap-2 md:mx-0">
        {[currentMark, selectedMark].map(
          (markName: ScavengingMarkType, index) => (
            <div
              key={markName}
              className="flex cursor-pointer flex-col items-center justify-between gap-3"
            >
              <Card
                className={classnames(
                  'relative h-[88px] w-[100px] overflow-hidden !px-2 !pb-0 !pt-1 md:h-[144px] md:w-[167px]',
                  {
                    active: index === 1,
                  }
                )}
                mark
              >
                <div className="absolute left-0 bottom-0 h-full w-full overflow-hidden md:mx-3 md:mb-3">
                  {SCAVENGING_MARKS_DEFS[markName].icon({
                    className:
                      'rotate-[22deg] top-0 w-[200px] h-auto -translate-x-5 md:-translate-x-10 md:translate-y-2',
                  })}
                </div>
              </Card>
              <div className="font-heading text-3xl">{markName}</div>
              <div className="text-center leading-[19px]">
                {SCAVENGING_MARKS_DEFS[markName].description}
              </div>
            </div>
          )
        )}
      </section>
      <section className="mb-8 flex justify-center">
        <div className="w-72">
          <Button blueMint onClick={handleConfirm} disabled={!amount}>
            Buy {amount ? `(${amount} Nectar)` : ''}
          </Button>
        </div>
      </section>
      <section className="text-center">
        <div className="inline-block rounded-[16px] bg-core-red-error py-2 px-3">
          WARNING: Your current Scavenging Mark will be replaced if you proceed.
        </div>
      </section>
    </>
  );
};

export default CompareView;
