// AppContextProvider.tsx
import UnloadUnityGameContextProvider from '@battlefly/hooks/unityInitializer/useUnity';
import { combineComponents } from './combineContexts';
import GardenContextProvider from './gardenContext';
import OwnedBattlefliesProvider from './holdingBattlefliesContext';
import HyperdomeContextProvider from './hyperdomeContext';
import MarketplaceContextProvider from './marketplaceContext';
import MissionControlFiltersContextProvider from './missionControlFiltersContext';
import SelectModsContextProvider from './selectModsContext';
import StakedBattlefliesProvider from './stakedBattlefliesContext';
import UserContextProvider from './userContext';
import UserBalanceProvider from './userBalanceContext';
import DrawerContextProvider from './drawerContext';
import ModalContextProvider from './modalContext';
import BattleflyContextProvider from './battleflyContext';

/**
 * ORDER IS IMPORTANT!
 */
export const contextProviders = [
  UserContextProvider,
  MissionControlFiltersContextProvider,
  OwnedBattlefliesProvider,
  MarketplaceContextProvider,
  StakedBattlefliesProvider,
  HyperdomeContextProvider,
  GardenContextProvider,
  SelectModsContextProvider,
  UnloadUnityGameContextProvider,
  UserBalanceProvider,
  DrawerContextProvider,
  ModalContextProvider,
  BattleflyContextProvider,
];
export const AppContextProvider = combineComponents(...contextProviders);
