import React from 'react';

type IconProps = React.ComponentProps<'svg'>;

const GridViewIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.5625 1.5H3.1875C2.25552 1.5 1.5 2.25552 1.5 3.1875V9.5625C1.5 10.4945 2.25552 11.25 3.1875 11.25H9.5625C10.4945 11.25 11.25 10.4945 11.25 9.5625V3.1875C11.25 2.25552 10.4945 1.5 9.5625 1.5ZM3.1875 3H9.5625C9.66605 3 9.75 3.08395 9.75 3.1875V9.5625C9.75 9.66605 9.66605 9.75 9.5625 9.75H3.1875C3.08395 9.75 3 9.66605 3 9.5625V3.1875C3 3.08395 3.08395 3 3.1875 3ZM20.8125 1.5H14.4375C13.5055 1.5 12.75 2.25552 12.75 3.1875V9.5625C12.75 10.4945 13.5055 11.25 14.4375 11.25H20.8125C21.7445 11.25 22.5 10.4945 22.5 9.5625V3.1875C22.5 2.25552 21.7445 1.5 20.8125 1.5ZM14.4375 3H20.8125C20.9161 3 21 3.08395 21 3.1875V9.5625C21 9.66605 20.9161 9.75 20.8125 9.75H14.4375C14.3339 9.75 14.25 9.66605 14.25 9.5625V3.1875C14.25 3.08395 14.3339 3 14.4375 3ZM3.1875 12.75H9.5625C10.4945 12.75 11.25 13.5055 11.25 14.4375V20.8125C11.25 21.7445 10.4945 22.5 9.5625 22.5H3.1875C2.25552 22.5 1.5 21.7445 1.5 20.8125V14.4375C1.5 13.5055 2.25552 12.75 3.1875 12.75ZM9.5625 14.25H3.1875C3.08395 14.25 3 14.3339 3 14.4375V20.8125C3 20.9161 3.08395 21 3.1875 21H9.5625C9.66605 21 9.75 20.9161 9.75 20.8125V14.4375C9.75 14.3339 9.66605 14.25 9.5625 14.25ZM20.8125 12.75H14.4375C13.5055 12.75 12.75 13.5055 12.75 14.4375V20.8125C12.75 21.7445 13.5055 22.5 14.4375 22.5H20.8125C21.7445 22.5 22.5 21.7445 22.5 20.8125V14.4375C22.5 13.5055 21.7445 12.75 20.8125 12.75ZM14.4375 14.25H20.8125C20.9161 14.25 21 14.3339 21 14.4375V20.8125C21 20.9161 20.9161 21 20.8125 21H14.4375C14.3339 21 14.25 20.9161 14.25 20.8125V14.4375C14.25 14.3339 14.3339 14.25 14.4375 14.25Z" />
    </svg>
  );
};

export default GridViewIcon;
