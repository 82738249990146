import React from 'react';
import { IconProps } from '@battlefly/components/Icons';

const MarkElectric: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="125"
      height="122"
      viewBox="0 0 125 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M124.281 53.7529L88.1567 34.644L85.8332 35.8327L85.5692 34.3574L41.8389 12.6063L29.3639 24.6604L23.9304 14.3362L20.4697 14.2128L15.2792 21.0716L4.28568 0L0.201782 2.10091L8.37035 42.1432L10.3713 43.0133L5.51577 47.7048L11.2007 84.905L20.1183 89.456L13.6945 95.7087L14.5567 99.0624L22.6066 102.096L5.51577 118.613L8.68822 121.933L44.7724 102.747L44.8544 102.08L55.2746 107.398L92.1515 71.1866L90.5856 62.4276L99.764 64.32L102.056 61.7247L100.051 53.3601L123.311 58.243L124.281 53.7529Z"
        fill="url(#paint0_linear_1_1121)"
      />
      <path
        d="M63.551 44.8751L39.2999 37.6313L32.2794 47.561L48.9852 47.0463L33.6579 65.2564L53.119 66.5716L36.2945 83.4906L68.5377 64.4082L49.0781 58.5694L63.551 44.8751Z"
        fill="url(#paint1_linear_1_1121)"
      />
      <path
        d="M96.3159 49.7299L120.847 54.7698L87.7709 37.7906L90.2248 49.4043L85.4809 48.2905L83.8471 36.9885L42.2341 15.8685L29.0023 28.5576L22.0771 16.7151L14.8473 26.2622L3.16806 4.10886L11.0469 40.4437L18.4626 31.1747L20.9673 36.2646L8.37036 48.3436L13.2579 82.148L24.7825 87.997L16.5022 96.8123L27.7074 101.039L9.77383 118.52L42.3841 100.663L31.3929 96.1796L34.77 93.0666L54.8701 103.268L88.6714 70.3595L87.0953 59.4566L99.1025 61.3771L96.3159 49.7299ZM54.3897 99.9457L16.3109 80.0846L18.9772 63.8451L11.8201 49.0255L42.6934 19.0472L81.1619 38.9996L78.0621 55.3024L85.5692 69.4816L54.3897 99.9457Z"
        fill="url(#paint2_linear_1_1121)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_1121"
          x1="-1.86039"
          y1="4.57234"
          x2="74.0839"
          y2="97.4116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#434343" />
          <stop offset="0.4888" stopColor="#3B3C3B" />
          <stop offset="1" stopColor="#4A494A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_1121"
          x1="32.2796"
          y1="60.561"
          x2="68.5379"
          y2="60.561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_1121"
          x1="3.1677"
          y1="61.3143"
          x2="120.846"
          y2="61.3143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MarkElectric;
