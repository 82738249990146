import React from 'react';
import { IconProps } from '@battlefly/components/Icons/index';

const PlusIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.14284 0.5C7.19607 0.5 6.42856 1.27007 6.42856 2.22V2.52C6.42856 4.72914 4.6377 6.52 2.42856 6.52H2.14284C1.19607 6.52 0.428558 7.29007 0.428558 8.24V9.96C0.428558 10.9099 1.19607 11.68 2.14284 11.68H2.42856C4.6377 11.68 6.42856 13.4709 6.42856 15.68V15.98C6.42856 16.9299 7.19607 17.7 8.14284 17.7H9.85713C10.8039 17.7 11.5714 16.9299 11.5714 15.98V15.68C11.5714 13.4709 13.3623 11.68 15.5714 11.68H15.8571C16.8039 11.68 17.5714 10.9099 17.5714 9.96V8.24C17.5714 7.29007 16.8039 6.52 15.8571 6.52H15.5714C13.3623 6.52 11.5714 4.72914 11.5714 2.52V2.22C11.5714 1.27007 10.8039 0.5 9.85713 0.5H8.14284Z"
        fill="#1A202C"
      />
    </svg>
  );
};

export default PlusIcon;
