import classnames from 'classnames';

import { WalletTab } from '@battlefly/ts/wallet';

type TabControlsProps = {
  className?: string;
  active: WalletTab;
  handleTabSwitch: (tab: WalletTab) => void;
};

const TabControls = (props: TabControlsProps) => {
  const { active, handleTabSwitch } = props;

  return (
    <div
      className={classnames(
        'flex items-center justify-between',
        props.className
      )}
    >
      {Object.values(WalletTab)
        .reduce((arr, v, i) => (i < 2 ? [...arr, v, 'pipe'] : [...arr, v]), [])
        .map((value, i) => {
          if (value === 'pipe') {
            return <div className="h-6 w-[2px] bg-white" key={`pipe-${i}`} />;
          }
          return (
            <div
              key={value}
              onClick={() => handleTabSwitch(value)}
              className={classnames('py-4 font-heading', {
                underline: active === value,
                'cursor-pointer text-[#c4c4c4]': active !== value,
                'pr-4': i < 4,
                'pl-4': i > 0,
              })}
            >
              {value}
            </div>
          );
        })}
    </div>
  );
};

export default TabControls;
