import {
  failureToast,
  pendingSignatureToast,
  successToast,
} from '@battlefly/components/CustomToast/CustomToast';
import { Result } from 'ethers/lib/utils';
import { useEffect, useState } from 'react';
import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { BattleflyGame, CONTRACT_ABIS } from '../../common/constants';
import { useContractAddresses } from './useContractAddresses';

/**
 *
 * @param tokenId: BattleFly Token Id
 * @returns Owner Address of Battlefly TokenId.
 */
export const useOwnerOfBattlefly = (tokenId: string | string[]) => {
  const [ownerAddress, setOwnerAddress] = useState<Result>();
  const contractAddress = useContractAddresses();
  const { data, isError, isLoading } = useContractRead({
    address: contractAddress[BattleflyGame.BattleflyContract],
    abi: CONTRACT_ABIS[BattleflyGame.BattleflyContract],
    functionName: 'ownerOf',
    args: [tokenId],
  });

  useEffect(() => {
    if (data && !isError) {
      setOwnerAddress(data as Result);
    }
  }, [data, isError]);

  return {
    ownerAddress,
    isLoading,
  };
};

/**
 * Check if Address is approved for use of Battlefly NFT
 * @returns boolean
 */
export const useIsApprovedForAll = (
  contractAddressRequestingApproval: string
) => {
  const { address } = useAccount();

  const contractAddress = useContractAddresses();
  const { data, isError, isLoading, isSuccess, refetch } = useContractRead({
    address: contractAddress[BattleflyGame.BattleflyContract],
    abi: CONTRACT_ABIS[BattleflyGame.BattleflyContract],
    functionName: 'isApprovedForAll',
    args: [address, contractAddressRequestingApproval],
  });

  const isApproved = data as boolean;

  return { isApproved, isError, isLoading, isSuccess, refetch };
};

/**
 * Approve inputted contract for use of Battlefly NFT
 * @returns void
 */
export const useSetApprovalForAll = (
  contractAddressRequestingApproval: string
) => {
  const contractAddress = useContractAddresses();
  const { write, isLoading, isError, isSuccess } = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: contractAddress[BattleflyGame.BattleflyContract],
    abi: CONTRACT_ABIS[BattleflyGame.BattleflyContract],
    functionName: 'setApprovalForAll',
    args: [contractAddressRequestingApproval, true],
    onMutate({ args, overrides }) {
      pendingSignatureToast();
      console.log('Mutate', { args, overrides });
    },
    onSuccess(data) {
      successToast('Approved', data);
      console.log('Success', data);
    },
    onError(error) {
      failureToast('Failed to Approve');
      console.log('Error', error);
    },
  });
  const setApprovalForAll = async () => {
    write();
  };

  return {
    setApprovalForAll,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useApproveHatching = (
  contractAddressRequestingApproval: string
) => {
  const contractAddress = useContractAddresses();
  const {
    isApproved: isStakingApproved,
    isLoading: loadingStakingApproval,
    refetch,
  } = useIsApprovedForAll(contractAddressRequestingApproval);
  const { write, isLoading, isError, isSuccess } = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: contractAddress[BattleflyGame.BattleflyContract],
    abi: CONTRACT_ABIS[BattleflyGame.BattleflyContract],
    functionName: 'setApprovalForAll',
    args: [contractAddressRequestingApproval, true],
    onMutate({ args, overrides }) {
      pendingSignatureToast();
      console.log('Mutate', { args, overrides });
    },
    onSuccess(data) {
      successToast('Approved', data);
      refetch();
      refetch();
      console.log('Success', data);
    },
    onError(error) {
      failureToast('Failed to Approve');
      console.log('Error', error);
    },
  });
  const setApprovalForAll = async () => {
    write();
  };

  return {
    setApprovalForAll,
    isLoading,
    isError,
    isSuccess,
    isStakingApproved,
    loadingStakingApproval,
  };
};
