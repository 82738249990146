import React from 'react';
import { IconProps } from '@battlefly/components/Icons';

const MarkBlackProject: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="149"
      height="128"
      viewBox="0 0 149 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M116.396 98.922L128.292 92.4951V88.922L113.214 78.2354C114.862 73.711 115.761 68.8258 115.761 63.7313C115.761 40.3252 96.7868 21.3514 73.3808 21.3514C72.9364 21.3514 72.4935 21.3592 72.0523 21.3725L73.3808 19.9862L72.6552 17.0199L3.41485 0.213394L0.201782 2.80869L32.6137 28.639L20.7173 35.0659V38.639L33.9984 48.0518C32.0654 52.9018 31.0016 58.1932 31.0016 63.7329C31.0016 87.1389 49.9755 106.113 73.3815 106.113C74.6647 106.113 75.9347 106.056 77.1897 105.944L75.6277 107.574L76.3533 110.54L145.594 127.348L148.807 124.753L116.396 98.922Z"
        fill="url(#paint0_linear_1_1126)"
      />
      <path
        d="M91.565 52.5512C83.498 52.5496 75.4302 52.5387 67.3631 52.566C66.4931 52.5691 66.0042 52.302 65.684 51.4765C65.0677 49.8832 64.3352 48.3345 63.6971 46.7498C63.3925 45.993 62.8903 45.6064 62.089 45.6056C59.4757 45.6025 56.8617 45.5791 54.2484 45.6166C52.9887 45.6345 52.7934 45.8649 52.7903 47.1216C52.7786 51.7889 52.7856 56.4554 52.7856 61.1227C52.7653 61.1227 52.7458 61.1227 52.7255 61.1227C52.7255 62.4098 52.7216 63.6961 52.7262 64.9833C52.7395 68.3307 52.7731 71.6788 52.7629 75.0262C52.759 76.2485 53.0785 76.6445 54.3343 76.6531C56.4642 76.6671 58.5955 76.6117 60.723 76.6789C61.7266 76.7109 62.007 76.3298 61.9914 75.3879C61.9515 73.0651 61.9648 70.7416 62.0031 68.4189C62.0234 67.1732 62.8739 66.1173 63.99 65.8674C64.3625 65.7838 64.7593 65.7971 65.1459 65.7861C66.3791 65.7533 66.429 65.7049 66.4322 64.4397C66.4337 63.7618 66.4228 63.0838 66.4423 62.4067C66.5353 59.1983 68.8994 56.4273 71.9406 55.9501C75.4395 55.4011 78.5675 57.2489 79.6609 60.4909C80.0858 61.7507 79.9366 63.0456 79.953 64.3295C79.9702 65.6963 80.0202 65.7666 81.3463 65.7877C83.4261 65.8213 84.3969 66.8022 84.4086 68.9024C84.4211 71.161 84.4274 73.4197 84.4266 75.6784C84.4266 76.2712 84.5633 76.6687 85.2857 76.6617C87.7061 76.639 90.1256 76.6461 92.546 76.6515C93.284 76.6531 93.5988 76.268 93.6011 75.5706C93.6136 71.9327 93.6277 68.2955 93.6339 64.6576C93.6402 61.3742 93.591 58.0893 93.6566 54.8075C93.6933 52.987 93.3785 52.5512 91.565 52.5512Z"
        fill="url(#paint1_linear_1_1126)"
      />
      <path
        d="M81.0339 67.4528C78.4191 67.4442 75.8043 67.4505 73.1902 67.4512C70.4786 67.452 67.7669 67.4575 65.056 67.4489C64.2391 67.4466 63.6549 67.673 63.6557 68.6298C63.6588 72.5676 63.6565 76.5055 63.6572 80.4441C63.6572 81.2821 64.0579 81.6594 64.9139 81.6578C70.3044 81.6453 75.6949 81.6648 81.0847 81.6476C82.3726 81.6437 82.742 81.2267 82.7443 79.9388C82.7506 76.3555 82.7498 72.773 82.7443 69.1898C82.7412 67.8175 82.389 67.4575 81.0339 67.4528ZM74.614 74.9934C74.2547 75.2441 74.0892 75.5144 74.097 75.9424C74.1103 76.714 74.1095 77.4872 74.0868 78.2588C74.0696 78.8305 73.858 79.2781 73.1855 79.2742C72.5545 79.2702 72.3756 78.8118 72.3257 78.3057C72.2811 77.8597 72.3163 77.406 72.3163 76.9553C72.4405 75.9799 72.2882 75.2582 71.3127 74.6248C70.0717 73.8188 70.0943 71.689 71.1502 70.5807C72.2272 69.4498 73.8791 69.4389 74.9912 70.5558C76.5111 72.0834 76.3806 73.7618 74.614 74.9934Z"
        fill="url(#paint2_linear_1_1126)"
      />
      <path
        d="M68.6502 50.9087C72.3288 50.8493 76.0089 50.8853 79.6882 50.8853C83.1418 50.8853 86.5955 50.8868 90.0491 50.8837C90.5958 50.8829 91.262 51.0446 91.6447 50.7892C91.8774 50.6338 91.6673 49.8223 91.6634 49.3053C91.6541 47.9908 91.2932 47.6183 90.0116 47.6253C87.3046 47.6386 84.5969 47.6714 81.8899 47.6683C77.314 47.6636 72.738 47.63 68.1613 47.6238C65.944 47.6207 65.9893 47.6183 66.7805 49.6708C67.1398 50.6018 67.649 50.9243 68.6502 50.9087Z"
        fill="url(#paint3_linear_1_1126)"
      />
      <path
        d="M69.1329 65.7869C70.4551 65.758 71.779 65.7783 73.102 65.7799C74.4898 65.7815 75.8777 65.7619 77.2648 65.7893C77.995 65.8033 78.3418 65.544 78.2957 64.774C78.2543 64.0664 78.298 63.3541 78.2855 62.6441C78.2363 59.7872 75.9706 57.5488 73.1465 57.5598C70.395 57.5707 68.1543 59.8294 68.1082 62.6465C68.0965 63.3564 68.1394 64.0687 68.0973 64.7763C68.0535 65.5487 68.4034 65.8033 69.1329 65.7869Z"
        fill="url(#paint4_linear_1_1126)"
      />
      <path
        d="M73.1715 71.4883C72.4772 71.5016 72.1655 71.9506 72.1124 72.5708C72.1749 73.2448 72.5162 73.6501 73.1535 73.6376C73.8072 73.6251 74.2571 73.2385 74.2602 72.5575C74.2626 71.8843 73.8588 71.475 73.1715 71.4883Z"
        fill="url(#paint5_linear_1_1126)"
      />
      <path
        d="M77.2077 107.698L144.161 124.012L113.759 99.9123L106.838 104.238L105.231 101.317L124.264 89.7772L109.004 77.8004L108.041 79.1304C110.186 74.314 111.314 69.0562 111.314 63.6259C111.314 53.4431 107.349 43.8695 100.148 36.6693C92.9481 29.4692 83.3745 25.5033 73.1917 25.5033C63.5502 25.5033 54.4561 29.0584 47.4044 35.5478L46.6702 34.4778L70.6121 19.2763L3.65775 2.96333L34.0601 27.0629L40.9814 22.7377L42.5879 25.6587L23.5555 37.1973L38.1205 48.6289C36.1196 53.3064 35.0691 58.3861 35.0691 63.6266C35.0691 73.8094 39.0343 83.383 46.2352 90.5832C53.4353 97.7833 63.0089 101.749 73.1917 101.749C83.2878 101.749 92.7841 97.8504 99.9632 90.7667L101.15 92.4966L77.2077 107.698ZM92.1062 92.6068L89.7796 89.0422C85.0115 92.16 79.3141 93.9751 73.1917 93.9751C67.1311 93.9751 61.486 92.1975 56.7476 89.1367L54.4366 92.7114C44.8981 86.5399 38.5727 75.808 38.5727 63.6259C38.5727 51.5265 44.8138 40.8587 54.2445 34.6668L56.5922 38.2173C61.3626 35.094 67.0647 33.2766 73.1917 33.2766C79.2813 33.2766 84.9498 35.0714 89.7023 38.1587L92.0023 34.577C101.51 40.7548 107.811 51.4679 107.811 63.6259C107.811 75.7401 101.555 86.4188 92.1062 92.6068Z"
        fill="url(#paint6_linear_1_1126)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_1126"
          x1="0.201524"
          y1="63.7805"
          x2="148.808"
          y2="63.7805"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#434343" />
          <stop offset="0.4888" stopColor="#3B3C3B" />
          <stop offset="1" stopColor="#4A494A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_1126"
          x1="52.7236"
          y1="61.1376"
          x2="93.6596"
          y2="61.1376"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_1126"
          x1="63.6553"
          y1="74.5529"
          x2="82.7478"
          y2="74.5529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_1126"
          x1="66.2852"
          y1="49.2786"
          x2="91.7539"
          y2="49.2786"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1_1126"
          x1="68.0955"
          y1="61.6749"
          x2="78.301"
          y2="61.6749"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1_1126"
          x1="72.1129"
          y1="72.5631"
          x2="74.2604"
          y2="72.5631"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1_1126"
          x1="3.65778"
          y1="63.4873"
          x2="144.162"
          y2="63.4873"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MarkBlackProject;
