import ChangeMintValueTab from '@battlefly/components/Comic/Mint/ChangeMintValueTab';
import useTabMintCount from '@battlefly/components/Comic/Mint/hooks/useTabMintCount';
import MaxMintCountTab from '@battlefly/components/Comic/Mint/MaxMintCountTab';
import MintButton from '@battlefly/components/Comic/Mint/MintButton';
import { useAppSelector } from '@battlefly/hooks';
import {
  ComicNetworkResponse,
  useComicAvailableBattleflyMint,
  useComicMintBattlefly,
} from '@battlefly/hooks/contractsNew/useComic';
import { useEffect, useRef } from 'react';

const MintByTokens = ({
  comics,
  comicId,
}: {
  comics: ComicNetworkResponse;
  comicId: number;
}) => {
  const {
    mintCount,
    maxMint,
    setMintCount,
    setMaxMint,
    handleMintMaxCount,
    handleIncreaseMintCount,
    handleDecreaseMintCount,
    decreaseMaxMint,
  } = useTabMintCount();

  const allStakingTokenIds = useAppSelector(
    (state) => state.staking.allStakingTokenIds
  );

  const {
    mixedData: dataUsedTokens,
    isError: isErrorUsedTokens,
    isLoading: isLoadingUsedTokens,
    refetch: refetchUsedTokens,
    isRefetching: isRefetchingUsedTokens,
  } = useComicAvailableBattleflyMint(allStakingTokenIds, Number(comicId));

  const {
    bulkMintBattleflys,
    status: statusMintByBattlefly,
    isLoading: isLoadingMintByBattlefly,
    isSuccess: isSuccessMintByBattlefly,
  } = useComicMintBattlefly();

  const unUsedTokens = useRef<any[]>([]);

  useEffect(() => {
    if (dataUsedTokens && dataUsedTokens.length) {
      const currentUnUsedTokens = dataUsedTokens.filter((x) => !x.used);
      unUsedTokens.current = currentUnUsedTokens;
      setMaxMint(currentUnUsedTokens.length);
      setMintCount(
        mintCount <= currentUnUsedTokens.length
          ? mintCount
          : currentUnUsedTokens.length
      );
    }
  }, [dataUsedTokens, isLoadingUsedTokens]);

  const handleMint = async () => {
    if (!maxMint) {
      return;
    }

    await bulkMintBattleflys(
      unUsedTokens.current.slice(0, mintCount).map((x) => x.tokenId),
      Number(comicId),
      mintedChangeValueCallback
    );
  };

  const mintedChangeValueCallback = (
    success: boolean,
    transactionTokens: number[]
  ) => {
    if (success) {
      unUsedTokens.current = unUsedTokens.current.filter(
        (x) => !transactionTokens.includes(x.tokenId)
      );
      decreaseMaxMint(transactionTokens.length);
    }
  };

  return (
    <div>
      <MaxMintCountTab maxMint={maxMint} />

      <ChangeMintValueTab
        mintCount={mintCount}
        handleDecreaseMintCount={handleDecreaseMintCount}
        handleMintMaxCount={handleMintMaxCount}
        handleIncreaseMintCount={handleIncreaseMintCount}
      />

      <MintButton
        disabled={!mintCount || isLoadingUsedTokens}
        text={isLoadingUsedTokens ? `Checking available mints...` : `Mint`}
        handleMint={handleMint}
      />
    </div>
  );
};

export default MintByTokens;
