import React from 'react';
import { ModFullInterface } from '@battlefly/components/BattleflyDetails/Mods/ModFull';
import AnimatedTooltip from '@battlefly/components/core/AnimatedTooltip';
import ModCompactDetails from '@battlefly/components/BattleflyDetails/Mods/ModCompactDetails';
import ModSmall from '@battlefly/components/Icons/Mods/ModSmall';

interface ModCompactProps extends ModFullInterface {
  preventTooltip?: boolean;
  containerClasses?: string;
}

const ModCompact: React.FC<ModCompactProps> = (props) => {
  return (
    <AnimatedTooltip
      tooltip={<ModCompactDetails mod={props.mod} />}
      preventTooltip={props?.preventTooltip}
    >
      <div>
        <ModSmall mod={props.mod} containerClasses={props?.containerClasses} />
      </div>
    </AnimatedTooltip>
  );
};

export default ModCompact;
