import { MaxUint256 } from '@ethersproject/constants';
import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { Result } from 'ethers/lib/utils';

import { BattleflyGame, CONTRACT_ABIS } from '@battlefly/common/constants';
import {
  failureToast,
  pendingSignatureToast,
  successToast,
} from '@battlefly/components/CustomToast/CustomToast';
import { useContractAddresses } from './useContractAddresses';
import { useMakeContractWrite, useMakePrepareContractWrite } from './utils';



export const useApproveMagic = (address: string) => {
  const contractAddress = useContractAddresses();
  const { isMagicApproved, refetch } = useCheckMagicAllowance(address);
  const { data, isError, isLoading, writeAsync, isSuccess } = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: contractAddress[BattleflyGame.MagicContract],
    abi: CONTRACT_ABIS[BattleflyGame.MagicContract],
    functionName: 'approve',
    args: [address && address, MaxUint256.toString()],
    onMutate({ args, overrides }) {
      pendingSignatureToast();
      console.log('Mutate', { args, overrides });
    },
    onSuccess(data) {
      refetch();
      refetch();
      successToast('Magic Approved', data);
      console.log('Success', data);
    },
    onError(error) {
      failureToast('Failed to Approve');
      console.log('Error', error);
    },
  });
  const approveMagic = async () => {
    return writeAsync();
  };

  // console.log('IS MAGIC APPROVED???', isMagicApproved);

  return {
    approveMagic,
    isMagicApproved,
    isLoading,
    isError,
    isSuccess,
  };
};

/**
 * Checks if contract is approved to use user's magic
 * @returns boolean of true / false. IF TRUE, NO MAGIC ALLOWANCE!!!
 */
export const useCheckMagicAllowance = (address: string | BattleflyGame) => {
  const { address: userAddress } = useAccount();
  const contractAddress = useContractAddresses();
  const { data, isError, isLoading, refetch } = useContractRead({
    address: contractAddress[BattleflyGame.MagicContract],
    abi: CONTRACT_ABIS[BattleflyGame.MagicContract],
    functionName: 'allowance',
    args: [userAddress, address],
    watch: false,
  });

  const noMagicAllowance = (data as Result)?.isZero() ?? true;

  return { isMagicApproved: !noMagicAllowance, isError, isLoading, refetch };
};

export const useGetMagicAllowance = (address: string | BattleflyGame) => {
  const { address: userAddress } = useAccount();
  const contractAddress = useContractAddresses();
  const { data, isError, isLoading, refetch } = useContractRead({
    address: contractAddress[BattleflyGame.MagicContract],
    abi: CONTRACT_ABIS[BattleflyGame.MagicContract],
    functionName: 'allowance',
    args: [userAddress, address],
  });

  return { magicAllowance: data, isError, isLoading, refetch };
};

export const useApproveMagicForOperator = (contract: BattleflyGame) => {
  const contractAddress = useContractAddresses();
  const operatorAddress = contractAddress[contract];
  const { isMagicApproved, refetch } = useCheckMagicAllowance(operatorAddress);

  const { config, error, data } = useMakePrepareContractWrite(
    {
      functionName: 'approve',
      enabled: !isMagicApproved,
      args: [operatorAddress, MaxUint256.toString()],
    },
    BattleflyGame.MagicContract
  );

  return {
    isContractApproved: isMagicApproved,
    ...useMakeContractWrite({
      ...config,
      onSuccess() {
        refetch();
      },
    }),
  };
};

export default useApproveMagic;
