import React from 'react';
import Chevron from '@battlefly/components/Accordion/Chevron';
import classNames from 'classnames';

interface SortingArrowsProps {
  onSortDesc: () => void;
  onSortAsc: () => void;
  nowSorting: 'asc' | 'desc';
  containerClasses?: string;
}

const SortingArrows: React.FC<SortingArrowsProps> = (props) => {
  return (
    <div
      className={classNames(
        'elative top-[1px] mr-[4px] flex flex-col items-center justify-center',
        props.containerClasses
      )}
    >
      <Chevron
        className="w-[5px] -rotate-90 cursor-pointer"
        fill={props.nowSorting === 'asc' ? 'white' : 'gray'}
        onClick={props.onSortAsc}
      />
      <Chevron
        className="relative -top-[1px] w-[5px] rotate-90 cursor-pointer"
        fill={props.nowSorting === 'desc' ? 'white' : 'gray'}
        onClick={props.onSortDesc}
      />
    </div>
  );
};

export default SortingArrows;
