import React from 'react';
import { IconProps } from '@battlefly/components/Icons';

const MarkMissile: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="125"
      height="122"
      viewBox="0 0 125 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M124.08 53.7529L87.9549 34.644L85.6314 35.8327L85.3674 34.3574L41.6371 12.6063L29.1621 24.6604L23.7286 14.3362L20.268 14.2128L15.0774 21.0716L4.08389 0L0 2.10091L8.16857 42.1432L10.1695 43.0133L5.31398 47.7048L10.9989 84.905L19.9165 89.456L13.4927 95.7087L14.3549 99.0624L22.4048 102.096L5.31398 118.613L8.48644 121.933L44.5706 102.747L44.6526 102.08L55.0728 107.398L91.9497 71.1866L90.3838 62.4276L99.5622 64.32L101.854 61.7247L99.8496 53.3601L123.11 58.243L124.08 53.7529Z"
        fill="url(#paint0_linear_1_1120)"
      />
      <path
        d="M66.4927 37.6047C66.4927 37.6032 59.4082 42.1026 58.7225 43.2506L55.6422 44.6127L56.8918 45.5468L47.3354 57.875L41.2232 61.8394L43.1703 63.0656L39.6909 67.5674L31.3044 72.123L28.7044 75.4493L36.8191 72.6369L66.4927 37.6047Z"
        fill="url(#paint1_linear_1_1120)"
      />
      <path
        d="M39.651 83.8608L42.1947 80.4931L44.4386 71.2162L47.8938 66.6965L49.58 68.2617L51.8379 61.3341L61.2912 48.9262L62.5142 49.8923L63.0383 46.5645C63.9708 45.6054 66.4943 37.6024 66.4943 37.6024L40.2781 75.2954L39.651 83.8608Z"
        fill="url(#paint2_linear_1_1120)"
      />
      <path
        d="M40.4 71.7903L36.5668 72.9345L28.4701 78.136L36.0217 77.765C36.0217 77.765 37.2799 84.4325 37.4267 84.313C37.5735 84.1935 40.0314 75.5797 40.0314 75.5797L40.4 71.7903Z"
        fill="url(#paint3_linear_1_1120)"
      />
      <path
        d="M96.1141 49.7299L120.645 54.7698L87.5691 37.7906L90.023 49.4043L85.2784 48.2905L83.6445 36.9885L42.0323 15.8685L28.8005 28.5576L21.8753 16.7151L14.6455 26.2622L2.96628 4.10886L10.8451 40.4437L18.2608 31.1747L20.7655 36.2646L8.16858 48.3436L13.0561 82.1473L24.5815 87.997L16.3004 96.8123L27.5056 101.039L9.57205 118.52L42.1823 100.663L31.1912 96.1796L34.5682 93.0666L54.6683 103.269L88.4696 70.3611L86.8935 59.4574L98.9007 61.3779L96.1141 49.7299ZM54.188 99.9457L16.1091 80.0846L18.7755 63.8451L11.6191 49.0255L42.4924 19.0472L80.961 38.9996L77.8611 55.3016L85.3674 69.4816L54.188 99.9457Z"
        fill="url(#paint4_linear_1_1120)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_1120"
          x1="-2.06218"
          y1="4.57234"
          x2="73.8822"
          y2="97.4116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#434343" />
          <stop offset="0.4888" stopColor="#3B3C3B" />
          <stop offset="1" stopColor="#4A494A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_1120"
          x1="28.7032"
          y1="56.5266"
          x2="66.4929"
          y2="56.5266"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_1120"
          x1="39.6507"
          y1="60.7318"
          x2="66.4951"
          y2="60.7318"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_1120"
          x1="28.4693"
          y1="78.0529"
          x2="40.3997"
          y2="78.0529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1_1120"
          x1="2.96592"
          y1="61.3143"
          x2="120.645"
          y2="61.3143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MarkMissile;
