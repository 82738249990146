import React from 'react';
import { ModType, Rarity } from '@battlefly/__generated__/globalTypes';
import ModWeaponBgRectangle from '@battlefly/components/Icons/Mods/ModWeaponBgRectangle';
import ModUtilityBgRectangle from '@battlefly/components/Icons/Mods/ModUtilityBgRectangle';
import { MODS_DEFS } from '@battlefly/components/Icons/Mods/ModBackground';
import ModDefenseBgRectangle from '@battlefly/components/Icons/Mods/ModDefenseBgRectangle';

interface ModFullBackgroundProps {
  type: ModType;
  rarity: Rarity;
  classes?: string;
}

const MOD_FULL_CLASS_BACKGROUND: Record<ModType, string> = {
  [ModType.Utility]: '/assets/images/mod-utility-bg.png',
  [ModType.Weapon]: '/assets/images/mod-weapon-bg.png',
  [ModType.Defense]: '/assets/images/mod-defense-bg.png',
};

const ModFullBackground: React.FC<ModFullBackgroundProps> = (props) => {
  const type = props.type.toLowerCase() as ModType;
  return (
    <div className={`${props.classes ?? props.classes}`}>
      <img
        className="h-auto w-full"
        src={MOD_FULL_CLASS_BACKGROUND[type]}
        alt={props.type}
      />
      {type === ModType.Weapon && (
        <ModWeaponBgRectangle
          rarity={props.rarity}
          fillColor={MODS_DEFS[props.rarity].compactGradientColor}
          className="absolute left-0 top-[4%] h-auto w-[97%]"
        />
      )}
      {type === ModType.Utility && (
        <ModUtilityBgRectangle
          rarity={props.rarity}
          fillColor={MODS_DEFS[props.rarity].compactGradientColor}
          className="absolute left-[6%] top-[9%] h-auto w-[81%]"
        />
      )}
      {type === ModType.Defense && (
        <ModDefenseBgRectangle
          rarity={props.rarity}
          fillColor={MODS_DEFS[props.rarity].compactGradientColor}
          className="absolute right-[2%] bottom-[2%] h-auto w-[89%]"
        />
      )}
    </div>
  );
};

export default ModFullBackground;
