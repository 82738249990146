import React from 'react';

import BalancePanel from 'components/BalancePanel';
import Breadcrumbs from 'components/Breadcrumbs';

type HeaderProps = {
  toggleWallet: () => void;
};

const Header = (props: HeaderProps) => {
  const { toggleWallet } = props;

  return (
    <nav
      id="__navigation"
      aria-roledescription="navigation"
      className="mr-4 hidden sm:block "
    >
      <div className="flex h-[50px] items-center justify-between">
        <div className="ml-24">
          <Breadcrumbs />
        </div>
        <BalancePanel toggleWallet={toggleWallet} />
      </div>
    </nav>
  );
};

export default Header;
