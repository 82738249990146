import { BattleFly } from '@battlefly/__generated__/globalTypes';

export enum BattleflyStage {
  cocoon = 'cocoon',
  battlefly = 'battlefly',
}

export enum BattleflyType {
  Genesis = 'Genesis',
  Edition = 'Edition',
}

export enum BattleflyClass {
  Edition = 'Edition',
  Original = 'Original',
}

export enum BattleflyEdition {
  'Genesis Cocoon' = 'Genesis Cocoon',
  'Anonymice' = 'Anonymice',
  'My Pet Hooligan' = 'My Pet Hooligan',
  'Mecha Chaotic' = 'Mecha Chaotic',
  'Toadstoolz' = 'Toadstoolz',
  'The Ascendants' = 'The Ascendants',
  'Rugburn' = 'Rugburn',
  'Habibiz' = 'Habibiz',
  'Tasty Bones' = 'Tasty Bones',
  'Kingdom' = 'Kingdom',
  'Katabolicz' = 'Katabolicz',
  'Scholarz' = 'Scholarz',
  'Peek-A-Boo' = 'Peek-A-Boo',
  'Copium Wars' = 'Copium Wars',
  'The Void' = 'The Void',
  'Nifted' = 'Nifted',
  'Rat Kingdom' = 'Rat Kingdom',
  'Project Godjira' = 'Project Godjira',
  'Kuroro Beasts' = 'Kuroro Beasts',
  'Origins' = 'Origins',
  'Ethernal Elves' = 'Ethernal Elves',
  'Imperative' = 'Imperative',
  'Merc DAO' = 'Merc DAO',
  'MVHQ' = 'MVHQ',
  'Nifty Nuggets' = 'Nifty Nuggets',
  'Smol Lizards' = 'Smol Lizards',
  'Smol Weapons' = 'Smol Weapons',
  'Takoa.eth' = 'Takoa.eth',
  'Treasure' = 'Treasure',
  'TribePass' = 'TribePass',
  'Shiny Object Club' = 'Shiny Object Club',
  'Ether Orcs' = 'Ether Orcs',
  'Neo Tokyo' = 'Neo Tokyo',
  'Generative Dungeons' = 'Generative Dungeons',
  'Chain Scouts' = 'Chain Scouts',
  'CryptoTesters' = 'CryptoTesters',
  'Smolverse' = 'Smolverse',
  'Funcles' = 'Funcles',
  'Kumo X World' = 'Kumo X World',
  'Hidden Society' = 'Hidden Society',
  'Chain Runners' = 'Chain Runners',
  'NFT Snipers' = 'NFT Snipers',
  'Karafuru' = 'Karafuru',
  'The Lost Donkeys' = 'The Lost Donkeys',
  'Dreamland Genesis' = 'Dreamland Genesis',
  'Arbiter Esports' = 'Arbiter Esports',
  'Uninterested Unicorn' = 'Uninterested Unicorn',
  'Veritas' = 'Veritas',
  'Illiquid Capital' = 'Illiquid Capital',
  'The Bears Deluxe' = 'The Bears Deluxe',
  'Dysto Apez' = 'Dysto Apez',
  'Icy Tools' = 'Icy Tools',
  'Shromies' = 'Shromies',
  'Club 721' = 'Club 721',
  'Anime Metaverse' = 'Anime Metaverse',
  'Syndicate 893' = 'Syndicate 893',
  'Tacha' = 'Tacha',
  'The Littles' = 'The Littles',
  'Sipher' = 'Sipher',
  'Elves' = 'Elves',
  'Alpacadabraz' = 'Alpacadabraz',
  'Mech Identity Protocol' = 'Mech Identity Protocol',
  'Seeds of Life' = 'Seeds of Life',
  'FOMO MOFO' = 'FOMO MOFO',
  'Crecoland' = 'Crecoland',
  'Tales of Elleria' = 'Tales of Elleria',
  'Crypties' = 'Crypties',
  'MOB Land' = 'MOB Land',
  'Llamaverse' = 'Llamaverse',
  'DAO Don' = 'DAO Don',
  'Kaiju Kings' = 'Kaiju Kings',
  'Frogland' = 'Frogland',
  'Tiny Ghost' = 'Tiny Ghost',
  'Gutter Cats' = 'Gutter Cats',
  'Valhalla' = 'Valhalla',
  'Ascended' = 'Ascended',
  'SSS' = 'SSS',
  'Scammed' = 'Scammed',
  'AcadArena' = 'AcadArena',
  'Best Notify' = 'Best Notify',
  'Wanderers' = 'Wanderers',
  'King Kong Capital' = 'King Kong Capital',
  'Unshift' = 'Unshift',
  'Furballs' = 'Furballs',
  '1/1 Iconics' = '1/1 Iconics',
  'Zen Academy' = 'Zen Academy',
  'KevinFly' = 'KevinFly',
  'Imperative 1/1' = 'Imperative 1/1',
  'Peace' = 'Peace',
  'Founder Genesis' = 'Founder Genesis',
}

export enum Rarity {
  Core = 'Core',
  Common = 'Common',
  Uncommon = 'Uncommon',
  Rare = 'Rare',
  Epic = 'Epic',
  Legendary = 'Legendary',
  Artefact = 'Artefact',
}

export enum Status {
  InInventory = 'InInventory',
  InGarden = 'InGarden',
  InHyperdome = 'InHyperdome',
  InSale = 'InSale',
}

interface BattleflyStats {
  hitPoints: number;
  hitPointsRegen: number;
  shieldPoints: number;
  shieldPointsRegen: number;
  evasion: number;
  criticalChange: number;
  criticalDamage: number;
  criticalResist: number;
  selfUpdateProtocol: number;
  scavenge: number;
  characteristics: {
    fusionBattery: number;
    sensorsArray: number;
    nanoFrame: number;
    engines: number;
    cpu: number;
  };
}

export type Mod = BaseMod & DefenseMod & WeaponMod;

export enum ModType {
  weapon = 'weapon',
  defense = 'defense',
  utility = 'utility',
}

interface BaseMod {
  id: string;
  name: string;
  appearance: { image: string; model: string };
  image: string;
  rarity: Rarity;
  type: ModType;
  equippedAt: string;
  effects: { description: string }[];
  slot: number;
}

export interface DefenseModStats {
  shieldPoints: number;
  armor: number;
}

export enum DamageType {
  Kinetic = 'Kinetic',
  Missile = 'Missile',
  Energy = 'Energy',
  Fusion = 'Fusion',
  Electric = 'Electric',
}

interface DefenseMod extends BaseMod {
  stats: DefenseModStats;
}

export interface WeaponModStats {
  damageType: DamageType;
  speed: number;
  reload: number;
  burst: number;
  damagePerFire: number;
}

interface WeaponMod extends BaseMod {
  stats: WeaponModStats;
}

export interface Battlefly extends Cocoon {
  appearance: { image: string };
  id: string;
  name: string;
  stage: BattleflyStage;
  image: string;
  description: string;
  type: BattleflyType;
  powerRating: number;
  class: BattleflyClass;
  edition: string | null;
  createdAt: string;
  rarity: Rarity;
  level: number;
  status: Status;
  experience: number;
  stats: BattleflyStats;
  mods: Mod[];
  revealedAt: string;
  isStaking: boolean;
  owner?: {
    id: string;
    address: string;
    role: string;
    wallets: Wallet[];
    createdAt: string;
    updatedAt: string;
  };
}

export interface Wallet {
  id: string;
  address: string;
  provider: string;
}

export interface Cocoon {
  appearance: { image: string };
  id: string;
  name: string;
  stage: BattleflyStage;
  image: string;
  description: string;
  type: BattleflyType;
  class: BattleflyClass;
  edition: string | null;
  createdAt: string;
}

export interface BattleflyWithMetadata extends BattleFly {
  isUnstaked: boolean;
  logs: any[];
}
