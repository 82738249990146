import useWindowDimensions from '@battlefly/hooks/useWindowDimensions';
import React, { useRef, useState } from 'react';
// @ts-ignore DO NOT INSTALL TYPES.
import { Document, Page, pdfjs } from 'react-pdf';
import workerSrc from '../../pdf-worker';
import ComicModalWrapper from './ComicModalWrapper';

pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

type Props = {
  handleClose: () => void;
  fileName: string;
};

const LeftArrow = () => {
  return (
    <svg
      width="3rem"
      height="3rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer transition-all hover:scale-125"
    >
      <path
        d="M17.0009 11.0002H9.41087L12.7109 7.71019C12.8992 7.52188 13.005 7.26649 13.005 7.00019C13.005 6.73388 12.8992 6.47849 12.7109 6.29019C12.5226 6.10188 12.2672 5.99609 12.0009 5.99609C11.7346 5.99609 11.4792 6.10188 11.2909 6.29019L6.29087 11.2902C6.19983 11.3853 6.12847 11.4974 6.08087 11.6202C5.98085 11.8636 5.98085 12.1367 6.08087 12.3802C6.12847 12.5029 6.19983 12.6151 6.29087 12.7102L11.2909 17.7102C11.3838 17.8039 11.4944 17.8783 11.6163 17.9291C11.7382 17.9798 11.8689 18.006 12.0009 18.006C12.1329 18.006 12.2636 17.9798 12.3854 17.9291C12.5073 17.8783 12.6179 17.8039 12.7109 17.7102C12.8046 17.6172 12.879 17.5066 12.9298 17.3848C12.9805 17.2629 13.0067 17.1322 13.0067 17.0002C13.0067 16.8682 12.9805 16.7375 12.9298 16.6156C12.879 16.4937 12.8046 16.3831 12.7109 16.2902L9.41087 13.0002H17.0009C17.2661 13.0002 17.5204 12.8948 17.708 12.7073C17.8955 12.5198 18.0009 12.2654 18.0009 12.0002C18.0009 11.735 17.8955 11.4806 17.708 11.2931C17.5204 11.1055 17.2661 11.0002 17.0009 11.0002Z"
        fill="white"
      />
    </svg>
  );
};

const RightArrow = () => {
  return (
    <svg
      width="3rem"
      height="3rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer transition-all hover:scale-125"
    >
      <path
        d="M17.92 11.6202C17.8724 11.4974 17.801 11.3853 17.71 11.2902L12.71 6.29019C12.6168 6.19695 12.5061 6.12299 12.3842 6.07253C12.2624 6.02207 12.1319 5.99609 12 5.99609C11.7337 5.99609 11.4783 6.10188 11.29 6.29019C11.1968 6.38342 11.1228 6.49411 11.0723 6.61594C11.0219 6.73776 10.9959 6.86833 10.9959 7.00019C10.9959 7.26649 11.1017 7.52188 11.29 7.71019L14.59 11.0002H7C6.73478 11.0002 6.48043 11.1055 6.29289 11.2931C6.10536 11.4806 6 11.735 6 12.0002C6 12.2654 6.10536 12.5198 6.29289 12.7073C6.48043 12.8948 6.73478 13.0002 7 13.0002H14.59L11.29 16.2902C11.1963 16.3831 11.1219 16.4937 11.0711 16.6156C11.0203 16.7375 10.9942 16.8682 10.9942 17.0002C10.9942 17.1322 11.0203 17.2629 11.0711 17.3848C11.1219 17.5066 11.1963 17.6172 11.29 17.7102C11.383 17.8039 11.4936 17.8783 11.6154 17.9291C11.7373 17.9798 11.868 18.006 12 18.006C12.132 18.006 12.2627 17.9798 12.3846 17.9291C12.5064 17.8783 12.617 17.8039 12.71 17.7102L17.71 12.7102C17.801 12.6151 17.8724 12.5029 17.92 12.3802C18.02 12.1367 18.02 11.8636 17.92 11.6202Z"
        fill="white"
      />
    </svg>
  );
};

const ComicViewerModal = (props: Props) => {
  const [divHeight, setDivHeight] = useState(0);
  const [mobileDivHeight, setMobileDivHeight] = useState(0);

  const dimensions = useWindowDimensions();
  const divRef = useRef(undefined);
  const mobileDivRef = useRef(undefined);

  const { handleClose, fileName } = props;
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setDivHeight(divRef?.current?.clientHeight * 1 ?? 150);
    setMobileDivHeight(mobileDivRef?.current?.clientHeight * 1 ?? 150);
    setNumPages(numPages);
    setPageNumber(1);
  }
  function previousPage() {
    const delta = pageNumber >= 3 ? -2 : 0;
    changePage(delta);
  }

  function nextPage() {
    const delta = numPages - pageNumber >= 2 ? 2 : numPages - pageNumber;
    changePage(delta);
  }

  return (
    <ComicModalWrapper>
      <div className="flex h-full min-h-[50vh] w-full flex-col p-4">
        <header className="relative mb-6 flex items-center justify-between">
          <div className="align-middle text-2xl md:text-4xl">Viewer</div>
          <div className="gra-019380 absolute left-1/2 flex -translate-x-1/2 rounded-md border-2 border-core-green">
            <div
              onClick={previousPage}
              className="flex items-center border-r-2 border-core-green"
            >
              <LeftArrow />
            </div>
            <div onClick={nextPage} className="flex items-center">
              <RightArrow />
            </div>
          </div>
          <div
            className="flex h-10 w-10 cursor-pointer items-center justify-center text-white"
            onClick={handleClose}
          >
            &#x2715;
          </div>
        </header>
        <Document
          ref={divRef}
          className="test grid max-w-[100%] grid-cols-1 justify-center md:grid-cols-2"
          file={fileName}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <>
            <div className="page-wrapper hidden justify-end sm:flex">
              <Page height={divHeight} pageNumber={pageNumber} />
            </div>
            <div className=" page-wrapper hidden justify-start sm:flex">
              {numPages > pageNumber && (
                <Page height={divHeight} pageNumber={pageNumber + 1} />
              )}
            </div>
          </>
          <>
            <div
              ref={mobileDivRef}
              className="page-wrapper flex justify-start sm:hidden"
            >
              {numPages > pageNumber && (
                <Page height={mobileDivHeight} pageNumber={pageNumber} />
              )}
            </div>
          </>
        </Document>
      </div>
    </ComicModalWrapper>
  );
};

export default ComicViewerModal;
