import { useEffect, useState } from 'react';
import bcrypt from 'bcryptjs';
import {
  CustomToast,
  ToastStatus,
} from '@battlefly/components/CustomToast/CustomToast';

export const usePasswordPage = () => {
  const pw = process.env.NEXT_PUBLIC_HASH_PASSWORD;
  let hashPassword: string;
  if (pw) {
    if (pw.indexOf('\\') > -1) {
      hashPassword = pw.replace(/\\/g, '');
    } else {
      hashPassword = pw;
    }
  }
  if (typeof window !== 'undefined') {
    (window as any).hashPassword = hashPassword;
  }
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (!hashPassword) {
      setLoggedIn(true);
      return;
    }

    const loggedInUser = localStorage.getItem('loggedInBeta');

    if (loggedInUser == 'true') {
      setLoggedIn(true);
    }
  }, []);

  const tryPassword = (e: any) => {
    bcrypt.compare(e, hashPassword, function (err, result) {
      if (result) {
        setLoggedIn(true);
        localStorage.setItem('loggedInBeta', 'true');
      }
      CustomToast({
        message: result ? 'Welcome!' : 'Incorrect Password',
        status: result ? ToastStatus.OK : ToastStatus.INFO,
      });
    });
  };

  return { loggedIn, tryPassword };
};
