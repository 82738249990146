import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { BattleflyLifetimeStats } from '@battlefly/ts/stats';

const NECTAR_PER_HYPERDOME_LOSS = 1;
const NECTAR_PER_PROVING_LOSS = 3;
const AFTER_TAX_MODIFIER = 0.095;

export const calculateStats = (
  battlefly: BattleFly
): BattleflyLifetimeStats => {
  const {
    hyperdomeLosses,
    hyperdomeWins,
    provingGroundsLosses,
    provingGroundsWins,
  } = battlefly;
  const wins = hyperdomeWins + provingGroundsWins;
  const losses = hyperdomeLosses + provingGroundsLosses;
  const totalFights = wins + losses;
  const winLossRatio = wins / losses || 0;
  const hyperdomeNectar = battlefly.lastHyperdomeCombatLost
    ? NECTAR_PER_HYPERDOME_LOSS
    : 0;
  const provingGroundsNectar = battlefly.lastProvingGroundsCombatLost
    ? NECTAR_PER_PROVING_LOSS
    : 0;

  return {
    wins,
    losses,
    totalFights,
    winRate: wins / totalFights || 0,
    totalNectar:
      hyperdomeLosses * NECTAR_PER_HYPERDOME_LOSS +
      provingGroundsWins * NECTAR_PER_PROVING_LOSS,
    lastNectarAmount: hyperdomeNectar || provingGroundsNectar,
    winLossRatio,
    winMagic: wins * AFTER_TAX_MODIFIER,
    lossMagic: losses * 0.1,
    profit: wins * AFTER_TAX_MODIFIER - losses * 0.1,
  };
};
