import React from 'react';
import Message from '@battlefly/components/Message';

const InactiveComicBlackFront = () => {
  return (
    <div className="absolute left-0 top-0 flex h-full w-full justify-items-center rounded-lg bg-black align-middle opacity-60">
      <Message text="This comic isn't active for mint now" display={true} />
    </div>
  );
};

export default InactiveComicBlackFront;
