import classnames from 'classnames';
import { Card } from 'components/core';
import { PersonalizedBattleLogItem } from '@battlefly/ts/BattleLog';
import WinLossIndicator from 'components/WinLossIndicator';
import BattleflyImageWithPlaceholder from '@battlefly/components/MissionControlGrid/BattleFlyCard/BattleflyImageWithPlaceholder';

type BattleLogItemProps = {
  log: PersonalizedBattleLogItem;
};

type NameAndRankProps = {
  name: string;
  rank: number;
  left?: boolean;
};
const NameAndRank = (props: NameAndRankProps) => {
  const { name, rank } = props;
  return (
    <div className="mt-2 flex flex-grow flex-col ">
      <div
        className={classnames('font-heading leading-4', {
          'text-right': !props.left,
        })}
      >
        {name}
      </div>
      <div
        className={classnames('text-xs font-light leading-[14px]', {
          'text-left': props.left,
          'text-right': !props.left,
        })}
      >
        {rank}
      </div>
    </div>
  );
};

const BattleLogItem = (props: BattleLogItemProps) => {
  const { isWinner, ownBattleFly, opponent } = props.log;
  return (
    <Card className="h-[62px] !p-1">
      <div className="flex h-full w-full gap-1">
        <div className="flex w-[46%] justify-between gap-1">
          <WinLossIndicator win={isWinner} />
          <div className="h-14 w-14">
            <BattleflyImageWithPlaceholder
              imageSrc={ownBattleFly.appearance.image}
              classes="h-[90%] w-[90%]"
              battleflyName={ownBattleFly.name}
            />
          </div>
          <NameAndRank
            left
            name={ownBattleFly.name}
            rank={ownBattleFly.placeInGlobalLeaderboard}
          />
        </div>
        <div className="w-[8%] text-center font-heading text-5xl text-core-grey">
          X
        </div>
        <div className="flex w-[46%] justify-between gap-1">
          <NameAndRank
            name={opponent.name}
            rank={opponent.placeInGlobalLeaderboard}
          />
          <div className="h-14 w-14">
            <BattleflyImageWithPlaceholder
              imageSrc={opponent.appearance.image}
              classes="h-[90%] w-[90%]"
              battleflyName={opponent.name}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default BattleLogItem;
