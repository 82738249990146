import React from 'react';
import { IconProps } from '@battlefly/components/Icons/index';

const NectarIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.3106 12.3307L11.7535 10.0398H14.6393L16.0822 12.3307L14.6393 14.6216H11.7535L10.3106 12.3307Z"
        fill="white"
      />
      <path
        d="M6.07816 13.3023L4.63527 15.5932L6.07816 17.8841H8.96393L10.4068 15.5932L8.96393 13.3023H6.07816Z"
        fill="white"
      />
      <path
        d="M10.5992 18.503L12.0421 16.2121H14.9279L16.3708 18.503L14.9279 20.7939H12.0421L10.5992 18.503Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9827 22.3061C15.3049 22.1711 15.6184 22.0172 15.9211 21.8452L15.7043 21.501L17.4804 18.6811H19.0738C19.17 18.5067 19.2592 18.329 19.3413 18.1486H17.4804L15.7043 15.3287L17.4804 12.5087H19.364C19.2737 12.331 19.1758 12.1537 19.0714 11.9763H17.0956L15.3195 9.15632L16.1921 7.77099L15.9608 7.43281L14.8763 9.15462H11.3241L9.54799 6.33467L11.3241 3.51472L13.281 3.51472L11.5611 1L7.70714 6.6009H9.10477L10.8809 9.42085L9.10477 12.2408H5.55255L4.70268 10.8914C3.73718 12.2572 3 13.5268 3 15.0084C3 17.1279 3.89691 19.1606 5.49341 20.6593C6.79119 21.8776 8.46484 22.6619 10.2585 22.9128L11.0355 21.6791H14.5877L14.9827 22.3061ZM9.64419 12.3307L11.4203 15.1506H14.9725L16.7486 12.3307L14.9725 9.51072H11.4203L9.64419 12.3307ZM3.96883 15.5932L5.74494 12.7732H9.29715L11.0733 15.5932L9.29715 18.4131H5.74494L3.96883 15.5932ZM9.93276 18.503L11.7089 21.323H15.2611L17.0372 18.503L15.2611 15.6831H11.7089L9.93276 18.503Z"
        fill="white"
      />
      <path
        d="M14.4453 22.511L14.2545 22.2081H11.3687L10.8838 22.9782C11.0924 22.9927 11.3023 23 11.513 23C12.5232 23 13.5148 22.8315 14.4453 22.511Z"
        fill="white"
      />
      <path
        d="M17.5327 20.6593C18.0021 20.2186 18.411 19.7318 18.7545 19.2101H17.8136L16.3708 21.501L16.4021 21.5507C16.8031 21.2867 17.1816 20.9889 17.5327 20.6593Z"
        fill="white"
      />
      <path
        d="M16.539 8.27827L15.986 9.15632L17.4289 11.4472H18.7435C18.4141 10.9397 18.0419 10.4245 17.6521 9.88513C17.5625 9.76113 17.4719 9.63579 17.3808 9.50902L16.539 8.27827Z"
        fill="white"
      />
      <path
        d="M20.0261 15.0084C20.0261 14.3157 19.8709 13.6693 19.6089 13.0378H17.8136L16.3708 15.3287L17.8136 17.6196H19.5588C19.8653 16.7874 20.0261 15.9057 20.0261 15.0084Z"
        fill="white"
      />
      <path
        d="M5.05778 10.3971L5.88577 11.7117H8.77155L10.2144 9.42085L8.77155 7.12996H7.34309L5.70605 9.50902C5.58724 9.67278 5.4692 9.83407 5.35266 9.99332L5.35243 9.99363L5.35186 9.9944C5.2526 10.13 5.15443 10.2642 5.05778 10.3971Z"
        fill="white"
      />
      <path
        d="M15.6138 6.92554L13.6429 4.04378L11.6573 4.04378L10.2144 6.33467L11.6573 8.62556H14.5431L15.6138 6.92554Z"
        fill="white"
      />
    </svg>
  );
};

export default NectarIcon;
