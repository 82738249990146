import { BattleflyGame, CONTRACT_ABIS } from '@battlefly/common/constants';
import { BigNumber } from 'ethers';
import { useCallback, useEffect, useState } from 'react';
import { useAccount, useContract, useContractReads, useProvider } from 'wagmi';
import { useContractAddresses } from './useContractAddresses';

const useContractDefaults = () => {
  const contractAddress = useContractAddresses();
  return {
    address: contractAddress[BattleflyGame.ComicContract],
    abi:CONTRACT_ABIS[BattleflyGame.ComicContract],
  };
};

const useFoundersV1Contract = () => {
  const contractAddress = useContractAddresses();
  return {
    address: contractAddress[BattleflyGame.FounderVaultV1],
    abi:CONTRACT_ABIS[BattleflyGame.FounderVaultV1],
  };
};

const useFoundersV2Contract = () => {
  const contractAddress = useContractAddresses();
  return {
    address: contractAddress[BattleflyGame.FounderVaultV2],
    abi:CONTRACT_ABIS[BattleflyGame.FounderVaultV2],
  };
};

export const useStakesOf = (address: string) => {
  const provider = useProvider();
  const foundersVaultV1 = useFoundersV1Contract();
  const foundersVaultV2 = useFoundersV2Contract();

  const [stakedTokenIds, setStakedTokenIds] = useState([]);

  const battleflyFounderVaultV1 = useContract({
    ...foundersVaultV1,
    signerOrProvider: provider,
  });

  const battleflyFounderVaultV2 = useContract({
    ...foundersVaultV2,
    signerOrProvider: provider,
  });

  const getStakesOf = useCallback(async () => {
    const stakedFoundersIds = await Promise.all([
      battleflyFounderVaultV1.stakesOf(address),
      battleflyFounderVaultV2.stakesOf(address),
    ]).then((values) => {
      const allTokenIds: number[] = [];
      values.forEach((value, index) => {
        value[1].forEach((staked: BigNumber[]) => {
          staked.map((id) => {
            allTokenIds.push(Number(id));
          });
        });
      });
      return allTokenIds;
    });

    return await stakedFoundersIds;
  }, [address, battleflyFounderVaultV1, battleflyFounderVaultV2]);

  useEffect(() => {
    if (address) {
      getStakesOf().then((response) => {
        setStakedTokenIds(response);
      });
    }
  }, [address, getStakesOf]);
  return { stakedTokenIds };
};

export const useFoundersVaultAvailableMints = (comicId: number) => {
  const { address } = useAccount();
  const { stakedTokenIds } = useStakesOf(address);
  const [mixedData, setMixedData] = useState([]);
  const contractDefaults = useContractDefaults();

  const prepareContracts = (tokenIds: number[]) => {
    return tokenIds.map((value) => {
      return {
        ...contractDefaults,
        functionName: 'usedTokens',
        args: [comicId, value],
      };
    });
  };

  const { isError, isLoading, data, refetch } = useContractReads({
    contracts: prepareContracts(stakedTokenIds),
    onSuccess(data) {
      setMixedData(
        data.map((item, index) => {
          if (index < stakedTokenIds.length)
            return {
              tokenId: stakedTokenIds[index],
              used: item,
            };
        })
      );
    },
    onError(e) {
      setMixedData(null);
    },
  });

  return { data, mixedData, isError, isLoading, refetch };
};
