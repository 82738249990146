import React from 'react';

import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { Card } from 'components/core';
import BattleflyImage from 'components/BattleflyImage';
import { shortenAddress, formatMoney, formatPercentage } from 'lib/utils';
import { calculateStats } from 'lib/mapper/stats';

type StatisticsPageProps = {
  loading: boolean;
  filter: 'user' | 'global';
  setFilter: (filter: 'user' | 'global') => void;
  data: any;
};

{
  /* <div className="absolute right-0 bottom-0 mr-1 flex gap-2">
<div className="text-xs">
  View ONLY <br /> your BattleFlys
</div>
<SwitchInput
  value={filter === 'user'}
  onChange={(value) => setFilter(value ? 'user' : 'global')}
/>
</div> */
}

type NumberCardProps = {
  label: string;
  value: number | string;
  isMagic?: boolean;
};

const StatCard = (props: NumberCardProps) => {
  const { label, value, isMagic } = props;
  return (
    <Card flat className="h-full !py-3 !px-4">
      <div className="flex flex-col items-center gap-2">
        <div className="font-heading text-xl">{label}</div>
        <div className="flex gap-2">
          <div className="font-heading text-4xl">{value}</div>
          {isMagic && <div className="mb-2 flex self-end text-xs">Magic</div>}
        </div>
      </div>
    </Card>
  );
};

const BattleflyCard = ({ battlefly }: { battlefly: Partial<BattleFly> }) => {
  return (
    <div className="gra-bf-card-border box-shadow-card inline-block rounded-2xl p-[2px]">
      <div className="gra-bf-card relative h-[240px] w-[280px] rounded-2xl">
        <BattleflyImage
          url={battlefly.appearance.image}
          containerClasses="!p-2 !pt-4"
          imageClassName="!w-[260px] !h-[200px]"
        />
      </div>
    </div>
  );
};

const getOwnerAddress = (battleFly: any) => {
  const address = battleFly.owner.user.wallets[0].address;
  return shortenAddress(address);
};

const StatisticsPage = (props: StatisticsPageProps) => {
  const { loading, data } = props;

  if (loading) {
    return <div>Loading...</div>;
  }

  const { totalBattleFlies, top } = data;
  const stats = calculateStats(top.topBattleFly);

  return (
    <div>
      <header className="mb-8">
        <h1 className="text-4xl">Battlegrounds Statistics</h1>
        <p className="invisible w-full text-[#D9D9D9] md:max-w-[30%]">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus
          facere non consequatur minus exercitationem vero, praesentium at
          delectus atque, aspernatur, dolorum illum odit.
        </p>
      </header>
      <section className="mb-6">
        <div className="auto-rows-max grid-cols-12 gap-4 xl:grid">
          <div className="col-span-5 mb-4 flex-grow xl:mb-0">
            <Card
              containerClassName="h-full"
              flat
              className="inline-block flex h-full flex-col justify-between font-heading text-xl"
            >
              <div className="">Total Number of BattleFlys</div>
              <div className="text-[96px] leading-[120px]">
                {totalBattleFlies}
              </div>
            </Card>
          </div>
          <div className="col-span-7">
            <Card flat>
              <div className="flex flex-col gap-4 md:flex-row">
                <div className="left flex flex-col justify-between gap-6 pb-8 md:w-7/12 xl:gap-0">
                  <div className="font-heading text-xl">Top Battlefly</div>
                  <div>
                    <div className="font-heading text-5xl leading-[30px] text-core-cyberpink">
                      {top.topBattleFly.name}
                    </div>
                    <div>Owned by: {getOwnerAddress(top.topBattleFly)} </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2">
                      <div>Win Ratio</div>
                      <div className="font-heading text-3xl">{formatPercentage(stats.winRate || 0)}</div>
                    </div>
                    <div className="w-1/2">
                      <div>Winnings in 24h</div>
                      <div className="flex gap-1">
                        <div className="font-heading text-3xl">
                          {formatMoney(top.topBattleFlyMagicWin)}
                        </div>
                        <div className="mb-2 flex self-end text-xs">Magic</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right text-center">
                  <BattleflyCard battlefly={top.topBattleFly} />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </section>
      <section>
        <div className="stat-card-grid">
          <StatCard label="# BFLY in the Hyperdome" value={totalBattleFlies} />
          <StatCard
            label="# BFLY in the proving ground"
            value={totalBattleFlies}
          />
          <StatCard
            label="No. of Battles in 24hrs"
            value={data.totalBattlesInDay}
          />
          <StatCard
            label="average no. daily battles"
            value={data.averageDailyBattles}
          />
          <StatCard
            isMagic
            label="total winnings in 24hrs"
            value={formatMoney(data.totalMagicWonInDay)}
          />
        </div>
      </section>
    </div>
  );
};

export default StatisticsPage;
