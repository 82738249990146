import { useEffect } from 'react';
import toast, { Toast, useToasterStore } from 'react-hot-toast';
import { useNetwork } from 'wagmi';
import { arbitrum, arbitrumGoerli } from 'wagmi/chains';

export enum ToastStatus {
  PENDING = 'PENDING',
  PENDING_SIGNATURE = 'PENDING_SIGNATURE',
  PENDING_SIGNATURE_REQUEST = 'PENDING_SIGNATURE_REQUEST',
  EXISTING_USER = 'EXISTING_USER',
  NOT_WHITELISTED = 'NOT_WHITELISTED',
  CHAIN_NOT_SUPPORTED = 'CHAIN_NOT_SUPPORTED',
  FAILURE = 'FAILURE',
  REJECTED = 'REJECTED',
  SUCCESS = 'SUCCESS',
  WALLET_CONNECTED = 'WALLET_CONNECTED',
  WALLET_DISCONNECTED = 'WALLET_DISCONNECTED',
  SUFFICIENT_MAGIC_BALANCE = 'SUFFICIENT_MAGIC_BALANCE',
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  REFRESH = 'REFRESH',
  LOADING = 'LOADING',
  INFO = 'INFO',
  OK = 'OK',
}

type ToastType = {
  status: ToastStatus;
  withTitle?: boolean;
  message?: string;
  account?: string;
  hash?: string;
  t?: Toast;
  customTitleText?: string;
};

export const getColor = (status: string) => {
  const colors: {
    [key: string]: { color: string; icon: string; description: string };
  } = {
    EXISTING_USER: {
      color: `transition bg-core-purple hover:bg-core-purple-highlight`,
      icon: '/assets/icons/transaction-success.svg',
      description: 'Welcome Back',
    },
    REFRESH: {
      color: `transition bg-core-purple hover:bg-core-purple-highlight`,
      icon: '/assets/icons/transaction-success.svg',
      description: 'Please Refresh After Switching Networks',
    },
    SUFFICIENT_MAGIC_BALANCE: {
      color: `transition bg-core-purple hover:bg-core-purple-highlight`,
      icon: '/assets/icons/transaction-success.svg',
      description: 'Sufficient Magic Balance',
    },
    LOADING: {
      color: `transition bg-core-purple hover:bg-core-purple-highlight`,
      icon: '/assets/icons/transaction-success.svg',
      description: 'Loading...',
    },
    PENDING: {
      color: `transition bg-core-purple hover:bg-core-purple-highlight`,
      icon: '/assets/icons/transaction-submit.svg',
      description: 'Transaction Submitted',
    },
    PENDING_SIGNATURE_REQUEST: {
      color: `transition bg-core-purple hover:bg-core-purple-highlight`,
      icon: '/assets/icons/transaction-success.svg',
      description: 'Pending Signature Request. Please Check Wallet.',
    },
    PENDING_SIGNATURE: {
      color: `transition bg-core-purple hover:bg-core-purple-highlight`,
      icon: '/assets/icons/transaction-success.svg',
      description: 'Pending...',
    },
    CHAIN_NOT_SUPPORTED: {
      color: `transition bg-core-red hover:bg-core-red-highlight`,
      icon: '/assets/icons/transaction-failure.svg',
      description: 'Chain Not Supported',
    },
    REJECTED: {
      color: `transition bg-core-red hover:bg-core-red-highlight`,
      icon: '/assets/icons/transaction-failure.svg',
      description: 'Rejected',
    },
    WALLET_CONNECTED: {
      color: `transition bg-core-green hover:bg-core-green-highlight`,
      icon: '/assets/icons/transaction-success.svg',
      description: 'Wallet Connected',
    },
    SUCCESS: {
      color: `transition bg-core-green hover:bg-core-green-highlight`,
      icon: '/assets/icons/transaction-success.svg',
      description: 'Transaction Confirmed',
    },
    WALLET_DISCONNECTED: {
      color: `transition bg-core-purple hover:bg-core-purple-highlight`,
      icon: '/assets/icons/transaction-success.svg',
      description: 'Wallet Disconnected',
    },
    INSUFFICIENT_BALANCE: {
      color: `transition bg-core-orange hover:bg-core-orange-highlight`,
      icon: '/assets/icons/transaction-warning.svg',
      description: 'Insufficient Balance',
    },
    INFO: {
      color: `transition bg-core-orange hover:bg-core-orange-highlight`,
      icon: '/assets/icons/transaction-warning.svg',
      description: 'Info',
    },
    NOT_WHITELISTED: {
      color: `transition bg-core-red hover:bg-core-red-highlight`,
      icon: '/assets/icons/transaction-whitelist.svg',
      description: 'Whitelist',
    },
    OK: {
      color: `transition bg-core-green hover:bg-core-green-highlight`,
      icon: '/assets/icons/transaction-success.svg',
      description: 'Success',
    },
  };
  if (colors[status]) return colors[status];
  return colors[ToastStatus.PENDING];
};

const isCenteringTextInToast = (status: string, message: string) => {
  const centers = [
    ToastStatus.WALLET_CONNECTED,
    ToastStatus.WALLET_DISCONNECTED,
    ToastStatus.EXISTING_USER,
    ToastStatus.SUFFICIENT_MAGIC_BALANCE,
    ToastStatus.INFO,
    ToastStatus.LOADING,
    ToastStatus.OK,
  ];
  return centers.some((el) => el === status) && !message;
};

const useHashlink = (hash: string) => {
  const { chain: activeChain } = useNetwork();

  const supportedChain = {
    [arbitrumGoerli.id]: `https://goerli.arbiscan.io/tx`,
    [arbitrum.id]: `${arbitrum.blockExplorers.default.url}/tx`,
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const hashLink = `${supportedChain[activeChain?.id]}/${hash}`;
  return hashLink;
};

export const CustomToast = ({
  status,
  message,
  account,
  hash,
  withTitle = true,
  customTitleText,
}: ToastType) => {
  return toast.custom(
    (t) => (
      <div
        onClick={() => toast.dismiss(t.id)}
        style={{ maxWidth: '450px' }}
        className={`flex w-full flex-col justify-center gap-2 rounded ${
          getColor(status).color
        } p-4 ${t.visible ? 'fade-in-toast' : 'fade-out-toast'}`}
      >
        {withTitle && (
          <ToastTitle
            status={status}
            message={message}
            account={account}
            customTitleText={customTitleText}
          />
        )}
        {message && <ToastMessage message={message} hash={hash} />}
      </div>
    ),
    { id: status }
  );
};

const ToastTitle = ({
  status,
  message,
  account,
  customTitleText,
}: ToastType) => {
  return (
    <div className="flex">
      <img src={getColor(status).icon} className="pr-4" />
      <div
        className={`flex ${
          isCenteringTextInToast(status, message) && `justify-center`
        } w-full text-center text-sm font-bold`}
      >
        {!customTitleText && getColor(status).description + ' '}
        {(customTitleText && customTitleText) ||
          (ToastStatus.EXISTING_USER && account)}
      </div>
    </div>
  );
};
export const ToastMessage = ({
  message,
  hash,
}: {
  message: string;
  hash: string;
}) => {
  const hashLink = useHashlink(hash);
  return (
    <div className="flex items-center justify-between">
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {hash ? (
          <a href={hashLink} target="_blank" rel="noreferrer">
            {message}
          </a>
        ) : (
          <>{message}</>
        )}
      </div>
      {hash && <TransactionLink hash={hash} />}{' '}
    </div>
  );
};

const TransactionLink = ({ hash }: { hash: string }) => {
  const hashLink = useHashlink(hash);
  return (
    <a
      style={{ width: '20px' }}
      href={hashLink}
      target="_blank"
      rel="noreferrer"
    >
      <img src="/assets/icons/transaction-link.svg" className="h-full w-full" />
    </a>
  );
};

export const pendingSignatureToast = () =>
  CustomToast({
    status: ToastStatus.PENDING_SIGNATURE,
  });

export const infoToast = (message: string) =>
  CustomToast({
    ...(message && { message }),
    status: ToastStatus.PENDING_SIGNATURE,
  });

export const pendingTrxToast = () =>
  CustomToast({
    status: ToastStatus.PENDING,
  });

export const successToast = (message: string, trx: any) =>
  CustomToast({
    ...(message && { message }),
    status: ToastStatus.SUCCESS,
    hash: trx.hash || trx.transactionHash,
  });

type ErrorMsg = {
  data?: { message: string };
  message?: string;
};

export const failureToast = (error: ErrorMsg | string) => {
  if ('string' === typeof error) {
    CustomToast({
      status: ToastStatus.REJECTED,
      message: error,
    });
    return;
  }
  CustomToast({
    status: ToastStatus.REJECTED,
    message: error.data ? error.data.message : error.message,
  });
};

export const useCustomToastRules = () => {
  const { toasts } = useToasterStore();
  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i === 3) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);
};
