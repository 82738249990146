import React from 'react';
import classnames from 'classnames';

import { Card, Link } from 'components/core';
import Chevron from 'components/Accordion/Chevron';
import { BattleLog } from '@battlefly/ts/BattleLog';
import WinLossIndicator from 'components/WinLossIndicator';
import BattleflyImageWithPlaceholder from 'components/MissionControlGrid/BattleFlyCard/BattleflyImageWithPlaceholder';
import BattleflyLeagueIcon from 'components/BattleflyLeagueIcon';
import Loadout from '@battlefly/components/Loadout';
import StarsRating from '@battlefly/components/Rating';
import { MobileOnly, DesktopOnly } from 'components/utility';
import {
  NameAndRarity,
  Name,
  Rarity,
  Ranking,
  DateTime,
} from 'components/lists/utils';

export type BattleLogListItemProps = {
  log: BattleLog;
};

const BattleLogListItem = (props: BattleLogListItemProps) => {
  const { isWinner, battleflies, matchTimestamp, id } = props.log;
  const [battlefly1, battlefly2] = battleflies;
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <Card className="relative max-w-[1280px] px-4 py-2 pr-6">
      <MobileOnly>
        <DateTime date={matchTimestamp} className="flex gap-2" />
      </MobileOnly>
      <div>
        <div className="flex items-center gap-4">
          <div className="flex h-full w-full gap-2 md:w-[calc(100%-60px)]">
            <div className="flex w-[48%] items-center justify-between gap-2">
              <div className="h-12">
                <WinLossIndicator win={isWinner} />
              </div>
              <DesktopOnly className="h-16 w-16 min-w-[40px]">
                <Link to={`/battleflies/view/${battlefly1.id}/battlelog/${id}`}>
                  <BattleflyImageWithPlaceholder
                    imageSrc={battlefly1.appearance.image}
                    classes="h-[90%] w-[90%]"
                    battleflyName={battlefly1.name}
                  />
                </Link>
              </DesktopOnly>
              <NameAndRarity>
                <Name>
                  <Link
                    to={`/battleflies/view/${battlefly1.id}/battlelog/${id}`}
                  >
                    {battlefly1.name}
                  </Link>
                </Name>
                {/* <Name>Anachronos Xiloscient</Name> */}
                {/* <Name>Kadaung - Battlefly x Blackpool</Name> */}
                <DesktopOnly>
                  <Rarity>{battlefly1.rarity}</Rarity>
                </DesktopOnly>
              </NameAndRarity>

              <DesktopOnly>
                <BattleflyLeagueIcon
                  use="default"
                  league={battlefly1.league}
                  classes="w-10 h-10"
                />
              </DesktopOnly>
              <DesktopOnly xl>
                <StarsRating total={5} filled={battlefly1.starsRate} />
              </DesktopOnly>
              <DesktopOnly>
                <Ranking>{battlefly1.placeInGlobalLeaderboard}</Ranking>
              </DesktopOnly>
            </div>
            <div className="w-[6%] text-center font-heading text-5xl text-core-grey">
              X
            </div>
            <div className="flex w-[48%] items-center justify-between gap-2">
              <DesktopOnly>
                <Ranking>{battlefly2.placeInGlobalLeaderboard}</Ranking>
              </DesktopOnly>

              <DesktopOnly xl>
                <StarsRating total={5} filled={battlefly2.starsRate} />
              </DesktopOnly>

              <DesktopOnly>
                <BattleflyLeagueIcon
                  use="default"
                  league={battlefly2.league}
                  classes="w-10 h-10"
                />
              </DesktopOnly>
              <NameAndRarity>
                <Name className="text-right">{battlefly2.name}</Name>
                <DesktopOnly>
                  <Rarity className="text-right">{battlefly2.rarity}</Rarity>
                </DesktopOnly>
              </NameAndRarity>
              <DesktopOnly className="h-16 w-16 min-w-[40px]">
                <BattleflyImageWithPlaceholder
                  imageSrc={battlefly2.appearance.image}
                  classes="h-[90%] w-[90%]"
                  battleflyName={battlefly2.name}
                />
              </DesktopOnly>
              <div className="h-12">
                <WinLossIndicator win={!isWinner} />
              </div>
            </div>
          </div>

          <div className="hidden w-[60px] items-center justify-between gap-1 self-stretch md:flex">
            <DateTime date={matchTimestamp} className="mb-2 self-end" />
          </div>
        </div>
        {isExpanded && (
          <div className="mb-8 flex gap-2 overflow-scroll py-4">
            <div className="ml-8">
              <Loadout
                mods={battlefly1.mods}
                elementClasses="w-[100px] !h-[100px]"
                modClasses="w-[100px] !h-[100px]"
              />
            </div>
            <div className="ml-32">
              <Loadout
                mods={battlefly2.mods}
                elementClasses="w-[100px] !h-[100px]"
                modClasses="w-[100px] !h-[100px]"
              />
            </div>
          </div>
        )}
        <div
          className={classnames(
            'absolute right-2 hidden h-full w-8 cursor-pointer md:flex',
            {
              'bottom-0': isExpanded,
              'top-0': !isExpanded,
            }
          )}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Chevron
            className={classnames('h-6 w-6', {
              'mb-4 -rotate-90 transform self-end': isExpanded,
              'mt-2 rotate-90 self-start': !isExpanded,
            })}
            fill="white"
          />
        </div>
      </div>
    </Card>
  );
};

export default BattleLogListItem;
