import React from 'react';
import { IconProps } from '@battlefly/components/Icons';

const StatShieldIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 3.375C16 3.125 15.8125 2.875 15.5625 2.8125L8.1875 0.25C8.0625 0.25 7.9375 0.25 7.8125 0.25L0.375 2.75C0.3125 2.8125 0.1875 2.875 0.125 2.9375L5.125 4.5625C5.4375 4.6875 5.625 5 5.5 5.375C5.375 5.75 5 5.875 4.6875 5.75L0 4.25C0 4.6875 -6.0536e-08 5.125 0.0624999 5.5625L5.125 7.25C5.4375 7.375 5.625 7.6875 5.5 8.0625C5.3125 8.5625 4.875 8.5 4.6875 8.4375L0.1875 6.9375C0.25 7.4375 0.375 7.875 0.5 8.375L5.125 9.9375C5.4375 10.0625 5.625 10.375 5.5 10.75C5.375 11.1875 4.875 11.1875 4.6875 11.125L0.9375 9.875C2.125 13 4.3125 15.5625 7.625 17.6875C7.8125 17.8125 8.0625 17.8125 8.3125 17.6875C13.6875 14.1875 16.25 9.5 16 3.375Z"
        fill="white"
      />
    </svg>
  );
};

export default StatShieldIcon;
