import classnames from 'classnames';

import { ScavengingMarkType } from '__generated__/globalTypes';
import { SCAVENGING_MARKS_DEFS } from 'common/battleflyMarks';
import { Card, Button } from 'components/core';

const orderedMarksTypes = [
  ScavengingMarkType.Bronze,
  ScavengingMarkType.Silver,
  ScavengingMarkType.Gold,
];

type DefaultProps = {
  handleSelect: (mark: ScavengingMarkType) => void;
  handleConfirm: () => void;
  amount: number;
  selectedMark: ScavengingMarkType;
};

const DefaultView = (props: DefaultProps) => {
  const { handleSelect, handleConfirm, amount, selectedMark } = props;
  return (
    <>
      <section className="mb-12">
        <h1 className="text-5xl md:text-3xl">Scavenging Marks</h1>
        <p className="leading-[19px]">
          Each of these Scavenging Marks will improve your BattleFly&apos;s
          ability to scavenger rarer mod. You can purchase a mark using Nectar -
          but be warned, your mark must be used within 7 days or it will expire.
          Also, your BattleFly can only be equipped with one Scavenging Mark at
          a time, so choose wisely.
        </p>
      </section>
      <section className="-mx-5 mb-12 flex justify-between gap-2 md:mx-0">
        {orderedMarksTypes.map((markName: ScavengingMarkType) => (
          <div
            key={markName}
            className="flex cursor-pointer flex-col items-center justify-between gap-3"
          >
            <Card
              onClick={() => handleSelect(markName as ScavengingMarkType)}
              className={classnames(
                'relative h-[88px] w-[100px] overflow-hidden !px-2 !pb-0 !pt-1 md:h-[144px] md:w-[167px]',
                {
                  active: selectedMark === markName,
                }
              )}
              mark
            >
              <div className="absolute left-0 bottom-0 h-full w-full overflow-hidden md:mx-3 md:mb-3">
                {SCAVENGING_MARKS_DEFS[markName].icon({
                  className:
                    'rotate-[22deg] top-0 w-[200px] h-auto -translate-x-5 md:-translate-x-10 md:translate-y-2',
                })}
              </div>
            </Card>
            <div className="font-heading text-3xl">{markName}</div>
            <div className="text-center leading-[19px]">
              {SCAVENGING_MARKS_DEFS[markName].description}
            </div>
          </div>
        ))}
      </section>
      <section className="flex justify-center">
        <div className="w-72">
          <Button blueMint onClick={handleConfirm} disabled={!amount}>
            Buy {amount ? `(${amount} Nectar)` : ''}
          </Button>
        </div>
      </section>
    </>
  );
};

export default DefaultView;
