import { CONTRACT_ADDRESSES } from '@battlefly/common/constants';
import { useNetwork } from 'wagmi';
import { arbitrum } from 'wagmi/chains';

export const DEFAULT_CHAIN = arbitrum;

export const useContractAddresses = () => {
  const { chain: activeChain } = useNetwork();
  const chainName = activeChain?.name;

  if (!chainName || !CONTRACT_ADDRESSES[chainName]) {
    return CONTRACT_ADDRESSES[DEFAULT_CHAIN.name];
  }

  return CONTRACT_ADDRESSES[chainName];
};
