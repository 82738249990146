import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';

import { GET_MY_STAKED_BATTLEFLYS } from '@battlefly/graphql/userGql';
import { GET_LEAGUE_DATA } from '@battlefly/graphql/arenasGql';
import { LeagueTrackPage } from 'components/pages';

const LeagueRoute = () => {
  const { id: battleflyId } = useParams();
  const { data, loading } = useQuery(GET_MY_STAKED_BATTLEFLYS);
  const { data: leagueData, loading: leagueDataLoading } =
    useQuery(GET_LEAGUE_DATA);
  const mappedLeagueData = leagueData?.getLeagues || [];
  const battleflies = data?.me?.battleFlies || [];

  if (loading || leagueDataLoading) {
    // <Spinner pending={true} />
    return <div>Loading...</div>;
  }

  return (
    <div className="mx-0 mb-8 w-full max-w-[100%] p-4 sm:mx-8 sm:mt-4 md:p-0 wxga+:max-w-[1560px]">
      <LeagueTrackPage
        leagueData={mappedLeagueData}
        battleflyId={battleflyId}
        battleflies={battleflies}
      />
    </div>
  );
};

export default LeagueRoute;
