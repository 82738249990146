import React from 'react';

import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { BattleflyWithMetadata } from 'ts/BattleflyType';
import Message from 'components/Message';
import { useStakeBattlefly } from 'hooks/useStakeBattlefly';
import { BattleflyContext } from 'context/battleflyContext';
import { ModalContext } from 'context/modalContext';
import { filterBattleflysByFilters, filterBattleflysByName } from 'util/rift';
import { dynamicSortBattleFly } from 'util/dynamicSortBattleFly';

import Controls from './Controls';
import SortingControls from './SortingControls';
import CocoonListItem from './CocoonListItem';
import BattleflyListItem from './BattleflyListItem';

type MissionControlProps = {
  loading: boolean;
  battleflies: (BattleflyWithMetadata | number)[];
};

const Input = ({ onChange, checked }: { onChange: any; checked: boolean }) => {
  return (
    <input
      checked={checked}
      type="checkbox"
      className="checkbox-round"
      onChange={onChange}
    />
  );
};

const MissionControlPage = (props: MissionControlProps) => {
  const { loading, battleflies } = props;
  const [filters, setFilters] = React.useState(null);
  const [sorting, setSorting] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [selected, setSelected] = React.useState([]);

  const { isUnstaked } = React.useContext(BattleflyContext);
  const { setBattleSettings } = React.useContext(ModalContext);
  const { unstakeBattlefly, stakeBattlefly } = useStakeBattlefly();

  const filteredBattleflies = React.useMemo(() => {
    let list: BattleflyWithMetadata[] = battleflies as BattleflyWithMetadata[];
    list = filterBattleflysByName(
      list as unknown as BattleflyWithMetadata[],
      searchValue
    ) as BattleflyWithMetadata[];
    list = filterBattleflysByFilters(
      list as unknown as BattleflyWithMetadata[],
      filters
    );
    if (sorting) {
      list.sort(
        dynamicSortBattleFly({
          property: sorting.property,
          sortOrder: sorting.sortOrder,
        } as any)
      );
    }
    return list;
  }, [battleflies, searchValue, filters, sorting]);

  function handleSearch(value: string) {
    setSearchValue(value);
  }

  function handleClearFilters() {
    setSearchValue('');
    setFilters({
      Status: [],
      Class: [],
      Type: [],
      Rarity: [],
      Edition: [],
    });
    setSorting(null);
  }

  function toggleSelect(id: string | number) {
    if (selected.includes(id)) {
      setSelected(selected.filter((el: number) => el !== id));
    } else {
      setSelected([...selected, id]);
    }
  }

  function handleSelectAll() {
    if (selected.length === filteredBattleflies.length) {
      setSelected([]);
    } else {
      setSelected(
        filteredBattleflies.map((bf) => (typeof bf === 'number' ? bf : bf.id))
      );
    }
  }

  function handleStake() {
    const stakeable = selected.filter((battleflyId) => isUnstaked(battleflyId));
    stakeBattlefly(stakeable);
  }

  function handleUnstake() {
    const unstakable = selected.filter(
      (battleflyId) => !isUnstaked(battleflyId)
    );
    unstakeBattlefly(unstakable);
  }

  function handleBattleSettings() {
    const bfs: BattleFly[] = selected
      .filter((battleflyId) => !isUnstaked(battleflyId))
      .map((battleflyId) => {
        return battleflies.find((battlefly) => {
          if (typeof battlefly !== 'number') {
            return battlefly.id === battleflyId;
          }
        }) as BattleflyWithMetadata;
      });
    setBattleSettings({ battleflies: bfs });
  }

  return (
    <div className="border-radius-5px w-full">
      <div className="mt-12 mb-8 flex">
        <div className="font-heading text-3xl md:text-5xl">Mission Control</div>
      </div>
      <Controls
        handleBattleSettings={handleBattleSettings}
        filters={filters}
        setFilters={setFilters}
        handleClearFilters={handleClearFilters}
        searchValue={searchValue}
        handleSearch={handleSearch}
        handleStake={handleStake}
        handleUnstake={handleUnstake}
      />
      <div className="mb-2 text-[12px] text-[#D9D9D9] mobile:hidden">
        <SortingControls
          handleSelectAll={handleSelectAll}
          sorting={sorting}
          setSorting={setSorting}
        />
      </div>

      <div className="flex flex-col gap-3">
        {filteredBattleflies.length > 0 &&
          !loading &&
          filteredBattleflies.map(
            (battlefly: BattleflyWithMetadata | number) => {
              if (typeof battlefly !== 'number') {
                return (
                  <div
                    id={battlefly.name}
                    className="flex items-center gap-2"
                    key={battlefly.id}
                  >
                    <div className="w-full md:w-[95%]">
                      <BattleflyListItem battlefly={battlefly} />
                    </div>
                    <div className="hidden justify-center md:flex md:w-[5%] ">
                      <Input
                        checked={selected.includes(battlefly.id)}
                        onChange={() => toggleSelect(battlefly.id)}
                      />
                    </div>
                  </div>
                );
              }
              return (
                <div className="flex items-center gap-2" key={battlefly}>
                  <div className="w-full md:w-[95%]">
                    <CocoonListItem key={battlefly} />
                  </div>
                  <div className="hidden justify-center md:flex md:w-[5%] ">
                    <Input
                      checked={selected.includes(battlefly)}
                      onChange={() => toggleSelect(battlefly)}
                    />
                  </div>
                </div>
              );
            }
          )}
        {filteredBattleflies.length == 0 && !loading && (
          <Message
            className={'grig-co'}
            text={`${loading ? `Loading...` : `None Found...`}`}
            display={true}
          />
        )}
        {loading && <Message text="Loading..." display={true} />}
      </div>
    </div>
  );
};

export default MissionControlPage;
