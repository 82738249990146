import ConnectWalletIcon from '../../public/assets/icons/connect-wallet.png';
import Button from './Button';

interface ConnectWalletButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  wrongNetwork?: boolean;
  account?: string;
  avatarImg?: string;
}

const ConnectWalletButton = ({
  avatarImg,
  onClick,
  children,
  wrongNetwork,
}: ConnectWalletButtonProps) => {
  let background = `linear-gradient(137.42deg, rgba(123, 97, 255, 0.5) 5%, rgba(15, 15, 55, 0) 93.91%)`;
  let className =
    'flex items-center gap-2 border-2 border-primary bg-gradient-to-br px-0 pl-2 hover:border-primary-hover rounded-sm ';
  if (wrongNetwork) {
    background = `linear-gradient(137.42deg, rgba(255, 97, 255, 0.5) 5%, rgba(15, 15, 55, 0) 93.91%)`;
    className = `flex items-center gap-2 border-2 border-core-red bg-gradient-to-br px-0 pl-2 hover:border-core-red-highlight`;
  }
  return (
    <Button
      onClick={onClick}
      type="connectWallet"
      style={{
        background,
      }}
      className={className}
    >
      <div className="fade-in relative flex h-full w-full items-center justify-between gap-2 rounded-md transition-all hover:scale-[1.02]">
        <div className="w-[38px] h-[30px] relative">
          <img
            className="rounded-full absolute w-[38px] h-[38px] left-0 top-[-4px]"
            alt="connectWalletIcon"
            onError={(event: any) => (event.target.src = ConnectWalletIcon.src)}
            src={avatarImg ? avatarImg : ConnectWalletIcon.src}
          ></img>
        </div>
        <div className="flex w-max items-center gap-2 whitespace-nowrap pr-2">
          {children}
        </div>
      </div>
    </Button>
  );
};

export const MobileConnectWalletButton = ({
  avatarImg,
  onClick,
  account,
  wrongNetwork,
}: Partial<ConnectWalletButtonProps>) => {
  if (wrongNetwork) {
    return (
      <button
        style={{
          background: `linear-gradient(137.42deg, rgba(255, 97, 255, 0.5) 5%, rgba(15, 15, 55, 0) 93.91%)`,
        }}
        className="flex gap-4 rounded-lg border-2 border-core-red p-2"
        onClick={() => onClick()}
      >
        <div className="w-[21px]">
          <img
            alt="connectWalletIcon"
            className="false rounded-full"
            src={avatarImg ? avatarImg : ConnectWalletIcon.src}
          />
        </div>
        <div className="w-[21px]">
          <img
            alt="connectWalletIcon"
            className="false"
            src="/assets/green-check.svg"
          />
        </div>
      </button>
    );
  }
  return (
    <>
      {!account && (
        <Button
          type="primary"
          className="!rounded-sm !px-6"
          onClick={() => onClick()}
        >
          Connect
        </Button>
      )}
      {account && (
        <button
          style={{
            background: `linear-gradient(137.42deg, rgba(123, 97, 255, 0.5) 5%, rgba(15, 15, 55, 0) 93.91%)`,
          }}
          className="flex gap-4 rounded-sm border-2 border-core-purple p-2"
          onClick={() => onClick()}
        >
          <div className="w-[21px]">
            <img
              alt="connectWalletIcon"
              className="false roudned-full"
              src={avatarImg ? avatarImg : ConnectWalletIcon.src}
            />
          </div>
          <div className="w-[21px]">
            <img
              alt="connectWalletIcon"
              className="false"
              src="/assets/green-check.svg"
            />
          </div>
        </button>
      )}
    </>
  );
};

export default ConnectWalletButton;
