import React from 'react';

import { Pill } from 'components/core';
import SearchBar, { SearchBarStyle } from 'components/SearchBar/index';
import InfoComponent from 'components/InfoComponent';
import { BattleflyBattleStatuses } from 'ts/battleStatus';

import useApproveMagic from '@battlefly/hooks/contractsNew/useApproveMagic';
import { BattleflyGame } from '@battlefly/common/constants';
import {
  useIsApprovedForAll,
  useSetApprovalForAll,
} from '@battlefly/hooks/contractsNew/useBattleflyContract';
import { useContractAddresses } from '@battlefly/hooks/contractsNew/useContractAddresses';

type ControlsProps = {
  filters: any;
  setFilters: any;
  handleClearFilters: () => void;
  searchValue: string;
  handleSearch: (event: any) => void;
  handleStake: () => void;
  handleUnstake: () => void;
  handleBattleSettings: () => void;
};

type StakeHandlerProps = {
  handleStake: () => void;
  handleApproveMagic: () => void;
  isMagicApproved: boolean;
  handleApproveRevealStaking: () => void;
  isRevealStakingApproved: boolean;
};

const StakeHandler = (props: StakeHandlerProps) => {
  const {
    handleStake,
    handleApproveMagic,
    isMagicApproved,
    handleApproveRevealStaking,
    isRevealStakingApproved,
  } = props;

  if (!isMagicApproved) {
    return (
      <button
        onClick={handleApproveMagic}
        className="mx-[10px] cursor-pointer underline hover:no-underline"
      >
        Approve Magic
      </button>
    );
  }
  if (!isRevealStakingApproved) {
    return (
      <button
        onClick={handleApproveRevealStaking}
        className="mx-[10px] cursor-pointer underline hover:no-underline"
      >
        Approve RevealStaking
      </button>
    );
  }

  return (
    <button
      onClick={handleStake}
      className="mx-[10px] cursor-pointer underline hover:no-underline"
    >
      Stake
    </button>
  );
};

const Controls = (props: ControlsProps) => {
  const {
    filters,
    setFilters,
    handleClearFilters,
    searchValue,
    handleSearch,
    handleStake,
    handleUnstake,
    handleBattleSettings,
  } = props;

  const contractAddress = useContractAddresses();

  const {
    approveMagic: approveMagicForRevealStakingContract,
    isLoading: loadingMagicApprovalForRevealStaking,
    isMagicApproved: isMagicApprovedForRevealStaking,
  } = useApproveMagic(contractAddress[BattleflyGame.RevealStakingContract]);

  const { setApprovalForAll: setRevealStakingContractApprovalForAll } =
    useSetApprovalForAll(contractAddress[BattleflyGame.RevealStakingContract]);

  const {
    isApproved: isRevealStakingApproved,
    isLoading: pendingApproveRevealStaking,
  } = useIsApprovedForAll(contractAddress[BattleflyGame.RevealStakingContract]);

  return (
    <div className="flex flex-wrap justify-between gap-3">
      <div className="grow">
        <div className="mb-[13px] flex items-center gap-3 mobile:flex-wrap">
          <h2 className="leading-none">Filter by:</h2>
          <div className="flex gap-2 mobile:flex-wrap">
            <Pill
              color="#FF2079"
              selected={filters?.Status.includes(
                BattleflyBattleStatuses.Battling
              )}
              onClick={() =>
                setFilters({
                  Status: [BattleflyBattleStatuses.Battling],
                  Class: [],
                  Type: [],
                  Rarity: [],
                  Edition: [],
                })
              }
            >
              Battling
            </Pill>
            <Pill
              color="#34EEA6"
              selected={filters?.Status.includes(
                BattleflyBattleStatuses.Unstaked
              )}
              onClick={() =>
                setFilters({
                  Status: [BattleflyBattleStatuses.Unstaked],
                  Class: [],
                  Type: [],
                  Rarity: [],
                  Edition: [],
                })
              }
            >
              Unstaked
            </Pill>
            <Pill
              color="#6037A3"
              selected={filters?.Status.includes(
                BattleflyBattleStatuses.Inactive
              )}
              onClick={() =>
                setFilters({
                  Status: [BattleflyBattleStatuses.Inactive],
                  Class: [],
                  Type: [],
                  Rarity: [],
                  Edition: [],
                })
              }
            >
              Idle
            </Pill>
            <Pill
              color="#FFA800"
              onClick={() =>
                setFilters({
                  Status: [],
                  Class: [],
                  Type: ['Cocoon'],
                  Rarity: [],
                  Edition: [],
                })
              }
            >
              Cocoon
            </Pill>
          </div>
        </div>
        <button
          onClick={handleClearFilters}
          className="cursor-pointer underline hover:no-underline"
        >
          Clear all filters
        </button>
      </div>
      <div className="m-w-full grow">
        <div className="flex justify-end mobile:mb-[19px]">
          <SearchBar
            value={searchValue}
            placeholder={'Search'}
            containerClasses="w-[325px] max-w-full mobile:w-full"
            onChange={handleSearch}
            style={SearchBarStyle.Blue}
          />
        </div>
        <div className="my-[19px] text-right mobile:hidden">
          <span className="font-bold">All selected: </span>
          <StakeHandler
            handleStake={handleStake}
            handleApproveMagic={approveMagicForRevealStakingContract}
            isMagicApproved={isMagicApprovedForRevealStaking}
            handleApproveRevealStaking={setRevealStakingContractApprovalForAll}
            isRevealStakingApproved={isRevealStakingApproved}
          />
          <button
            onClick={handleUnstake}
            className="mx-[10px] cursor-pointer underline hover:no-underline"
          >
            Unstake
          </button>
          <button
            onClick={handleBattleSettings}
            className="mx-[10px] mr-0 cursor-pointer underline hover:no-underline"
          >
            Change Battle Rate
          </button>
        </div>
      </div>
      <InfoComponent className="mb-2 -mt-5 w-full md:hidden">
        Click your BattleFly to see current loadout
      </InfoComponent>
    </div>
  );
};

export default Controls;
