import { AnimatePresence, motion } from 'framer-motion';
import SimpleComicCard from './SimpleComicCard';

const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
};

const backdrop = {
  visible: { opacity: 1 },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.5,
      delay: 0,
    },
  },
};

export interface IModal {
  className?: string;
  children?: React.ReactElement | React.ReactElement[];
  onClose: () => void;
  title: string;
  height?: string;
}

const Modal: React.FC<IModal> = ({
  title,
  onClose,
  children,
  className = '',
  height = '90%',
}) => (
  <AnimatePresence exitBeforeEnter>
    <motion.div
      variants={dropIn}
      animate="visible"
      initial="hidden"
      exit="exit"
      className="bg-modalBg fixed top-0 left-0 mx-auto flex h-full w-full justify-center backdrop-blur-sm sm:items-center"
      style={{ zIndex: '30' }}
    >
      <motion.div
        variants={backdrop}
        initial="hidden"
        animate="visible"
        className={`${className} relative mx-auto h-[${height}] max-h-[95vh] min-h-[600px] w-4/5 max-w-[960px] overflow-hidden overflow-y-scroll rounded border border-primary bg-black p-8`}
      >
        <div className="absolute z-20 w-[95%] bg-black">
          <header className="mb-6 flex justify-between">
            <div className="text-2xl">{title} </div>
            <div
              className="flex h-10 w-10 cursor-pointer items-center justify-center text-white"
              onClick={onClose}
            >
              &#x2715;
            </div>
          </header>
        </div>
        {children}
      </motion.div>
    </motion.div>
  </AnimatePresence>
);

const ComicsInWalletModal = ({
  comicsInWallet,
  onClose,
  buttonActions,
  handleSelectNft,
  selectedNfts,
  showExchangeModal,
  burnComicIdNeeded,
}: any) => {
  return (
    <Modal title={`Comics in wallet`} onClose={onClose}>
      <div className="scrollable-element-container">
        <div className="scrollable-element-wrapper">
          <div className="scrollable-content my-2 py-4">
            <div className="mt-8 overflow-scroll">
              <div className="xl:grid-col-8 mt-2 grid grid-cols-1 gap-12 overflow-y-auto py-4 pr-6 lg:grid-cols-4">
                {comicsInWallet
                  .filter((comic: number) => {
                    if (burnComicIdNeeded) {
                      return comic === burnComicIdNeeded;
                    }
                    return true;
                  })
                  .map((comicId: number, index: number) => (
                    <SimpleComicCard
                      burnComicIdNeeded={burnComicIdNeeded}
                      showExchangeModal={showExchangeModal}
                      comicIndex={index}
                      handleSelectNft={handleSelectNft}
                      selectedNfts={selectedNfts}
                      id={comicId}
                      key={`${comicId}-${index}`}
                    />
                  ))}
              </div>
            </div>
          </div>
          {showExchangeModal && (
            <div className="flex flex-wrap justify-end">{buttonActions}</div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ComicsInWalletModal;
