import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { createAction } from '@reduxjs/toolkit';

export const setStakingBattlefliesList = createAction<BattleFly[]>(
  'staking/setStakingBattlefliesList'
);

/**
 * Action dispatched after calling revealstaking contract to return
 * token id's staked by user. this array is used as a reference,
 * when calling getStakedBattlefliesByTokenId
 */
export const setAllStakingTokenIds = createAction<number[]>(
  'staking/setAllStakingTokenIds'
);

export const addBattleflyToUnstakingCart = createAction<BattleFly>(
  'staking/addBattleflyToUnstakingCart'
);

export const emptyUnstakingCart = createAction<{ id: string }>(
  'staking/battleflies/emptyUnstakingCart'
);

export const removeBattleflyFromUnstakingCart = createAction<{
  id: string;
}>('staking/battleflies/removeBattleflyFromUnstakingCart');
