import React from 'react';
import { IconProps } from '@battlefly/components/Icons';

const MarkKinetic: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="125"
      height="122"
      viewBox="0 0 125 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M124.887 53.7529L88.7619 34.644L86.4384 35.8327L86.1745 34.3574L42.4442 12.6063L29.9692 24.6604L24.5357 14.3362L21.075 14.2128L15.8844 21.0716L4.89096 0L0.807068 2.10091L8.97564 42.1432L10.9766 43.0133L6.12105 47.7048L11.806 84.905L20.7236 89.456L14.2998 95.7087L15.162 99.0624L23.2119 102.096L6.12105 118.613L9.29351 121.933L45.3777 102.747L45.4597 102.08L55.8799 107.398L92.7568 71.1866L91.1909 62.4276L100.369 64.32L102.662 61.7247L100.657 53.3601L123.917 58.243L124.887 53.7529Z"
        fill="url(#paint0_linear_1_1124)"
      />
      <path
        d="M39.945 50.6039L30.5244 70.4501L39.8395 74.8057L49.9474 54.4995L39.945 50.6039ZM33.7024 69.9908L32.1872 69.2598L40.6487 51.6168L42.2185 52.2744L33.7024 69.9908Z"
        fill="url(#paint1_linear_1_1124)"
      />
      <path
        d="M44.7286 73.2641L54.0437 77.6197L64.1515 57.3135L54.1492 53.4178L44.7286 73.2641ZM56.4235 55.0876L47.9073 72.8041L46.3922 72.073L54.8536 54.43L56.4235 55.0876Z"
        fill="url(#paint2_linear_1_1124)"
      />
      <path
        d="M49.9458 42.7016C46.1001 41.7175 42.1599 47.3338 40.362 50.3141L49.8778 54.0184C49.8778 54.0184 53.7915 43.6865 49.9458 42.7016ZM43.0331 50.8788L41.3758 50.0696C41.3758 50.0696 43.9047 45.3453 48.6439 43.2178C48.6439 43.2178 44.2483 47.95 43.0331 50.8788Z"
        fill="url(#paint3_linear_1_1124)"
      />
      <path
        d="M64.15 45.5148C60.3043 44.5307 56.3641 50.147 54.5663 53.1273L64.0821 56.8316C64.0821 56.8316 67.9957 46.4997 64.15 45.5148ZM57.2373 53.692L55.58 52.8828C55.58 52.8828 58.1089 48.1585 62.8481 46.0311C62.8481 46.0311 58.4526 50.7632 57.2373 53.692Z"
        fill="url(#paint4_linear_1_1124)"
      />
      <path
        d="M96.9211 49.7299L121.452 54.7698L88.3761 37.7906L90.83 49.4043L86.0862 48.2905L84.4523 36.9885L42.8394 15.8685L29.6075 28.5576L22.6823 16.7151L15.4525 26.2622L3.77332 4.10886L11.6521 40.4437L19.0678 31.1747L21.5725 36.2646L8.97562 48.3436L13.8632 82.148L25.3877 87.997L17.1075 96.8123L28.3126 101.039L10.3791 118.52L42.9893 100.663L31.9982 96.1796L35.3753 93.0666L55.4753 103.268L89.2766 70.3595L87.7005 59.4566L99.7078 61.3771L96.9211 49.7299ZM54.995 99.9457L16.9161 80.0846L19.5825 63.8451L12.4253 49.0255L43.2986 19.0472L81.7672 38.9996L78.6674 55.3024L86.1744 69.4816L54.995 99.9457Z"
        fill="url(#paint5_linear_1_1124)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_1124"
          x1="-1.25511"
          y1="4.57234"
          x2="74.6892"
          y2="97.4116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#434343" />
          <stop offset="0.4888" stopColor="#3B3C3B" />
          <stop offset="1" stopColor="#4A494A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_1124"
          x1="30.5244"
          y1="62.7053"
          x2="49.9473"
          y2="62.7053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_1124"
          x1="44.7288"
          y1="65.5185"
          x2="64.1517"
          y2="65.5185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_1124"
          x1="40.3621"
          y1="48.3031"
          x2="51.6375"
          y2="48.3031"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1_1124"
          x1="54.5666"
          y1="51.1163"
          x2="65.842"
          y2="51.1163"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1_1124"
          x1="3.77296"
          y1="61.3143"
          x2="121.452"
          y2="61.3143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MarkKinetic;
