import React, { useState } from 'react';
import { MODS_DEFS } from '@battlefly/components/Icons/Mods/ModBackground';
import { ModType, Rarity } from '@battlefly/__generated__/globalTypes';
import {
  MOD_CATEGORY_ICON,
  ModFullInterface,
} from '@battlefly/components/BattleflyDetails/Mods/ModFull';
import useFitText from 'use-fit-text';

interface ModSmallProps extends ModFullInterface {
  containerClasses?: string;
}

const ModSmall: React.FC<ModSmallProps> = (props) => {
  const rarity = props.mod.rarity as Rarity;
  const modTypeIconSrc = MOD_CATEGORY_ICON[props.mod.blueprint.category];
  const modImageWidthPercentage =
    props.mod.blueprint.type.toLowerCase() === ModType.Weapon.toLowerCase()
      ? '110'
      : props.mod.blueprint.type.toLowerCase() === ModType.Defense.toLowerCase()
      ? '120'
      : '100';
  const { fontSize, ref } = useFitText();
  const { fontSize: bottomFontSize, ref: bottomRef } = useFitText();
  const bottomText = `${props.mod.rarity.toString()} ${props.mod.blueprint.category.toString()}`;
  const [hovered, setHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={`relative aspect-[100/100] cursor-pointer ${props?.containerClasses}`}
    >
      <div
        className="absolute left-0 bottom-0 h-[86%] w-full rounded-[16px] p-[2px]"
        style={{
          boxShadow: hovered ? '0px 0px 40px -15px white' : 'none',
          background: !hovered
            ? `linear-gradient(180deg, #2a2829 60%, ${MODS_DEFS[rarity].compactGradientColor}40)`
            : `linear-gradient(180deg, ${MODS_DEFS[rarity].compactGradientColor}99 60%, ${MODS_DEFS[rarity].compactGradientColor}40)`,
        }}
      >
        <div
          className="h-full w-full rounded-[14px]"
          style={{
            background: `linear-gradient(119.42deg, rgba(37, 33, 55, 0.5) -16.72%, ${MODS_DEFS[rarity].compactGradientColor} 153.84%)`,
            boxShadow: `inset 2px 6px ${
              !hovered ? '15px' : '150px'
            } 2px rgba(0, 0, 0, 0.6)`,
          }}
        ></div>
      </div>

      <div className="absolute right-0 top-0 aspect-[100/100] h-[80%] h-full w-[80%] w-full">
        <img
          className={`absolute -translate-x-2/4 -translate-y-2/4 h-[${modImageWidthPercentage}%] w-auto`}
          src={props.mod.blueprint.appearance.image}
          alt={props.mod.name}
          style={{
            left:
              props.mod.blueprint.type.toLowerCase() ===
              ModType.Weapon.toLowerCase()
                ? '35%'
                : props.mod.blueprint.type.toLowerCase() ===
                  ModType.Defense.toLowerCase()
                ? '50%'
                : '45%',
            top:
              props.mod.blueprint.type.toLowerCase() ===
              ModType.Weapon.toLowerCase()
                ? '40%'
                : props.mod.blueprint.type.toLowerCase() ===
                  ModType.Defense.toLowerCase()
                ? '30%'
                : '35%',
          }}
        />
      </div>
      <div className="absolute left-[10%] bottom-[40%] flex h-auto h-[13%] w-[13%] items-center justify-center">
        <img
          src={modTypeIconSrc}
          className="relative top-[3%] h-auto w-full"
          alt={props.mod.blueprint.category.toString()}
        />
      </div>
      <div className="absolute left-[10%] bottom-0 h-[37%] w-[80%]">
        <div
          ref={ref}
          className="absolute left-0 top-0 h-[60%] w-full w-[60%] font-heading"
          style={{ fontSize: fontSize, lineHeight: 1 }}
        >
          {props.mod.name}
        </div>
        <div className="">
          <div
            className="absolute left-0 bottom-[16%] inline-block h-[100%] h-[24%] w-full w-full font-main leading-[1] text-[#D9D9D9]"
            ref={bottomRef}
            style={{
              fontSize: bottomFontSize,
            }}
          >
            {bottomText} {bottomText.length < 21 ? 'Mod' : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModSmall;
