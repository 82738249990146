import {
  BattleflyBattleStatuses,
  ISelectedMissionControlFilters,
} from '@battlefly/components/sidebars/AdvancedFiltersSidebar/AdvancedFiltersSidebar';
import {
  BattleFly,
  BattleFlyLocation,
  GenericBattleFly,
} from '@battlefly/__generated__/globalTypes';

import { BattleflyWithMetadata } from 'ts/BattleflyType';

export const separateBattlelys = (inner: GenericBattleFly[]): BattleFly[] => {
  if (inner?.length) {
    return inner.reduce((acc: BattleFly[], el: GenericBattleFly) => {
      if (el.battleFly) {
        acc.push(el.battleFly);
      }
      return acc;
    }, []);
  }

  return [];
};

export const filterBattleflysByName = (
  battleflys: BattleflyWithMetadata[],
  battleflySearchValue: string
): BattleflyWithMetadata[] => {
  const trimmedSearch = battleflySearchValue.trim().toLowerCase();
  if (trimmedSearch) {
    return battleflys
      .filter((bf: BattleflyWithMetadata) => typeof bf === 'object')
      .filter((bf: BattleflyWithMetadata) =>
        bf.name.toLowerCase()?.includes(trimmedSearch)
      );
  }
  return battleflys;
};

export const filterBattleflysByFilters = (
  battleflys: BattleflyWithMetadata[],
  filters: ISelectedMissionControlFilters
): BattleflyWithMetadata[] => {
  if (filters) {
    let ret: BattleflyWithMetadata[] = [...battleflys];
    if (filters.Class.length) {
      ret = ret.filter((bf) =>
        Object.values(filters['Class']).includes(bf.class)
      );
    }
    if (filters.Type.length) {
      ret = ret.filter((bf) => {
        if (filters['Type'].includes('Cocoon')) {
          return typeof bf !== 'object';
        }
      });
    }
    if (filters.Rarity.length) {
      ret = ret.filter((bf) =>
        Object.values(filters['Rarity']).includes(bf.rarity)
      );
    }
    if (filters.Edition.length) {
      ret = ret.filter((bf) =>
        Object.values(filters['Edition']).includes(bf.edition)
      );
    }
    if (filters.Status.length) {
      ret = ret.filter((bf) => {
        if (filters['Status'].includes(BattleflyBattleStatuses.Battling)) {
          return (
            (bf.location === BattleFlyLocation.Hyperdome ||
              bf.location === BattleFlyLocation.ProvingGrounds) &&
            !bf.isUnstaked
          );
        } else if (
          filters['Status'].includes(BattleflyBattleStatuses.Inactive)
        ) {
          return (
            bf.location === BattleFlyLocation.MissionControl && !bf.isUnstaked
          );
        } else if (
          filters['Status'].includes(BattleflyBattleStatuses.Unstaked)
        ) {
          return bf.isUnstaked;
        }
      });
    }
    return ret;
  }

  return battleflys;
};

export const battleflyHasHyperdomeMark = (battlefly: {
  tattoos: { id: string }[];
}): boolean =>
  battlefly?.tattoos.filter((x) => x.id === 'HyperdomeTattoo').length > 0;
