import React from 'react';
import classnames from 'classnames';

import { BattleFly } from '@battlefly/__generated__/globalTypes';
import BattleflyImage from 'components/BattleflyImage';

import CloseButton from './CloseButton';

type ListItemProps = {
  battlefly: BattleFly;
  onRemove: () => void;
};

const BattleflyCard = ({ battlefly }: { battlefly: Partial<BattleFly> }) => {
  return (
    <div className="gra-bf-card-border box-shadow-card rounded-2xl p-[1px]">
      <div className="gra-bf-card relative h-[70px] w-[80px] rounded-xl">
        <BattleflyImage
          url={battlefly.appearance.image}
          containerClasses="!p-1 !pt-3"
          imageClassName="!w-[70px] !h-[30px]"
        />
      </div>
    </div>
  );
};
const ListItem = (props: ListItemProps) => {
  const [isActive, setIsActive] = React.useState(false);
  const { battlefly, onRemove } = props;

  return (
    <li
      onClick={() => setIsActive(!isActive)}
      className="relative flex cursor-pointer flex-col gap-1 pt-4 first:ml-4 last:mr-4"
      key={battlefly.id}
    >
      <CloseButton
        className={classnames('absolute top-1 right-0 z-10', {
          visible: isActive,
          invisible: !isActive,
        })}
        onClick={onRemove}
      />
      <BattleflyCard battlefly={battlefly} />
      <div className="ml-1 text-xs">{battlefly.name}</div>
    </li>
  );
};

export default ListItem;
