import {
  holdingTokenIds as setHoldingTokenIds,
  stakedTokenIds as setStakedTokenIds,
} from '@battlefly/graphql/cache/cache';
import { useAppDispatch } from '@battlefly/hooks';
import { useGetBattlefliesOfOwner } from '@battlefly/hooks/contractsNew/useBattleflyGameContract';
import { useStakingBattlefliesOfOwner } from '@battlefly/hooks/contractsNew/useRevealStakingContract';
import { setAllHoldingTokenIds } from '@battlefly/redux/holding';
import { setAllStakingTokenIds } from '@battlefly/redux/staking';
import { useEffect } from 'react';
import { useAccount, useNetwork } from 'wagmi';

const useInitAppConfig = () => {
  console.log('App init');
};
export default useInitAppConfig;
