import { ApolloClient, getApolloContext } from '@apollo/client';
import { LOGIN_SIGNATURE } from '@battlefly/common/constants';
import { useAppDispatch } from '@battlefly/hooks';

import { logoutUser, setUserAuthorized } from '@battlefly/redux/user';
import { ConnectUserRequest } from '@battlefly/redux/user/userTypes';
import {
  clearUserCookies,
  isWalletAuthenticated,
  removeAuthToken,
  setAuthCookie,
  setWalletAddress,
} from '@battlefly/util/cookieManager';
import { verifyMessage } from 'ethers/lib/utils';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import { CustomToast, ToastStatus } from 'components/CustomToast/CustomToast';

import { loginUser } from 'actions/user';

const useWalletManager = () => {
  const { client: apolloClient } = useContext(getApolloContext());

  const dispatch = useAppDispatch();

  const connectedAddress = useRef<string>();

  const recoveredAddress = useRef<string>();

  const { signMessage, isLoading: isSignMessageLoading } = useSignMessage({
    message: LOGIN_SIGNATURE,
    onSuccess(data, variables) {
      console.log('SIGN_IN_SUCCESS', data, variables);
      console.log('RECOVERED_ADDRESS', recoveredAddress);
      console.log('CONNECTED_ADDRESS', connectedAddress);
      // Verify signature when sign message succeeds
      const addr = verifyMessage(variables.message, data);

      recoveredAddress.current = addr;

      const connectUserRequest: ConnectUserRequest = {
        address: recoveredAddress.current,
        sign: data,
      };

      loginUser(apolloClient, dispatch, connectUserRequest);
    },
    onError() {
      CustomToast({ status: ToastStatus.WALLET_DISCONNECTED });
      clearUserCookies();
      disconnectProvider();
    },
  });

  const switchAccount = useCallback(
    async (connectingAddress?: string) => {
      console.log('SWTICH ACCOUNT', connectingAddress);
      setWalletAddress(connectingAddress);
      connectedAddress.current = connectingAddress;
      try {
        // THEN CHECK IF GRAPHQL AUTHORIZED
        if (
          connectingAddress &&
          !isWalletAuthenticated(connectingAddress) &&
          !isSignMessageLoading
        ) {
          signMessage();
        } else {
          dispatch(setUserAuthorized(true));
        }
      } catch {
        if (!isSignMessageLoading) {
          signMessage();
        }
      }
    },
    [dispatch, isSignMessageLoading, signMessage]
  );

  const { address } = useAccount({
    onConnect: async ({ address: connectingAddress }) => {
      console.log('ON.Connect.use.account.address', connectedAddress);
      connectedAddress.current = connectingAddress;
      recoveredAddress.current = connectingAddress;

      console.log('USE.account.>connectingaddress?', connectingAddress);
      console.log('use.account.revoceredAddress', recoveredAddress);
      switchAccount(connectingAddress);
    },
    onDisconnect: () => {
      CustomToast({
        status: ToastStatus.WALLET_DISCONNECTED,
      });
      dispatch(setUserAuthorized(false));
      removeAuthToken(address);
      setWalletAddress(null);
      connectedAddress.current = null;
    },
  });

  console.log('ADDRESS', address);

  useEffect(() => {
    console.log('EFFECT.to.maybe.swtich.wallet');
    if (address && connectedAddress.current) {
      if (connectedAddress.current !== address) {
        switchAccount(address);
      }
    }
  }, [address, switchAccount]);

  const { disconnect } = useDisconnect();

  const disconnectProvider = async () => {
    await Promise.resolve(disconnect).then(() => {
      dispatch(logoutUser());
      dispatch(setUserAuthorized(false));
      dispatch(logoutUser());
      disconnect();
    });
  };

  useEffect(() => {
    if (isSignMessageLoading) {
      CustomToast({ status: ToastStatus.PENDING_SIGNATURE_REQUEST });
    }
  }, [isSignMessageLoading]);
};

export default useWalletManager;
