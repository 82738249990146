import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_BATTLE_LOG } from '@battlefly/graphql/arenasGql';
import { mapPersonalizedCombatLog } from 'lib/mapper/battlelog';

const useCombatLogData = (combatLogId: string, battleflyId: string) => {
  const { data, loading } = useQuery(GET_BATTLE_LOG, {
    skip: !battleflyId,
    variables: {
      combatId: combatLogId,
    },
  });

  const battleLog = useMemo<any | undefined>(() => {
    if (data) {
      return mapPersonalizedCombatLog(data.combat, battleflyId);
    }
  }, [data]);

  return { data: battleLog, loading };
};

export default useCombatLogData;
