import { useEffect, useState } from 'react';
import {
  useDefaultNames,
  useGetDomainData,
} from './contractsNew/useSmolDomainsContract';

export const useSmolData = (address: string) => {
  const { data: smolName } = useDefaultNames(address && address);
  const { userAvatarImg: avatar } = useGetDomainData(
    smolName && smolName?.toString()
  );
  const [userAvatarImg, setUserAvatarImg] = useState<string>();

  const [smolDomain, setSmolDomain] = useState('');

  useEffect(() => {
    if (smolName) {
      setSmolDomain(`${smolName}.smol`);
    }
  }, [smolName]);

  useEffect(() => {
    if (avatar) {
      setUserAvatarImg(avatar);
    }
  }, [avatar]);
  return { smolDomain, userAvatarImg };
};
