import React from 'react';
import { LeagueWithDigitProps } from '@battlefly/components/Icons/Leagues/PupaWithDigitIcon';
import LarvaeIcon from '@battlefly/components/Icons/Leagues/LarvaeIcon';
import LeagueWithDigitIcon from '@battlefly/components/Icons/Leagues/LeagueWithDigitIcon';

const LarvaeWithDigitIcon: React.FC<LeagueWithDigitProps> = (props) => {
  return (
    <LeagueWithDigitIcon
      digit={props.digit}
      hideLeagueDigit={props.hideLeagueDigit}
      icon={LarvaeIcon}
      colorBorderStart="#F595CE"
      colorBorderEnd="#F5CBDF"
      colorRectStart="#DE4A8B"
      colorRectMiddle="#6650A1"
      colorRectEnd="#EE5592"
    />
  );
};

export default LarvaeWithDigitIcon;
