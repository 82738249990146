import React from 'react';
import { useAccount } from 'wagmi';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';

import { SCAVENGE_GENERATE_MODS } from '@battlefly/graphql/battleflyGql';
import { BattleFly } from '@battlefly/__generated__/globalTypes';
import WastelandCard from '@battlefly/components/WastelandCard';
import { WASTELANDS } from 'common/wastelandData';
import useBattleflyDetails from '@battlefly/hooks/components/useBattleflyDetails';
import { isBattleFlyStakedAndBelongsToUser } from '@battlefly/util/helpers';

import { CustomToast, ToastStatus } from '@battlefly/components/CustomToast';
import useNotEnoughMagicModal from 'hooks/ui/useNotEnoughMagicModal';
import { ModalContext } from 'context/modalContext';
import { UserBalanceContext } from 'context/userBalanceContext';
import { SCAVENGE_PACK_MAGIC_PRICE } from 'common/constants';
import { ChevronLeft } from 'components/Icons';

const ScavengingPage = () => {
  const { setHuntersMarkModalState, setScavengerMarkModalState, setConfirmationModal } =
    React.useContext(ModalContext);
  const { magicBalance } = React.useContext(UserBalanceContext);
  const navigate = useNavigate();
  const { showModal: showNotEnoughMagicModal } = useNotEnoughMagicModal();
  const { data, loading, error } = useBattleflyDetails();
  const battleFly: BattleFly = data?.getBattleFly?.battleFly;
  const { address: userAddress } = useAccount();
  const isOwnedByUserAndStaked = isBattleFlyStakedAndBelongsToUser(
    battleFly,
    userAddress
  );

  const [
    purchaseMods,
    {
      data: purchasedMods,
      loading: loadingPurchasedMods,
      error: errorPurchasedMods,
    },
  ] = useMutation(SCAVENGE_GENERATE_MODS);

  function handleMarksModalOnUnstakedBattlefly() {
    CustomToast({
      status: ToastStatus.REJECTED,
      message: 'First stake battlefly to apply marks',
    });
  }

  function handleManageHunterMark() {
    if (isOwnedByUserAndStaked) {
      return setHuntersMarkModalState(battleFly);
    }
    handleMarksModalOnUnstakedBattlefly();
  }
  function handleManageScavengingMark() {
    if (isOwnedByUserAndStaked) {
      return setScavengerMarkModalState(battleFly);
    }
    handleMarksModalOnUnstakedBattlefly();
  }

  async function finalizeScavenge(wastelandId: number) {
    const modCat = WASTELANDS.find((w) => w.id === wastelandId).modCategory;
    const result = await purchaseMods({
      variables: {
        battleflyId: battleFly.id,
        category: modCat,
      },
    });
    const modPackId = result.data?.createModPack.id;
    navigate(`/battleflies/view/${battleFly.id}/modpacks/${modPackId}`, {
      replace: true,
    });
  }

  async function handleScavenge(wastelandId: number) {
    if (magicBalance < SCAVENGE_PACK_MAGIC_PRICE) {
      return showNotEnoughMagicModal();
    }

    setConfirmationModal({
      onConfirm: async () => {
        await finalizeScavenge(wastelandId);
      },
      onCancel: () => {
        setConfirmationModal(null);
      }
    })

  }

  if (loading) return <div>Loading...</div>;

  return (
    <div className="w-full md:pt-24">
      <div className="relative">
        <div className="max-w-[380px] px-3 sm:px-6">
          <h1 className="mb-2 font-heading text-xxl leading-[40px]">
            Scavenge for Mods
          </h1>
          <p className="leading-[19px]">
            Choose a wasteland to scavenge, every land contains different mods.
            You`ll get to see three random mods and you`ll get to keep at least
            one!
          </p>
        </div>
        <div className="absolute top-6 max-w-full md:-top-[100px]">
          <ul className="flex h-full max-w-[100%] gap-8 overflow-scroll py-[100px]">
            <li>
              <div className="h-full w-[120px] md:w-[380px]">
                <div className="mt-8 flex h-full items-center justify-end">
                  <ChevronLeft
                    className="h-[162]"
                    fill="rgba(255,255,255,0.4)"
                  />
                </div>
              </div>
            </li>
            {WASTELANDS.map((wasteland) => (
              <li key={wasteland.id}>
                <WastelandCard
                  battlefly={battleFly}
                  handleScavenge={handleScavenge}
                  handleManageHunterMark={handleManageHunterMark}
                  handleManageScavengingMark={handleManageScavengingMark}
                  {...wasteland}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ScavengingPage;
