import React from 'react';
import { ModWeaponBgRectangleProps } from '@battlefly/components/Icons/Mods/ModWeaponBgRectangle';
import { Rarity } from '@battlefly/__generated__/globalTypes';

const ModDefenseBgRectangle: React.FC<ModWeaponBgRectangleProps> = (props) => {
  return (
    <svg
      width="249"
      height="195"
      viewBox="0 0 249 195"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="1">
        <path
          d="M206.266 194.582L248.263 152.585L248.263 0.579956L245.475 0.579956L245.475 41.0899L227.915 58.6504L227.915 105.571L214.071 119.415L213.885 119.601L213.885 166.243L188.427 191.701L7.60421e-05 191.701L7.62858e-05 194.489L206.266 194.489L206.266 194.582ZM245.568 88.0108L239.25 94.3289L239.25 69.3353L245.568 63.0172L245.568 88.0108ZM229.401 59.2078L245.568 43.041L245.568 61.0661L237.857 68.7779L237.857 95.7226L229.401 104.178L229.401 59.2078ZM215.093 167.079L215.279 166.894L215.279 120.251L245.568 89.9619L245.568 151.377L205.151 191.794L190.378 191.794L215.093 167.079Z"
          fill={props.fillColor}
        />
        <path
          d="M225.592 113.097L217.323 121.366L217.323 132.516L225.592 124.247L225.592 113.097Z"
          fill={props.fillColor}
        />
        <path
          d="M225.592 128.242L217.323 136.511L217.323 147.661L225.592 139.299L225.592 128.242Z"
          fill={props.fillColor}
        />
        <path
          d="M225.592 143.387L217.323 151.656L217.323 162.805L225.592 154.443L225.592 143.387Z"
          fill={props.fillColor}
        />
        <path
          d="M186.104 189.007L194.374 180.738L183.224 180.738L174.955 189.007L186.104 189.007Z"
          fill={props.fillColor}
        />
        <path
          d="M170.866 189.007L179.136 180.738L167.986 180.738L159.717 189.007L170.866 189.007Z"
          fill={props.fillColor}
        />
        <path
          d="M163.898 180.738L152.841 180.738L144.479 189.007L155.629 189.007L163.898 180.738Z"
          fill={props.fillColor}
        />
      </g>
    </svg>
  );
};

export default ModDefenseBgRectangle;
