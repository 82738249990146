import { useApproveMagicForOperator } from '@battlefly/hooks/contractsNew/useApproveMagic';
import classnames from 'classnames';

import { Card, Button, Link } from 'components/core';
import { CloseIcon, MagicIcon } from 'components/Icons';
import Spinner from 'components/Spinner';
import { useBurnMagicForInventorySlot } from 'hooks/contractsNew/useBattleflyCredits';
import { BattleflyGame } from '@battlefly/common/constants';

type MagicOptionCardProps = {
  isPolling: boolean;
  battleflyId: string;
  onClose: () => void;
  onPurchase: () => void;
};

const MagicOptionCard = (props: MagicOptionCardProps) => {
  const { onClose, battleflyId, onPurchase, isPolling } = props;
  const { isContractApproved, write: approveMagic } = useApproveMagicForOperator(BattleflyGame.BattleflyCreditsContract);
  const { write: burnMagic } = useBurnMagicForInventorySlot(
    isContractApproved,
    battleflyId,
    onPurchase
  );

  const isSubmitDisabled = isContractApproved && !burnMagic;

  function handleSubmit() {
    if (!isContractApproved) {
      return approveMagic();
    }
    burnMagic();
  }
  return (
    <div className="relative">
      <div
        className={classnames(
          'absolute left-[50%] top-[50%] z-10 -ml-12 -mt-12 flex h-12 w-12 items-center justify-center',
          {
            'pointer-events-none visible ': isPolling,
            invisible: !isPolling,
          }
        )}
      >
        <Spinner pending={isPolling} className="!h-12 !w-12" />
      </div>
      <Card
        containerClassName="!p-0"
        className={classnames(
          'relative w-full overflow-hidden border-none !p-10 shadow-none md:!p-16',
          {
            'pointer-events-none opacity-25': isPolling,
          }
        )}
      >
        <div className="absolute -bottom-28 -right-12 opacity-70 md:-bottom-20 md:-right-32">
          <MagicIcon className="h-72 w-72 md:h-[480px] md:w-[480px]" />
        </div>
        <header className="mb-4 mb-12 flex flex-row items-start justify-between md:mb-10">
          <div className="mt-8">
            <h1 className="mb-4 text-left text-4xl">
              Purchase a Slot with $Magic
            </h1>
            <p className="invisible md:max-w-[30%]">
              Each BattleFly starts with four inventory slots, and can add more slots by burning $Magic.
            </p>
          </div>
          <div
            onClick={onClose}
            className="translate-x-5 -translate-y-8 cursor-pointer p-4"
          >
            <CloseIcon fill="#fff" />
          </div>
        </header>

        <div className="relative z-10 flex flex-col gap-4">
          <div className="mb-8 max-w-[240px]">
            <Button
              disabled={isSubmitDisabled}
              transparent
              light
              onClick={handleSubmit}
            >
              {!isContractApproved ? 'Approve' : "Let's burn it"}
            </Button>
          </div>
          <div>
            <p>
              Need to buy $Magic: Visit{' '}
              <a
                href="https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0xB0c7a3Ba49C7a6EaBa6cD4a96C55a1391070Ac9A"
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                Sushiswap
              </a>
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MagicOptionCard;
