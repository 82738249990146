import { gql } from '@apollo/client';
import { CORE_BATTLEFLY_FIELDS } from '@battlefly/graphql/fragments';
import { BattleFly, Cocoon } from '@battlefly/__generated__/globalTypes';

export const USER_CONNECT = gql`
  mutation UserConnect($input: ConnectInput!) {
    connect(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const USER_REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshTokens(input: { refreshToken: $refreshToken }) {
      accessToken
      refreshToken
    }
  }
`;

export const GET_USER_INFO = gql`
  query GetUserInfo {
    me {
      id
      wallets {
        id
        address
        magic
      }
      role
      createdAt
      updatedAt
    }
  }
`;

export const GET_BALANCES = gql`
  query BattleflyUserBalances {
    me {
      id
      wallets {
        nectar
        magic
      }
      credits {
        type
        quantity
      }
    }
  }
`;

export const GET_BATTLEFLYS_BY_IDS = gql`
  query GetBattleFliesByIds($battleFlyIds: [ID!]) {
    getBattleFlies(input: { battleFlyIds: $battleFlyIds }) {
      id
      stage
      cocoon {
        appearance {
          image
        }
        edition
      }
    }
  }
`;

export interface GetBattleflysByIdsData {
  getBattleFlies: {
    id: number;
    stage: string;
    cocoon: Cocoon;
    battleFly: BattleFly;
  }[];
}

export const GET_BATTLEFLYS_ALL_DATA_BY_IDS = gql`
  ${CORE_BATTLEFLY_FIELDS}

  query GetBattleFliesAllDataByIds($battleFlyIds: [ID!]) {
    getBattleFlies(input: { battleFlyIds: $battleFlyIds }) {
      id
      stage
      cocoon {
        appearance {
          image
        }
      }
      battleFly {
        ...CorBattleflyFields
        hyperdomeWins
        hyperdomeLosses
        provingGroundsWins
        provingGroundsLosses
        combatHistoryPaginated(first: 5, returnTotalCount: false) {
          edges {
            node {
              battleFlyWinner {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_MY_STAKED_BATTLEFLYS = gql`
  ${CORE_BATTLEFLY_FIELDS}

  query GetMyBattleflys {
    me {
      battleFlies {
        ...CorBattleflyFields
        hyperdomeWins
        hyperdomeLosses
        provingGroundsWins
        provingGroundsLosses
        combatHistoryPaginated(first: 5, returnTotalCount: false) {
          edges {
            node {
              battleFlyWinner {
                id
              }
            }
          }
        }
      }
    }
  }
`;
