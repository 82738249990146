import React from 'react';

const ModalWrapper = ({
  children,
  transparent,
}: {
  children: React.ReactNode;
  transparent?: boolean;
}) => {
  return (
    <div
      style={{ width: '80vw', maxWidth: '1000px', maxHeight: '50%' }}
      className={`absolute top-1/2 left-1/2 flex h-auto max-h-[569px] w-[80vw] -translate-x-1/2 -translate-y-1/2 transform flex-col rounded-sm  ${
        !transparent &&
        `border-2 border-button-default bg-primary p-4 backdrop-blur-md`
      }`}
    >
      <div className="flex h-full min-h-[50vh] w-full flex-col justify-between p-[15%]">
        {children}
      </div>
    </div>
  );
};

export default ModalWrapper;
