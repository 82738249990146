import React from 'react';
import { IconProps } from '@battlefly/components/Icons/index';

const AttentionIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.888 8.848C12.5253 8.848 12.2267 8.73067 11.992 8.496C11.7573 8.25067 11.64 7.94667 11.64 7.584C11.64 7.21067 11.752 6.90667 11.976 6.672C12.2107 6.43733 12.5147 6.32 12.888 6.32C13.2613 6.32 13.5653 6.43733 13.8 6.672C14.0347 6.90667 14.152 7.21067 14.152 7.584C14.152 7.94667 14.0293 8.25067 13.784 8.496C13.5493 8.73067 13.2507 8.848 12.888 8.848ZM11.96 18C11.9067 18 11.8587 17.984 11.816 17.952C11.784 17.9093 11.768 17.8613 11.768 17.808V9.968C11.768 9.91467 11.784 9.872 11.816 9.84C11.8587 9.79733 11.9067 9.776 11.96 9.776H13.832C13.8853 9.776 13.928 9.79733 13.96 9.84C14.0027 9.872 14.024 9.91467 14.024 9.968V17.808C14.024 17.8613 14.0027 17.9093 13.96 17.952C13.928 17.984 13.8853 18 13.832 18H11.96Z"
        fill="white"
      />
      <circle cx="12.5" cy="12.5" r="11.5" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export default AttentionIcon;
