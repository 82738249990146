import PageTitle from '../PageTitle/PageTitle';

const Maintenance = () => {
  return (
    <div className="flex h-full items-center gap-1">
      <PageTitle title=" Sorry, we are upgrading some feature. we will be back soon - thank you." />
    </div>
  );
};

export default Maintenance;
