import { gql } from '@apollo/client';

export const CORE_MOD_BLUEPRINT_FIELDS_MODPACK = gql`
  fragment CoreModPackModBlueprintFields on ModBlueprintInModPack {
    appearance {
      image
    }
    category
    description
    type
    id
    rarity
    name
    isPicked
    isUpgraded
    weapon {
      reload
      damagePerFire
      burst
    }
    defense {
      shield
      armor
      evasion
    }
  }
`;

export const CORE_MOD_BLUEPRINT_FIELDS = gql`
  fragment CoreModBlueprintFields on ModBlueprint {
    appearance {
      image
      model
      location
    }
    category
    description
    type
    id
    rarity
    name
    weapon {
      reload
      damagePerFire
      burst
    }
    defense {
      shield
      armor
      evasion
    }
  }
`;

export const CORE_MOD_FIELDS = gql`
  ${CORE_MOD_BLUEPRINT_FIELDS}

  fragment CoreModFields on Mod {
    id
    name
    damageType
    rarity
    type
    effects
    stats
    slot
    blueprint {
      ...CoreModBlueprintFields
    }
  }
`;

export const CORE_BATTLEFLY_FIELDS = gql`
  ${CORE_MOD_FIELDS}

  fragment CorBattleflyFields on BattleFly {
    id
    slotsAvailable
    stage
    traits {
      name
      damageType
      stat
      value
      unitType
      id
    }
    name
    class
    edition
    rarity
    location
    provingGroundsCombatRate
    hyperdomeCombatRate
    placeInGlobalLeaderboard
    age
    starsRate
    league
    leagueTier
    contestPoints
    level
    appearance {
      image
    }
    class
    mods {
      ...CoreModFields
    }
    inventory {
      ...CoreModFields
    }
    owner {
      id
      user {
        id
        wallets {
          address
        }
      }
    }
    tattoos {
      id
      name
      amount
    }
  }
`;

export const CORE_HUNTERS_MARK_FIELDS = gql`
  fragment CoreHuntersMarkFields on BattleFly {
    huntersMark {
      id
      type
      createdAt
      willExpireAt
    }
  }
`;

export const CORE_SCAVENGING_MARK_FIELDS = gql`
  fragment CoreScavengingMarkFields on BattleFly {
    scavengingMark {
      id
      type
      createdAt
      willExpireAt
    }
  }
`;
