import Card from 'components/core/Card';
import { PlusIconSlim } from 'components/Icons';

type BattleflyMarkSlotProps = {
  className?: string;
};

const BattleflyMarkSlot = (props: BattleflyMarkSlotProps) => {
  return (
    <Card
      square
      className="flex h-20 w-20 items-center justify-center wxga+:h-24 wxga+:w-24"
    >
      <div className="text-[40px]">
        <PlusIconSlim />
      </div>
    </Card>
  );
};

export default BattleflyMarkSlot;
