import { formatUnits } from 'ethers/lib/utils';
import { useEffect, useState } from 'react';
import { useAccount, useBalance } from 'wagmi';

import ComicMintCardImage from '@battlefly/components/Comic/Mint/ComicMintCardImage';
import ComicMintTabs, {
  MintComicPageTabs,
} from '@battlefly/components/Comic/Mint/ComicMintTabs';
import InactiveComicBlackFront from '@battlefly/components/Comic/Mint/InactiveComicBlackFront';
import MintByFounders from '@battlefly/components/Comic/Mint/TabsContent/MintByFounders';
import MintByMagic from '@battlefly/components/Comic/Mint/TabsContent/MintByMagic';
import MintByTokens from '@battlefly/components/Comic/Mint/TabsContent/MintByTokens';
import Message from '@battlefly/components/Message';
import useSingleComic, {
  useGetComicsByWalletAddress,
} from '@battlefly/hooks/contractsNew/useComic';

import { BattleflyGame } from '@battlefly/common/constants';
import BackButton from '@battlefly/components/BackButton/BackButton';
import Button from '@battlefly/components/Buttons/Button';
import NotAuthorizedBlock from '@battlefly/components/Layout/NotAuthorizedBlock';
import { useAppSelector } from '@battlefly/hooks';
import { useContractAddresses } from '@battlefly/hooks/contractsNew/useContractAddresses';
import { selectIsUserAuthorized } from '@battlefly/redux/user/userReducer';
import { useParams } from 'react-router-dom';
import { comicFileLocationMap } from '..';

enum ComicMintType {
  FoundersMint = 1,
  BattleflyStakedMint = 2,
  BurnMint = 3,
  TreasuryMint = 4,
}

const ComicId = () => {
  const { comicsInUserWallet } = useGetComicsByWalletAddress();

  const { id } = useParams();
  const { comics, loading } = useSingleComic(Number(id));
  const [activeTab, setActiveTab] = useState(MintComicPageTabs.Claim);
  const COMIC_MINT_TYPE = comics?.mintType?.toNumber();
  const isUserAuthorized = useAppSelector(selectIsUserAuthorized());

  const comicDetails: { trait_type: string; value: string }[] =
    comics?.details?.attributes;

  const contractAddress = useContractAddresses();
  const { address } = useAccount();

  const magicBalance = useBalance({
    address: address,
    token: contractAddress[BattleflyGame.MagicContract],
  });

  useEffect(() => {
    if (!comics) {
      return;
    }
    console.log('comic details', comics);
    console.log('id ' + comics?.id.toString());
    console.log('burnAmount ' + comics?.burnAmount.toString());
    console.log('maxMints ' + comics?.maxMints.toString());
    console.log('burnableIn ' + comics?.burnableIn.toString());
    console.log(
      'maxPaidMintsPerWallet ' + comics?.maxPaidMintsPerWallet.toString()
    );
    console.log('mintType ' + comics?.mintType.toString());
    console.log('priceMagic ' + formatUnits(comics?.priceInWei, 18));
  }, [comics]);

  if (!isUserAuthorized) {
    return <NotAuthorizedBlock />;
  }

  if (loading) {
    return <Message text="Loading..." display={true} />;
  }

  if (!comics) {
    return <Message text="Error while fetching comic" display={true} />;
  }

  return (
    <div>
      <BackButton />
      <h1 className="f-size-42 text-center">{comics?.name}</h1>
      <div className="flex max-w-full justify-center">
        <div className="fade-in relative w-full max-w-[808px] p-3">
          <div
            style={{
              background: `linear-gradient(135deg, rgba(1, 147, 128, 0.25) 0%, rgba(15, 15, 55, 0) 100%), rgba(0, 0, 0, 0.5)`,
              backdropFilter: `blur(45px)`,
            }}
            className="c-card flex h-full flex-row justify-between overflow-hidden rounded-xl border-[1px] border-button-default p-2 shadow-md hover:shadow-xl mobile:block"
          >
            <ComicMintCardImage comics={comics} />
            <div className="flex w-[48%] flex-col justify-between pl-1 pr-1 mobile:w-full">
              <>
                {[
                  ComicMintType.FoundersMint,
                  ComicMintType.BattleflyStakedMint,
                ].includes(COMIC_MINT_TYPE) && (
                  <>
                    <ComicMintTabs
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      tabDescription={
                        activeTab === MintComicPageTabs.Mint
                          ? 'Mint Comic by Magic'
                          : COMIC_MINT_TYPE === ComicMintType.FoundersMint
                          ? `Claim Comic by V1/V2 Founders Tokens`
                          : 'Mint Comic by Staked BattleFly Tokens'
                      }
                    />
                  </>
                )}
                <div className="flex w-full flex-col justify-between gap-2">
                  {comicDetails.map(({ trait_type, value }) => (
                    <div className="flex gap-2 text-xl" key={value}>
                      <img src="/assets/icons/bullet.svg" className="w-[3%]" />
                      {trait_type}
                      {': '}
                      {value}
                    </div>
                  ))}
                </div>
                <div>
                  {[
                    ComicMintType.FoundersMint,
                    ComicMintType.BattleflyStakedMint,
                  ].includes(COMIC_MINT_TYPE) && (
                    <>
                      {activeTab === MintComicPageTabs.Mint && (
                        <MintByMagic
                          magicBalance={magicBalance}
                          comics={comics}
                          comicId={Number(id)}
                        />
                      )}
                      {activeTab === MintComicPageTabs.Claim && (
                        <>
                          {COMIC_MINT_TYPE === ComicMintType.FoundersMint ? (
                            <MintByFounders
                              comics={comics}
                              comicId={Number(id)}
                            />
                          ) : (
                            <MintByTokens
                              comics={comics}
                              comicId={Number(id)}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                  {COMIC_MINT_TYPE === ComicMintType.BurnMint && (
                    <Button
                      type="primary"
                      disabled
                      className="mb-2 inline-block"
                    >
                      Only Mintable By Burning
                    </Button>
                  )}
                  {COMIC_MINT_TYPE === ComicMintType.TreasuryMint && (
                    <Button
                      type="primary"
                      disabled
                      className="mb-2 inline-block"
                    >
                      Only Mintable By Treasury
                    </Button>
                  )}
                  <div>
                    <a
                      className="block w-full"
                      href={comicFileLocationMap[comics.id.toNumber()]}
                    >
                      <Button
                        // onClick={() => handleViewComic()}
                        type="secondary"
                        disabled={comicsInUserWallet.includes(
                          comics.id.toNumber()
                        )}
                        className="mb-2 inline-block"
                      >
                        View
                      </Button>
                    </a>
                  </div>
                </div>
              </>
            </div>
          </div>
          {!comics.active && <InactiveComicBlackFront />}
        </div>
      </div>
    </div>
  );
};

export default ComicId;
