import React from 'react';

import Drawer from 'components/Drawer';
import { UserBalancePanel } from 'components/UserBalancePanel';

export type WalletDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const WalletDrawer = (props: WalletDrawerProps) => {
  const { isOpen, onClose } = props;

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      position="right"
      querySelector="#__content"
    >
      <div className="h-full bg-core-blue-light p-6">
        <div className="w-full">
          <UserBalancePanel onClose={onClose} />
        </div>
      </div>
    </Drawer>
  );
};

export default WalletDrawer;
