import React from 'react';

const WalletIcon: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    width="56"
    height="51"
    viewBox="0 0 56 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} inline-block stroke-current`}
  >
    <path
      d="M28 40.5H10.5V23V14.2071L15.3036 9.40355L19.2071 5.5H28H45.5V23V31.7929L41.1464 36.1464L36.7929 40.5H28Z"
      stroke="white"
    />
    <path d="M46 37L46 41L42 41" stroke="white" />
    <g filter="url(#filter0_d_2079_8397)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4913 20.1763H35.5087C35.6416 20.1763 35.75 20.2847 35.75 20.4177V23.0441H32.4121C31.3382 23.0441 30.4604 23.9219 30.4604 24.9959C30.4604 26.0698 31.3382 26.9477 32.4121 26.9477H35.75V29.5741C35.75 29.707 35.6416 29.8154 35.5086 29.8154H20.4913C20.4913 29.8154 20.25 29.707 20.25 29.5741V20.4177C20.25 20.2847 20.3584 20.1763 20.4913 20.1763ZM23.8504 17.8222C24.8057 18.093 25.8127 17.7006 26.3844 16.9003L24.8798 16.2067C24.7598 16.1514 24.6172 16.2033 24.5606 16.3226L23.8504 17.8222ZM23.6343 18.2787C24.8456 18.6627 26.1451 18.1593 26.8439 17.1122L32.4065 19.6764H22.9723L23.6343 18.2787ZM32.4154 24.2983C32.0302 24.2983 31.7179 24.6107 31.7179 24.9958C31.7179 25.3811 32.0302 25.6933 32.4154 25.6933C32.8006 25.6933 33.1129 25.3811 33.1129 24.9958C33.1129 24.6107 32.8006 24.2983 32.4154 24.2983ZM35.75 23.5441H32.4121C31.6142 23.5441 30.9604 24.1979 30.9604 24.9959C30.9604 25.7938 31.6142 26.4477 32.4121 26.4477H35.75V23.5441Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2079_8397"
        x="0.25"
        y="0.18457"
        width="55.5"
        height="53.6309"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.447059 0 0 0 0 0.980392 0 0 0 0 0.760784 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2079_8397"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2079_8397"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default WalletIcon;
