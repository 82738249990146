import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  UpgradeModInModPackMutation,
  UpgradeModInModPackMutationVariables,
} from '@battlefly/components/BattleflyDetails/Modals/__generated__/useUpgradeModInModPack';
import { CORE_MOD_BLUEPRINT_FIELDS_MODPACK } from '@battlefly/graphql/fragments';

export const UPGRADE_MOD_IN_MOD_PACK = gql`
  mutation UpgradeModInModPack($modPackId: ID!, $modBlueprintId: ID!) {
    upgradeModInModPack(
      input: { modPackId: $modPackId, modBlueprintId: $modBlueprintId }
    ) {
      id
      modBlueprints {
        ...CoreModPackModBlueprintFields
      }
    }
  }

  ${CORE_MOD_BLUEPRINT_FIELDS_MODPACK}
`;

const useUpgradeModInModPack = () => {
  const [upgradeMod] = useMutation<
    UpgradeModInModPackMutation,
    UpgradeModInModPackMutationVariables
  >(UPGRADE_MOD_IN_MOD_PACK);

  return useCallback(
    async (modPackId: string, modId: string) => {
      const { data: respData } = await upgradeMod({
        variables: {
          modPackId,
          modBlueprintId: modId,
        },
      });

      return respData?.upgradeModInModPack;
    },
    [upgradeMod]
  );
};

export default useUpgradeModInModPack;
