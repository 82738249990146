import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useContract, useContractRead, useNetwork, useProvider } from 'wagmi';
import { arbitrum, arbitrumGoerli } from 'wagmi/chains';
import { BattleflyGame, CONTRACT_ABIS } from '../../common/constants';
import { useContractAddresses } from './useContractAddresses';

/**
 * get smol domain name
 * @returns boolean
 */
export const useDefaultNames = (account: string) => {
  const contractAddress = useContractAddresses();
  const { chain: activeChain } = useNetwork();
  // this call ONLY WORKS ON ARBITRUM MAINNET
  const isMainnet = activeChain?.id == arbitrum.id;
  const { data, isError, isLoading } = useContractRead({
    address: contractAddress[BattleflyGame.SmolDomainsContract],
    abi: CONTRACT_ABIS[BattleflyGame.SmolDomainsContract],
    functionName: 'defaultNames',
    args: [account],
    enabled: isMainnet,
  });

  return { data, isError, isLoading };
};
/**
 * get smol domain data
 * @returns boolean
 */
export const useGetDomainData = (smolAddress: string) => {
  const contractAddress = useContractAddresses();
  const provider = useProvider();
  const { chain: activeChain } = useNetwork();

  const [imgUrl, setImgUrl] = useState();
  const isMainnet = activeChain?.id == arbitrum?.id;

  const contract = useContract({
    address: contractAddress[BattleflyGame.SmolDomainsContract],
    abi: CONTRACT_ABIS[BattleflyGame.SmolDomainsContract],
    signerOrProvider: provider,
  });

  const getDomainMetadata = async () => {
    const response = await contract.getDomainData(smolAddress);
    let smolDomainData;
    try {
      smolDomainData = JSON.parse(response);
    } catch (e) {
      console.log(e);
      console.log(response);
    }

    let metadata = '';
    let imgFound = false;
    if (smolDomainData) {
      if (smolDomainData.imgAddress) {
        if (!smolDomainData.imgAddress.startsWith('0x')) {
          imgFound = true;
          setImgUrl(
            smolDomainData.imgAddress.replace(
              'ipfs://',
              'https://ipfs.io/ipfs/'
            )
          );
        } else {
          // fetch image URL of that PFP
          const pfpInterface = new ethers.utils.Interface([
            'function tokenURI(uint256 tokenId) public view returns (string memory)',
          ]);
          const pfpContract = new ethers.Contract(
            smolDomainData.imgAddress,
            pfpInterface,
            provider
          );

          metadata = await pfpContract.tokenURI(smolDomainData.imgTokenId);
        }
      }
    }
    if (metadata.includes('ipfs://')) {
      metadata = metadata.replace('ipfs://', 'https://ipfs.io/ipfs/');
    }
    if (metadata.includes('http')) {
      const response = await fetch(metadata);
      const result = await response.json();

      if (result && result.image) {
        setImgUrl(result.image.replace('ipfs://', 'https://ipfs.io/ipfs/'));
        imgFound = true;
      }

      if (metadata && !imgFound) {
        const json = atob(metadata.substring(29));
        const result = JSON.parse(json);

        if (result && result.image) {
          setImgUrl(result.image);
        } else {
          setImgUrl(null);
        }
      }
    }
  };

  useEffect(() => {
    if (provider && contract && smolAddress && isMainnet) {
      getDomainMetadata();
    }
    if (!smolAddress) {
      setImgUrl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, contract, smolAddress]);

  return { userAvatarImg: imgUrl };
};
