import React from 'react';
import classnames from 'classnames';

import { Checkmark } from '@battlefly/components/Icons';

interface SwitchInputInterface {
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  testId?: string;
}

const SwitchInput: React.FC<SwitchInputInterface> = (props) => {
  const { testId = 'SwitchInput' } = props;
  const handleClick = () => {
    if (props?.disabled) {
      return;
    }
    props.onChange(!props.value);
  };

  return (
    <div
      onClick={() => handleClick()}
      className={classnames(
        'relative inline-flex h-6 w-12 cursor-pointer items-center rounded-full',
        {
          'bg-core-purple': props.value,
          'bg-[#544766]': !props.value,
        }
      )}
      data-testid={testId}
    >
      <span className="absolute left-2">
        {props.value && <Checkmark className="relative top-[-2px] left-0" />}
      </span>
      <span className="absolute right-[5px] text-[#C4C4C4]">
        {!props.value && <span data-testid="cross-unchecked">&#x2715;</span>}
      </span>
      <span
        className={classnames(
          'inline-block h-4 w-4 transform rounded-full bg-white',
          {
            'translate-x-7': props.value,
            'translate-x-1': !props.value,
          }
        )}
      />
    </div>
  );
};

export default SwitchInput;
