import React from 'react';

const ScavengeBronze = ({ className }: { className?: string }) => {
  return (
    <img
      width={120}
      height={167}
      src="/assets/icons/ScavengeBronze.png"
      alt="Scavenge Bronze"
      className={className}
    />
  );
};

export default ScavengeBronze;
