import React from 'react';

import { setTACCookie, getTACCookie } from 'util/cookieManager';

const useCheckTermsAndConditions = () => {
  const [showTerms, setShowTerms] = React.useState(false);

  React.useEffect(() => {
    const cookie = getTACCookie();
    if (!cookie) {
      setShowTerms(true);
    }
  }, []);

  return {
    showTerms,
    saveTermsAndConditionsResult: setTACCookie,
  };
};

export default useCheckTermsAndConditions;
