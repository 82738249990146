import ChangeMintValueTab from '@battlefly/components/Comic/Mint/ChangeMintValueTab';
import useTabMintCount from '@battlefly/components/Comic/Mint/hooks/useTabMintCount';
import MaxMintCountTab from '@battlefly/components/Comic/Mint/MaxMintCountTab';
import MintButton from '@battlefly/components/Comic/Mint/MintButton';
import {
  ComicNetworkResponse,
  useIsApprovedForAll,
  useMintFounders,
  useSetApprovalForAll,
} from '@battlefly/hooks/contractsNew/useComic';
import { useContractAddresses } from '@battlefly/hooks/contractsNew/useContractAddresses';
import { useFoundersVaultAvailableMints } from '@battlefly/hooks/contractsNew/useFounderVaultContracts';
import { useEffect, useRef } from 'react';

const MintByFounders = ({
  comics,
  comicId,
}: {
  comics: ComicNetworkResponse;
  comicId: number;
}) => {
  const {
    mintCount,
    maxMint,
    setMintCount,
    setMaxMint,
    handleMintMaxCount,
    handleIncreaseMintCount,
    handleDecreaseMintCount,
    decreaseMaxMint,
  } = useTabMintCount();

  const {
    mixedData: dataUsedTokens,
    isError: isErrorUsedTokens,
    isLoading: isLoadingUsedTokens,
    refetch: refetchUsedTokens,
  } = useFoundersVaultAvailableMints(Number(comicId));

  const { mintFounders } = useMintFounders();
  const unUsedTokens = useRef<any[]>([]);

  useEffect(() => {
    if (dataUsedTokens && dataUsedTokens.length) {
      const currentUnUsedTokens = dataUsedTokens.filter((x) => !x.used);
      unUsedTokens.current = currentUnUsedTokens;
      setMaxMint(currentUnUsedTokens.length);
      setMintCount(
        mintCount <= currentUnUsedTokens.length
          ? mintCount
          : currentUnUsedTokens.length
      );
    }
  }, [dataUsedTokens, isLoadingUsedTokens]);

  const handleMint = async () => {
    if (!maxMint) {
      return;
    }

    await mintFounders(
      unUsedTokens.current.slice(0, mintCount).map((x) => x.tokenId),
      Number(comicId),
      mintedChangeValueCallback
    );
  };

  const mintedChangeValueCallback = (
    success: boolean,
    transactionTokens: number[]
  ) => {
    if (success) {
      unUsedTokens.current = unUsedTokens.current.filter(
        (x) => !transactionTokens.includes(x.tokenId)
      );
      decreaseMaxMint(transactionTokens.length);
    }
  };

  return (
    <div>
      <MaxMintCountTab maxMint={maxMint} />

      <ChangeMintValueTab
        mintCount={mintCount}
        handleDecreaseMintCount={handleDecreaseMintCount}
        handleMintMaxCount={handleMintMaxCount}
        handleIncreaseMintCount={handleIncreaseMintCount}
      />

      <MintButton
        disabled={!mintCount || isLoadingUsedTokens}
        // disabled={false}
        text={isLoadingUsedTokens ? `Checking available mints...` : `Mint`}
        handleMint={handleMint}
      />
    </div>
  );
};

export default MintByFounders;
