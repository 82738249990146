import React from 'react';

import Card from '@battlefly/components/core/Card';
import { Mod } from '@battlefly/__generated__/globalTypes';
import Loadout from '@battlefly/components/Loadout';
import AlterLoadoutBlock from '@battlefly/components/BattleflyCard/AlterLoadoutBlock';
import GetNewModsBlock from '@battlefly/components/BattleflyCard/GetNewModsBlock';

type BattleflyLoadoutProps = {
  mods: Mod[];
  readonly?: boolean;
  battleflyId: string;
};
const BattleflyLoadout = (props: BattleflyLoadoutProps) => {
  const { mods = [], readonly, battleflyId } = props;
  return (
    <Card className="mobile:p-6">
      <div className="pb-4 font-heading text-5xl leading-10">
        Current loadout
      </div>
      <div className="mb-6 text-base leading-[19px] text-[#D9D9D9]">
        Each BattleFly is equipped with two weapon, one defense and one utility
        mod.
        <br />
        You can swap mods from your inventory, or buy new mods by Scavenging in
        the Wastelands.
      </div>
      <Loadout
        mods={mods}
        elementClasses="w-[25%] mobile:w-[200px] min-w-[150px]"
        overflow
      />
      {!readonly && (
        <div className="mt-4">
          <div className="flex flex-col gap-2 xl:flex-row">
            <div className="grow">
              <AlterLoadoutBlock battleflyId={battleflyId} />
            </div>
            <div className="min-h-[90px] grow">
              <GetNewModsBlock battleflyId={battleflyId} />
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default BattleflyLoadout;
