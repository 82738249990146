import InfoComponent from 'components/InfoComponent';
import SortingArrows from '@battlefly/components/pages/MissionControl/SortingArrows';

type SortingControlsProps = {
  sorting: any;
  setSorting: any;
  handleSelectAll: () => void;
};

const SortingControls = (props: SortingControlsProps) => {
  const { sorting, setSorting, handleSelectAll } = props;
  return (
    <div className="relative flex h-full h-[38px] w-full items-end gap-3">
      <InfoComponent wide className="absolute w-[200px]">
        Click your BattleFly to see current loadout
      </InfoComponent>
      <div className="w-[330px]"></div>
      <div className="relative flex h-full w-[50px] items-end">League</div>
      <div className="flex w-[110px] items-end justify-center">
        <SortingArrows
          onSortDesc={() =>
            setSorting({ property: 'starsRate', sortOrder: -1 })
          }
          onSortAsc={() => setSorting({ property: 'starsRate', sortOrder: 1 })}
          nowSorting={
            sorting?.property === 'starsRate'
              ? sorting?.sortOrder === -1
                ? 'desc'
                : 'asc'
              : null
          }
        />
        Power Score
      </div>
      <div className="flex w-[82px] items-end justify-center">
        <SortingArrows
          onSortDesc={() =>
            setSorting({ property: 'contestPoints', sortOrder: -1 })
          }
          onSortAsc={() =>
            setSorting({ property: 'contestPoints', sortOrder: 1 })
          }
          nowSorting={
            sorting?.property === 'contestPoints'
              ? sorting?.sortOrder === 1
                ? 'asc'
                : 'desc'
              : null
          }
        />
        Ranking
      </div>
      <div className="flex w-[100px] items-center justify-center">Status</div>
      <div className="flex w-[82px] items-end justify-center text-center leading-none">
        <SortingArrows
          onSortDesc={() => setSorting({ property: 'winRate', sortOrder: -1 })}
          onSortAsc={() => setSorting({ property: 'winRate', sortOrder: 1 })}
          nowSorting={
            sorting?.property === 'winRate'
              ? sorting?.sortOrder === 1
                ? 'asc'
                : 'desc'
              : null
          }
        />
        <div>Win Rate</div>
      </div>
      <div className="flex w-[82px] items-end justify-center text-center leading-none">
        <SortingArrows
          onSortDesc={() => setSorting({ property: 'winMagic', sortOrder: -1 })}
          onSortAsc={() => setSorting({ property: 'winMagic', sortOrder: 1 })}
          nowSorting={
            sorting?.property === 'winMagic'
              ? sorting?.sortOrder === 1
                ? 'asc'
                : 'desc'
              : null
          }
        />
        <div>
          Lifetime
          <br />
          Winnings
        </div>
      </div>
      <div className="flex w-[82px] items-end justify-center text-center leading-none">
        <SortingArrows
          onSortDesc={() =>
            setSorting({ property: 'totalNectar', sortOrder: -1 })
          }
          onSortAsc={() =>
            setSorting({ property: 'totalNectar', sortOrder: 1 })
          }
          nowSorting={
            sorting?.property === 'totalNectar'
              ? sorting?.sortOrder === 1
                ? 'asc'
                : 'desc'
              : null
          }
        />
        <div>
          Total Nectar
          <br />
          Earned
        </div>
      </div>
      <div className="flex w-[120px] items-center justify-center gap-1 pr-[10px] leading-none">
        Last Five Battles Result
      </div>
      <div
        onClick={handleSelectAll}
        className="flex cursor-pointer leading-none underline"
      >
        Select All
      </div>
    </div>
  );
};

export default SortingControls;
