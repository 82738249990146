import React from 'react';
import classnames from 'classnames';

import { Mod } from '@battlefly/__generated__/globalTypes';
import ModFull from '@battlefly/components/BattleflyDetails/Mods/ModFull';
import { MODS_DEFS } from '@battlefly/components/Icons/Mods/ModBackground';

interface ModCompactDetailsInterface {
  mod: Mod;
  className?: string;
  onClick?: () => void;
  highlight?: boolean;
}

const ModCompactDetails: React.FC<ModCompactDetailsInterface> = (props) => {
  const { className, onClick, highlight } = props;

  function handleClick() {
    if (onClick) {
      onClick();
    }
  }
  const radius = highlight ? '0px 0px 25px' : '0px 0px 10px';
  return (
    <div
      onClick={handleClick}
      className={classnames(
        'relative z-40 min-w-[290px] max-w-[290px] rounded-xl',
        className
      )}
      style={{
        filter: `drop-shadow(${radius} ${
          MODS_DEFS[props.mod.blueprint.rarity].compactGradientColor
        })`,
      }}
    >
      <ModFull mod={props.mod} />
    </div>
  );
};

export default ModCompactDetails;
