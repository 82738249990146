import type { NextPage } from 'next';
import { useState, useContext } from 'react';
import { useAccount } from 'wagmi';

import PageStatusMessage from '@battlefly/components/Layout/PageStatusMessage';
import { useAppSelector } from '@battlefly/hooks';
import useBattleflyDetails from '@battlefly/hooks/components/useBattleflyDetails';
import { selectIsUserAuthorized } from '@battlefly/redux/user/userReducer';
import { BattleFly } from '@battlefly/__generated__/globalTypes';

import {
  isBattleFlyStakedAndBelongsToUser,
  getBattleflyLocation,
} from '@battlefly/util/helpers';
import {
  CustomToast,
  ToastStatus,
} from '@battlefly/components/CustomToast/CustomToast';
import BattleflyName from '@battlefly/components/BattleflyName';
import BattleflyLoadout from '@battlefly/components/BattleflyLoadout';
import BattleflyCard from '@battlefly/components/BattleflyCard';
import BattleStatusCard from '@battlefly/components/BattleStatusCard';
import BattleflyMarksCard from '@battlefly/components/BattleflyMarksCard';
import BattleflyFightStats from '@battlefly/components/BattleflyFightStats';
import BattleflyCombatLog from '@battlefly/components/BattleflyCombatLog';

import { useCombatLogHistoryData } from 'hooks/useCombatLogsData';
import { useLeaveCombat } from 'hooks/battle/useLeaveCombat';
import { ModalContext } from '@battlefly/context/modalContext';
import { RarityRankingPower } from '@battlefly/components/battlefly';
import { BattleflyContext } from '@battlefly/context/battleflyContext';

const BattleflyDetailedView: NextPage = () => {
  const isUserAuthorized = useAppSelector(selectIsUserAuthorized());
  const {
    setHuntersMarkModalState,
    setScavengerMarkModalState,
    setBattleSettings,
  } = useContext(ModalContext);
  const [leagueLadderModalOpened, setLeagueLadderModalOpened] = useState(false);
  const { isUnstaked } = useContext(BattleflyContext);

  const { data, loading, error } = useBattleflyDetails();
  const battleFly: BattleFly = data?.getBattleFly?.battleFly;
  const { address: userAddress } = useAccount();
  const isOwnedByUserAndStaked = isBattleFlyStakedAndBelongsToUser(
    battleFly,
    userAddress
  );
  const isBattleflyUnstaked = isUnstaked(battleFly?.id);
  const [leaveCombat, loadingLeaveCombat] = useLeaveCombat(battleFly?.location);

  const { logs, location, endCursor, hasNextPage, fetchMore } =
    useCombatLogHistoryData(battleFly?.id?.toString(), 5);

  function handleHuntersMark() {
    if (isOwnedByUserAndStaked) {
      return setHuntersMarkModalState(battleFly);
    }
    handleMarksModalOnUnstakedBattlefly();
  }
  function handleScavenging() {
    if (isOwnedByUserAndStaked) {
      return setScavengerMarkModalState(battleFly);
    }
    handleMarksModalOnUnstakedBattlefly();
  }

  function handleBattleNow() {
    // TODO: check if battlefly is staked
    // is Wating for unstake ?

    setBattleSettings({ battleflies: [battleFly] });

    // modalBfToProvingGroundsId(Number(battleFly.id));
  }

  function handleUpdateBattleSettings() {
    setBattleSettings({ battleflies: [battleFly] });
    // setBattleSettingsDrawer(battleFly);
  }

  async function handleLeaveBattle() {
    try {
      await leaveCombat({
        variables: {
          battleFlyIds: [parseInt(battleFly.id.toString())],
        },
      });
    } catch (e) {
      console.error(e);
      CustomToast({
        status: ToastStatus.FAILURE,
        message: `Can't leave ${getBattleflyLocation(battleFly.location)}`,
      });
    }
  }

  const handleMarksModalOnUnstakedBattlefly = () => {
    CustomToast({
      status: ToastStatus.REJECTED,
      message: 'First stake battlefly to apply marks',
    });
  };

  if (loading || !battleFly) {
    return <PageStatusMessage message="Loading...." />;
  }

  if (!isUserAuthorized) {
    return <PageStatusMessage message="Connect wallet first" />;
  }

  if (error) {
    return <PageStatusMessage message="Error" />;
  }

  return (
    <div className="px-3 sm:mt-4 sm:px-6">
      <div className="flex flex-col gap-4">
        <div className="grid-cols-8 gap-4 lg:grid">
          <div className="col-span-4 items-end px-2 md:flex xl:col-span-3 mobile:mb-4">
            <BattleflyName tokenId={battleFly.id} name={battleFly.name} />
          </div>
          <div className="col-span-4 xl:col-span-5 mobile:mb-4">
            <RarityRankingPower
              battlefly={battleFly}
              className="justify-end px-2"
            />
          </div>
          <div className="col-span-4 max-w-[620px] xl:col-span-3">
            <div className="grid grid-flow-row gap-4">
              <BattleflyCard
                battlefly={battleFly}
                readonly={!isOwnedByUserAndStaked}
              />
              <BattleStatusCard
                isWithdrawing={loadingLeaveCombat}
                handleBattleNow={handleBattleNow}
                handleWithdraw={handleLeaveBattle}
                handleUpdateSettings={handleUpdateBattleSettings}
                battlefly={battleFly}
                logs={logs}
                readonly={!isOwnedByUserAndStaked}
              />
            </div>
          </div>

          <div className="col-span-4 flex flex-col justify-between gap-4 xl:col-span-5 mobile:mt-6">
            <BattleflyLoadout
              battleflyId={battleFly.id}
              mods={battleFly.mods}
              readonly={!isOwnedByUserAndStaked}
            />

            {isOwnedByUserAndStaked && (
              <BattleflyMarksCard
                handleHuntersMark={handleHuntersMark}
                handleScavenging={handleScavenging}
                battlefly={battleFly}
              />
            )}
          </div>
        </div>

        <div className="grid-flow-dense grid-cols-8 gap-4 sm:grid">
          <div className="col-span-4 sm:order-last xl:col-span-5">
            <BattleflyFightStats battlefly={battleFly} />
          </div>
          <div className="col-span-4 xl:col-span-3">
            <BattleflyCombatLog logs={logs} battlefly={battleFly} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BattleflyDetailedView;
