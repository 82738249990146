import React from 'react';

import Image from 'components/core/Image';
import { useAppSelector } from 'hooks';
import { selectIsUserAuthorized } from 'redux/user/userReducer';
import { UserBalanceContext } from 'context/userBalanceContext';
import { shortenMoney } from '@battlefly/lib/utils';
import RainbowKitModalButton from 'components/RainbowKitModalButton/RainbowKitModalButton';
import { UserProfileIcon } from 'components/Icons';

type BalancePanelProps = {
  toggleWallet: () => void;
};

const Box = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex items-center gap-2 bg-core-blue-light px-2 pr-6">
      {children}
    </div>
  );
};

const Label = ({ children }: { children: React.ReactNode }) => {
  return (
    <span className="mt-1 -ml-[2px] text-[11px] font-light">{children}</span>
  );
};

const BalancePanel = (props: BalancePanelProps) => {
  const { toggleWallet } = props;
  const isUserAuthorized = useAppSelector(selectIsUserAuthorized());

  const {
    magicBalance,
    nectarBalance,
    isDepositing,
    isWithdrawing,
    unstakedMagicBalance,
  } = React.useContext(UserBalanceContext);

  if (!isUserAuthorized) {
    return <RainbowKitModalButton />;
  }

  return (
    <div className="flex gap-2 bg-core-blue p-2">
      <Box>
        <UserProfileIcon />
        <Label>User Profile</Label>
      </Box>
      <Box>
        <Image
          width={17}
          height={22}
          className="opacity-40"
          src="/assets/icons/nectar-icon.png"
          alt="Nectar"
        />
        <div>{shortenMoney(nectarBalance)}</div>
        <Label>Nectar</Label>
      </Box>

      <Box>
        <Image
          width={21}
          height={33}
          className="h-6 w-6 opacity-40"
          src="/assets/icons/magic_logo_ic.svg"
          alt="Magic"
        />
        <div>{shortenMoney(unstakedMagicBalance)}</div>
        <Label>Magic</Label>
      </Box>

      <Box>
        <Image
          width={26}
          height={26}
          className="h-8 w-8"
          src="/assets/icons/magic_staked.svg"
          alt="Magic"
        />
        <div>{shortenMoney(magicBalance)}</div>
        <Label>Magic Staked</Label>
      </Box>
      <div
        className="flex mt-2 items-center bg-core-blue"
        onClick={toggleWallet}
      >
        <Image
          width={46}
          height={46}
          src="/assets/icons/wallet-icon.png"
          alt="Wallet"
        />
      </div>
    </div>
  );
};

export default BalancePanel;
