import React from 'react';

import {
  BattleFly,
  BattleFlyLocation,
} from '@battlefly/__generated__/globalTypes';
import { ModalContext } from 'context/modalContext';
import { Modal, Button, Card, Link } from 'components/core';
import { CloseIcon } from 'components/Icons';
import InputTypeRange from 'components/InputTypeRange';
import { battleflyIsIn, battleflyHasHyperdomeMark } from 'util/helpers';
import { MobileOnly } from 'components/utility';
import { useGoToCombat } from 'hooks/game/useGoToCombat';
import { useMutateCombatRate } from 'hooks/game/useMutateCombatRate';

import MobileInput from './MobileInput';
import ListItem from './ListItem';

const MAX_BATTLES_PER_DAY = 100;

const getDefaultBattlesPerDay = (battleflies: BattleFly[]) => {
  if (battleflies.length > 1 || battleflies.length === 0) return 50;
  const battlefly = battleflies[0];
  if (battleflyIsIn(battlefly.location, BattleFlyLocation.Hyperdome)) {
    return battlefly.hyperdomeCombatRate;
  }
  if (battleflyIsIn(battlefly.location, BattleFlyLocation.ProvingGrounds)) {
    return battlefly.provingGroundsCombatRate;
  }
  return 50;
};

const BattleSettingsModal = () => {
  const { battleSettings, setBattleSettings } = React.useContext(ModalContext);
  const battleflies = battleSettings?.battleflies || [];
  const [battlesPerDay, setBattlesPerDay] = React.useState(
    getDefaultBattlesPerDay(battleflies).toString()
  );
  const [loading, setLoading] = React.useState(false);

  const [goToHyperDome] = useGoToCombat(true);
  const [goToProvingGrounds] = useGoToCombat(false);
  const [updateHyperDomeFrequency] = useMutateCombatRate(true);
  const [updateProvingGroundsFrequency] = useMutateCombatRate(false);

  function handleChange(input: string) {
    if (Number(input) > MAX_BATTLES_PER_DAY) {
      return setBattlesPerDay(`${MAX_BATTLES_PER_DAY}`);
    }

    setBattlesPerDay(input);
  }
  function handleSubmit() {
    const newBattleRate = Number(battlesPerDay);
    const apiCalls = [];
    const [
      hyperDomeIds,
      hyperDomeUpdateIds,
      provingGroundsIds,
      provingGroundsUpdateIds,
    ] = battleflies.reduce(
      (acc, battlefly) => {
        const hasHyperdomeMark = battleflyHasHyperdomeMark({
          tattoos: battlefly.tattoos,
        });
        if (battleflyIsIn(battlefly.location, BattleFlyLocation.Hyperdome)) {
          acc[1].push(Number(battlefly.id));
        } else if (
          battleflyIsIn(battlefly.location, BattleFlyLocation.ProvingGrounds)
        ) {
          acc[3].push(Number(battlefly.id));
        } else if (hasHyperdomeMark) {
          acc[0].push(Number(battlefly.id));
        } else {
          acc[2].push(Number(battlefly.id));
        }
        return acc;
      },
      [[], [], [], []]
    );

    if (hyperDomeIds.length) {
      apiCalls.push(
        goToHyperDome({
          variables: { battleFlyIds: hyperDomeIds, combatRate: newBattleRate },
        })
      );
    }
    if (hyperDomeUpdateIds.length) {
      apiCalls.push(
        updateHyperDomeFrequency({
          variables: {
            battleFlyIds: hyperDomeUpdateIds,
            newCombatRate: newBattleRate,
          },
        })
      );
    }
    if (provingGroundsIds.length) {
      apiCalls.push(
        goToProvingGrounds({
          variables: {
            battleFlyIds: provingGroundsIds,
            combatRate: newBattleRate,
          },
        })
      );
    }
    if (provingGroundsUpdateIds.length) {
      apiCalls.push(
        updateProvingGroundsFrequency({
          variables: {
            battleFlyIds: provingGroundsUpdateIds,
            newCombatRate: newBattleRate,
          },
        })
      );
    }

    setLoading(true);
    Promise.all(apiCalls).then(() => {
      setLoading(false);
      setBattleSettings(null);
    });
  }

  function handleRemove(battleflyId: string) {
    setBattleSettings({
      ...battleSettings,
      battleflies: battleflies.filter(
        (battlefly) => battlefly.id !== battleflyId
      ),
    });
  }

  return (
    <Modal
      className="!block w-full max-w-[600px] xl:max-w-[800px]"
      onClose={() => setBattleSettings(null)}
      isOpen={!!battleSettings}
    >
      <Card className="! !p-2 pt-8 md:!p-8">
        <div className="flex w-[100%] max-w-[100%] flex-col gap-4 md:w-[600px] xl:w-[800px]">
          <header className="flex items-center justify-between">
            <div className="font-heading text-4xl text-core-cyberpink">
              Preparing for Battle
            </div>
            <div className="cursor-pointer">
              <CloseIcon fill="#fff" onClick={() => setBattleSettings(null)} />
            </div>
          </header>
          <section>
            <Card flat className="py-3 px-0">
              <div className="mb-2 text-center font-heading text-xl">
                Selected BattleFlys
              </div>
              {/* <div className="mx-8"> */}
              <ul className="flex w-full gap-2 overflow-x-scroll">
                {battleflies.map((battlefly) => (
                  <ListItem
                    onRemove={() => handleRemove(battlefly.id)}
                    key={battlefly.id}
                    battlefly={battlefly}
                  />
                ))}
              </ul>
              {/* </div> */}
            </Card>
          </section>
          <section className="flex flex-col gap-4">
            <Card flat className="!p-2">
              <div className="mb-2 text-center font-heading text-xl">
                Battle Frequency
              </div>
              <Card className="!p-0 md:!p-2">
                <div className="hidden md:block">
                  <div className="justify-centerflex">
                    <div className="relative -ml-[10px] w-[calc(100%-50px)]">
                      <div
                        className="absolute left-0 -bottom-14 w-full"
                        // style={{
                        //   transform: `translateX(calc(${
                        //     (Number(battlesPerDay) + 35) / 9.9
                        //   }% - ${(Number(battlesPerDay) / MAX_BATTLES_PER_DAY) * 55}px))`,
                        // }}
                        style={{
                          transform: `translateX(calc(${
                            (Number(battlesPerDay) - 5 ) / 1
                          }% + ${(Number(battlesPerDay) / MAX_BATTLES_PER_DAY) * 20}px))`,
                        }}
                      >
                        <div className="w-16 rounded-xl bg-[#58dcdf] text-center text-core-black">
                          {battlesPerDay}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div>
                      10<span className="invisible">00</span>
                    </div>
                    <InputTypeRange
                      value={battlesPerDay}
                      min={10}
                      max={MAX_BATTLES_PER_DAY}
                      onChange={handleChange}
                      className="hidden md:block"
                    />
                    <div>{MAX_BATTLES_PER_DAY}</div>
                  </div>
                </div>
                <MobileOnly className="pl-4 pr-0">
                  <MobileInput
                    onChange={handleChange}
                    value={battlesPerDay}
                    min={10}
                    max={MAX_BATTLES_PER_DAY}
                  />
                </MobileOnly>
              </Card>
            </Card>
            <div className="flex justify-center">
              <div className="w-60">
                <Button
                  disabled={loading || Number(battlesPerDay) < 10}
                  onClick={handleSubmit}
                  cyberPink
                  isLoading={loading}
                >
                  Lock & Battle
                </Button>
              </div>
            </div>
          </section>
          <hr className="border-t-2 border-core-grey" />
          <section>
            <div className="flex justify-between">
              <div>
                <div className="mb-2 text-center font-heading text-xl">
                  Cost per Battle
                </div>
                <div className="text-xs">0.1 Magic</div>
              </div>
              <div className="min-w-[150px] self-end">
                <Button transparent light>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0xB0c7a3Ba49C7a6EaBa6cD4a96C55a1391070Ac9A"
                  >
                    Buy Magic
                  </a>
                </Button>
              </div>
            </div>
          </section>
        </div>
      </Card>
    </Modal>
  );
};

export default BattleSettingsModal;
