import React from 'react';
import {IconProps} from '@battlefly/components/Icons';
import {Rarity} from "@battlefly/__generated__/globalTypes";

export type ModWeaponBgRectangleProps = IconProps & {
  fillColor: string;
  rarity: Rarity;
};

const ModWeaponBgRectangle: React.FC<ModWeaponBgRectangleProps> = (props) => {
  return (
    <svg
      width="279"
      height="342"
      viewBox="0 0 279 342"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="1">
        <path
          d="M225.056 342H12.936V204.37H17.0504V337.694H223.38L274.733 285.871V4.152H68.5565L17.0504 56.1286V104.261L16.4409 104.876L-68.7423 190.837L-71.6377 187.915L12.936 102.569V54.4371L66.8803 0H279V287.563L225.056 342Z"
          fill={props.fillColor}
        />
        <path
          d="M213.322 323.086H132.406V321.702H212.713L240.142 294.022L241.056 295.098L213.322 323.086Z"
          fill={props.fillColor}
        />
        <path
          d="M126.158 318.165H117.929V326.469H126.158V318.165Z"
          fill={props.fillColor}
        />
        <path
          d="M110.919 318.165H102.69V326.469H110.919V318.165Z"
          fill={props.fillColor}
        />
        <path
          d="M95.681 318.165H87.4521V326.469H95.681V318.165Z"
          fill={props.fillColor}
        />
      </g>
    </svg>
  );
};

export default ModWeaponBgRectangle;
