import PageWrapper from '@battlefly/components/Layout/PageWrapper/PageWrapper';
import { useGetMyBattleflysQuery } from '@battlefly/graphql/__generated__/userGql';
import { useAppSelector } from '@battlefly/hooks';
import { useStakingBattlefliesOfOwner } from '@battlefly/hooks/contractsNew/useRevealStakingContract';
import ModalWrapper from '@battlefly/Wrappers/ModalWrapper';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { browserName, isMobile } from 'react-device-detect';
import { Link, useNavigate } from 'react-router-dom';
import { useNetwork } from 'wagmi';
const AcceptedBrowserTypes = {
  Chrome: 'Chrome',
  Firefox: 'Firefox',
  // Opera: 'Opera',
  // Yandex: 'Yandex',
  // Safari: 'Safari',
  // InternetExplorer: 'Internet Explorer',
  // Edge: 'Edge',
  // Chromium: 'Chromium',
  // Ie: 'IE',
  // MobileSafari: 'Mobile Safari',
  // EdgeChromium: 'Edge Chromium',
  // MIUI: 'MIUI Browser',
  // SamsungBrowser: 'Samsung Browser',
};

const ValidationPrompts = ({ children }: { children: React.ReactNode }) => {
  const { stakedTokenIds, isLoading } = useStakingBattlefliesOfOwner();

  const { chain: activeChain } = useNetwork();
  const navigate = useNavigate();
  const isNetworkUnsupported = activeChain?.unsupported;
  const hasHoldingTokenIds =
    useAppSelector((state) => state.holding.allHoldingTokenIds)?.length > 0;

  const isUserAuthorized = useAppSelector(
    (state) => state.user.isUserAuthorized
  );

  const { data, loading } = useGetMyBattleflysQuery();

  const hasBattleFliesStaked =
    data?.me?.battleFlies.some((bf) => bf.stage === 'battlefly') ?? false;

  const loaded = !(loading || isLoading);

  const ALERTS = {
    userNotConnected: () => <ConnectWalletPrompt />,
    noBattlefliesOwned: () => (
      <Prompt
        message={`You'll need a BattleFly to play Racer.
Get yours at the Trove Marketplace`}
        buttonText={`Buy On Trove`}
        buttonLink={`https://trove.treasure.lol/collection/battlefly`}
      />
    ),
    checkingForBattleflies: () => (
      <Prompt message={`Checking For BattleFlys...`} />
    ),
    noBattlefliesStakedInGame: () => (
      <Prompt
        message={`Your Battlefly must be staked in Mission Control to Play Racer`}
        buttonText={`Hatch or Stake in The Rift`}
        onClick={() => navigate('/the-rift/cocoons')}
      />
    ),
    noDesktop: () => (
      <Prompt
        message={`Racer is currently designed for Desktop Only. We're hard at work on mobile. Please use Desktop until then.`}
      />
    ),
    noChromeUser: () => (
      <Prompt
        message={`Racer is built for Chrome. Other browsers are coming soon - stay tuned.`}
      />
    ),
    noArbitrumNetwork: () => <SwitchNetworkPrompt />,
  };

  let component;

  component = children;

  if (isMobile) {
    component = ALERTS.noDesktop();
  } else if (
    !Object.entries(AcceptedBrowserTypes).some(
      (entry) => entry[1] === browserName
    )
  ) {
    component = ALERTS.noChromeUser();
  } else if (isNetworkUnsupported) {
    component = ALERTS.noArbitrumNetwork();
  } else if (!isUserAuthorized) {
    component = ALERTS.userNotConnected();
  } else if (!hasHoldingTokenIds && !hasBattleFliesStaked) {
    component = ALERTS.noBattlefliesOwned();
  } else if (!loaded) {
    component = ALERTS.checkingForBattleflies();
  } else if (hasHoldingTokenIds && !hasBattleFliesStaked) {
    component = ALERTS.noBattlefliesStakedInGame();
  } else {
    component = children;
  }

  return <PageWrapper>{component}</PageWrapper>;
};

const ConnectWalletPrompt = () => {
  return (
    <ModalWrapper transparent>
      <div className="flex w-full flex-col justify-center justify-items-center gap-8">
        <h1 className="text-center text-[1rem] md:text-[2rem] lg:text-[3rem] xl:text-[3rem]">
          Connect Your Wallet to Play
        </h1>
        <ConnectButton.Custom>
          {({ openConnectModal, mounted }) => {
            return (
              <div
                {...(!mounted && {
                  'aria-hidden': true,
                  style: {
                    opacity: 0,
                    pointerEvents: 'none',
                    userSelect: 'none',
                  },
                })}
              >
                {(() => {
                  return (
                    <div className="flex w-full justify-center">
                      <button
                        onClick={openConnectModal}
                        className="w-1/2 py-2 text-2xl font-bold"
                        style={{
                          border: `3px solid #7B61FF`,
                          background: `linear-gradient(134.97deg, #7B61FF 0.29%, #5541C7 29.89%, #0F0F37 100%)`,
                        }}
                      >
                        Connect Wallet
                      </button>
                    </div>
                  );
                })()}
              </div>
            );
          }}
        </ConnectButton.Custom>
      </div>
    </ModalWrapper>
  );
};

const SwitchNetworkPrompt = () => {
  return (
    <ModalWrapper transparent>
      <div className="flex w-full flex-col justify-center justify-items-center gap-8">
        <h1 className="text-center text-[1rem] md:text-[2rem] lg:text-[3rem] xl:text-[3rem]">
          You must be on Arbitrum to play Racer
        </h1>
        <ConnectButton.Custom>
          {({ openConnectModal, mounted, openChainModal }) => {
            return (
              <div
                {...(!mounted && {
                  'aria-hidden': true,
                  style: {
                    opacity: 0,
                    pointerEvents: 'none',
                    userSelect: 'none',
                  },
                })}
              >
                {(() => {
                  return (
                    <div className="flex w-full justify-center">
                      <button
                        onClick={openChainModal}
                        className="w-1/2 py-2 text-2xl font-bold"
                        style={{
                          border: `3px solid #7B61FF`,
                          background: `linear-gradient(134.97deg, #7B61FF 0.29%, #5541C7 29.89%, #0F0F37 100%)`,
                        }}
                      >
                        Switch Networks
                      </button>
                    </div>
                  );
                })()}
              </div>
            );
          }}
        </ConnectButton.Custom>
      </div>
    </ModalWrapper>
  );
};

export const Prompt = ({
  message,
  buttonLink,
  buttonText,
  onClick,
}: {
  message: string;
  buttonText?: string;
  buttonLink?: string;
  onClick?: () => void;
}) => {
  return (
    <ModalWrapper transparent>
      <div className="flex w-full flex-col justify-center justify-items-center gap-8">
        <h1 className="text-center text-[1rem] md:text-[2rem] lg:text-[3rem] xl:text-[3rem]">
          {message}
        </h1>
        {onClick && (
          <div onClick={onClick} className="flex w-full justify-center">
            <button
              className="w-full py-2 text-2xl font-bold"
              style={{
                border: `3px solid #7B61FF`,
                background: `linear-gradient(134.97deg, #7B61FF 0.29%, #5541C7 29.89%, #0F0F37 100%)`,
              }}
            >
              {buttonText}
            </button>
          </div>
        )}
        {buttonLink && (
          <div className="flex w-full justify-center">
            <Link to={buttonLink} className="w-full text-center">
              <button
                className="w-1/2 py-2 text-2xl font-bold"
                style={{
                  border: `3px solid #7B61FF`,
                  background: `linear-gradient(134.97deg, #7B61FF 0.29%, #5541C7 29.89%, #0F0F37 100%)`,
                }}
              >
                {buttonText}
              </button>
            </Link>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};

export default ValidationPrompts;
