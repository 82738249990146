import { useAccount, useContractRead } from 'wagmi';
import { MaxUint256 } from '@ethersproject/constants';
import { BigNumber } from 'ethers';

import { CONTRACT_ABIS } from 'common/constants';
import { BattleflyGame } from 'common/constants';
import {
  useMakeContractWrite,
  useMakePrepareContractWrite,
} from 'hooks/contractsNew/utils';
import { useContractAddresses } from './useContractAddresses';

export const useApproveGFly = () => {
  const { isContractApproved, refetch } = useCheckGFlyAllowance();
  const contractAddresses = useContractAddresses();
  const operatorAddress =
    contractAddresses[BattleflyGame.BattleflyCreditsContract];

  const { config, error, data } = useMakePrepareContractWrite(
    {
      functionName: 'approve',
      enabled: !isContractApproved,
      args: [operatorAddress, MaxUint256.toString()],
    },
    BattleflyGame.GFlyContract
  );

  return {
    isContractApproved,
    ...useMakeContractWrite({
      ...config,
      onSuccess() {
        refetch();
      },
    }),
  };
};

export const useCheckGFlyAllowance = () => {
  const { address } = useAccount();
  const contractAddresses = useContractAddresses();
  const contractAddress =
    contractAddresses[BattleflyGame.BattleflyCreditsContract];
  const { data, isError, isLoading, refetch } = useContractRead({
    address: contractAddresses[BattleflyGame.GFlyContract],
    abi: CONTRACT_ABIS[BattleflyGame.GFlyContract],
    functionName: 'allowance',
    args: [address, contractAddress],
    watch: false,
  });

  const dataOrDefault = BigNumber.isBigNumber(data) ? data : BigNumber.from(0);
  const isContractApproved = dataOrDefault.gt(0);
  return { isContractApproved, isError, isLoading, refetch };
};
