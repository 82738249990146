import React, { useCallback } from 'react';

interface BalanceInputProps {
  value: number;
  maxValue: number;
  onChange: (amount: number) => void;
}

const BalanceInput: React.FC<BalanceInputProps> = (props) => {
  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        const val = parseFloat(event.target.value);
        props.onChange(val);
      },
      [props.onChange]
    );

  const handleMaxClick = useCallback(() => {
    props.onChange(props.maxValue);
  }, [props.maxValue, props.onChange]);

  return (
    <div className="flex w-full">
      <div className="flex h-[44px] grow items-center rounded-l-lg border border-[#ddd] text-[#718096]">
        <input
          type="number"
          step="0.001"
          className="h-full w-full grow rounded-l-lg bg-[#fff] px-2 text-base outline-0"
          value={isNaN(props.value) ? '' : props.value}
          onChange={handleInputChange}
        />
        <span className="flex h-full items-center justify-center bg-white px-2 font-mono text-base text-[#718096]">
          $Magic
        </span>
      </div>
      <button
        className="gra-purple flex items-center rounded-r-lg bg-[#7B61FF] px-8 font-heading text-xl"
        onClick={handleMaxClick}
      >
        Max
      </button>
    </div>
  );
};

export default BalanceInput;
