import PageTitle from '@battlefly/components/PageTitle/PageTitle';
import { useRacerGame } from '@battlefly/hooks/racer/useRacerGame';
import { RACER_BASE_URL } from '@battlefly/hooks/racer/utils';
import { useUnity } from '@battlefly/hooks/unityInitializer/useUnity';
import useUnloadUnityOnAccountChange from '@battlefly/hooks/useUnloadUnityOnAccountChange';
import Portal from '@battlefly/util/Portal';
import { NextPage } from 'next';
import { Unity } from 'react-unity-webgl';

const RacerGame: NextPage = () => {
  const {
    unityProvider,
    sendToUnity,
    isLoaded,
    loadingProgression,
    onUnityEvent,
    offUnityEvent,
    unload,
  } = useUnity({ gameURL: RACER_BASE_URL });

  useRacerGame(sendToUnity, onUnityEvent, offUnityEvent, isLoaded);

  useUnloadUnityOnAccountChange({ unload });

  return (
    <>
      <Portal isOpen={true}>
        <div
          className={`fade-in-black-bg fixed top-0 flex h-screen w-full flex-col items-center overflow-scroll pl-[80px] pt-[72px]`}
        >
          <>
            {!isLoaded && (
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                <PageTitle
                  title={`Loading BattleFly Racer ${parseFloat(
                    (loadingProgression * 100).toString()
                  ).toFixed(2)}%`}
                ></PageTitle>
              </div>
            )}
            <Unity
              style={{
                visibility: isLoaded ? 'visible' : 'hidden',
                width: '100%',
                height: '100%',
              }}
              unityProvider={unityProvider}
            />
          </>
        </div>
      </Portal>
    </>
  );
};

export default RacerGame;
