import { Combat } from '@battlefly/__generated__/globalTypes';
import { BattleLog, PersonalizedBattleLogItem } from '@battlefly/ts/BattleLog';

function mapMod(mod: any) {
  return {
    ...mod,
    rarity: mod.blueprint.rarity,
    stats: mod.stats,
    type: mod.blueprint.type,
  };
}

export const mapPersonalizedCombatLog = (
  combat: Partial<Combat>,
  battleflyId: string
): PersonalizedBattleLogItem => {
  const winnerParticipant = combat.log.participants.find(
    (participant) =>
      Number(participant.battleFlyId) === Number(combat.battleFlyWinner.id)
  );
  const loserParticipant = combat.log.participants.find(
    (participant) =>
      Number(participant.battleFlyId) === Number(combat.battleFlyLooser.id)
  );
  const winner = {
    ...combat.battleFlyWinner,
    mods: [
      ...winnerParticipant.weapons.map(mapMod),
      ...winnerParticipant.defense.map(mapMod),
      ...winnerParticipant.utilities.map(mapMod),
    ],
  };

  const loser = {
    ...combat.battleFlyLooser,
    mods: [
      ...loserParticipant.weapons.map(mapMod),
      ...loserParticipant.defense.map(mapMod),
      ...loserParticipant.utilities.map(mapMod),
    ],
  };

  return {
    id: combat.id,
    isWinner: battleflyId === combat.battleFlyWinner.id,
    ownBattleFly: combat.battleFlyWinner.id === battleflyId ? winner : loser,
    opponent: battleflyId === combat.battleFlyWinner.id ? loser : winner,
    createdAt: combat.createdAt,
    location: combat.location,
    matchTimestamp: combat.matchTimestamp,
  };
};

export const mapCombatLog = (combat: Partial<Combat>): BattleLog => {
  const winnerParticipant = combat.log.participants.find(
    (participant) =>
      Number(participant.battleFlyId) === Number(combat.battleFlyWinner.id)
  );
  const loserParticipant = combat.log.participants.find(
    (participant) =>
      Number(participant.battleFlyId) === Number(combat.battleFlyLooser.id)
  );
  const winner = {
    ...combat.battleFlyWinner,
    mods: [
      ...winnerParticipant.defense.map(mapMod),
      ...winnerParticipant.utilities.map(mapMod),
      ...winnerParticipant.weapons.map(mapMod),
    ],
  };

  const loser = {
    ...combat.battleFlyLooser,
    mods: [
      ...loserParticipant.defense.map(mapMod),
      ...loserParticipant.utilities.map(mapMod),
      ...loserParticipant.weapons.map(mapMod),
    ],
  };
  return {
    id: combat.id,
    battleflies: [winner, loser],
    isWinner: !!winner,
    createdAt: combat.createdAt,
    location: combat.location,
    matchTimestamp: combat.matchTimestamp,
  };
};

export const mapSimpleBattleLog = (
  combat: Partial<Combat>,
  battleflyId: string
): PersonalizedBattleLogItem => {
  return {
    id: combat.id,
    isWinner: battleflyId === combat.battleFlyWinner.id,
    ownBattleFly:
      combat.battleFlyWinner.id === battleflyId
        ? combat.battleFlyWinner
        : combat.battleFlyLooser,
    opponent:
      battleflyId === combat.battleFlyWinner.id
        ? combat.battleFlyLooser
        : combat.battleFlyWinner,
    createdAt: combat.createdAt,
    location: combat.location,
    matchTimestamp: combat.matchTimestamp,
  };
};
