import { gql } from '@apollo/client';
import {
  CORE_BATTLEFLY_FIELDS,
  CORE_HUNTERS_MARK_FIELDS,
  CORE_MOD_BLUEPRINT_FIELDS,
  CORE_MOD_BLUEPRINT_FIELDS_MODPACK,
  CORE_SCAVENGING_MARK_FIELDS,
} from '@battlefly/graphql/fragments';

export const BATTLEFLY_BY_ID = gql`
  ${CORE_BATTLEFLY_FIELDS}
  ${CORE_HUNTERS_MARK_FIELDS}
  ${CORE_SCAVENGING_MARK_FIELDS}

  query GetBattlelyByID($input: BattleFlyInput!) {
    getBattleFly(input: $input) {
      battleFly {
        ...CorBattleflyFields
        hyperdomeWins
        hyperdomeLosses
        provingGroundsWins
        provingGroundsLosses
        lastHyperdomeCombatLost {
          location
          createdAt
        }
        lastProvingGroundsCombatLost {
          location
          createdAt
        }
        ...CoreHuntersMarkFields
        ...CoreScavengingMarkFields
      }
    }
  }
`;

export const GET_BF_MOD_CATEGORIES = gql`
  query getBfModCategories {
    getModCategories
  }
`;

export const MOVE_MOD = gql`
  ${CORE_BATTLEFLY_FIELDS}

  mutation UpdateInentory(
    $battleFlyId: ID!
    $inventory: [Int]!
    $loadout: [Int!]!
  ) {
    updateInventory(
      input: {
        battleFlyId: $battleFlyId
        inventory: $inventory
        loadout: $loadout
      }
    ) {
      ...CorBattleflyFields
    }
  }
`;

export const WITHDRAW_MAGIC = gql`
  mutation WithdrawMagic($magic: Float!) {
    withdrawMagic(input: { amount: $magic }) {
      transactionId
      expiry
      s
      v
      r
    }
  }
`;

export const SCAVENGE_GENERATE_MODS = gql`
  ${CORE_MOD_BLUEPRINT_FIELDS_MODPACK}

  mutation GenerateMods($battleflyId: ID!, $category: String!) {
    createModPack(input: { battleflyId: $battleflyId, category: $category }) {
      id
      modPackCategory
      modBlueprints {
        ...CoreModPackModBlueprintFields
      }
    }
  }
`;

export const REROLL_MODPACK = gql`
  ${CORE_MOD_BLUEPRINT_FIELDS_MODPACK}

  mutation RerollModPack($modPackId: ID!) {
    rerollModPack(input: { modPackId: $modPackId }) {
      id
      modPackCategory
      modBlueprints {
        ...CoreModPackModBlueprintFields
      }
    }
  }
`;

export const EQUIP_SCAVENGE = gql`
  ${CORE_BATTLEFLY_FIELDS}

  mutation EquipScavenge($modPackId: ID!, $modBlueprintId: ID!, $slot: Float!) {
    pickModFromModPack(
      input: {
        modPackId: $modPackId
        modBlueprintId: $modBlueprintId
        slot: $slot
      }
    ) {
      ...CorBattleflyFields
    }
  }
`;

export const APPLY_HUNTERS_MARK = gql`
  mutation applyHuntersMark($applyHuntersMarkInput: ApplyHuntersMarkInput!) {
    applyHuntersMark(input: $applyHuntersMarkInput) {
      id
      type
      createdAt
      willExpireAt
    }
  }
`;

export const APPLY_SCAVENGING_MARK = gql`
  mutation applyScavengingMark(
    $applyScavengingMarkInput: ApplyScavengingMarkInput!
  ) {
    applyScavengingMark(input: $applyScavengingMarkInput) {
      id
      type
      createdAt
      willExpireAt
    }
  }
`;

export const GET_BATTLEFLY_MARKS = gql`
  ${CORE_HUNTERS_MARK_FIELDS}
  ${CORE_SCAVENGING_MARK_FIELDS}

  query GetBattlefyMarks($input: BattleFlyInput!) {
    getBattleFly(input: $input) {
      battleFly {
        id
        ...CoreHuntersMarkFields
        ...CoreScavengingMarkFields
      }
    }
  }
`;

export const GET_MODPACK_BY_ID = gql`
  ${CORE_MOD_BLUEPRINT_FIELDS_MODPACK}

  query GetModPackById($modPackId: ID!) {
    getModPack(input: { modPackId: $modPackId }) {
      id
      modBlueprints {
        ...CoreModPackModBlueprintFields
      }
      modPackCategory
      primaryModBlueprintId
      isRecycled
      rerolled
    }
  }
`;

export const BATTLEFLY_SLOTS_BY_ID = gql`
  query GetBattleFlySlotsByID($input: BattleFlyInput!) {
    getBattleFly(input: $input) {
      battleFly {
        id
        slotsAvailable
      }
    }
  }
`;
