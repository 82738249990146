import React from 'react';

const ScavengeGold = ({ className }: { className?: string }) => {
  return (
    <img
      width={120}
      height={167}
      src="/assets/icons/ScavengeGold.png"
      alt="Scavenge Gold"
      className={className}
    />
  );
};

export default ScavengeGold;
