import React from 'react';

const Inventory: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} inline-block`}
  >
    <path
      d="M19.2498 18.5945V16.5901H17.2611L14.5322 11.8638L17.2611 7.1372H19.2498V5.13281H16.1038L12.2178 11.8638L16.1038 18.5945H19.2498Z"
      fill="white"
    />
    <path
      d="M20.7295 5.13281V7.1372H22.7182L25.4471 11.8638L22.7182 16.5901H20.7295V18.5945H23.8755L27.7615 11.8638L23.8755 5.13281H20.7295Z"
      fill="white"
    />
    <path
      d="M4 26.1372L7.88601 32.8679H11.032V30.8636H9.0433L6.31459 26.1372L9.0433 21.4106H11.032V19.4062H7.88601L4 26.1372Z"
      fill="white"
    />
    <path
      d="M12.5122 19.4062V21.4106H14.5009L17.2296 26.1372L14.5009 30.8636H12.5122V32.8679H15.658L19.544 26.1372L15.658 19.4062H12.5122Z"
      fill="white"
    />
    <path
      d="M20.4351 26.1372L24.3211 32.8679H27.4669V30.8636H25.4784L22.7494 26.1372L25.4784 21.4106H27.4669V19.4062H24.3211L20.4351 26.1372Z"
      fill="white"
    />
    <path
      d="M32.0933 19.4062H28.9473V21.4106H30.936L33.6649 26.1372L30.936 30.8636H28.9473V32.8679H32.0933L35.9793 26.1372L32.0933 19.4062Z"
      fill="white"
    />
  </svg>
);

export default Inventory;
