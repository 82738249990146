import React from 'react';

import { Card, Link, Button } from 'components/core';
import { MobileOnly } from 'components/utility';
import { ArrowRight } from 'components/Icons';
import { BattleFly, Mod } from '__generated__/globalTypes';
import ModCompactDetails from 'components/BattleflyDetails/Mods/ModCompactDetails';
import { filterInventoryByModType } from 'util/battleflyInventory';
import ActionPanel from './ActionPanel';
import Tab from './Tab';

type LoadoutPageProps = {
  className?: string;
  battlefly: BattleFly;
  handleSubmit: (inventory: number[], loadout: number[]) => void;
  isSubmitting?: boolean;
};

const LoadoutPage = (props: LoadoutPageProps) => {
  const { battlefly, handleSubmit, isSubmitting } = props;
  const { mods: initialLoadout, inventory: initialInventory } = battlefly;
  const [index, setIndex] = React.useState<number>(0);
  const [activeModId, setActiveModId] = React.useState<string>(null);
  const [loadout, setLoadout] = React.useState<Mod[]>(
    initialLoadout.slice().sort((a, b) => a.slot - b.slot)
  );
  const [inventory, setInventory] = React.useState<Mod[]>(initialInventory);
  const activeMod = loadout[index];
  const filteredInventory = filterInventoryByModType(inventory, activeMod);

  function handleEquip(mod: Mod) {
    const targetMod = loadout[index];
    setLoadout((prevLoadout) => {
      const newLoadout = [...prevLoadout];
      newLoadout[index] = mod;
      return newLoadout;
    });
    setInventory((prevInventory) => {
      const newInventory = [...prevInventory];
      newInventory[prevInventory.indexOf(mod)] = targetMod;
      return newInventory;
    });
  }

  function handleApplyLoadout() {
    handleSubmit(
      inventory.map((mod) => mod.slot),
      loadout.map((mod) => mod.slot)
    );
  }

  return (
    <div className="md:pr-8 md:pt-16">
      <section className="md:flex">
        <header className="max-w-[390px] p-4 pt-2 md:pr-16 md:pl-8">
          <div className="font-heading text-4xl leading-[40px]">
            Change loadout
          </div>
          <div className="z-50 mb-6 leading-[19px]">
            Select from your inventory the mods you want to apply to your
            loadout. Once you have finished click Apply this Loadout to save.
          </div>
          <div
            id="activeMod"
            className="mb-8 flex justify-center md:static md:max-w-[300px]"
          >
            <ModCompactDetails
              className="cursor-pointer"
              highlight
              mod={
                {
                  id: activeMod.id,
                  name: activeMod.name,
                  type: activeMod.type,
                  rarity: activeMod.rarity,
                  blueprint: activeMod.blueprint,
                } as Mod
              }
            />
          </div>
          <div
            role="tab"
            aria-controls="activeMod"
            className="mx-auto mb-8 flex max-w-[320px] justify-between gap-4 md:mb-0"
          >
            {loadout.map((mod, i) => (
              <div
                className="cursor-pointer"
                key={i}
                onClick={() => setIndex(i)}
              >
                <Tab n={i + 1} active={index === i} type={mod.type} />
              </div>
            ))}
          </div>
          <div className="mt-8 block w-full md:hidden">
            <Button
              blueMint
              onClick={handleApplyLoadout}
              isLoading={isSubmitting}
            >
              Apply this Loadout
            </Button>
          </div>
        </header>
        <div className="max-width-loadout-section  flex h-full min-h-[500px] w-full flex-col gap-6 overflow-hidden">
          <Card
            containerClassName="mobile:bg-none"
            className="min-h-[500px] !p-0 mobile:bg-none"
          >
            <header className="flex items-center justify-between p-4 pt-4 md:p-8">
              <div>
                <h2 className="text-3xl md:text-2xl">{activeMod.type} mods </h2>
                <p className="leading-[19px]">
                  Click on the {activeMod.type} mod you wish to apply to your
                  loadout.
                </p>
                <MobileOnly className="mt-4">
                  <ArrowRight />
                </MobileOnly>
              </div>
              <div className="hidden min-w-[200px] md:block wxga+:min-w-[250px]">
                <Link to={`/battleflies/view/${battlefly.id}/scavenge`}>
                  <Button light transparent>
                    Scavenge Mods
                  </Button>
                </Link>
              </div>
            </header>
            <div className="flex overflow-x-scroll pb-4 pt-4 md:pb-8">
              <ul className="mx-4 flex gap-8 md:mx-8">
                {filteredInventory.map((targetMod) => (
                  <li key={targetMod.id} className="relative">
                    <ModCompactDetails
                      onClick={() => setActiveModId(targetMod.id)}
                      className="cursor-pointer"
                      mod={
                        {
                          ...targetMod,
                          blueprint: targetMod.blueprint || targetMod,
                        } as Mod
                      }
                    />

                    {activeModId === targetMod.id && (
                      <ActionPanel
                        className="absolute bottom-0 left-0 z-50 w-full"
                        handleClose={() => setActiveModId(null)}
                        handleEquip={() => handleEquip(targetMod)}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="my-8 block w-full px-4 md:hidden">
              <Link to={`/battleflies/view/${battlefly.id}/scavenge`}>
                <Button light transparent>
                  Scavenge Mods
                </Button>
              </Link>
            </div>
          </Card>

          <div className="hidden w-[250px] md:block">
            <Button
              blueMint
              onClick={handleApplyLoadout}
              isLoading={isSubmitting}
            >
              Apply this Loadout
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoadoutPage;
