import { createSlice } from '@reduxjs/toolkit';
import { GlobalState } from '../../store/store';
import { clearUserCookies } from '../../util/cookieManager';
import { ErrorResponseBody } from '../commonActions/types';
import { logoutUser, setUserAuthorized, setUser } from './userActions';
import { ConnectUserResponse } from './userTypes';

export const initialUserState: ConnectUserResponse = {
  token: '',
  user: {
    id: '',
    address: '',
    role: '',
    wallets: [
      {
        id: '',
        address: '',
        provider: '',
      },
    ],
    tokens: {
      magic: 0,
    },
    createdAt: '',
    updatedAt: '',
  },
  error: { type: '', message: '', status: 0, timestamp: '' },
  pending: false,
  isUserAuthorized: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setUser, (state, { payload }) => {
        state.user = payload;
      })
      .addCase(setUserAuthorized, (state, { payload }) => {
        state.isUserAuthorized = payload;
      })
      .addCase(logoutUser, (state) => {
        return Object.assign(state, initialUserState);
      });
  },
});

export const selectIsUserAuthorized = () => (state: GlobalState) =>
  state.user.isUserAuthorized;

export default userSlice.reducer;
