import {
  connectorsForWallets,
  getDefaultWallets,
} from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { configureChains, createClient } from 'wagmi';
import { arbitrum, arbitrumGoerli } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
// END WAGMI
// import {
//   useDefaultNames,
//   useGetDomainData,
// } from '@battlefly/hooks/contractsNew/useSmolDomainsContract';

// import { AvatarComponent } from '@rainbow-me/rainbowkit';
// import { useEffect, useState } from 'react';
// import { useAccount } from 'wagmi';
// import BattleflyLogo from '../public/assets/icons/battlefly-logo.png';
import bitkeepWallet from './bitkeepCustomWallet';

const isDev = process.env.NEXT_PUBLIC_ENV === 'development';

export const { chains, provider } = configureChains(
  [isDev ? arbitrumGoerli : arbitrum],
  [
    alchemyProvider({ apiKey: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY }),
    publicProvider(),
  ]
);

const { wallets } = getDefaultWallets({
  appName: 'Battlefly Game',
  chains,
});

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: 'Other',
    wallets: [bitkeepWallet({ chains })],
  },
]);

export const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

// const CustomAvatar: AvatarComponent = ({ address, ensImage, size }) => {
//   const { address: userAddress } = useAccount();
//   const { data: smolName } = useDefaultNames(userAddress);
//   const { userAvatarImg: avatar } = useGetDomainData(
//     smolName && smolName?.toString()
//   );
//   const [userAvatarImg, setUserAvatarImg] = useState();

//   useEffect(() => {
//     if (avatar) {
//       setUserAvatarImg(avatar);
//     } else {
//       setUserAvatarImg(null);
//     }
//   }, [avatar]);
//   return userAvatarImg ? (
//     <img
//       src={userAvatarImg}
//       width={size}
//       height={size}
//       style={{ borderRadius: 999 }}
//     />
//   ) : (
//     <img
//       src={BattleflyLogo.src}
//       width={size}
//       height={size}
//       style={{ borderRadius: 999 }}
//     />
//   );
// };

export default wagmiClient;
