import React from 'react';
import { IconProps } from '@battlefly/components/Icons';
import { ModType, Rarity } from '@battlefly/__generated__/globalTypes';
import ModWeaponBackground from '@battlefly/components/Icons/Mods/ModWeaponBackground';
import ModWeaponBackgroundDefs from '@battlefly/components/Icons/Mods/ModWeaponBackgroundDefs';
import ModBackgroundImageBgDefs from '@battlefly/components/Icons/Mods/ModBackgroundImageBgDefs';
import ModDefenseBackground from '@battlefly/components/Icons/Mods/ModDefenseBackground';
import ModDefenseBackgroundDefs from '@battlefly/components/Icons/Mods/ModDefenseBackgroundDefs';
import ModUtilityBackground from '@battlefly/components/Icons/Mods/ModUtilityBackground';
import ModUtilityBackgroundDefs from '@battlefly/components/Icons/Mods/ModUtilityBackgroundDefs';

type ModBackgroundProps = IconProps & {
  rarity: Rarity;
  type: ModType;
  statsCount: number;
};

interface ModColors {
  backgroundColor: string;
  borderColor: string;
  textPlatesColor: string;
  feColorMatrix: string;
  compactGradientColor: string;
}

export const MODS_DEFS: Record<Rarity, ModColors> = {
  [Rarity.Core]: {
    backgroundColor: '#3F3F3F',
    borderColor: '#B2B2B9',
    textPlatesColor: '#555555',
    feColorMatrix: '0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0',
    compactGradientColor: '#3F3F3F',
  },
  [Rarity.Common]: {
    backgroundColor: '#FFFFFF',
    borderColor: '#B2B2B9',
    textPlatesColor: '#999999',
    feColorMatrix: '0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0',
    compactGradientColor: '#EEEEEE',
  },
  [Rarity.Uncommon]: {
    backgroundColor: '#1A5B19',
    borderColor: '#59FF3C',
    textPlatesColor: '#30712E',
    feColorMatrix: '0 0 0 0 0.34902 0 0 0 0 1 0 0 0 0 0.235294 0 0 0 1 0',
    compactGradientColor: '#34EEA6',
  },
  [Rarity.Rare]: {
    backgroundColor: '#0A0C2E',
    borderColor: '#16DEFE',
    textPlatesColor: '#192A5A',
    feColorMatrix:
      '0 0 0 0 0.0862745 0 0 0 0 0.870588 0 0 0 0 0.996078 0 0 0 1 0',
    compactGradientColor: '#00F0FF',
  },
  [Rarity.Epic]: {
    backgroundColor: '#511F83',
    borderColor: '#B216FF',
    textPlatesColor: '#632F95',
    feColorMatrix: '0 0 0 0 0.698039 0 0 0 0 0.0862745 0 0 0 0 1 0 0 0 1 0',
    compactGradientColor: '#8136BD',
  },
  [Rarity.Legendary]: {
    backgroundColor: '#745A19',
    borderColor: '#FED12D',
    textPlatesColor: '#957729',
    feColorMatrix:
      '0 0 0 0 0.996078 0 0 0 0 0.819608 0 0 0 0 0.176471 0 0 0 1 0',
    compactGradientColor: '#FFA800',
  },
  [Rarity.Artefact]: {
    backgroundColor: '#3F3F3F',
    borderColor: '#B2B2B9',
    textPlatesColor: '#555555',
    feColorMatrix: '0 0 0 0 0.34902 0 0 0 0 1 0 0 0 0 0.235294 0 0 0 1 0',
    compactGradientColor: '#3F3F3F',
  },
};

const ModBackground: React.FC<ModBackgroundProps> = ({
  statsCount,
  ...props
}) => {
  const currentModDefs = MODS_DEFS[props.rarity];
  const addToId = props.rarity.toString();
  return (
    <svg
      width="500"
      height="835"
      viewBox="0 0 500 835"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_7868)">
        <path d="M500 0H0V835H500V0Z" fill={currentModDefs.backgroundColor} />
        {props.type === ModType.Weapon && (
          <ModWeaponBackground uniqueId={addToId} />
        )}
        {props.type === ModType.Utility && (
          <ModUtilityBackground uniqueId={addToId} />
        )}
        <g filter={`url(#filter0_i_3_7868${addToId})`}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M500 3.40949e-06H497L461 0L196 3.40949e-06H188H183H175H171H163H159H151H146H138H133.5H125.5H39H3H0V3V54V380V388V392V400V404V412V417V425V430V438V441.5V449.5V454V462V468V476V480V488V493V501V505V513V517V525V530V538V832V835H3H497H500V832V745.5V737.5V732.5V724.5V720.5V712.5V708.5V700.5V695.5V687.5V683V675V54V3V3.40949e-06ZM497 672.3V52.4725L487.351 47.5596V12.3853H468.378L462.787 3H193.15L186.5 10H179L185.3 3H180.15L173.5 10H166L172.3 3H168.15L161.5 10H154L160.3 3H156.15L149.5 10H142L148.3 3H143.15L136.5 10H129L135.3 3H130.65L124 10H116.5L122.8 3H37.2128L31.6216 12.3853H12.6486V47.5596L3 52.4725V382.85L10 389.5V397L3 390.7V394.85L10 401.5V409L3 402.7V406.85L10 413.5V421L3 414.7V419.85L10 426.5V434L3 427.7V432.85L10 439.5V447L3 440.7V444.35L10 451V458.5L3 452.2V456.85L10 463.5V471L3 464.7V470.85L10 477.5V485L3 478.7V482.85L10 489.5V497L3 490.7V495.85L10 502.5V510L3 503.7V507.85L10 514.5V522L3 515.7V519.85L10 526.5V534L3 527.7V532.85L10 539.5V547L3 540.7V755.861H53.1599H53.7906L54.2318 756.311L76.298 778.852L76.7261 779.289V779.901V832H497V742.65L490 736V728.5L497 734.8V729.65L490 723V715.5L497 721.8V717.65L490 711V703.5L497 709.8V705.65L490 699V691.5L497 697.8V692.65L490 686V678.5L497 684.8V680.15L490 673.5V666L497 672.3ZM3 832V758.861H52.5292L73.7261 780.513V832H3Z"
            fill={currentModDefs.borderColor}
          />
        </g>
        <path
          d={
            statsCount === 0
              ? 'M463.5 714C463.238 714 462.987 714.103 462.8 714.286L436.591 740H34V440.921L54.4072 420H465.5V714H463.5Z'
              : statsCount === 2
              ? 'M463.5 714C463.238 714 462.987 714.103 462.8 714.286L436.591 740H34V529.921L54.4072 510H465.5V714H463.5Z'
              : 'M463.5 714C463.238 714 462.987 714.103 462.8 714.286L436.591 740H34V611.921L54.4072 592H465.5V714H463.5Z'
          }
          fill="black"
          fillOpacity="0.4"
          stroke={currentModDefs.borderColor}
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <g filter={`url(#filter1_i_3_7868${addToId})`}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41 351.538L62 347H437.5L456 351.538H41ZM456 402.462L435 407H59.5L41 402.462H456Z"
            fill={currentModDefs.borderColor}
          />
        </g>
        {props.type === ModType.Defense && (
          <ModDefenseBackground uniqueId={addToId} />
        )}
      </g>
      <defs>
        <filter
          id={`filter0_i_3_7868${addToId}`}
          x="0"
          y="0"
          width="502"
          height="837"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values={currentModDefs.feColorMatrix} />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_3_7868"
          />
        </filter>
        <filter
          id={`filter1_i_3_7868${addToId}`}
          x="41"
          y="347"
          width="417"
          height="62"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values={currentModDefs.feColorMatrix} />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_3_7868"
          />
        </filter>
        <ModBackgroundImageBgDefs />
        {props.type === ModType.Weapon && (
          <ModWeaponBackgroundDefs
            backgroundColor={currentModDefs.backgroundColor}
            borderColor={currentModDefs.borderColor}
            uniqueId={addToId}
          />
        )}
        {props.type === ModType.Defense && (
          <ModDefenseBackgroundDefs
            backgroundColor={currentModDefs.backgroundColor}
            borderColor={currentModDefs.borderColor}
            uniqueId={addToId}
          />
        )}
        {props.type === ModType.Utility && (
          <ModUtilityBackgroundDefs
            backgroundColor={currentModDefs.backgroundColor}
            borderColor={currentModDefs.borderColor}
            uniqueId={addToId}
          />
        )}
        <clipPath id="clip0_3_7868">
          <rect width="500" height="835" fill={currentModDefs.borderColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ModBackground;
