import { BattleflyWithMetadata } from 'ts/BattleflyType';

export interface BattleFlySortingProps {
  property: keyof BattleflyWithMetadata;
  sortOrder: 1 | -1;
}

export const dynamicSortBattleFly = ({
  property,
  sortOrder,
}: BattleFlySortingProps) => {
  return (a: BattleflyWithMetadata, b: BattleflyWithMetadata) => {
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};
