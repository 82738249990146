import { createSlice } from '@reduxjs/toolkit';
import { GlobalState } from '../../store/store';
import { BattleFly } from '@battlefly/__generated__/globalTypes';
import {
  addBattleFlyToStakingCart,
  emptyStakingCart,
  removeBattleFlyFromStakingCart,
  setAllHoldingTokenIds,
  setHoldingBattlefliesList,
} from './holdingActions';
import { InitialHoldingBattlefliesState } from './holdingTypes';

export const initialState: InitialHoldingBattlefliesState = {
  allHoldingTokenIds: [],
  holdingBattlefliesList: [],
  holdingPaginationGrid: [],
  pagination: {
    items: [],
    meta: {
      perPage: undefined,
      page: undefined,
      total: undefined,
      totalPages: undefined,
    },
  },
  selectedBattleflies: [],
  error: { type: '', message: '', status: 0, timestamp: '' },
  pending: false,
  hasFetched: false,
};

const holding = createSlice({
  name: 'holding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setAllHoldingTokenIds, (state, { payload }) => {
        state.allHoldingTokenIds = payload;
      })
      .addCase(setHoldingBattlefliesList, (state, { payload }) => {
        state.holdingBattlefliesList = payload;
      })
      .addCase(addBattleFlyToStakingCart, (state, { payload }) => {
        const newArray = [...state.selectedBattleflies, payload];
        state.selectedBattleflies = newArray;
      })
      .addCase(removeBattleFlyFromStakingCart, (state, { payload }) => {
        const newArray = state.selectedBattleflies.filter(
          (item: BattleFly) => item.id !== payload.id
        );
        state.selectedBattleflies = newArray;
      })
      .addCase(emptyStakingCart, (state) => {
        state.selectedBattleflies = new Array(0);
      });
  },
});

export const selectHoldingBattlefliesList = () => (state: GlobalState) =>
  state.holding.holdingBattlefliesList;

export const selectHoldingBattlefliesTokenIds = () => (state: GlobalState) =>
  state.holding.allHoldingTokenIds;

export default holding.reducer;
