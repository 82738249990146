import { HuntersMarkType } from '@battlefly/__generated__/globalTypes';

export const WASTELANDS = [
  {
    id: 1,
    name: 'Sunken Energy Core',
    inGameName: 'Megacity depths',
    image: 'BF-Lands-SunkenEnergyCore.png',
    buttonColor: 'wasteland-blue',
    findChanceLabel: 'Weapon: Energy mod',
    modCategory: HuntersMarkType.Energy,
  },
  {
    id: 2,
    name: 'Sands Alaska',
    inGameName: 'Desert Subs',
    image: 'BF-Lands-SandsAlaska_Afternoon.png',
    buttonColor: 'wasteland-pink',
    findChanceLabel: 'Weapon: Fusion mod',
    modCategory: HuntersMarkType.Fusion,
  },
  {
    id: 3,
    name: 'Hydro Plant DT',
    inGameName: 'Hydro Plant',
    image: 'BF-Lands-HydroPlantDT.png',
    buttonColor: 'wasteland-blue',
    findChanceLabel: 'Weapon: Electric mod',
    modCategory: HuntersMarkType.Electric,
  },
  {
    id: 4,
    name: 'Battlefly Factory Tau',
    inGameName: 'BFT Factory',
    image: 'BF-Lands-BattleflyFactoryTau.png',
    buttonColor: 'wasteland-purple',
    findChanceLabel: 'Weapon: Missiles mod',
    modCategory: HuntersMarkType.Missile,
  },
  {
    id: 5,
    name: 'Freight Graveyard',
    inGameName: 'Freight graveyard',
    image: 'BF-Lands-FreightGraveyards.png',
    buttonColor: 'wasteland-pink',
    findChanceLabel: 'Utility: Ammunitions mod',
    modCategory: HuntersMarkType.Ammunition,
  },
  {
    id: 6,
    name: 'Burning Beacons',
    inGameName: 'Hidden Base',
    image: 'BF-Lands-BurningBeacon.png',
    buttonColor: 'wasteland-blue',
    colorCodes: 'border-wasteland-jade drop-shadow-wl6',
    findChanceLabel: 'Weapon: Kinetic mod',
    modCategory: HuntersMarkType.Kinetic,
  },
  {
    id: 7,
    name: 'Remote Drone Base',
    inGameName: 'Arctic research HQ',
    image: 'BF-Lands-RemoteDroneBase2.png',
    buttonColor: 'wasteland-blue',
    findChanceLabel: 'Utility: Black Project mod',
    modCategory: 'BlackProject',
  },
  {
    id: 8,
    name: 'Qi Yi Vertical Forests',
    inGameName: 'Megacity heights',
    image: 'BF-Lands-QiyiVerticalForests_Night.png',
    buttonColor: 'wasteland-purple',
    findChanceLabel: 'Utility: Cybernetics mod',
    modCategory: HuntersMarkType.Cybernetics,
  },
  {
    id: 9,
    name: 'Holographic Pagodas',
    inGameName: 'Techno Shrines',
    image: 'BF-Lands-HolographicPagodas_Noon.png',
    buttonColor: 'wasteland-blue',
    findChanceLabel: 'Defense: Shield mod',
    modCategory: HuntersMarkType.Shield,
  },
  {
    id: 10,
    name: 'Last Citadel',
    inGameName: 'Jungle Fortress',
    image: 'BF-Lands-LastCitadel_Noon.png',
    buttonColor: 'wasteland-blue',
    findChanceLabel: 'Defense: Armor or Evasion mod',
    modCategory: 'Armor',
  },
];
