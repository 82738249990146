import React from 'react';

import { FullScreenCard } from 'components/Layout';
import { GlobalBattleLog } from 'components/pages';
import { useBattleLogData } from 'hooks/game';

const LeaderBoardRoute = () => {
  const [filter, setFilter] = React.useState<'user' | 'global'>('global');
  const { logs, loading, ...rest } = useBattleLogData(filter);
  const logHistory = {
    items: logs || [],
    ...rest,
  };

  return (
    <FullScreenCard>
      {/* {loading && <div>Loading...</div>} */}
      <GlobalBattleLog
        loading={loading}
        filter={filter}
        setFilter={setFilter}
        logHistory={logHistory}
      />
    </FullScreenCard>
  );
};

export default LeaderBoardRoute;
