import {
  createContext,
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useUnityContext } from 'react-unity-webgl';
import { useAccount } from 'wagmi';
import { Navlink } from '../useNavigation';

export const useUnity = ({
  gameURL,
  compression,
  onUnloaded,
}: {
  gameURL: string;
  compression?: string;
  onUnloaded?: (show: string) => void;
}) => {
  const { unloadUnityGameRef } = useUnloadUnityGame();
  const {
    unityProvider,
    sendMessage,
    isLoaded,
    loadingProgression,
    addEventListener,
    removeEventListener,
    unload,
    requestFullscreen,
  } = useUnityContext({
    loaderUrl: `${gameURL}/Build/WebGL.loader.js`,
    dataUrl: `${gameURL}/Build/WebGL.data${
      compression ? '.' + compression : ''
    }`,
    frameworkUrl: `${gameURL}/Build/WebGL.framework.js${
      compression ? '.' + compression : ''
    }`,
    codeUrl: `${gameURL}/Build/WebGL.wasm${
      compression ? '.' + compression : ''
    }`,
  });

  const sendToUnity = (type: string, data: any): void => {
    console.log(`%c ${type}`, 'background: #222; color: #bada55; padding: 5px');
    console.log(data);
    sendMessage('App', type, JSON.stringify(data));
  };

  const [delayedUnload, setDelayedUnload] = useState<boolean>(false);

  useEffect(() => {
    if (delayedUnload) {
      unloadUnityGameRef.current = undefined;
    }
    if (isLoaded && delayedUnload) {
      unload().then(() => {
        onUnloaded(null);
      });
    }
  }, [delayedUnload, isLoaded]);

  useEffect(() => {
    unloadUnityGameRef.current = unload;
  }, [unload, unloadUnityGameRef]);

  useAccount({
    onDisconnect() {
      unload();
    },
  });

  return {
    unityProvider,
    sendToUnity,
    isLoaded,
    loadingProgression,
    onUnityEvent: addEventListener,
    offUnityEvent: removeEventListener,
    unload,
    setDelayedUnload,
    delayedUnload,
    requestFullscreen,
  };
};

interface UnloadUnityGameContextType {
  unloadUnityGameRef: MutableRefObject<any>;
  delayLink: (
    link: Navlink | string,
    router: ReturnType<typeof useNavigate>
  ) => void;
}

export const UnloadUnityGameContext = createContext(
  {} as UnloadUnityGameContextType
);

/**
 * As it stands there is an ongoing bug with the latest version of UnityWebgl
 * https://react-unity-webgl.dev/docs/api/unload/
 * https://github.com/jeffreylanters/react-unity-webgl/issues/250
 * In order for the game to fully unmount and free up memory,
 * we must currently for unload() to return a promise BEFORE navigating away from the game.
 * is no official way of incercepting next/link page navigation
 * with asynchronous requests, so the reference to this unload function is being lifted up in this context
 * object, and then the link navigation wraps around this promise.
 * That's our workaround until this issue resolved.
 *
 * NOTE: Unless first consulted, the ONLY thing that should go in this context provider is that one function
 * value; you have to be careful what is in here, because any other children will unecessarily re-render
 * on context value object changes.
 *
 */

export const UnloadUnityGameContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  // STATE
  const unloadUnityGameRef = useRef<() => Promise<void>>();

  const delayLink = async (
    link: Navlink | string,
    navigate: ReturnType<typeof useNavigate>
  ) => {
    if (typeof link == 'string') {
      if (unloadUnityGameRef.current !== undefined) {
        await unloadUnityGameRef.current().then(() => {
          navigate(link);
          unloadUnityGameRef.current = undefined;
        });
      } else {
        navigate(link);
      }
      return;
    }
    if (!link.isUnityGame && unloadUnityGameRef.current !== undefined) {
      await unloadUnityGameRef.current().then(() => {
        navigate(link.pathname);
        unloadUnityGameRef.current = undefined;
      });
    } else {
      navigate(link.pathname);
    }
    return;
  };

  const contextData = useMemo(
    () => ({
      unloadUnityGameRef,
      delayLink,
    }),
    []
  );

  return (
    <UnloadUnityGameContext.Provider value={contextData}>
      {children}
    </UnloadUnityGameContext.Provider>
  );
};

export default UnloadUnityGameContextProvider;

// Let's only export the `useUnloadUnityGame` hook instead of the context.
export function useUnloadUnityGame() {
  return useContext(UnloadUnityGameContext);
}
