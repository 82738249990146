import {
  BattleFly,
  BattleFlyLeague,
} from '@battlefly/__generated__/globalTypes';
import {
  ApexIcon,
  LarvaeIcon,
  MonarchIcon,
  PredatorIcon,
  PupaIcon,
} from 'components/Icons/Leagues';
import { SingleLeagueBlockParams } from '@battlefly/ts/leagues';

export const LEAGUES_BLOCKS: SingleLeagueBlockParams[] = [
  {
    color: '#89EB36',
    borderClass: 'gra-league-larvae-border',
    league: BattleFlyLeague.Larvae,
    progressElements: [
      { subLevel: 0, league: BattleFlyLeague.Larvae, cpRequired: 0 },
      { subLevel: 3, league: BattleFlyLeague.Pupa, cpRequired: 1 },
      { subLevel: 2, league: BattleFlyLeague.Pupa, cpRequired: 251 },
      { subLevel: 1, league: BattleFlyLeague.Pupa, cpRequired: 501 },
    ],
    numBfsSharing: 1344,
    numMagicSharing: 15430,
    icon: LarvaeIcon,
  },
  {
    color: '#0098F8',
    league: BattleFlyLeague.Pupa,
    borderClass: 'gra-league-pupa-border',
    progressElements: [
      { subLevel: 3, league: BattleFlyLeague.Pupa, cpRequired: 1 },
      { subLevel: 2, league: BattleFlyLeague.Pupa, cpRequired: 251 },
      { subLevel: 1, league: BattleFlyLeague.Pupa, cpRequired: 501 },
      { subLevel: 3, league: BattleFlyLeague.Monarch, cpRequired: 751 },
    ],
    numBfsSharing: 650,
    numMagicSharing: 22120,
    icon: PupaIcon,
  },
  {
    color: '#FF3CAA',
    league: BattleFlyLeague.Monarch,
    borderClass: 'gra-league-monarch-border',
    progressElements: [
      { subLevel: 3, league: BattleFlyLeague.Monarch, cpRequired: 751 },
      { subLevel: 2, league: BattleFlyLeague.Monarch, cpRequired: 1001 },
      { subLevel: 1, league: BattleFlyLeague.Monarch, cpRequired: 1251 },
      { subLevel: 3, league: BattleFlyLeague.Predator, cpRequired: 1501 },
    ],
    numBfsSharing: 431,
    numMagicSharing: 11233,
    icon: MonarchIcon,
  },
  {
    color: '#FFA504',
    league: BattleFlyLeague.Predator,
    borderClass: 'gra-league-predator-border',
    progressElements: [
      { subLevel: 3, league: BattleFlyLeague.Predator, cpRequired: 1501 },
      { subLevel: 2, league: BattleFlyLeague.Predator, cpRequired: 1751 },
      { subLevel: 1, league: BattleFlyLeague.Predator, cpRequired: 2001 },
      { subLevel: 0, league: BattleFlyLeague.Apex, cpRequired: 2251 },
    ],
    numBfsSharing: 211,
    numMagicSharing: 51231,
    icon: PredatorIcon,
  },
  {
    color: '#FF0000',
    league: BattleFlyLeague.Apex,
    borderClass: 'gra-league-apex-border',
    progressElements: [
      { subLevel: 1, league: BattleFlyLeague.Predator, cpRequired: 2001 },
      { subLevel: 3, league: BattleFlyLeague.Apex, cpRequired: 2251 },
      { subLevel: 2, league: BattleFlyLeague.Apex, cpRequired: 2501 },
      { subLevel: 1, league: BattleFlyLeague.Apex, cpRequired: 3000 },
    ],
    numBfsSharing: 63,
    numMagicSharing: 99812,
    icon: ApexIcon,
  },
];
