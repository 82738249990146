import { gql } from '@apollo/client';
import client from '@battlefly/util/apolloClient';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import {
  BattleflyIdsRequest,
  GetBattleflyAttributesResponse,
  WithdrawBattlefliesResponse,
} from './types';

import attributes from './attributes.json';
/**
 * Get All Battlefly Attributes; Used for advanced filtering.
 */
export const getBattleflyAttributes = createAsyncThunk<
  GetBattleflyAttributesResponse[],
  null
>('common/battleflies/getBattleflyAttributes', async () => {
  return attributes;
});

/**
 * Withdraw Battlefly from Reveal process
 */
export const withdrawBattlefly = createAsyncThunk<
  WithdrawBattlefliesResponse,
  BattleflyIdsRequest
>(
  'staking/battleflies/withdraw',
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const result = await client.mutate({
        mutation: gql`
          mutation withdrawBattleFlyOld($input: WithdrawBattleFliesInput!) {
            withdrawRevealedBattleFlies(input: $input) {
              battleflyIds
              stagesIds
              signature {
                v
                r
                s
              }
            }
          }
        `,
        variables: {
          input: {
            battleFlyIds: data.ids.map((id: BigNumber) => Number(id)),
          },
        },
        context: { skipQueue: true },
      });
      return result.data.withdrawRevealedBattleFlies;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
