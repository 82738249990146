import React from 'react';

import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { PersonalizedBattleLogItem } from '@battlefly/ts/BattleLog';
import BattleflyImage from 'components/BattleflyImage';
import WinLossIndicator from 'components/WinLossIndicator';
import Loadout from '@battlefly/components/Loadout';
import { RarityRankingPower } from 'components/battlefly';
import BattleViewer, { BattlePreview } from 'components/BattleViewer';

type PageProps = {
  log: PersonalizedBattleLogItem;
};

const BattleflyCard = ({ battlefly }: { battlefly: Partial<BattleFly> }) => {
  return (
    <div className="gra-bf-card-border box-shadow-card rounded-2xl p-[2px]">
      <div className="gra-bf-card relative h-[80px] w-[100px] rounded-2xl xl:h-[180px] xl:w-[200px]">
        <BattleflyImage
          url={battlefly.appearance.image}
          containerClasses="!p-2 !pt-4"
          imageClassName="!w-[100px] !h-[40px] xl:!w-[200px] xl:!h-[100px]"
        />
      </div>
    </div>
  );
};

const BattlefyLoadout = ({
  battlefly,
  ...otherProps
}: {
  battlefly: Partial<BattleFly>;
  containerClasses?: string;
}) => {
  return (
    <Loadout
      mods={battlefly.mods}
      elementClasses="w-[160px] !h-[160px] xl:w-[120px] xl:!h-[120px] wxga+:w-[160px] wxga+:!h-[160px]"
      modClasses="w-[160px] !h-[160px] xl:w-[120px] xl:!h-[120px] wxga+:w-[160px] wxga+:!h-[160px]"
      {...otherProps}
    />
  );
};

const BattleLogDetailsPage = (props: PageProps) => {
  const { log } = props;
  const [combatMode, setCombatMode] = React.useState(false);

  const { ownBattleFly, opponent } = log;

  return (
    <div className="flex w-full max-w-[100%] max-w-screen-xl flex-col gap-6 gap-6 px-2 sm:mt-4 xl:mx-6 wxga+:max-w-screen-2xl">
      <section className="flex w-full flex-col justify-between xl:flex-row">
        <div className="flex flex-col gap-4">
          <div className="flex gap-4 xl:min-w-[500px]">
            <div className="w-[100px] xl:w-[200px]">
              <BattleflyCard battlefly={ownBattleFly} />
            </div>
            <div className="flex flex-col justify-between">
              <div className="flex flex-row gap-2 xl:flex-col xl:flex-col-reverse">
                <div className="h-10 xl:mb-6 xl:h-12">
                  <WinLossIndicator win={log.isWinner} />
                </div>
                <div className="mb-2 font-heading text-3xl">
                  {ownBattleFly.name}
                </div>
              </div>
              <RarityRankingPower
                battlefly={ownBattleFly}
                className="!xl:gap-10 !gap-4"
              />
            </div>
          </div>
          <div>
            <BattlefyLoadout battlefly={ownBattleFly} />
          </div>
        </div>
        <div className="w-full self-start text-center font-heading text-[50px] opacity-25 xl:w-auto xl:text-[180px] xl:leading-[180px]">
          X
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex gap-4 xl:min-w-[500px] xl:flex-row-reverse">
            <div className="w-[100px] xl:w-[200px]">
              <BattleflyCard battlefly={opponent} />
            </div>
            <div className="flex flex-col justify-between">
              <div className="flex flex-row gap-2 xl:flex-col xl:flex-col-reverse xl:items-end">
                <div className="h-10 xl:mb-6 xl:h-12">
                  <WinLossIndicator win={!log.isWinner} />
                </div>
                <div className="mb-2 font-heading text-3xl">
                  {opponent.name}
                </div>
              </div>
              <RarityRankingPower
                battlefly={opponent}
                className="!xl:gap-10 !gap-4"
              />
            </div>
          </div>
          <div>
            <BattlefyLoadout battlefly={opponent} />
          </div>
        </div>
      </section>
      <section className="mb-16">
        {combatMode ? (
          <BattleViewer
            className="mx-auto max-w-[1200px]"
            onStop={() => setCombatMode(false)}
            combatLogId={log.id}
          />
        ) : (
          <BattlePreview
            className="h-[400px] md:h-[500px] wxga+:h-[600px]"
            onStart={() => setCombatMode(true)}
          />
        )}
      </section>
    </div>
  );
};

export default BattleLogDetailsPage;
