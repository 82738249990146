import useSingleComic from '@battlefly/hooks/contractsNew/useComic';

type ComicCardProps = {
  id: number;
  isSelected?: boolean;
  handleSelectNft: any;
  comicIndex: number;
  selectedNfts: any;
  showExchangeModal: boolean;
  burnComicIdNeeded: number;
};

const SimpleComicCard = ({
  id,
  handleSelectNft,
  selectedNfts,
  comicIndex,
  showExchangeModal,
  burnComicIdNeeded,
}: ComicCardProps) => {
  const { comics, loading } = useSingleComic(id);
  const isNftSelected = selectedNfts.find(
    (selectedToken: { comic: number; index: number }) =>
      selectedToken.index === comicIndex
  );

  const isNftSelectable = burnComicIdNeeded
    ? burnComicIdNeeded === comics?.id?.toNumber() ||
      (selectedNfts.length > 0 && selectedNfts[0]?.index === comicIndex)
    : true;
  return (
    <div
      key={id}
      className={`nft-hover relative cursor-pointer rounded-lg border border-transparent p-4 ${
        isNftSelected ? 'border-7B61FF gra-7B61FF border' : ''
      }`}
      {...(showExchangeModal && {
        onClick: () => {
          if (isNftSelectable) {
            handleSelectNft({ comic: id, index: comicIndex });
          }
        },
      })}
    >
      {isNftSelected && (
        <label className="label bg-accent absolute -top-3 -right-3 cursor-pointer rounded-full p-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.5rem"
            fill="currentColor"
            className=""
            viewBox="0 0 16 16"
          >
            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
          </svg>
        </label>
      )}
      <img
        src={
          comics?.details?.image ||
          '/assets/test-placeholder/BattleFly-Issue1-Cover-GOLD1.png'
        }
        className={`m-auto w-[120px] ${loading && `animate-pulse`} ${
          !isNftSelectable && `opacity-25`
        }`}
      ></img>
      <div className="my-3 text-sm">{comics?.name}</div>
      <div className="flex justify-between">
        <div>ID: </div> <div>{id}</div>
      </div>
    </div>
  );
};

export default SimpleComicCard;
