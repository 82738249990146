import React from 'react';

import { FullScreenCard } from 'components/Layout';
import LeaderboardPage from 'components/LeaderboardPage';
import { useLeaderboardData } from '@battlefly/hooks/arenas';

const LeaderBoardRoute = () => {
  const { data = [], loading } = useLeaderboardData();

  return (
    <FullScreenCard>
      {loading && <div>Loading...</div>}
      {<LeaderboardPage items={data} />}
    </FullScreenCard>
  );
};

export default LeaderBoardRoute;
