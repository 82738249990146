import React from 'react';
import { LeagueWithDigitProps } from '@battlefly/components/Icons/Leagues/PupaWithDigitIcon';
import ApexIcon from '@battlefly/components/Icons/Leagues/ApexIcon';
import LeagueWithDigitIcon from '@battlefly/components/Icons/Leagues/LeagueWithDigitIcon';

const ApexWithDigitIcon: React.FC<LeagueWithDigitProps> = (props) => {
  return (
    <LeagueWithDigitIcon
      digit={props.digit}
      hideLeagueDigit={props.hideLeagueDigit}
      icon={ApexIcon}
      colorBorderStart="#F595CE"
      colorBorderEnd="#F5CBDF"
      colorRectStart="#DE4A8B"
      colorRectMiddle="#6650A1"
      colorRectEnd="#EE5592"
    />
  );
};

export default ApexWithDigitIcon;
