import classnames from 'classnames';

import { Card, Button } from 'components/core';

type RerollCardProps = {
  className?: string;
  isRerolling: boolean;
  isRecycling: boolean;
  handleReroll: () => void;
  handleRecycle: () => void;
  isRerollingDisabled: boolean;
  isRecyclingDisabled: boolean;
};

const RerollCard = (props: RerollCardProps) => {
  const {
    className,
    isRerolling,
    handleReroll,
    isRerollingDisabled,
    isRecycling,
    handleRecycle,
    isRecyclingDisabled,
  } = props;
  return (
    <Card className={classnames('pt-4 sm:pl-12', className)}>
      <div className="mx-auto w-[80%] text-center md:w-auto md:text-left">
        <div className="mb-2 font-heading text-2xl">Reroll for 3 new mods</div>
        {/* <div>Cost: 50 Nectar</div> */}
        <div className="mb-6 leading-[19px]">
          Once you have equipped or attempted to upgrade a mod, you can no
          longer reroll the pack
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Button
          disabled={isRerollingDisabled}
          isLoading={isRerolling}
          onClick={handleReroll}
          type="button"
          className="w-32"
          cyberPink
        >
          Reroll with Nectar
        </Button>
        <Button
          disabled={isRecyclingDisabled}
          isLoading={isRecycling}
          onClick={handleRecycle}
          type="button"
          className="w-32"
          gray
        >
          Recycle ModPack
        </Button>
      </div>
    </Card>
  );
};

export default RerollCard;
