type TimeRemainingOptions = {
  leadingZero?: boolean;
};

const pad = (n: number) => (n < 10 ? `0${n}` : n);

const getTimeRemaining = (
  endtime: string,
  options: TimeRemainingOptions = {}
) => {
  const total = Date.parse(endtime) - Date.parse(new Date().toString());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  if (options?.leadingZero) {
    return {
      total,
      days: pad(days),
      hours: pad(hours),
      minutes: pad(minutes),
      seconds: pad(seconds),
    };
  }

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
};
export type TimeRemaining = ReturnType<typeof getTimeRemaining>;
export default getTimeRemaining;
