const shortRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
const longRegex = /^(0x[a-zA-Z0-9]{8})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

export function shortenAddress(address: string, len: 'sm' | 'lg' = 'sm') {
  const match = address.match(len === 'sm' ? shortRegex : longRegex);

  return match ? `${match[1]}…${match[2]}` : address;
}

export const shortenMoney = (amount: number) => {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(amount);
};

type FormatMoneyOptions = {
  digits?: number;
};
export const formatMoney = (
  num = 0,
  options: FormatMoneyOptions = {}
): string => {
  const mappedOptions = {
    maximumFractionDigits: options.digits !== undefined ? options.digits : 2,
  };
  const mergedOptions = Object.assign(
    {
      style: 'currency',
      currency: 'USD',
    },
    mappedOptions
  );
  return new Intl.NumberFormat('en-US', mergedOptions)
    .format(num)
    .replace('$', '');
};

export const formatPercentage = (num: number, digits = 2): string => {
  return `${Number(num * 100)
    .toFixed(digits)
    .toString()}%`;
};
