import { createContext } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GardenContextType {}

export const GardenContext = createContext({} as GardenContextType);

export const GardenContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  const contextData = {};

  return (
    <GardenContext.Provider value={contextData}>
      {children}
    </GardenContext.Provider>
  );
};

export default GardenContextProvider;
