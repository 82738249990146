import { gql } from '@apollo/client';

import { CORE_MOD_BLUEPRINT_FIELDS } from 'graphql/fragments';

export const GET_LEADERBOARD_DATA = gql`
  query getLeaderboardData {
    getGlobalLeaderboard {
      avatar
      nickName
      earnedMagic
      earnedNectar
    }
  }
`;

export const GET_BATTLE_LOG = gql`
  query getCombat($combatId: ID!) {
    combat(input: { id: $combatId }) {
      id
      location
      log {
        participants {
          battleFlyId
          weapons {
            name
            blueprint {
              ...CoreModBlueprintFields
            }
          }
          defense {
            name
            blueprint {
              ...CoreModBlueprintFields
            }
          }
          utilities {
            name
            blueprint {
              ...CoreModBlueprintFields
            }
          }
        }
      }
      battleFlyWinner {
        id
        starsRate
        rarity
        name
        appearance {
          image
        }
        owner {
          user {
            wallets {
              address
            }
          }
        }
        placeInGlobalLeaderboard
        league
      }
      battleFlyLooser {
        id
        starsRate
        rarity
        name
        appearance {
          image
        }
        owner {
          user {
            wallets {
              address
            }
          }
        }
        placeInGlobalLeaderboard
        league
      }
    }
  }
  ${CORE_MOD_BLUEPRINT_FIELDS}
`;

export const GET_USER_BATTLE_LOG = gql`
  query getUserBattleLog {
    getUserCombatLog {
      id
      location
      log {
        participants {
          battleFlyId
          weapons {
            name
            blueprint {
              ...CoreModBlueprintFields
            }
          }
          defense {
            name
            blueprint {
              ...CoreModBlueprintFields
            }
          }
          utilities {
            name
            blueprint {
              ...CoreModBlueprintFields
            }
          }
        }
      }
      battleFlyWinner {
        name
        appearance {
          image
        }
        owner {
          user {
            wallets {
              address
            }
          }
        }
        placeInGlobalLeaderboard
        league
      }
      battleFlyLooser {
        name
        appearance {
          image
        }
        owner {
          user {
            wallets {
              address
            }
          }
        }
        placeInGlobalLeaderboard
        league
      }
    }
  }
  ${CORE_MOD_BLUEPRINT_FIELDS}
`;

export const GET_LEAGUE_DATA = gql`
  query GetLeagueData {
    getLeagues {
      name
      topBattleFliesAmount
      sharedMagic
    }
  }
`;
