import {
  getWalletConnectConnector,
  Wallet,
  Chain,
} from '@rainbow-me/rainbowkit';
import { Connector } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';

// https://github.com/bitkeepwallet/example/blob/master/evm-dapp-demo/wagmi-bitkeep-react/src/connectors/BitkeepConnector.js

class BitkeepConnector extends InjectedConnector {
  provider: any;
  declare id: string;
  declare ready: boolean;

  constructor({
    chains = [],
    options_ = {},
  }: {
    chains?: Chain[];
    options_?: any;
  }) {
    const options = {
      name: 'BitKeep',
      ...options_,
    };
    super({ chains, options });

    this.id = 'Bitkeep';
    this.ready =
      typeof window != 'undefined' &&
      !!this.findProvider(window?.bitkeep?.ethereum);
  }
  async getProvider() {
    if (typeof window !== 'undefined') {
      this.provider = window.bitkeep?.ethereum;
    }
    return this.provider;
  }
  getReady(ethereum: any) {
    if (!ethereum.isBitKeep || !ethereum) return;
    if (ethereum.isBraveWallet && !ethereum._events && !ethereum._state) return;
    if (ethereum.isTokenPocket) return;
    if (ethereum.isTokenary) return;
    return ethereum;
  }
  findProvider(ethereum: any) {
    if (ethereum?.providers) return ethereum.providers.find(this.getReady);
    return this.getReady(ethereum);
  }
}

declare global {
  interface Window {
    bitkeep: any;
  }
}

type WalletOptions = {
  chains: Chain[];
};
const BitkeepWallet = ({
  chains,
}: WalletOptions): Wallet<Connector<any, any>> => {
  const isBitkeepInjected =
    typeof window !== 'undefined' &&
    typeof window.bitkeep !== 'undefined' &&
    typeof window.bitkeep.ethereum !== 'undefined' &&
    typeof window.ethereum !== 'undefined';
  return {
    id: 'bitkeep-custom',
    name: 'Bitkeep',
    iconUrl: '/assets/icons/bitkeep-logo.svg',
    iconBackground: '#fff',
    downloadUrls: {
      ios: 'https://apps.apple.com/app/bitkeep-defi-wallet/id1395301115?l=en',
      android:
        'https://play.google.com/store/apps/details?id=com.bitkeep.wallet',
      qrCode: 'https://bitkeep.com/',
    },
    createConnector: () => {
      const connector = isBitkeepInjected
        ? new BitkeepConnector({ chains })
        : getWalletConnectConnector({ chains });

      return {
        connector,
        mobile: {
          getUri: async () => {
            const { uri } = (await connector.getProvider()).connector;
            return `bitkeep://?uri=${uri}`;
          },
        },
      };
    },
  };
};

export default BitkeepWallet;
