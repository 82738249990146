import { gql, useMutation } from '@apollo/client';

export const GO_TO_HYPERDOME = gql`
  mutation goToHyperdome($battleFlyIds: [Int!]!, $combatRate: Int!) {
    goToHyperdome(
      input: { battleFlyIds: $battleFlyIds, combatRate: $combatRate }
    ) {
      id
      location
      provingGroundsCombatRate
      hyperdomeCombatRate
      tattoos {
        id
      }
    }
  }
`;

export const GO_TO_PROVING_GROUNDS = gql`
  mutation goToProvingGrounds($battleFlyIds: [ID!]!, $combatRate: Int!) {
    goToProvingGrounds(
      input: { battleFlyIds: $battleFlyIds, combatRate: $combatRate }
    ) {
      id
      location
      provingGroundsCombatRate
      hyperdomeCombatRate
      tattoos {
        id
      }
    }
  }
`;

export const useGoToCombat = (
  hasHyperdomeMark: boolean
): [ReturnType<typeof useMutation>[0], boolean] => {
  const [goToHyperdome, { loading: loadingEnterHyperdome }] =
    useMutation(GO_TO_HYPERDOME);

  const [goToProvingGrounds, { loading: loadingEnterProving }] = useMutation(
    GO_TO_PROVING_GROUNDS
  );

  if (hasHyperdomeMark) {
    return [goToHyperdome, loadingEnterHyperdome];
  }
  return [goToProvingGrounds, loadingEnterProving];
};
