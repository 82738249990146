import React from 'react';

type IconProps = React.ComponentProps<'svg'>;

const ZipperIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.9175 7.16247C14.8552 7.03879 14.7598 6.93477 14.642 6.86196C14.5242 6.78915 14.3885 6.75039 14.25 6.74997H10.5V2.24997C10.5081 2.08548 10.4618 1.92292 10.3682 1.78737C10.2747 1.65182 10.1392 1.55083 9.98252 1.49997C9.83194 1.45043 9.66953 1.44987 9.51861 1.49838C9.3677 1.54689 9.23603 1.64197 9.14252 1.76997L3.14252 10.02C3.06734 10.1286 3.0222 10.2552 3.01167 10.3869C3.00113 10.5186 3.02557 10.6508 3.08252 10.77C3.13496 10.9063 3.22606 11.0243 3.34464 11.1096C3.46321 11.1949 3.6041 11.2436 3.75002 11.25H7.50002V15.75C7.50013 15.9081 7.55025 16.0622 7.64319 16.1902C7.73613 16.3181 7.86715 16.4134 8.01752 16.4625C8.09287 16.4858 8.17114 16.4985 8.25002 16.5C8.36836 16.5003 8.48509 16.4726 8.59067 16.4191C8.69626 16.3657 8.7877 16.288 8.85752 16.1925L14.8575 7.94247C14.9383 7.83057 14.9867 7.69854 14.9973 7.56091C15.0079 7.42329 14.9803 7.28542 14.9175 7.16247ZM9.00002 13.44V10.5C9.00002 10.3011 8.921 10.1103 8.78035 9.96964C8.6397 9.82899 8.44893 9.74997 8.25002 9.74997H5.25002L9.00002 4.55997V7.49997C9.00002 7.69888 9.07904 7.88965 9.21969 8.0303C9.36034 8.17095 9.55111 8.24997 9.75002 8.24997H12.75L9.00002 13.44Z" />
    </svg>
  );
};

export default ZipperIcon;
