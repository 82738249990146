import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { Combat } from '@battlefly/__generated__/globalTypes';
import {
  CORE_MOD_FIELDS,
  CORE_MOD_BLUEPRINT_FIELDS,
} from '@battlefly/graphql/fragments';
import { useGetBattleFlyCombatLogHistoryQuery } from '@battlefly/hooks/__generated__/useCombatLogsData';
import { mapPersonalizedCombatLog } from '@battlefly/lib/mapper/battlelog';

export const useCombatLogHistoryData = (battleFlyId: string, first = 10) => {
  const { data, loading, fetchMore } = useGetBattleFlyCombatLogHistoryQuery({
    skip: !battleFlyId,
    variables: {
      first,
      battleFlyId,
    },
  });

  const logs: any[] =
    data?.getBattleFly.battleFly.combatHistoryPaginated.edges
      .filter((edge) => dayjs().isAfter(dayjs(edge.node.matchTimestamp)))
      .map((edge) =>
      mapPersonalizedCombatLog(edge.node as Partial<Combat>, battleFlyId)
    ) ?? [];

  const endCursor =
    data?.getBattleFly.battleFly.combatHistoryPaginated.pageInfo.endCursor;

  const hasNextPage =
    data?.getBattleFly.battleFly.combatHistoryPaginated.pageInfo.hasNextPage;

  return {
    logs,
    location: data?.getBattleFly.battleFly.location,
    endCursor,
    hasNextPage,
    loading,
    fetchMore,
  };
};

gql`
  query GetBattleFlyCombatLogHistory(
    $battleFlyId: ID
    $first: Int
    $returnTotalCount: Boolean
    $after: String
  ) {
    getBattleFly(input: { battleFlyId: $battleFlyId }) {
      battleFly {
        location
        combatHistoryPaginated(
          first: $first
          returnTotalCount: $returnTotalCount
          after: $after
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              location
              createdAt
              matchTimestamp
              battleFlyLooser {
                id
                name
                appearance {
                  image
                }
                league
                placeInGlobalLeaderboard
                starsRate
                rarity
              }
              battleFlyWinner {
                id
                name
                appearance {
                  image
                }
                league
                placeInGlobalLeaderboard
                starsRate
                rarity
              }
              log {
                participants {
                  battleFlyId
                  weapons {
                    name
                    blueprint {
                      ...CoreModBlueprintFields
                    }
                  }
                  defense {
                    name
                    blueprint {
                      ...CoreModBlueprintFields
                    }
                  }
                  utilities {
                    name
                    blueprint {
                      ...CoreModBlueprintFields
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${CORE_MOD_BLUEPRINT_FIELDS}
  ${CORE_MOD_FIELDS}
`;
