import classnames from 'classnames';

import { HUNTERS_MARKS_DEFS } from 'common/battleflyMarks';
import { HuntersMarkType } from '@battlefly/__generated__/globalTypes';

type HuntersMarkProps = {
  className?: string;
  type: HuntersMarkType;
};

function getIcon(type: HuntersMarkType): React.ReactNode {
  const huntersMark = HUNTERS_MARKS_DEFS[type];

  if (!huntersMark) {
    throw new Error(`Invalid Hunters Mark Type: ${type}`);
  }

  return <huntersMark.icon />;
}

const HuntersMark = (props: HuntersMarkProps) => {
  const { type, className } = props;
  return (
    <span className={classnames('flex items-center', className)}>
      {getIcon(type)}
    </span>
  );
};

export default HuntersMark;
