import React from 'react';
import classnames from 'classnames';

import { Modal, Button, Card } from 'components/core';
import { ModalContext } from 'context/modalContext';
import { CloseIcon, GFlyIcon, MagicIcon, TroveIcon } from 'components/Icons';
import { useBridgeworldTreasures } from 'hooks/bridgeworld/useBridgeWorldGraph';
import { usePollForInventoryUpgrade } from 'hooks/game/usePollForInventoryUpgrade';
import Spinner from 'components/Spinner';
import TreasureOptionCard from './TreasureOptionCard';
import GFlyOptionCard from './GFlyOptionCard';
import MagicOptionCard from './MagicOptionCard';

const PuchaseInventorySlotModal = () => {
  const [activeFlow, setActiveFlow] = React.useState<'treasure' | 'gfly' | 'magic'>(null);
  const {
    purchaseInventorySlotModalState: battlefly,
    setPurchaseInventorySlotModalState,
    setConfirmModalState,
  } = React.useContext(ModalContext);
  const { data: treasures, loading: treasuresLoading } =
    useBridgeworldTreasures(!!battlefly);

  const { startPolling, success, isPolling, resetListener } =
    usePollForInventoryUpgrade(battlefly);

  React.useEffect(() => {
    if (success) {
      setConfirmModalState({
        type: 'success',
        title: 'Done',
        description: 'Your request has been actioned successfully',
        onConfirm: () => {
          setConfirmModalState(null);
          setPurchaseInventorySlotModalState(null);
        },
        confirmText: 'Continue',
      });
      resetListener();
      setActiveFlow(null);
    }
  }, [success]);

  if (!battlefly) return null;

  return (
    <Modal
      isOpen={true}
      onClose={() => setPurchaseInventorySlotModalState(null)}
    >
      {activeFlow === 'magic' && (
        <MagicOptionCard
          battleflyId={battlefly.id}
          onClose={() => setActiveFlow(null)}
          onPurchase={startPolling}
          isPolling={isPolling}
        />
      )}
      {activeFlow === 'treasure' && (
        <TreasureOptionCard
          loading={treasuresLoading}
          treasures={treasures}
          onClose={() => setActiveFlow(null)}
          battleflyId={battlefly.id}
          onPurchase={startPolling}
          isPolling={isPolling}
        />
      )}
      {activeFlow === 'gfly' && (
        <GFlyOptionCard
          battleflyId={battlefly.id}
          onClose={() => setActiveFlow(null)}
          onPurchase={startPolling}
          isPolling={isPolling}
        />
      )}

      {activeFlow === null && (
        <div className="relative h-full scroll-auto">
          <div
            className={classnames(
              'absolute left-[50%] top-[50%] -ml-12 -mt-12 h-12 w-12',
              {
                'pointer-events-none visible ': isPolling,
                invisible: !isPolling,
              }
            )}
          >
            <Spinner pending={isPolling} className="h-12 w-12" />
          </div>
          <Card
            className={classnames(
              'relative mb-16 h-full w-full pt-4 pb-12 shadow-none',
              {
                'opacity-40': isPolling,
              }
            )}
          >
            <header className="mb-4 flex flex-row items-start justify-between xl:mb-10">
              <div>
                <h1 className="text-left text-3xl">Take your Pick</h1>
                <p>
                  Each BattleFly starts with four inventory slots, and can add
                  more slots by burning Treasures or $gFLY.
                </p>
              </div>
              <div
                onClick={() => setPurchaseInventorySlotModalState(null)}
                className="cursor-pointer p-4"
              >
                <CloseIcon fill="#fff" />
              </div>
            </header>

            <div className="flex flex-col items-center gap-4">
              <div className="w-[480px] max-w-[100%] xl:w-1/2">
                <Card gfly>
                  <div className="flex flex-col items-center justify-center gap-6 text-center">
                    <MagicIcon className="h-12 w-12" />
                    <h2 className="text-2xl">Option 1: use Magic</h2>
                    <p>
                      Use 7 $Magic and get an inventory slot for this BattleFly
                      in return {' '}.
                    </p>
                    <div className="w-2/3">
                      <Button
                        transparent
                        light
                        onClick={() => setActiveFlow('magic')}
                      >
                        Use Magic
                      </Button>
                    </div>
                  </div>
              </Card>
              </div>
              <div className="flex flex-col gap-4 xl:flex-row">
              <Card treasure>
                <div className="flex flex-col items-center justify-center gap-6 text-center">
                  <TroveIcon stroke="#FF0000" className="h-12 w-12" />
                  <h2 className="text-2xl">Option 2: use Treasure</h2>
                  <p>
                    Use 2 treasures and get an inventory slot for this BattleFly
                    in return.
                  </p>
                  <div className="w-2/3">
                    <Button
                      transparent
                      light
                      onClick={() => setActiveFlow('treasure')}
                    >
                      Use Treasure
                    </Button>
                  </div>
                </div>
              </Card>

              <Card gfly>
                <div className="flex flex-col items-center justify-center gap-6 text-center">
                  <GFlyIcon className="h-12 w-12" />
                  <h2 className="text-2xl">Option 3: Burn $gFLY</h2>
                  <p>
                    Use 2 gFLY and get an inventory slot for this BattleFly in
                    return.
                  </p>
                  <div className="w-2/3">
                    <Button
                      transparent
                      light
                      onClick={() => setActiveFlow('gfly')}
                    >
                      Let&apos;s Burn it
                    </Button>
                  </div>
                </div>
              </Card>
              </div>
            </div>
          </Card>
        </div>
      )}
    </Modal>
  );
};

export default PuchaseInventorySlotModal;
