import React from 'react';

type IconProps = React.ComponentProps<'svg'>;

const WarningIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9255 17.2886C11.9022 16.7368 12.3305 16.2705 12.8823 16.2472C13.4341 16.2238 13.9004 16.6522 13.9237 17.204C13.9471 17.7558 13.5188 18.222 12.967 18.2454C12.4152 18.2688 11.9489 17.8404 11.9255 17.2886Z"
        fill="white"
      />
      <path
        d="M12.8083 14.4988C12.3945 14.5163 12.0448 14.195 12.0272 13.7812L11.8156 8.78567C11.7981 8.37184 12.1194 8.02213 12.5332 8.0046C12.947 7.98707 13.2968 8.30836 13.3143 8.72219L13.5259 13.7177C13.5434 14.1315 13.2221 14.4813 12.8083 14.4988Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0204 3.99262C13.9285 2.39496 11.5717 2.39495 10.4797 3.99262L10.0478 4.62457C7.34171 8.58378 5.01675 12.7904 3.10402 17.1881L3.0138 17.3955C2.33975 18.9453 3.35811 20.705 5.03766 20.8927C10.1633 21.4656 15.3368 21.4656 20.4625 20.8927C22.142 20.705 23.1604 18.9453 22.4863 17.3955L22.3961 17.1881C20.4834 12.7904 18.1584 8.58379 15.4524 4.62457L15.0204 3.99262ZM11.7181 4.83903C12.2144 4.11282 13.2857 4.11282 13.7821 4.83903L14.214 5.47098C16.8666 9.352 19.1456 13.4755 21.0206 17.7863L21.1108 17.9938C21.3822 18.6178 20.9721 19.3264 20.2959 19.402C15.281 19.9625 10.2192 19.9625 5.20426 19.402C4.52797 19.3264 4.11791 18.6178 4.38933 17.9938L4.47954 17.7863C6.35449 13.4755 8.63354 9.35199 11.2862 5.47098L11.7181 4.83903Z"
        fill="white"
      />
    </svg>
  );
};

export default WarningIcon;
