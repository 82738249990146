import React from 'react';

import { BattleFly } from '@battlefly/__generated__/globalTypes';
import { PaginatedList } from '@battlefly/ts/pagination';
import { BattleLog } from '@battlefly/ts/BattleLog';
import InfiniteList from 'components/InfiniteList';
import { BattleLogListItem, BattleLogHeader } from 'components/lists';
import SwitchInput from '@battlefly/components/Switch/SwitchInput';

type BattlelogPageProps = {
  logHistory: PaginatedList<BattleLog>;
  setFilter: (filter: 'user' | 'global') => void;
  filter: 'user' | 'global';
  loading: boolean;
};

const BattlelogPage = (props: BattlelogPageProps) => {
  const { logHistory, filter, setFilter, loading } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  async function handleFetchMore() {
    setIsLoading(true);
    try {
      await logHistory.fetchMore({
        variables: {
          first: 10,
          returnTotalCount: true,
          after: logHistory.endCursor,
        },
      });
    } finally {
      setIsLoading(false);
    }
  }
  if (!logHistory.items.length && !loading) {
    return <div>No battle logs found</div>;
  }
  return (
    <div>
      <header className="mb-8">
        <h1 className="text-3xl">Battlegrounds Battle Log</h1>
      </header>
      <section className="flex flex-col gap-2">
        <div className="relative min-h-[66px]">
          {logHistory.items.length > 0 && (
            <BattleLogHeader
              battlefly={logHistory.items[0].battleflies[0] as BattleFly}
            />
          )}
          <div className="absolute right-0 bottom-0 mr-1 flex gap-2">
            <div className="text-xs">
              View ONLY <br /> your BattleFlys
            </div>
            <SwitchInput
              value={filter === 'user'}
              onChange={(value) => setFilter(value ? 'user' : 'global')}
            />
          </div>
        </div>
        {loading && <div>Loading...</div>}
        {!loading && (
          <InfiniteList
            fetchMore={handleFetchMore}
            hasNextPage={logHistory.hasNextPage}
            isLoading={isLoading}
            endCursor={logHistory.endCursor}
            items={logHistory.items}
            renderItem={(log) => <BattleLogListItem log={log} />}
          />
        )}
      </section>
    </div>
  );
};

export default BattlelogPage;
