import classnames from 'classnames';

type InputProps = {
  className?: string;
  type: string;
  value: string;
  onChange: (val: string) => void;
};

const Input = (props: InputProps) => {
  const { className, onChange } = props;

  return (
    <input
      {...props}
      onChange={(e) => onChange(e.target.value)}
      className={classnames(
        'rounded-md p-2 text-[16px] text-[#718096]',
        className
      )}
    />
  );
};

export default Input;
