import React from 'react';

import { DrawerContext } from 'context/drawerContext';
import { ModalContext } from 'context/modalContext';
import { WalletTab } from 'ts/wallet';

const useNotEnoughMagicModal = () => {
  const { setShowBalanceDrawer } = React.useContext(DrawerContext);
  const { setConfirmModalState } = React.useContext(ModalContext);

  function showModal() {
    setConfirmModalState({
      type: 'error',
      title: 'Not enough Magic',
      description:
        'You have not enough Magic staked into the game - Top up now?',
      onConfirm: () => {
        setShowBalanceDrawer(true, WalletTab.Deposit);
        setConfirmModalState(null);
      },
      confirmText: 'Okay',
    });
  }

  return { showModal };
};

export default useNotEnoughMagicModal;
