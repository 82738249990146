import Button from '@battlefly/components/Buttons/Button';

export enum MintComicPageTabs {
  'Claim',
  'Mint',
}

const ComicMintTabs = ({
  activeTab,
  setActiveTab,
  tabDescription,
}: {
  activeTab: MintComicPageTabs;
  setActiveTab: any;
  tabDescription: string;
}) => {
  return (
    <div className="mb-4 w-full gap-4 border-core-green-muted/20 text-lg">
      <div className="flex gap-2">
        <Button
          onClick={() => setActiveTab(MintComicPageTabs.Claim)}
          className={`flex items-center rounded-md border-2 border-button-default bg-core-black ${
            activeTab === MintComicPageTabs.Claim && `border-button-focus`
          }`}
          type={''}
        >
          Claim
        </Button>
        <Button
          onClick={() => setActiveTab(MintComicPageTabs.Mint)}
          className={`flex items-center rounded-md border-2 border-button-default bg-core-black ${
            activeTab === MintComicPageTabs.Mint && `border-button-focus`
          }`}
          type={''}
        >
          Mint
        </Button>
      </div>
      <div className="mt-4 text-sm">{tabDescription}</div>
    </div>
  );
};

export default ComicMintTabs;
