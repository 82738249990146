import { useUnloadUnityGame } from '@battlefly/hooks/unityInitializer/useUnity';
import { useNavigate } from 'react-router';

const BattleflyIcon = () => {
  const { delayLink } = useUnloadUnityGame();
  const navigate = useNavigate();

  return (
    <div
      onClick={() => delayLink('/', navigate)}
      className="flex h-full items-center gap-1"
    >
      <img className="h-full" src="/assets/icons/battlefly-logo-2.png" />
    </div>
  );
};

export default BattleflyIcon;
