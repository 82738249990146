import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  createHttpLink,
} from '@apollo/client';
// END WAGMI
import { setContext } from '@apollo/client/link/context';
import { USER_REFRESH_TOKEN } from '@battlefly/graphql/userGql';
import {
  getGraphqlRefreshToken,
  getGraphqlToken,
  getGraphqlTokenExpiresAt,
  removeAuthCookie,
  updateGraphqlTokens,
} from '@battlefly/util/cookieManager';

import QueueLink from 'apollo-link-queue';
import cache, { cacheTypeDefs } from '@battlefly/graphql/cache/cache';
import fetch from 'cross-fetch';
import { TokenPair } from '@battlefly/__generated__/globalTypes';
import { DECREASE_REFRESH_TOKEN_DURATION_BY_SECONDS } from '@battlefly/common/constants';
// START WAGMI

const GRAPHQL_BASE_URL = process.env.NEXT_PUBLIC_GRAPHQL_URL;
const httpLink = createHttpLink({
  uri: GRAPHQL_BASE_URL,
  fetch,
});

const queueLink = new QueueLink();
let nowRefreshingToken = false;

const preAuthLink = setContext(async (rq, { headers }) => {
  const needRefreshTokenAt = getGraphqlTokenExpiresAt();
  // console.log('PRE____AUTH___LINK')
  // console.log(needRefreshTokenAt)
  if (
    rq.operationName != 'RefreshToken' &&
    rq.operationName != 'UserConnect' &&
    needRefreshTokenAt &&
    new Date() >= new Date(needRefreshTokenAt) &&
    !nowRefreshingToken
  ) {
    nowRefreshingToken = true;
    queueLink.close();
    const refreshToken = getGraphqlRefreshToken();
    await refetchToken(refreshToken);
  }

  return {
    headers,
  };
});

const authLink = setContext(async (rq, { headers }) => {
  const accessToken = getGraphqlToken();
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const client = new ApolloClient({
  uri: GRAPHQL_BASE_URL,
  link: ApolloLink.from([preAuthLink, queueLink, authLink, httpLink]),
  cache: cache,
  connectToDevTools: true,
  typeDefs: cacheTypeDefs,
});

const refetchToken = async (refreshToken: string) => {
  try {
    const refreshResolverResponse = await client.mutate({
      mutation: USER_REFRESH_TOKEN,
      variables: {
        refreshToken: refreshToken,
      },
      context: { skipQueue: true },
    });
    const tokens: TokenPair = refreshResolverResponse?.data?.refreshTokens;
    updateGraphqlTokens(tokens);
    queueLink.open();
    nowRefreshingToken = false;
  } catch (err) {
    console.log('Refresh Token ERROR');
    console.log(err);
    // we have to log the user out and clear state if there is a refresh token failure, because they must resign to get a new token.
    if (
      err instanceof ApolloError &&
      err.message !== 'Network request failed'
    ) {
      removeAuthCookie();
    }
    queueLink.open();
    nowRefreshingToken = false;
  }
};

export default client;
