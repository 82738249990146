import React from 'react';

type IconProps = React.ComponentProps<'svg'>;

interface StarIconProps extends IconProps {
  filled?: string;
}
const EmptyStar = (props: StarIconProps) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.5">
        <path
          d="M8.55553 1.60341L9.97636 5.93254L10.0893 6.27662H10.4514L15.0352 6.27662L11.3312 8.94086L11.0339 9.15471L11.1481 9.50268L12.5656 13.8217L8.84749 11.1473L8.55553 10.9373L8.26357 11.1473L4.54547 13.8217L5.96297 9.50268L6.07718 9.15471L5.77986 8.94086L2.07581 6.27662L6.65963 6.27662H7.02177L7.1347 5.93254L8.55553 1.60341Z"
          stroke="white"
        />
        <path
          d="M8.55553 1.60341L9.97636 5.93254L10.0893 6.27662H10.4514L15.0352 6.27662L11.3312 8.94086L11.0339 9.15471L11.1481 9.50268L12.5656 13.8217L8.84749 11.1473L8.55553 10.9373L8.26357 11.1473L4.54547 13.8217L5.96297 9.50268L6.07718 9.15471L5.77986 8.94086L2.07581 6.27662L6.65963 6.27662H7.02177L7.1347 5.93254L8.55553 1.60341Z"
          stroke="url(#paint0_linear_3041_1245)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3041_1245"
          x1="17.6066"
          y1="-2.10918"
          x2="-5.07853"
          y2="7.23611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const StarIcon = (props: StarIconProps) => {
  if (!props.filled) {
    return <EmptyStar {...props} />;
  }
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      data-testid="svg-StarIcon"
    >
      <path
        d="M8.44445 0L10.3403 5.77662L16.4756 5.77662L11.5121 9.34676L13.408 15.1234L8.44445 11.5532L3.48093 15.1234L5.37682 9.34676L0.413301 5.77662L6.54855 5.77662L8.44445 0Z"
        fill="url(#paint0_linear_3041_1241)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3041_1241"
          x1="17.4955"
          y1="-2.10918"
          x2="-5.18961"
          y2="7.23611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BF8FC" />
          <stop offset="0.0001" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default StarIcon;
