import React from 'react';

import { Button, Card } from '@battlefly/components/core';
import ModCompactDetails from '@battlefly/components/BattleflyDetails/Mods/ModCompactDetails';
import {
  Mod,
  ModBlueprintInModPack,
  BattleFly,
} from '@battlefly/__generated__/globalTypes';
import {
  filterLoadoutByModType,
  getEmptyInventorySlots,
} from 'util/battleflyInventory';
import { ArrowRight } from 'components/Icons';
import { MobileOnly, DesktopOnly } from 'components/utility';
import Chevron from 'components/Accordion/Chevron';
import ModLoadoutLabel from './ModLoadoutLabel';
import EmptySlot from './EmptySlot';
import ActionPanel from './EquipActionPanel';
import { ModalContext } from 'context/modalContext';

type AddModToInventoryPageProps = {
  battlefly: BattleFly;
  mod: ModBlueprintInModPack;
  modPackId: string;
  className?: string;
  handleSelectTarget: (target?: Mod) => void;
  goBack: () => void;
};

const AddModToInventoryPage = (props: AddModToInventoryPageProps) => {
  const [activeModId, setActiveModId] = React.useState<string | null>(null);
  const { setPurchaseInventorySlotModalState } = React.useContext(ModalContext);
  const { mod, battlefly, handleSelectTarget, goBack } = props;
  const loadout = filterLoadoutByModType(battlefly, mod);
  const { inventory } = battlefly;
  const numberOfEmptySlots = getEmptyInventorySlots(battlefly);

  function handlePuchaseInventorySlot() {
    setPurchaseInventorySlotModalState(battlefly);
  }

  return (
    <div className="max-w-[100%] xl:pr-8 xl:pt-16">
      <section className="xl:flex">
        <header className="p-4 xl:pr-12 xl:pl-8">
          <div className="font-heading text-4xl leading-[40px] text-[#5BF8FC]">
            Add Mod
          </div>
          <div className="mb-8 text-xl xl:mb-16">Add the mod</div>
          <div className="mb-6 flex justify-center xl:static xl:max-w-[300px]">
            <ModCompactDetails
              className="cursor-pointer"
              highlight
              mod={
                {
                  id: mod.id,
                  name: mod.name,
                  type: mod.type,
                  rarity: mod.rarity,
                  blueprint: mod,
                } as any as Mod
              }
            />
          </div>
          <DesktopOnly xl>
            <Button light transparent onClick={goBack}>
              <div className="relative">
                <Chevron
                  fill="#fff"
                  className="absolute -left-10 h-6 w-6 rotate-180"
                />
                <span>Go Back to mod pack</span>
              </div>
            </Button>
          </DesktopOnly>
        </header>
        <div className="max-width-loadout-section mt-8">
          <Card
            containerClassName="bg-xl-and-up"
            className="bg-xl-and-up min-h-[500px] !p-0"
          >
            <header className="flex justify-between p-8 py-2 xl:py-8">
              <div>
                <h2 className="xl:text-normal text-2xl">
                  Mod Category: {mod.type}{' '}
                </h2>
                <p className="leading-[19px]">
                  If you run out of mod slots, you can purchase an additional
                  one for treasures or magic.
                </p>
                <MobileOnly xl className="mt-4">
                  <ArrowRight />
                </MobileOnly>
              </div>
              <DesktopOnly xl className="flex min-w-[250px]">
                <Button blueMint onClick={handlePuchaseInventorySlot}>
                  Purchase Mod Slot
                </Button>
              </DesktopOnly>
            </header>
            <div className="flex overflow-x-scroll pb-8 pt-4">
              <ul className="ml-8 mr-8 flex gap-8">
                {numberOfEmptySlots > 0 && (
                  <li className="h-[370px] w-[290px]">
                    <EmptySlot
                      onClick={() => handleSelectTarget()}
                      n={numberOfEmptySlots}
                    />
                  </li>
                )}
                {loadout.map((targetMod, i) => (
                  <li key={targetMod.id} className="relative">
                    <ModCompactDetails
                      onClick={() => setActiveModId(targetMod.id)}
                      className="cursor-pointer"
                      mod={
                        {
                          ...targetMod,
                          blueprint: targetMod.blueprint || targetMod,
                        } as Mod
                      }
                    />
                    <ModLoadoutLabel mod={targetMod} index={i + 1} />
                    {activeModId === targetMod.id && (
                      <ActionPanel
                        showWarning={
                          battlefly.inventory.length ===
                          battlefly.slotsAvailable
                        }
                        isEquipping={false}
                        className="absolute bottom-[50px] left-0 z-50 w-full"
                        handleClose={() => setActiveModId(null)}
                        handleEquip={() => handleSelectTarget(targetMod)}
                      />
                    )}
                  </li>
                ))}
                {inventory.map((targetMod) => (
                  <li key={targetMod.id} className="relative">
                    <ModCompactDetails
                      onClick={() => setActiveModId(targetMod.id)}
                      className="cursor-pointer"
                      mod={
                        {
                          ...targetMod,
                          blueprint: targetMod.blueprint || targetMod,
                        } as Mod
                      }
                    />

                    {activeModId === targetMod.id && (
                      <ActionPanel
                        isEquipping={false}
                        className="absolute bottom-[50px] left-0 z-50 w-full"
                        handleClose={() => setActiveModId(null)}
                        handleEquip={() => handleSelectTarget(targetMod)}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </Card>
          <MobileOnly xl className="mb-12 flex justify-center">
            <div className="flex min-w-[300px] flex-col gap-4">
              <Button onClick={handlePuchaseInventorySlot} blueMint>
                Purchase Mod Slot
              </Button>
              <Button light transparent onClick={goBack}>
                <div className="relative">
                  <Chevron
                    fill="#fff"
                    className="absolute -left-10 h-6 w-6 rotate-180"
                  />
                  <span>Go Back to mod pack</span>
                </div>
              </Button>
            </div>
          </MobileOnly>
        </div>
      </section>
    </div>
  );
};

export default AddModToInventoryPage;
