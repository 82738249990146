import { ApolloClient, gql, InMemoryCache, useQuery } from '@apollo/client';
import { useAccount } from 'wagmi';

import { TreasureToken } from 'ts/bridgeworld';

const apolloClient = new ApolloClient({
  uri: process.env.NEXT_PUBLIC_BRIDGEWORLD_GRAPHQL_URL,
  cache: new InMemoryCache(),
});

type UserToken = {
  quantity: number;
  token: {
    id: string;
    tokenId: string;
    image: string;
    rarity: string;
    name: string;
  };
};

type BridgeWordGraphResponse = {
  user: {
    tokens: UserToken[];
  };
};

// 0xebba467ecb6b21239178033189ceae27ca12eadf treasures arbitrum

const query = gql`
  query GetTreasures(
    $userAddress: String!
    $treasuresContractAddress: String!
  ) {
    user(id: $userAddress) {
      tokens(where: { token_contains: $treasuresContractAddress }) {
        quantity
        token {
          id
          tokenId
          image
          rarity
          name
        }
      }
    }
  }
`;

const mapData = (data: BridgeWordGraphResponse): TreasureToken[] => {
  const { user } = data;

  if (!user) {
    return [];
  }

  return user.tokens.map((userToken: UserToken) => ({
    id: userToken.token.id,
    tokenId: userToken.token.tokenId,
    imageUrl: userToken.token.image.replace(
      'ipfs://',
      'https://treasure-marketplace.mypinata.cloud/ipfs/'
    ),
    rarity: userToken.token.rarity,
    name: userToken.token.name,
    quantity: userToken.quantity,
  }));
};

export const useBridgeworldTreasures = (
  enabled: boolean
): {
  data: TreasureToken[];
  loading: boolean;
} => {
  const { address } = useAccount();
  const { loading, data } = useQuery(query, {
    client: apolloClient,
    fetchPolicy: 'network-only',
    variables: {
      userAddress: address?.toLowerCase(),
      treasuresContractAddress:
        process.env.NEXT_PUBLIC_TREASURES_CONTRACT_ADDRESS,
    },
    skip: !enabled,
  });
  if (!data) {
    return {
      data: [],
      loading,
    };
  }
  return {
    data: mapData(data),
    loading,
  };
};
