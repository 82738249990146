import { useEffect } from 'react';
import { useNavigate } from 'react-router';

export default function useUnloadUnityOnAccountChange({
  unload,
}: {
  unload: () => Promise<void>;
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (window && window.ethereum) {
      window.addEventListener('load', function () {
        window.ethereum.on('accountsChanged', function () {
          unload().then(() => {
            navigate(0);
          });
        });
      });
    }
  }, [navigate, unload]);
}
