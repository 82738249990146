import React from 'react';
import { IconProps } from '@battlefly/components/Icons/index';

const UserProfile: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.96774 6.0074C4.96774 2.68961 7.66848 0 11 0C14.3315 0 17.0323 2.68961 17.0323 6.0074C17.0323 9.3252 14.3315 12.0148 11 12.0148C7.66848 12.0148 4.96774 9.3252 4.96774 6.0074ZM11 2.12026C8.84428 2.12026 7.09677 3.8606 7.09677 6.0074C7.09677 8.15421 8.84428 9.89455 11 9.89455C13.1557 9.89455 14.9032 8.15421 14.9032 6.0074C14.9032 3.8606 13.1557 2.12026 11 2.12026Z"
        fill="#646B7F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.32258 16.2553C3.55883 16.2553 2.12903 17.6793 2.12903 19.4357V21.1154C2.12903 21.141 2.14764 21.1627 2.17299 21.1668C8.01896 22.1174 13.9811 22.1174 19.827 21.1668C19.8524 21.1627 19.871 21.141 19.871 21.1154V19.4357C19.871 17.6793 18.4411 16.2553 16.6774 16.2553H16.1936C16.1562 16.2553 16.119 16.2613 16.0834 16.2727L14.855 16.6723C12.3501 17.4868 9.64991 17.4868 7.145 16.6723L5.91654 16.2727C5.88098 16.2613 5.8438 16.2553 5.8064 16.2553H5.32258ZM0 19.4357C0 16.5082 2.383 14.1351 5.32258 14.1351H5.8064C6.06825 14.1351 6.32846 14.1763 6.57739 14.2572L7.80587 14.6568C9.88141 15.3316 12.1186 15.3316 14.1941 14.6568L15.4226 14.2572C15.6715 14.1763 15.9317 14.1351 16.1936 14.1351H16.6774C19.617 14.1351 22 16.5082 22 19.4357V21.1154C22 22.18 21.2252 23.0878 20.17 23.2594C14.0969 24.2469 7.90311 24.2469 1.82993 23.2594C0.774797 23.0878 0 22.18 0 21.1154V19.4357Z"
        fill="#646B7F"
      />
    </svg>
  );
};

export default UserProfile;
