import React from 'react';
import {
  BattleFly,
  BattleFlyLeague,
} from '@battlefly/__generated__/globalTypes';
import { Card, Button, Link } from 'components/core';
import { numberToRoman } from '@battlefly/util/helpers';
import { getLadderProgress } from '@battlefly/util/getLadderProgress';

import LeagueStatus from './LeagueStatus';
import LeagueProgressPath from './LeagueProgressPath';
import { LEAGUES_BLOCKS } from './config';

type LeagueTrackPageProps = {
  className?: string;
  battleflies: BattleFly[];
  battleflyId: string;
  leagueData: any;
};

type ProgressBarProps = {
  color: string;
  progress: number;
};

const ProgressBar = ({ color, progress }: ProgressBarProps) => {
  return (
    <div className="rounded-[20px] bg-[rgba(217,217,217,0.25)] p-0">
      <div
        className="h-6 rounded-[15px]"
        style={{
          backgroundColor: color,
          width: `${progress}%`,
        }}
      />
    </div>
  );
};

const LeagueTrackPage = (props: LeagueTrackPageProps) => {
  const { battleflies, battleflyId, leagueData } = props;

  const defaultSelectedBattlefly = battleflies.find(
    (battlefly: BattleFly) => Number(battlefly.id) === Number(battleflyId)
  );

  const [selectedBattlefly, setSelectedBattlefly] = React.useState<BattleFly>(
    defaultSelectedBattlefly
  );
  const currentLeagueParams = LEAGUES_BLOCKS.find(
    (x) => x.league === selectedBattlefly?.league
  );

  const handleChangeBattlefly = (newBfId: number) => {
    setSelectedBattlefly(
      battleflies.find(
        (battlefly: BattleFly) => Number(battlefly.id) === newBfId
      )
    );
  };
  return (
    <div>
      <div className="flex flex-col gap-6">
        <header>
          <div className="mb-2 font-heading text-5xl">BATTLEFLY LEAGUES</div>
          <div className="flex flex-col justify-between md:flex-row">
            <div className="custom-arrow-appearance-v-container h-[42px]">
              <select
                className="no-arrow-appearance custom-arrow-appearance-v h-[42px] w-[340px] rounded-xl pl-8 text-core-black"
                onChange={(e) => handleChangeBattlefly(Number(e.target.value))}
              >
                {battleflies.map((x: BattleFly) => (
                  <option
                    key={x.id}
                    value={x.id}
                    selected={x.id === selectedBattlefly.id}
                  >
                    {x.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="buttons flex flex-col justify-between gap-4 md:flex-row">
              <div className="w-[250px]">
                <Button transparent light>
                  <Link to={`/battleflies/view/${battleflyId}`}>
                    Back to BattleFly View
                  </Link>
                </Button>
              </div>
              <div className="w-[250px]">
                <Button blueMint>
                  <Link to={'/battleflies'}>Go to Mission Control</Link>
                </Button>
              </div>
            </div>
          </div>
        </header>

        <section className="flex grid-flow-col auto-rows-max grid-cols-8 flex-col gap-4 md:grid">
          <div className="col-span-3">
            <div className="gra-bf-card-border box-shadow-card rounded-2xl p-[2px]">
              <div className="gra-bf-card relative rounded-2xl p-6">
                <div className="flex flex-col gap-8">
                  <div className="font-heading text-3xl">
                    Current League Status
                  </div>
                  <div className="flex gap-12">
                    <div className="mb-4 text-2xl">
                      Rank:{' '}
                      <span style={{ color: currentLeagueParams.color }}>
                        {selectedBattlefly.league.toString()}{' '}
                        {selectedBattlefly.league !== BattleFlyLeague.Larvae &&
                          numberToRoman(selectedBattlefly.leagueTier)}
                      </span>
                    </div>
                    <div className="text-2xl">
                      CP: {selectedBattlefly.contestPoints}
                    </div>
                  </div>
                  <div className="flex flex-col gap-6">
                    <ProgressBar
                      progress={getLadderProgress(
                        selectedBattlefly,
                        currentLeagueParams
                      )}
                      color={currentLeagueParams.color}
                    />
                    <LeagueStatus
                      items={currentLeagueParams.progressElements}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-5">
            <LeagueProgressPath
              leagueData={leagueData}
              currentLeague={selectedBattlefly.league}
            />
          </div>
        </section>

        <section>
          <Card flat>
            <ul className="mb-12 flex list-disc flex-col gap-1 leading-4 leading-[19px] mobile:leading-6">
              <li>
                Larvae League in the proving grounds is the starting point for
                all BattleFlys.
              </li>
              <li>
                By achieving a Win/Loss record of 60% W/L after a minimum of 20
                battles, or getting 5 wins in a row, a BattleFly will promote
                from Larvae to the Hyperdome leagues.
              </li>
              <li>
                The rewards for playing are significantly richer than the Larvae
                league in the proving grounds.
              </li>
              <li>
                Each of the Hyperdome leagues consists of three ranks, “III”
                being lowest and “I” highest.
              </li>
              <li>
                The top 1% BattleFly of each rank win rewards in Magic, every
                24h.
              </li>
              <li>
                When you surpass the top Rank I of your League, you are promoted
                to the next League, at rank III. Each league - and each rank
                within a league - offer greater daily rewards in Magic.
              </li>
              <li>
                Battlefly can be demoted down to Pupa III but can never go back
                to Larvae League and have access to Proving Grounds once
                qualified for Hyperdome.
              </li>
            </ul>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default LeagueTrackPage;
