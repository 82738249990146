import React from 'react';
import { IconProps } from '@battlefly/components/Icons';

const MarkEnergy: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="125"
      height="122"
      viewBox="0 0 125 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M124.483 53.7529L88.3584 34.644L86.0349 35.8327L85.771 34.3574L42.0407 12.6063L29.5657 24.6604L24.1322 14.3362L20.6715 14.2128L15.4809 21.0716L4.48746 0L0.403564 2.10091L8.57214 42.1432L10.5731 43.0133L5.71755 47.7048L11.4025 84.905L20.3201 89.456L13.8963 95.7087L14.7585 99.0624L22.8084 102.096L5.71755 118.613L8.89001 121.933L44.9742 102.747L45.0562 102.08L55.4764 107.398L92.3533 71.1866L90.7874 62.4276L99.9658 64.32L102.258 61.7247L100.253 53.3601L123.513 58.243L124.483 53.7529Z"
        fill="url(#paint0_linear_1_1122)"
      />
      <path
        d="M96.5176 49.7299L121.048 54.7698L87.9726 37.7906L90.4266 49.4043L85.6827 48.2905L84.0488 36.9885L42.4359 15.8685L29.2041 28.5576L22.2788 16.7151L15.049 26.2622L3.36984 4.10886L11.2487 40.4437L18.6643 31.1747L21.169 36.2646L8.57214 48.3436L13.4597 82.148L24.9843 87.997L16.704 96.8123L27.9091 101.039L9.97561 118.52L42.5859 100.663L31.5947 96.1796L34.9718 93.0666L55.0718 103.268L88.8731 70.3595L87.2971 59.4566L99.3043 61.3771L96.5176 49.7299ZM54.5915 99.9457L16.5127 80.0846L18.9291 65.3665L39.29 62.4229L39.2884 62.4112L45.5365 61.5075L45.5381 61.5193L46.0731 61.4419C46.7361 62.4596 47.9498 63.0571 49.2315 62.872C50.9911 62.6174 52.211 60.9851 51.9564 59.2254C51.7018 57.4658 50.0695 56.2459 48.3099 56.5005C46.8377 56.7137 45.7435 57.8915 45.5748 59.302L45.2249 59.3527L44.0057 59.5293L41.9142 59.8315L38.976 60.2564L18.8502 63.1664L12.0211 49.0262L42.8944 19.048L81.3629 39.0004L78.2631 55.3032L85.7702 69.4824L54.5915 99.9457Z"
        fill="url(#paint1_linear_1_1122)"
      />
      <path
        d="M55.5092 58.6451C55.4983 58.5701 55.4842 58.4959 55.4709 58.4217L70.2781 52.1135L69.4588 50.1899L54.793 56.438C54.5806 56.035 54.3307 55.657 54.0471 55.3079L64.1339 42.925L62.5133 41.6043L52.4937 53.9036C52.1407 53.6709 51.7666 53.4694 51.3753 53.3038L54.283 37.7672L52.2282 37.3821L49.3431 52.7961C48.8308 52.7493 48.3052 52.7594 47.7741 52.8328L43.3544 37.775L41.3479 38.3639L45.7708 53.4319C45.3155 53.649 44.8922 53.9153 44.5017 54.2207L33.4402 42.8664L31.9423 44.3253L43.0685 55.746C42.7834 56.1607 42.5452 56.6059 42.3562 57.0753L27.2992 51.9394L26.6244 53.9184L41.5159 58.9981L44.058 58.6303C44.4649 56.7355 46.0106 55.2001 48.0381 54.9064C50.6404 54.53 53.0639 56.3411 53.4395 58.9435C53.816 61.5458 52.0048 63.9692 49.4025 64.3449C47.5671 64.6105 45.8223 63.7865 44.8211 62.3658L42.5858 62.6892C42.6624 62.8422 42.7428 62.993 42.8303 63.139L29.6258 73.2023L30.8934 74.8651L44.1674 64.7495C44.4767 65.0236 44.8094 65.2712 45.1624 65.486L37.7428 80.4626L39.6164 81.3904L47.0985 66.2873C47.3531 66.3498 47.6124 66.3974 47.8756 66.4302L48.7035 83.0649L50.7919 82.961L49.9664 66.3677C50.4264 66.2834 50.8677 66.1537 51.2871 65.9842L59.4267 80.6125L61.2535 79.5957L53.0842 64.9135C53.3036 64.7323 53.5098 64.5363 53.7043 64.3301L67.7546 73.2461L68.8746 71.481L54.8938 62.6095C55.0554 62.276 55.1898 61.9277 55.2968 61.5684L71.341 63.0234L71.53 60.9413L55.5779 59.4948C55.5709 59.2152 55.5506 58.931 55.5092 58.6451Z"
        fill="url(#paint2_linear_1_1122)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_1122"
          x1="-1.65861"
          y1="4.57234"
          x2="74.2857"
          y2="97.4116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#434343" />
          <stop offset="0.4888" stopColor="#3B3C3B" />
          <stop offset="1" stopColor="#4A494A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_1122"
          x1="3.36948"
          y1="61.3143"
          x2="121.048"
          y2="61.3143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_1122"
          x1="26.6244"
          y1="60.2249"
          x2="71.5286"
          y2="60.2249"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DFEBF8" />
          <stop offset="0.5" stopColor="#F9D1DA" />
          <stop offset="1" stopColor="#E0E6F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MarkEnergy;
